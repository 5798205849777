<template>
  <!-- Here are NONE DDI cases -->
  <div class="card mb-3" :class="caseItem.className4CardBg">
    <div class="card-header" :class="[`cardHeader--${caseItem.state_display}`]">
      <span class="card__progress">
        <i :class="caseItem.className4Progress"></i>
        {{ caseItem.progress }}
      </span>
      <small v-if="caseItem.state == 2" style="float: right">{{
        caseItem.qa_case_id
      }}</small>
      <button
        v-if="caseItem.state == 0"
        type="button"
        class="btn btn-outline-light position-relative float-end"
        style="
          --bs-btn-padding-y: 0.25rem;
          --bs-btn-padding-x: 0.5rem;
          --bs-btn-font-size: 0.75rem;
        "
        @click.stop="showCommentPopup(caseItem.Alert_id)"
      >
        <span
          v-if="caseItem.CommentCount > 0"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
        >
          {{ caseItem.CommentCount }}
          <span class="visually-hidden">Has comments</span>
        </span>
        <i class="bi bi-chat-left-dots me-1"></i>備註
      </button>
      <!-- <div
        v-if="caseItem.state_display == 'unconfirmed'"
        class="crossShadowRibbon"
      >
        {{ caseState }}
      </div> -->
      <!-- <div class="crossShadowRibbon crossShadowRibbon--secondary">IR</div> -->
    </div>
    <router-link
      :to="{ name: caseToPath, params: { caseId: caseItem.Alert_id } }"
      target="_blank"
    >
      <div class="card__wrapper" style="padding-bottom: 0">
        <div class="cardContent">
          <!-- <strong class="cardContent__customerName">中華航空</strong> -->
          <small>{{ caseItem.WRCaseId }}</small>
          <p class="cardContent__desc van-multi-ellipsis--l2">
            {{ caseItem.case_subject }}
          </p>
          <ul class="cardItemlist">
            <li class="cardItemList__row">
              <i class="bi bi-person-fill-gear"></i>
              {{ caseItem.Username ? caseItem.Username : "-" }}
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-person-workspace"></i>
              {{ caseItem.engineer1_name ? caseItem.engineer1_name : "-" }}
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-bookmarks-fill"></i>
              <span class="van-ellipsis" style="width: 77%">
                {{ caseItem.customer_short_name }}
              </span>
              <span class="badge text-bg-light float-end">
                {{ caseItem.priority_display }}
              </span>
              <!-- {{ caseItem.consult }} -->
              <!-- <br /> -->
              <!-- <span class="cardItemList__subRow">
                {{ caseItem.issue_type ? caseItem.issue_type : "-" }}
                <template v-if="caseItem.issue_subtype"
                  >/ {{ caseItem.issue_subtype }}</template
                >
              </span> -->
            </li>
            <!-- <li class="cardItemList__row">
              <i class="bi bi-bookmark-star-fill"></i>
              {{ caseItem.service_type }}
            </li> -->
            <li class="cardItemList__row">
              <i class="bi bi-box-seam-fill"></i>
              <a :href="caseItem.Link" target="_blank">
                {{ caseItem.source_name ? caseItem.source_name : "-" }}
                <i class="bi bi-box-arrow-up-right" style="font-size: 15px"></i>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </div>
    </router-link>
    <div class="card__wrapper" style="padding-top: 0">
      <div class="cardFooter">
        <div class="cardFooter__brick pe-2">
          <strong class="cardFooter__label">告警時間</strong>
          <span class="cardFooter__time">{{ caseItem.create_time }}</span>
        </div>
        <div class="cardFooter__brick ps-2">
          <strong v-if="caseItem.state == 0" class="cardFooter__label"
            >更新時間</strong
          >
          <strong v-if="caseItem.state == 1" class="cardFooter__label"
            >取消時間</strong
          >
          <strong v-if="caseItem.state == 2" class="cardFooter__label"
            >建案時間</strong
          >
          <span class="cardFooter__time">{{ caseItem.update_time }}</span>
        </div>
        <div class="cardFooter__brick pe-2 mt-2">
          <button
            v-if="caseItem.state_display == 'unconfirmed'"
            type="button"
            class="btn btn-danger"
            @click.stop="showCancelCasePopup()"
          >
            取消案件
          </button>
        </div>
        <div class="cardFooter__brick ps-2 mt-2">
          <router-link
            :to="{ name: caseToPath, params: { caseId: caseItem.Alert_id } }"
            target="_blank"
          >
            <button
              v-if="caseItem.state_display == 'unconfirmed'"
              type="button"
              class="btn btn-primary"
            >
              建立案件
            </button>
          </router-link>
          <!-- <button
            v-if="caseItem.state_display == 'confirmed'"
            type="button"
            class="btn btn-primary"
          >
            開始處理
          </button>
          <button
            v-if="caseItem.state_display == 'established'"
            type="button"
            class="btn btn-primary"
          >
            完成案件
          </button> -->
        </div>
      </div>
    </div>
    <div class="card-arrow">
      <div class="card-arrow-top-left"></div>
      <div class="card-arrow-top-right"></div>
      <div class="card-arrow-bottom-left"></div>
      <div class="card-arrow-bottom-right"></div>
    </div>
  </div>

  <van-popup
    v-model:show="popupCancelCase"
    class="popup"
    round
    style="width: 500px"
  >
    <div class="popup__header">取消案件？</div>
    <div class="popup__content">
      取消案件後，案件會標記為 False Alert，並儲存狀態。
      <textarea
        class="form-control mt-3"
        v-model="cancelReason"
        rows="5"
        placeholder="輸入原因"
      ></textarea>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupCancelCase = false"
      >
        不取消案件
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="!cancelReason"
        @click.stop="handleSendCancelCaseRequest()"
      >
        取消案件
      </button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupComment"
    class="popup"
    round
    style="width: 500px"
  >
    <div class="popup__header">備註 / Comment</div>
    <div class="popup__content">
      <!-- caseComment: {{ caseComment }} -->
      <textarea
        class="form-control mt-3"
        v-model="caseComment"
        rows="5"
        placeholder="輸入備註"
      ></textarea>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupComment = false"
      >
        不備註
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="!caseComment"
        @click.stop="handleSendAlertComment()"
      >
        送出備註
      </button>
    </div>
  </van-popup>

  <!--
  <router-link :to="{ name: caseToPath, params: { caseId: 'Q202206300013' } }">
    <div class="card" style="display: none">
      <div class="card-header cardHeader--preparing">
        <span class="card__progress">
          <i class="bi bi-list-task"></i>
          案件準備
        </span>
        <div class="crossShadowRibbon">待確認</div>
      </div>
      <div class="card__wrapper">
        <div class="cardContent">
          <p class="cardContent__desc">
            CHINA-AIRLINES | High | 連線已知 APT惡意中繼站 | Q202206300013
          </p>
          <ul class="cardItemlist">
            <li class="cardItemList__row">
              <i class="bi bi-person-fill-gear"></i>
              未指定
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-bookmarks-fill"></i>
              APT 連線威脅事件<br />
              <span class="cardItemList__subRow">連線已知 APT 惡意中繼站</span>
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-box-seam-fill"></i>
              Deep Discovery Inspector 5.6
            </li>
          </ul>
        </div>
        <hr />
        <div class="cardFooter">
          <div class="cardFooter__brick pe-2">
            <strong class="cardFooter__label">建立時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick ps-2">
            <strong class="cardFooter__label">更新時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick pe-2 mt-2">
            <button type="button" class="btn btn-danger">取消案件</button>
          </div>
          <div class="cardFooter__brick ps-2 mt-2">
            <button type="button" class="btn btn-primary">建立案件</button>
          </div>
        </div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </router-link>

  <router-link :to="{ name: caseToPath, params: { caseId: 'Q202206300013' } }">
    <div class="card" style="display: none">
      <div class="card-header cardHeader--preparing">
        <span class="card__progress">
          <i class="bi bi-list-task"></i>
          案件準備
        </span>
      </div>
      <div class="card__wrapper">
        <div class="cardContent">
          <p class="cardContent__desc">
            CHINA-AIRLINES | High | 連線已知 APT惡意中繼站 | Q202206300013
          </p>
          <ul class="cardItemlist">
            <li class="cardItemList__row">
              <i class="bi bi-person-fill-gear"></i>
              Goat_楊典哲
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-bookmarks-fill"></i>
              APT 連線威脅事件<br />
              <span class="cardItemList__subRow">連線已知 APT 惡意中繼站</span>
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-box-seam-fill"></i>
              Deep Discovery Inspector 5.6
            </li>
          </ul>
        </div>
        <hr />
        <div class="cardFooter">
          <div class="cardFooter__brick pe-2">
            <strong class="cardFooter__label">建立時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick ps-2">
            <strong class="cardFooter__label">更新時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick pe-2 mt-2 invisible">
            <button type="button" class="btn btn-danger">取消案件</button>
          </div>
          <div class="cardFooter__brick ps-2 mt-2">
            <button type="button" class="btn btn-primary">開始處理</button>
          </div>
        </div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </router-link>

  <router-link :to="{ name: caseToPath, params: { caseId: 'Q202206300013' } }">
    <div class="card" style="display: none">
      <div class="card-header cardHeader--inprogress">
        <span class="card__progress">
          <i class="bi bi-wrench-adjustable-circle"></i>
          案件進行
        </span>
      </div>
      <div class="card__wrapper">
        <div class="cardContent">
          <p class="cardContent__desc">
            [MxDR Alert] ATEN | High | Possible Credential Dumping from Web
            Browsers |
          </p>
          <ul class="cardItemlist">
            <li class="cardItemList__row">
              <i class="bi bi-person-fill-gear"></i>
              Goat_楊典哲
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-bookmarks-fill"></i>
              APT 連線威脅事件<br />
              <span class="cardItemList__subRow">連線已知 APT 惡意中繼站</span>
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-box-seam-fill"></i>
              Deep Discovery Inspector 5.6
            </li>
          </ul>
        </div>
        <hr />
        <div class="cardFooter">
          <div class="cardFooter__brick pe-2">
            <strong class="cardFooter__label">建立時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick ps-2">
            <strong class="cardFooter__label">更新時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick pe-2 mt-2 invisible">
            <button type="button" class="btn btn-danger">取消案件</button>
          </div>
          <div class="cardFooter__brick ps-2 mt-2">
            <button type="button" class="btn btn-primary">完成案件</button>
          </div>
        </div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </router-link>

  <router-link :to="{ name: caseToPath, params: { caseId: 'Q202206300013' } }">
    <div class="card" style="display: none">
      <div class="card-header cardHeader--closed">
        <span class="card__progress">
          <i class="bi bi-shield-check"></i>
          案件完成
        </span>
        <div class="crossShadowRibbon crossShadowRibbon--irCase">IR</div>
      </div>
      <div class="card__wrapper">
        <div class="cardContent">
          <p class="cardContent__desc">
            [MxDR Alert]Quantatw | [中] | 存取或查詢C&C惡意中繼站或網站
          </p>
          <ul class="cardItemlist">
            <li class="cardItemList__row">
              <i class="bi bi-person-fill-gear"></i>
              Goat_楊典哲
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-bookmarks-fill"></i>
              APT 連線威脅事件<br />
              <span class="cardItemList__subRow">連線已知 APT 惡意中繼站</span>
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-box-seam-fill"></i>
              Deep Discovery Inspector 5.6
            </li>
          </ul>
        </div>
        <hr />
        <div class="cardFooter">
          <div class="cardFooter__brick pe-2">
            <strong class="cardFooter__label">建立時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick ps-2">
            <strong class="cardFooter__label">更新時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
        </div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </router-link>

  <router-link :to="{ name: caseToPath, params: { caseId: 'Q202206300013' } }">
    <div class="card card--canceled" style="display: none">
      <div class="card-header cardHeader--cancel">
        <span class="card__progress">
          <i class="bi bi-x-octagon"></i>
          案件取消
        </span>
      </div>
      <div class="card__wrapper">
        <div class="cardContent">
          <p class="cardContent__desc">
            [MxDR Alert] BOT | Medium | IOC Sweeping | Q202304190008
          </p>
          <ul class="cardItemlist">
            <li class="cardItemList__row">
              <i class="bi bi-person-fill-gear"></i>
              Goat_楊典哲
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-bookmarks-fill"></i>
              Intelligence -Sweeping/SPN<br />
              <span class="cardItemList__subRow">CTI Threat Hunting</span>
            </li>
            <li class="cardItemList__row">
              <i class="bi bi-box-seam-fill"></i>
              Deep Discovery Inspector 6.0
            </li>
          </ul>
        </div>
        <hr />
        <div class="cardFooter">
          <div class="cardFooter__brick pe-2">
            <strong class="cardFooter__label">建立時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
          <div class="cardFooter__brick ps-2">
            <strong class="cardFooter__label">更新時間</strong>
            <span class="cardFooter__time">04/10 14:03</span>
          </div>
        </div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </router-link>
  -->
</template>

<script>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import formHelper from "../formHelper";
export default {
  name: "TicketCard",
  props: ["caseItem"],
  setup(props) {
    const store = useStore();
    const popupCancelCase = ref(false);
    const popupComment = ref(false);
    const cancelReason = ref(null);
    const caseComment = ref(null);
    const singleSelectedAlertId = ref(null);
    const newCaseRecord2Send = reactive({
      state: 2,
      draft: "",
      owner_email: "",
      alert_id: "",
      remark: "",
    });
    const comment2Send = reactive({
      submitter_email: "",
      comment: "",
    });

    const userData = computed(() => {
      return store.getters.user;
    });
    const caseToPath = computed({
      get: () => {
        return "CaseDetail";
      },
    });
    // const caseProgress = computed(() => {
    //   return formHelper.whatCaseProgress(props.caseItem.progress);
    // });

    const caseState = computed(() => {
      return formHelper.whatCaseState(props.caseItem.state_display);
    });

    const showCancelCasePopup = () => {
      popupCancelCase.value = true;
    };
    const showCommentPopup = (alert_id) => {
      caseComment.value = "";
      singleSelectedAlertId.value = alert_id;
      popupComment.value = true;
    };

    const handleSendCancelCaseRequest = async () => {
      newCaseRecord2Send.owner_email = userData.value.data.mail;
      newCaseRecord2Send.alert_id = props.caseItem.Alert_id;
      newCaseRecord2Send.state = 1;
      newCaseRecord2Send.remark = cancelReason.value;
      console.log(
        "handleSendCancelCaseRequest - newCaseRecord2Send",
        newCaseRecord2Send
      );

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("postSetCaseState", newCaseRecord2Send);
        setTimeout(() => {
          popupCancelCase.value = false;
          location.reload();
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("User 尚未撈到", err);
      }
    };
    const handleSendAlertComment = async () => {
      comment2Send.submitter_email = userData.value.data.mail;
      comment2Send.comment = caseComment.value;

      const comment2SendPackage = {
        caseId: singleSelectedAlertId.value,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("postAlertComment", comment2SendPackage);

        setTimeout(() => {
          popupComment.value = false;
          location.reload();
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Send Commit FAIL", err);
      }
    };

    return {
      caseToPath,
      // caseProgress,
      caseState,
      newCaseRecord2Send,
      popupCancelCase,
      popupComment,
      showCancelCasePopup,
      showCommentPopup,
      handleSendCancelCaseRequest,
      handleSendAlertComment,
      cancelReason,
      caseComment,
    };
  },
  methods: {
    translateTimeMMDDHHMM(time) {
      return moment.utc(time).utcOffset(8).format("MM/DD HH:mm");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
  color: var(--bs-body-color);
}
.card {
  &--canceled {
    opacity: 0.5;
  }
}
.cardContent {
  &__desc {
    margin-bottom: 12px;
    min-height: 48px;
    font-size: 16px;
    font-weight: bold;
  }
}
.cardFooter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  &__brick {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 50%;
  }
  &__label {
    color: rgba(var(--bs-inverse-rgb), 0.55);
  }
  .btn {
    width: 100%;
  }
}
.crossShadowRibbon {
  position: absolute;
  top: -5px;
  right: 12px;
  margin-left: 15px;
  padding: 4px;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  background: #c96678;
  border-radius: 0 0 2px 2px;

  &--dispatchFinished {
    background: #34b37e;
    &:before {
      border-bottom: 5px solid #3d7d1b;
    }
  }
  &--left {
    background: #fff;
    color: #555;
    &:before {
      border-bottom: 5px solid #888;
    }
  }
  &--irCase {
    background: #333;
    color: #fff;
    &:before {
      border-bottom: 5px solid #888;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: -10px;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 5px solid #696969;
    border-left: 10px solid transparent;
  }
  &--secondary {
    right: 68px;
  }
}
.cardHeader {
  &--unconfirmed {
    color: #fff;
    background-color: var(--bs-process-preparing-bg-color-rgba);
  }
  &--established {
    color: #fff;
    background-color: var(--bs-process-inprogress-bg-color-rgba);
  }
  &--closed {
    color: #fff;
    background-color: var(--bs-process-closed-bg-color-rgba);
  }
  &--cancel {
    color: #fff;
    background-color: var(--bs-process-cancel-bg-color-rgba);
  }
}
</style>
