import * as Msal from "msal";

export default class AuthService {
  constructor() {
    // const PROD_REDIRECT_URI = process.env.VUE_APP_MSAL_PROD_REDIRECT_URI;
    let PROD_REDIRECT_URI = null;
    const hostName = window.location.hostname;
    if (hostName == "csirt.twbu.trendmicro.com") {
      PROD_REDIRECT_URI = process.env.VUE_APP_MSAL_PROD_REDIRECT_URI_2;
    } else if (hostName == "tm-csirt.azurewebsites.net") {
      PROD_REDIRECT_URI = process.env.VUE_APP_MSAL_PROD_REDIRECT_URI;
    } else if (hostName == "tm-csirt-blue.azurewebsites.net") {
      PROD_REDIRECT_URI = process.env.VUE_APP_MSAL_PROD_REDIRECT_URI_blue;
    } else {
      PROD_REDIRECT_URI = process.env.VUE_APP_MSAL_PROD_REDIRECT_URI_2;
    }

    const POST_LOGOUT_REDIRECT_URI = "http://localhost:4001/#/login";
    let redirectUri = "http://localhost:4001/#/caseManagement";
    if (window.location.hostname !== "localhost") {
      redirectUri = PROD_REDIRECT_URI;
    }

    console.log("Auth.service - redirectUri", redirectUri);

    this.applicationConfig = {
      auth: {
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
        authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
        redirectUri: redirectUri,
        postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
      },
      cache: {
        cacheLocation: "localStorage", // Options are localStorage, sessionStorage, memoryStorage
      },
      graphScopes: {
        scopes: ["user.read"],
      },
      tokenRequest: {
        scopes: ["user_impersonation"],
      },
    };

    this.app = new Msal.UserAgentApplication(this.applicationConfig);

    // this.app = new Msal.UserAgentApplication(
    //   this.applicationConfig,
    //   "",
    //   () => {
    //     // callback for login redirect
    //   },
    //   {
    //     redirectUri,
    //   }
    // );
  }
  login() {
    console.log("this.app", this.app);
    console.log("this.applicationConfig", this.applicationConfig);
    return this.app
      .loginPopup(this.applicationConfig.graphScopes)
      .then(
        (idToken) => {
          console.log("login idToken - auth.service", idToken);
          // eslint-disable-next-line no-debugger
          const user = this.app.account;
          if (user) {
            return user;
          } else {
            return null;
          }
        },
        () => {
          console.log("login idToken - auth.service");
          return null;
        }
      )
      .catch((err) => {
        console.log("login() error - auth.service", err);
        // handle error
      });
  }
  logout() {
    this.app.logout();
  }
  getToken() {
    return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
      (accessToken) => {
        return accessToken;
      },
      (error) => {
        console.log("getToken error", error);
        return this.app
          .acquireTokenPopup(this.applicationConfig.graphScopes)
          .then(
            (accessToken) => {
              return accessToken;
            },
            (err) => {
              console.error(err);
            }
          );
      }
    );
  }
}
