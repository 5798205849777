<template>
  <section class="mitreList">
    <button
      v-if="!isAddMitrePanelOpen"
      type="button"
      class="btn btn-outline-theme btn__tagging"
      title="Add"
      alt="Add"
      :class="{ 'w-100': isAddMitrePanelOpen }"
      :disabled="!isAllMitreReferenceDataLoaded"
      @click="isAddMitrePanelOpen = true"
    >
      <i class="bi bi-bookmark-plus-fill me-2"></i>
      Add tag
      <van-loading
        v-if="!isAllMitreReferenceDataLoaded"
        type="spinner"
        class="ms-2"
      />
    </button>

    <!-- Add Mitre Tag Panel -->
    <template v-if="isAddMitrePanelOpen">
      <div class="panel highlight">
        <div class="panel__header">
          <i class="bi bi-plus-lg"></i>
          Add MITRE tag
          <i
            class="bi bi-x-lg cursor-pointer"
            @click="isAddMitrePanelOpen = false"
          ></i>
        </div>
        <div class="formAddMitre">
          <div class="mb-3">
            <label
              for="inputTechniqueId"
              class="form-label van-field__label--required"
            >
              Technique ID
            </label>

            <v-select
              v-model="addMitreTagData.mitreTagId"
              id="inputTechniqueId"
              class="vs-datalist form-control"
              :class="{
                invalid: v$.mitreTagId.mitreTagIdRule1.$invalid,
                valid: !v$.mitreTagId.mitreTagIdRule1.$invalid,
              }"
              :options="optionsAllMitreReferenceVuex"
              :reduce="(mitre) => mitre.Id"
              placeholder="選擇..."
            >
              <template
                #selected-option="{ TechniqueID, Tactics, TechniqueName }"
              >
                <div style="display: flex; align-items: baseline">
                  <strong>{{ TechniqueID }} -</strong>
                  <span style="margin-left: 0.5rem">
                    [{{ Tactics }}] {{ TechniqueName }}
                  </span>
                </div>
              </template>

              <template
                #option="{
                  TechniqueID,
                  Tactics,
                  TechniqueName,
                  TechniqueNameCht,
                }"
              >
                <div class="optionTitle">
                  {{ TechniqueID }} - [{{ Tactics }}] {{ TechniqueName }}
                  <br />
                  <span>
                    {{ TechniqueNameCht }}
                  </span>
                </div>
              </template>
            </v-select>
            <div
              v-if="v$.mitreTagId.mitreTagIdRule1.$invalid"
              class="van-field__error-message"
            >
              {{ validationRegex.errorMessages.required }}
            </div>
          </div>
          <div class="mb-3">
            <label for="inputVulnerability" class="form-label">
              Vulnerability
            </label>
            <input
              v-model="addMitreTagData.vulnerability"
              class="form-control"
              list="datalistOptionsVulnerability"
              id="inputVulnerability"
              placeholder="選擇..."
              style="letter-spacing: 0.75px"
            />
            <datalist id="datalistOptionsVulnerability">
              <template
                v-for="(
                  item, index
                ) in optionsMitreVulnerabilityHistoryDataVuex"
                :key="index"
              >
                <option :value="item">
                  <span>{{ item }}</span>
                </option>
              </template>
            </datalist>
          </div>
          <div class="mb-3">
            <label for="inputTool" class="form-label"> Tool </label>
            <input
              v-model="addMitreTagData.tool"
              class="form-control"
              list="datalistOptionsTool"
              id="inputTool"
              placeholder="選擇..."
              style="letter-spacing: 0.75px"
            />
            <datalist id="datalistOptionsTool">
              <template
                v-for="(item, index) in optionsMitreToolHistoryDataVuex"
                :key="index"
              >
                <option :value="item">
                  <span>{{ item }}</span>
                </option>
              </template>
            </datalist>
          </div>
          <div class="mb-3">
            <label for="textareaNote" class="form-label"> Note </label>
            <textarea
              v-model="addMitreTagData.note"
              class="form-control"
              id="textareaNote"
              rows="3"
            ></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-success"
              :disabled="v$.$invalid"
              @click="handleAddMitreTag()"
            >
              新增
            </button>
          </div>
        </div>
      </div>
    </template>

    <!-- Mitre Tags List -->
    <template v-for="item in caseMitreTagsDataVuex" :key="item.Id">
      <div class="card">
        <div class="card-header">
          <span class="card__title">
            {{ item.TechniqueID }} - {{ item.Tactics }} ({{
              item.TechniqueName
            }})
          </span>
          <div v-if="!isEditMode[item.Id]">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm me-1"
              title="Edit"
              alt="Edit"
              @click="handleToggleEditMitreTagMode(item.Id)"
            >
              <i class="bi bi-pencil"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm"
              title="Delete"
              alt="Delete"
              @click="handleShowDeleteConfirm(item.Id)"
            >
              <i class="bi bi-trash3"></i>
            </button>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <i class="bi bi-shield-fill-exclamation me-3"></i>
            <template v-if="!isEditMode[item.Id]">
              {{ item.Vulnerability }}
            </template>
            <template v-else>
              <input
                v-model="item.Vulnerability"
                class="form-control"
                list="datalistOptionsVulnerability"
                id="inputEditVulnerability"
                placeholder="選擇..."
                style="letter-spacing: 0.75px"
              />
              <datalist id="datalistOptionsVulnerability">
                <template
                  v-for="(
                    item, index
                  ) in optionsMitreVulnerabilityHistoryDataVuex"
                  :key="index"
                >
                  <option :value="item">
                    <span>{{ item }}</span>
                  </option>
                </template>
              </datalist>
            </template>
          </li>
          <li class="list-group-item">
            <i class="fa-solid fa-wrench me-3"></i>
            <template v-if="!isEditMode[item.Id]">
              {{ item.Tool }}
            </template>
            <template v-else>
              <input
                v-model="item.Tool"
                class="form-control"
                list="datalistOptionsTool"
                id="inputEditTool"
                placeholder="選擇..."
                style="letter-spacing: 0.75px"
              />
              <datalist id="datalistOptionsTool">
                <template
                  v-for="(item, index) in optionsMitreToolHistoryDataVuex"
                  :key="index"
                >
                  <option :value="item">
                    <span>{{ item }}</span>
                  </option>
                </template>
              </datalist>
            </template>
          </li>
          <li class="list-group-item">
            <i class="bi bi-journal-medical me-3"></i>
            <template v-if="!isEditMode[item.Id]">
              {{ item.Note }}
            </template>
            <template v-else>
              <textarea
                v-model="item.Note"
                class="form-control"
                id="textareaEditNote"
                rows="3"
              ></textarea>
            </template>
          </li>
          <li
            v-if="isEditMode[item.Id]"
            class="list-group-item justify-content-end"
          >
            <button
              type="button"
              class="btn btn-outline-danger btn-sm me-1"
              @click="handleToggleEditMitreTagMode(item.Id)"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-outline-success btn-sm me-1"
              @click="handleUpdateCaseMitreTag(item.Id)"
            >
              更新
            </button>
          </li>
        </ul>

        <div class="card-arrow">
          <div class="card-arrow-top-left"></div>
          <div class="card-arrow-top-right"></div>
          <div class="card-arrow-bottom-left"></div>
          <div class="card-arrow-bottom-right"></div>
        </div>
      </div>
    </template>
  </section>

  <van-popup
    v-model:show="popupDeleteCaseMitre"
    class="popup"
    round
    style="width: 350px"
  >
    <div class="popup__header">刪除標籤？</div>
    <div class="popup__content">請確認是否要刪除此 Mitre tag.</div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupDeleteCaseMitre = false"
      >
        取消刪除
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click.stop="handleDeleteCaseTag()"
      >
        刪除標籤
      </button>
    </div>
  </van-popup>
</template>

<script setup>
import { ref, reactive, computed, onMounted, defineProps } from "vue";
import { useStore } from "vuex";
import { showDialog, showSuccessToast } from "vant";
import formHelper from "../formHelper";
import { useVuelidate } from "@vuelidate/core";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const caseMitreTagsDataVuex = computed(() => {
  return store.getters.caseMitreTagsData;
});
const optionsAllMitreReferenceVuex = computed(() => {
  return store.getters.optionsAllMitreReference;
});
const optionsMitreVulnerabilityHistoryDataVuex = computed(() => {
  return store.getters.optionsMitreVulnerabilityHistoryData;
});
const optionsMitreToolHistoryDataVuex = computed(() => {
  return store.getters.optionsMitreToolHistoryData;
});

const props = defineProps(["caseId"]);
const store = useStore();
const isAddMitrePanelOpen = ref(false);
const isAllMitreReferenceDataLoaded = ref(false);
const popupDeleteCaseMitre = ref(false);
const selectedDeleteTagId = ref(null);
const addMitreTagData = reactive({
  mitreTagId: null,
  vulnerability: null,
  tool: null,
  note: null,
});
const isEditMode = ref(
  caseMitreTagsDataVuex.value.reduce(
    (agg, item) => ({ ...agg, [item.Id]: false }),
    {}
  )
);

// Validations
const validationRegex = formHelper.validation;
const mitreTagIdRule1 = (value) => {
  if (value === "" || value === null) return false;
  else return true;
};
const rules = {
  mitreTagId: { mitreTagIdRule1 },
};
const v$ = useVuelidate(rules, addMitreTagData, { $scope: false });
// END of Validations

const handleAddMitreTag = async () => {
  console.log("handleAddMitreTag");
  store.dispatch("handlePageLoadingState", true);

  const sourceData = {
    caseId: props.caseId,
    data: addMitreTagData,
  };

  console.log("sourceData - handleAddMitreTag", sourceData);
  const response = await store.dispatch("postAddMitreData", sourceData);
  console.log("response - handleAddMitreTag", response);

  if (response.status == 200) {
    showSuccessToast("儲存成功!");
    isAddMitrePanelOpen.value = false;
    clearAddMitreTagData();
    getCurrentCaseMitreTagsData(props.caseId);
    store.dispatch("handlePageLoadingState", false);
  } else {
    store.dispatch("handlePageLoadingState", false);
    showDialog({
      message: "新增 Mitre - 失敗",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};
const handleToggleEditMitreTagMode = (mitreTagId) => {
  isEditMode.value[mitreTagId] = !isEditMode.value[mitreTagId];
};
const handleUpdateCaseMitreTag = (id) => {
  console.log("mitreTagId - handleUpdateCaseMitreTag", id);
  const updateTagTargetData = caseMitreTagsDataVuex.value.filter(
    (item) => item.Id == id
  );
  const sourceData = {
    caseId: props.caseId,
    tagId: id,
    data: {
      vulnerability: updateTagTargetData[0].Vulnerability,
      note: updateTagTargetData[0].note,
      tool: updateTagTargetData[0].tool,
    },
  };
  console.log("sourceData - handleUpdateCaseMitreTag", sourceData);
  postUpdateCaseMitreTagData(sourceData);
};
const handleShowDeleteConfirm = (tagId) => {
  popupDeleteCaseMitre.value = true;
  selectedDeleteTagId.value = tagId;
};
const handleDeleteCaseTag = () => {
  popupDeleteCaseMitre.value = false;
  const sourceData = {
    caseId: props.caseId,
    data: selectedDeleteTagId.value,
  };
  deleteCaseMitreTagData(sourceData);
};

const clearAddMitreTagData = () => {
  addMitreTagData.mitreTagId = null;
  addMitreTagData.vulnerability = null;
  addMitreTagData.tool = null;
  addMitreTagData.note = null;
};

const getCurrentCaseMitreTagsData = async (caseId) => {
  console.log("caseId - getCurrentCaseMitreTagsData", caseId);
  const response = await store.dispatch("getCurrentCaseMitreTagsData", caseId);
  console.log("response - getCurrentCaseMitreTagsData", response);
};
const getAllMitreReferenceData = async () => {
  const response = await store.dispatch("getAllMitreReferenceData");
  if (response.status == 200) {
    // store.dispatch("getAllMitreAutoCompleteData");
    setTimeout(() => {
      isAllMitreReferenceDataLoaded.value = true;
    }, 2000);
  }
};
const postUpdateCaseMitreTagData = async (sourceData) => {
  store.dispatch("handlePageLoadingState", true);
  const response = await store.dispatch(
    "postUpdateCaseMitreTagData",
    sourceData
  );
  if (response.status == 200) {
    showSuccessToast("更新成功");
    store.dispatch("handlePageLoadingState", false);
    handleToggleEditMitreTagMode(sourceData.tagId);
    getCurrentCaseMitreTagsData(props.caseId);
  } else {
    store.dispatch("handlePageLoadingState", false);
    showDialog({
      message: "更新 Mitre - 失敗",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};
const deleteCaseMitreTagData = async (sourceData) => {
  store.dispatch("handlePageLoadingState", true);
  const response = await store.dispatch("deleteCaseMitreTagData", sourceData);
  if (response.status == 200) {
    showSuccessToast("刪除成功");
    popupDeleteCaseMitre.value = false;
    store.dispatch("handlePageLoadingState", false);
    handleToggleEditMitreTagMode(sourceData.tagId);
    getCurrentCaseMitreTagsData(props.caseId);
  } else {
    store.dispatch("handlePageLoadingState", false);
    showDialog({
      message: "刪除 Mitre - 失敗",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};

onMounted(() => {
  if (!optionsAllMitreReferenceVuex.value.length) {
    getAllMitreReferenceData();
  } else {
    isAllMitreReferenceDataLoaded.value = true;
  }
  if (!caseMitreTagsDataVuex.value.length) {
    getCurrentCaseMitreTagsData(props.caseId);
  }
});
</script>

<style lang="scss" scoped>
.mitreList {
  padding: 16px 0;
  .card {
    margin-bottom: 8px;
    // background: rgb(142 186 247 / 40%);
    background: rgb(45 155 119 / 22%);
    &__title {
      width: calc(100% - 80px);
      line-height: 30px;
    }

    .form-control {
      background-color: #dfeeea;
      border: 1px solid #b5dacf;
    }
  }
  .card-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    .btn {
      font-size: 13px;
    }
  }
  .list-group {
    font-size: 14px;
  }
}
.btn__tagging {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}
.panel {
  position: relative;
  margin-bottom: 8px;
  padding: 4px 16px;
  border: 1px solid var(--bs-theme);
  border-radius: 5px;
  color: var(--bs-theme);
  background-color: rgb(36 157 121 / 2%);
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding-top: 4px;
  }
}
.highlight::before {
  --white-area: -2px;
  content: "";
  position: absolute;
  left: var(--white-area);
  right: var(--white-area);
  top: var(--white-area);
  bottom: var(--white-area);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5),
    0px 0px 0px 9999px rgba(0, 0, 0, 0.25);
  pointer-events: none;
  border-radius: 0;
  z-index: 50;
}
.formAddMitre {
  padding: 16px 0 4px 0;
  text-align: left;
  border-top: 1px dashed var(--bs-theme);

  .form-control {
    color: var(--bs-theme);
    font-size: 16px;
    border-color: var(--bs-theme);
  }
  .form-control::placeholder {
    color: var(--bs-theme);
  }
}

/* Error Fields */
.form-select.invalid,
.form-control.invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.van-field__error-message {
  padding-top: 4px;
}

.list-group-item {
  display: flex;
  align-items: center;
}
</style>

<style scoped>
/* v-select component styles */
.vs-datalist {
  width: 100% !important;
}
::v-deep(.vs__dropdown-menu) {
  font-size: 0.85rem;
  overflow-x: hidden;
}
::v-deep(.vs__dropdown-toggle) {
  padding: 0;
  height: 38px;
  border: 0;
}
::v-deep(.vs__search) {
  padding-left: 0;
  color: var(--bs-theme);
}
::v-deep(.vs__actions) {
  display: none;
  padding: 0px 6px 0 3px;
}
::v-deep(.vs__clear) {
  fill: var(--bs-theme);
}
::v-deep(.vs__open-indicator) {
  fill: var(--bs-theme);
}
::v-deep(.vs__selected) {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  color: var(--bs-theme);
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:deep(.vs__selected-options) {
  overflow: hidden;
}
:deep(.vs__dropdown-option .optionTitle) {
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
}
:deep(.vs__dropdown-option span) {
  color: #848282;
  font-weight: normal;
}
:deep(.vs__dropdown-option--highlight span) {
  color: #fff;
}
::v-deep(.invalid.v-select) {
  padding-top: 3px;
  padding-bottom: 3px;
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
