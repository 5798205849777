<template>
  <div class="rightPanelButtons row gx-2">
    <div class="col-6">
      <button
        v-if="caseDetail.state == 2"
        type="button"
        class="btn btn-primary"
        @click="handleButtonFinishCase()"
        style="width: 100%"
      >
        完成案件
      </button>
    </div>
    <div
      :class="{
        'col-12': caseDetail.state == 1 || caseDetail.state == 3,
        'col-6': caseDetail.state == 2,
      }"
    >
      <button
        type="button"
        class="btn btn-danger"
        @click="handleButtonGo2Workspace()"
        style="width: 100%"
      >
        Workspace
      </button>
    </div>
  </div>

  <div class="tab">
    <div
      v-if="ENABLE_SECTION_MITRETAGS"
      class="tab__item"
      :class="{ 'tab__item--active': rightSideTab === 'mitreInfo' }"
      @click="rightSideTab = 'mitreInfo'"
    >
      MITRE Info
    </div>
    <div
      class="tab__item"
      :class="{ 'tab__item--active': rightSideTab === 'eventTrack' }"
      @click="rightSideTab = 'eventTrack'"
    >
      案件軌跡
    </div>
  </div>

  <template v-if="rightSideTab == 'eventTrack'">
    <TrackEventBlock
      v-for="(item, index) in computedHistoryList"
      :key="index"
      :event="item"
    ></TrackEventBlock>
    <div class="noDataDisplay">無資料</div>
  </template>

  <template v-if="rightSideTab == 'mitreInfo'">
    <AddMitreTagTemplate
      :case-id="props.caseDetail.CaseID"
    ></AddMitreTagTemplate>
  </template>

  <van-popup
    v-model:show="popupFinishCaseConfirm"
    class="popup"
    round
    style="width: 350px"
  >
    <div class="popup__header">完成案件？</div>
    <div class="popup__content">
      <p>完成案件後將不可編輯，請確認是否要完成案件。</p>
      <p>
        <i class="bi bi-exclamation-triangle-fill"></i>
        必填欄位確認：
      </p>
      <ul class="requiredDataList">
        <li>
          <template v-if="caseDetail.AttackTypeID !== null">
            <i class="bi bi-check-lg text-success"></i>
          </template>
          <template v-else>
            <i class="bi bi-x-lg text-danger"></i>
          </template>
          攻擊方式 -
          {{
            caseDetail.attack_type_display
              ? caseDetail.attack_type_display
              : "未填寫"
          }}
        </li>
        <li>
          <template v-if="caseDetail.ParticipationNumbers !== null">
            <i class="bi bi-check-lg text-success"></i>
          </template>
          <template v-else>
            <i class="bi bi-x-lg text-danger"></i>
          </template>
          參與人數 -
          {{
            caseDetail.ParticipationNumbers == 0
              ? 0 + "人"
              : caseDetail.ParticipationNumbers == null
              ? "未填寫"
              : caseDetail.ParticipationNumbers + "人"
          }}
        </li>
        <li>
          <template v-if="caseDetail.FirstHackTime !== null">
            <i class="bi bi-check-lg text-success"></i>
          </template>
          <template v-else>
            <i class="bi bi-x-lg text-danger"></i>
          </template>
          最早入侵時間 -
          {{
            caseDetail.first_hack_time_display
              ? caseDetail.first_hack_time_display
              : "未填寫"
          }}
        </li>
      </ul>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupFinishCaseConfirm = false"
      >
        取消
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="
          caseDetail.AttackTypeID === null ||
          caseDetail.ParticipationNumbers === null ||
          caseDetail.FirstHackTime === null
        "
        @click.stop="handleFinishCaseConfirmed()"
      >
        完成
      </button>
    </div>
  </van-popup>
</template>

<script setup>
import { computed, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { showDialog } from "vant";
import { special } from "../special";
import TrackEventBlock from "../components/units/TrackEventBlock";
import AddMitreTagTemplate from "../components/AddMitreTagTemplate";
import formHelper from "../formHelper";

const ENABLE_SECTION_MITRETAGS = special.ENABLE_SECTION_MITRETAGS || false;
const props = defineProps(["caseDetail"]);
const store = useStore();
const router = useRouter();
const popupFinishCaseConfirm = ref(false);
const rightSideTab = ref(ENABLE_SECTION_MITRETAGS ? "mitreInfo" : "eventTrack");

const computedHistoryList = computed(() => {
  let sProgress = ""; // 上一event的個狀態
  let sList = [];
  console.log("computedHistoryList");
  props.caseDetail.history.map((item) => {
    if (sProgress === item.progress) {
      let cLength = sList.length - 1;
      if (sList[cLength]) {
        sList[cLength].events.push(item);
      }
    } else {
      sProgress = item.progress;
      sList.push({
        event_progress: item.progress,
        event_progress_eng: formHelper.whatCaseProgressEng(item.State),
        event_icon_className: formHelper.whatIconClassName4ProgressInEventTrack(
          item.State
        ),
        events: [item],
      });
    }
  });
  console.log("sList", sList);
  return sList;
});

const handleButtonGo2Workspace = () => {
  console.log("Go to page - workspace", props.caseDetail);
  router.push(`/workspaceDetail/${props.caseDetail.CaseID}`);
};
const handleButtonFinishCase = () => {
  popupFinishCaseConfirm.value = true;
};
const handleFinishCaseConfirmed = async () => {
  store.dispatch("handlePageLoadingState", true);
  console.log("handleFinishCaseConfirmed");
  try {
    const finalCaseFormData2Send = {
      State: 3,
    };
    const sourceData = {
      caseId: props.caseDetail.CaseID,
      data: finalCaseFormData2Send,
    };
    console.log("handleFinishCaseConfirmed - sourceData", sourceData);
    const response = await store.dispatch("putUpdateCaseState", sourceData);
    popupFinishCaseConfirm.value = false;
    if (response.status !== 200) {
      store.dispatch("handlePageLoadingState", false);
      showDialog({
        message: "Update IR case state FAIL.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      setTimeout(() => {
        store.dispatch("handlePageLoadingState", false);
        location.reload();
      }, 2000);
    }
  } catch (err) {
    console.log("Update IR case FAIL.", err);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.rightPanelButtons {
  margin-bottom: 16px;
  .btn {
    flex: 1 1 50%;
  }
  .btn-danger {
    background-color: #c96678;
  }
}

.tab {
  display: flex;
  margin: 0;
  // padding: 0 2rem;
  white-space: nowrap;
  list-style-type: none;
  border-bottom: 1px solid var(--bs-border-color);

  &__item {
    // cursor: pointer;
    // display: block;
    // margin-bottom: -1px;
    // padding: 0 0.625rem 0.5rem;
    // width: 33%;
    // text-align: center;
    // color: rgba(var(--bs-inverse-rgb), 0.5);
    // font-weight: 500;
    // border-bottom: 3px solid transparent;
    // text-decoration: none;

    cursor: pointer;
    position: relative;
    bottom: -1px;
    display: inline-block;
    margin-left: 4px;
    font-weight: normal;
    padding: 4px 8px;
    width: 33%;
    border-radius: 5px 6px 0 0;
    color: #848484;
    background-color: transparent;
    border: 1px solid #ddd;
    border-bottom: 0;
    // flex: 1 1 1%;
    text-align: center;

    &--active {
      cursor: default;
      font-weight: 600;
      color: rgba(var(--bs-inverse-rgb), 1);
      background-color: #eaeaea;
      background: linear-gradient(
        to bottom,
        rgba(216, 211, 211, 1) 0%,
        rgba(238, 238, 238, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      // border-bottom-color: var(--bs-color-theme);
    }
  }
}
.requiredDataList {
  li {
    padding-left: 18px;
  }
}
.btn.w-100:hover {
  cursor: default;
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
}
</style>
