<template>
  <div v-if="isProgress.page" class="preLoader">
    <span class="spin"></span>
  </div>

  <section class="content container-fluid">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h4>
        <i class="bi bi-person-badge-fill text-theme"></i> Customer Management
      </h4>
      <span class="btn btn-outline-theme">
        <i class="fa fa-plus-circle fa-fw me-1"></i> Add Customer
      </span>
    </div>

    <div class="card">
      <div class="card-body">
        <label for="filter" class="form-label">SearchBy:</label>
        <div class="position-relative">
          <input
            type="text"
            v-model="searchTerm"
            id="filter"
            class="filter__input form-control"
            placeholder="輸入關鍵字..."
          />
          <i class="bi bi-search filter__icon"></i>
        </div>
        <hr />
        <table-lite
          :is-loading="table.isLoading"
          :is-static-mode="true"
          :columns="table.columns"
          :rows="table.rows"
          :total="table.totalRecordCount"
          :sortable="table.sortable"
          :page-options="table.pageOptions"
          @row-clicked="rowClicked"
          @is-finished="tableLoadingFinish"
        ></table-lite>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { onMounted, computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import TableLite from "vue3-table-lite";

const sampleData1 = [
  {
    id: "c21877218",
    company_name: "交通部民用航空局飛航服務總台",
    company_name_eng: "AIR NAVIGATION AND WEATHER SERVICES",
    company_logo:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/ROC_Civil_Aeronautics_Administration_Logo.svg/1200px-ROC_Civil_Aeronautics_Administration_Logo.svg.png",
    address: "台北市松山區濱江街362號",
    zip: "105",
    sales_force_number: "sa2313131",
    navision_id: "na32423432432",
    is_pov: false,
    contract: {},
    contact: [
      {
        ccid: "m21877218",
        name: "葉睿騰",
        title: "組長",
        contact_types: ["primary", "technical"],
        telephone_number: "(03)3841373",
        mobile_phone: "0918790015",
        email: "juiteng@anws.gov.tw",
      },
      {
        ccid: "m323990832",
        name: "Eva",
        title: "主任",
        contact_types: ["finance"],
        telephone_number: "(02)22293932",
        mobile_phone: "0911555111",
        email: "xxxxxx@anws.gov.tw",
      },
      {
        ccid: "m392829",
        name: "Peter Huang",
        title: "",
        contact_types: ["sales"],
        telephone_number: "",
        mobile_phone: "0988222444",
        email: "peter_huang@trend.com.tw",
      },
    ],
    profile_setting: { account: "", password: "", user_token: "" },
    create_time: "2023-05-10 08:22",
    updated_time: "2023-05-12 14:55",
  },
  {
    id: "c21877832",
    company_name: "交通部民用航空局飛航服務總台 2",
    company_name_eng: "AIR NAVIGATION AND WEATHER SERVICES 2",
    company_logo:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/ROC_Civil_Aeronautics_Administration_Logo.svg/1200px-ROC_Civil_Aeronautics_Administration_Logo.svg.png",
    address: "台北市松山區濱江街362號",
    zip: "105",
    sales_force_number: "sa2313131",
    navision_id: "na32423432432",
    is_pov: false,
    contract: {},
    contact: [
      {
        ccid: "m21877218",
        name: "葉睿騰",
        title: "組長",
        contact_types: ["primary", "technical"],
        telephone_number: "(03)3841373",
        mobile_phone: "0918790015",
        email: "juiteng@anws.gov.tw",
      },
      {
        ccid: "m323990832",
        name: "Eva",
        title: "主任",
        contact_types: ["finance"],
        telephone_number: "(02)22293932",
        mobile_phone: "0911555111",
        email: "xxxxxx@anws.gov.tw",
      },
      {
        ccid: "m392829",
        name: "Peter Huang",
        title: "",
        contact_types: ["sales"],
        telephone_number: "",
        mobile_phone: "0988222444",
        email: "peter_huang@trend.com.tw",
      },
    ],
    profile_setting: { account: "", password: "", user_token: "" },
    create_time: "2023-05-10 08:22",
    updated_time: "2023-05-12 14:55",
  },
];

export default {
  name: "ProfileList",
  components: { TableLite },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isProgress = computed(() => {
      return store.getters.isProgress;
    });

    // Init table settings
    const searchTerm = ref(""); // Search text

    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "ID",
          field: "id",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "客戶名稱",
          field: "company_name",
          width: "15%",
          sortable: true,
        },
        {
          label: "Company Name",
          field: "company_name_eng",
          width: "15%",
          sortable: true,
        },
        {
          label: "POV",
          field: "is_pov",
          width: "5%",
          sortable: false,
        },
      ],
      rows: computed(() => {
        return sampleData1.filter(
          (x) =>
            x.company_name
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()) ||
            x.company_name_eng
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()) ||
            x.id.toLowerCase().includes(searchTerm.value.toLowerCase())
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    /**
     * Table search finished event
     */
    const tableLoadingFinish = (elements) => {
      console.log("tableLoadingFinish - elements", elements);
      table.isLoading = false;
    };

    // First get data
    // doSearch(0, table.pageOptions[0].value, "id", "asc");

    const rowClicked = (row) => {
      // console.log("Row clicked!", row);
      router.push(`/profileDetail/${row.id}`);
    };

    onMounted(() => {});

    return {
      isProgress,
      searchTerm,
      table,
      tableLoadingFinish,
      rowClicked,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/_viewport.scss";
@import "../assets/css/_tableLite.css";
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-form-placholder-color);
}
.content {
  margin: auto;
  max-width: 1320px;

  @include desktop {
    max-width: 1640px;
  }
}
.filter {
  &__input {
    padding: 12px;
    background-color: #ffffff;

    &:focus {
      background-color: #ffffff;
    }
  }
  &__icon {
    position: absolute;
    top: 7px;
    right: 15px;
    font-size: 20px;
    color: #6f6f6f;
  }
}
</style>
