<template>
  <!-- user.data: {{ user }} -->
  <!-- ENABLE_MENU_DASHBOARD: {{ ENABLE_MENU_DASHBOARD }} -->
  <!-- salesNameListDataVuex: {{ salesNameListDataVuex }} -->
  <header class="header">
    <div class="header__wrapper container-fluid">
      <div
        class="header__brand"
        :class="{
          'header__brand--trend': COMPANY_CODE == 'trendmicro',
          'header__brand--digicosmos': COMPANY_CODE == 'digicosmos',
        }"
      >
        <template v-if="COMPANY_CODE == 'digicosmos'">
          <img
            class="header__logo header__logo--digicosmos"
            src="img/logo_digicosmos_white_horizon_small_chinese.png"
            alt="DIgicosmos Intranet"
            @click="handleGo2CaseManagement()"
          />
        </template>
        <template v-else>
          <img
            class="header__logo header__logo--trend"
            src="https://cdn-assets-cloud.frontify.com/s3/frontify-cloud-files-us/eyJwYXRoIjoiZnJvbnRpZnlcL2ZpbGVcL21iSmExcFRMSnhzTm1aUjlrZThvLnBuZyJ9:frontify:vm412E3oxj8PRlBTaZWFXk6_rvyIVIrnyQ_i7tBV4Nk?width={width}&rect=0,26.981707317073,800,223.01829268293&reference_width=800"
            alt="Trend Micro"
            @click="handleGo2CaseManagement()"
          />
        </template>
        <span class="header__logoBrandText" @click="handleGo2CaseManagement()"
          >IR Case Management Platform</span
        >
      </div>

      <div class="header__toolbar">
        <nav class="navigation">
          <span
            v-if="ENABLE_MENU_DASHBOARD"
            class="navigation__items"
            :class="{
              'navigation__items--active': currentRouteName == 'DashboardView',
            }"
            @click="handleGo2Dashboard()"
            >總覽</span
          >
          <span
            class="navigation__items"
            :class="{
              'navigation__items--active': currentRouteName == 'CaseManagement',
            }"
            @click="handleGo2CaseManagement()"
            >案件管理</span
          >
          <a
            class="navigation__items"
            href="https://lookerstudio.google.com/reporting/1139da10-0ff1-419b-9615-a5714d74d036/page/QTYlD?s=lC_gyHHoDy0"
            target="_blank"
          >
            APT Cases (Looker)
          </a>
          <span
            v-if="ENABLE_MENU_CUSTOMER"
            class="navigation__items"
            :class="{
              'navigation__items--active': currentRouteName == 'ProfileDetail',
            }"
            @click="handleGo2ProfileList()"
            >客戶管理</span
          >
          <span
            v-if="ENABLE_MENU_FILTER"
            class="navigation__items"
            :class="{
              'navigation__items--active': currentRouteName == 'FilterConfig',
            }"
            @click="handleGo2FilterConfig()"
            >篩選設定</span
          >
        </nav>
        <div
          v-if="ENABLE_MENU_NOTIFICATION"
          class="notification"
          @click="
            state.notificationPanelIsShow = !state.notificationPanelIsShow
          "
        >
          <i class="bi bi-bell-fill notification__icon">
            <span class="notification__dot"></span>
          </i>
        </div>
        <div
          class="user"
          @click="state.userPanelIsShow = !state.userPanelIsShow"
        >
          <div class="user__avatar">
            <i class="bi bi-person-fill"></i>
          </div>
          {{ user.data ? user.data.displayName : "-" }}
        </div>
      </div>
    </div>

    <div
      v-if="state.notificationPanelIsShow"
      class="dropdownPanel dropdownPanel--notification"
    >
      <ul class="tabs">
        <li
          class="tabs__item"
          :class="{
            'tabs__item--active': state.notificationDefautTabActive,
          }"
          @click="state.notificationDefautTabActive = true"
        >
          ASRM
        </li>
        <li
          class="tabs__item"
          :class="{
            'tabs__item--active': !state.notificationDefautTabActive,
          }"
          @click="state.notificationDefautTabActive = false"
        >
          Tasks
        </li>
      </ul>
      <div class="tabContent">
        <template v-if="state.notificationDefautTabActive">
          <div class="notificationItem">
            <!-- <div class="notificationItem__level">

            </div> -->
            <div class="notificationItem__detail">
              <p class="notificationItem__desc">
                Potential Disabling of Security Software
              </p>
              <span class="notificationItem__time">2023-03-10 16:25:05</span>
            </div>
          </div>
          <div class="notificationItem">
            <!-- <div class="notificationItem__level">

            </div> -->
            <div class="notificationItem__detail">
              <p class="notificationItem__desc">
                Potential Disabling of Security Software
              </p>
              <span class="notificationItem__time">2023-03-10 16:25:05</span>
            </div>
          </div>
        </template>
        <template v-if="!state.notificationDefautTabActive">
          <div class="notificationItem">
            <!-- <div class="notificationItem__level">

            </div> -->
            <div class="notificationItem__detail">
              <p class="notificationItem__desc">系統新建案件匯入</p>
              <span class="notificationItem__time">2023-03-29 06:11:22</span>
            </div>
          </div>
          <div class="notificationItem">
            <!-- <div class="notificationItem__level">

            </div> -->
            <div class="notificationItem__detail">
              <p class="notificationItem__desc">
                Lucas03 建立中華航空案件 - Q202206300013
                <span class="badge text-bg-danger">Critical</span>
              </p>
              <span class="notificationItem__time">2023-03-10 16:25:05</span>
            </div>
          </div>
        </template>
      </div>
      <span class="dropdownPanel__bottomButton">View all Notifications</span>
    </div>

    <div v-if="state.userPanelIsShow" class="dropdownPanel dropdownPanel--user">
      <div class="dropdownPanel__top">
        <div class="dropdownPanel__avatar">
          <i class="bi bi-person-fill"></i>
        </div>
        <ul class="text-start">
          <li v-if="!AZURE_AD_LOGIN">{{ user.data ? user.data.email : "" }}</li>
          <li v-else>{{ user.data ? user.data.displayName : "" }}</li>
          <li v-if="!AZURE_AD_LOGIN" class="text-secondary">lucas_chen</li>
          <li v-else class="text-secondary">
            {{ user.data ? user.data.jobTitle : "" }}
          </li>
        </ul>
      </div>
      <hr />
      <div class="dropdownPanel__bottom">
        <button
          v-if="ENABLE_MENU_MYPROFILE"
          type="button"
          class="btn btn-secondary"
        >
          帳號資訊
        </button>

        <template v-if="!AZURE_AD_LOGIN">
          <button
            type="button"
            class="btn btn-trend"
            @click="LogoutFromFirebase()"
          >
            登出
          </button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-trend" @click="LogoutFromAAD()">
            登出
          </button>
        </template>
        <small class="text-secondary">
          Config Version: {{ CONFIG_VERSION }}
        </small>
        <small class="text-secondary">Core Version: {{ CORE_VERSION }}</small>
      </div>
    </div>
  </header>

  <div
    v-if="state.userPanelIsShow || state.notificationPanelIsShow"
    class="virtualMask"
    style="top: 0; z-index: 1"
    @click="hiddenAllPopup()"
  ></div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import { auth } from "@/firebaseConfig";
import AuthService from "../services/auth.service";
import { special } from "../special";

export default {
  name: "HeaderComponent",
  props: {},
  setup() {
    const INTRANET_USE_ONLY = special.INTRANET_USE_ONLY || false;
    const COMPANY_CODE = special.customer_code || "";
    const AZURE_AD_LOGIN = special.AZURE_AD_LOGIN || false;
    const ENABLE_MENU_DASHBOARD = special.ENABLE_MENU_DASHBOARD || false;
    const ENABLE_MENU_FILTER = special.ENABLE_MENU_FILTER || false;
    const ENABLE_MENU_CUSTOMER = special.ENABLE_MENU_CUSTOMER || false;
    const ENABLE_MENU_MYPROFILE = special.ENABLE_MENU_MYPROFILE || false;
    const ENABLE_MENU_NOTIFICATION = special.ENABLE_MENU_NOTIFICATION || false;
    // eslint-disable-next-line no-undef
    const CORE_VERSION = versionControl.core.version || "v0.1.00";
    const CONFIG_VERSION = special.version || "v0.1.00";

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const authService = new AuthService();
    // const graphService = new GraphService();
    const state = reactive({
      userPanelIsShow: false,
      notificationPanelIsShow: false,
      notificationDefautTabActive: true,
    });
    let currentRouteName = ref(null);

    // auth.onAuthStateChanged((user) => {
    //   store.dispatch("fetchUser", user);
    // });

    const user = computed(() => {
      return store.getters.user;
    });
    const shiftMemberListDataVuex = computed(() => {
      return store.getters.shiftMemberListData;
    });
    const salesNameListDataVuex = computed(() => {
      return store.getters.salesMemberListData;
    });
    const industryListDataVuex = computed(() => {
      return store.getters.industryListData;
    });

    const handleGo2Dashboard = () => {
      router.push("/dashboard");
    };

    const handleGo2CaseManagement = () => {
      router.push("/caseManagement");
    };

    const handleGo2ProfileList = () => {
      router.push("/profileList");
    };

    const handleGo2ProfileDetail = () => {
      router.push("/profileDetail");
    };

    const handleGo2FilterConfig = () => {
      router.push("/filterConfig");
    };

    const hiddenAllPopup = () => {
      state.userPanelIsShow = false;
      state.notificationPanelIsShow = false;
    };

    const LogoutFromFirebase = async () => {
      await store.dispatch("logOut");
      router.push("/login");
    };

    const LogoutFromAAD = () => {
      authService.logout();
    };

    const getAzureUserData = async () => {
      try {
        console.log("getAzureUserData");
        await store.dispatch("getAzureAdUserData");
        store.dispatch("handleLoginLoadingState", false);
        // console.log("response - getAzureUserData", response);
      } catch (err) {
        console.log("User 尚未撈到 - getAzureUserData / Header.vue", err);
        router.push("/login");
      }
    };

    watch(
      route,
      () => {
        currentRouteName.value = route.name;
      },
      { deep: true, immediate: true }
    );

    onMounted(() => {
      currentRouteName.value = route.name;
      store.dispatch("handleLoginLoadingState", true);

      if (!shiftMemberListDataVuex.value.length) {
        store.dispatch("getShiftMemberList");
      }
      if (!salesNameListDataVuex.value.length) {
        store.dispatch("getSalesDataList");
      }
      if (!industryListDataVuex.value.length) {
        store.dispatch("getAllIndustryListData");
      }

      setTimeout(() => {
        if (!user.value.data && !INTRANET_USE_ONLY && !AZURE_AD_LOGIN) {
          store.dispatch("handleLoginLoadingState", false);
          router.push("/login");
        } else if (AZURE_AD_LOGIN) {
          console.log("透過 AD 取得登入資料");
          store.commit("SET_ISPROGRESS_LOGIN", false);
          authService.getToken().then(
            (token) => {
              console.log("透過 AD 取得登入資料 - token", token);
              if (!token) {
                console.log("Login Fail - Header onMounted");
                router.push("/login");
              } else {
                localStorage.setItem("access_token", token.accessToken);
                getAzureUserData();
                store.dispatch("handlePageLoadingState", false);
              }
            },
            (error) => {
              console.error(error);
              // store.dispatch("handleLoginLoadingState", false);
            }
          );
        }
      }, 1000);
    });

    return {
      user,
      state,
      currentRouteName,
      shiftMemberListDataVuex,
      salesNameListDataVuex,
      handleGo2Dashboard,
      handleGo2CaseManagement,
      handleGo2ProfileDetail,
      handleGo2ProfileList,
      handleGo2FilterConfig,
      hiddenAllPopup,
      LogoutFromFirebase,
      LogoutFromAAD,
      // CallAzureAPI,
      INTRANET_USE_ONLY,
      COMPANY_CODE,
      AZURE_AD_LOGIN,
      getAzureUserData,
      ENABLE_MENU_DASHBOARD,
      ENABLE_MENU_FILTER,
      ENABLE_MENU_CUSTOMER,
      ENABLE_MENU_MYPROFILE,
      ENABLE_MENU_NOTIFICATION,
      CORE_VERSION,
      CONFIG_VERSION,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
hr {
  color: #ccc;
}
.virtualMask {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 1990;
  // position: relative;
  display: flex;
  align-items: center;
  // min-height: 56px;
  color: #fff;
  // background-image: url("../assets/image/background/Red-Black-Gradient-3-with-pattern-No-Dots.jpg");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background-color: #2c2c2c;
  background-color: #383838;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  &__brand {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50px;
    &--trend {
      &:before {
        content: "";
        position: absolute;
        left: 168px;
        top: 50%;
        margin-top: -12px;
        width: 1px;
        height: 24px;
        background-color: #fff;
      }
    }
    &--digicosmos {
      &:before {
        content: "";
        position: absolute;
        left: 160px;
        top: 50%;
        margin-top: -13px;
        width: 1px;
        height: 26px;
        background-color: #fff;
      }
    }
  }
  &__logo {
    width: 160px;
    height: auto;

    &--trend {
      position: relative;
      top: 2px;
      width: 177px;
    }
    &--digicosmos {
      padding-right: 16px;
      padding-left: 16px;
      width: 166px;
    }
  }
  &__logoBrandText {
    position: relative;
    top: 1px;
    // padding-left: 20px;
    padding-left: 4px;
    // font-size: 26.5px;
    font-size: 26px;
  }
  &__toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.navigation {
  display: flex;

  &__items {
    cursor: pointer;
    display: block;
    padding: 0 12px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.8);
    &:hover,
    &--active {
      color: rgba(255, 255, 255, 1);
    }
  }
}
.notification {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 12px;
  margin-right: 0;
  padding: 0 16px;
  height: 100%;
  border-left: 1px solid #151515;
  border-right: 1px solid #151515;

  &__icon {
    position: relative;
    font-size: 16px;
  }
  &__dot {
    position: absolute;
    right: -2px;
    top: 1px;
    width: 10px;
    height: 10px;
    background-color: var(--bs-notification-alert-dot-color);
    border: 1px solid #fe666c;
    border-radius: 50%;
    animation: blinker 1s linear infinite;
  }
  &:hover {
    background-color: #ffffff26;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.user {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 100%;

  &:hover {
    background-color: #ffffff26;
  }
  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border: 1px solid #fff;
    border-radius: 50%;
  }
}
.dropdownPanel {
  position: absolute;
  top: 54px;
  color: #333;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid var(--bs-border-color-translucent);
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  z-index: 19;

  &--notification {
    right: 112px;
    max-width: 290px;
    // max-height: 560px;
  }
  &--user {
    padding: 8px;
    // width: 240px;
    right: 4px;
  }
  &__top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .btn {
      margin-bottom: 8px;
      width: 100%;
      max-width: 120px;
    }
  }
  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 60px;
    height: 60px;
    font-size: 43px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
  &__bottomButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-link-color);
    line-height: 45px;
    border-top: 1px solid #cccccc26;

    &:hover {
      color: var(--bs-link-hover-color);
    }
  }
}
.tabs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  &__item {
    cursor: pointer;
    width: 50%;
    padding: 8px 0;
    text-align: center;
    color: var(--bs-notification-tabs-color);
    background-color: var(--bs-notification-tabs-bg);

    &--active {
      cursor: default;
      color: #666;
      background-color: #ffffff;
    }
  }
}
.tabContent {
  padding: 0 12px;
}
.notificationItem {
  padding: 16px;
  border-top: 1px solid #cccccc26;
  // &__detail {}
  &__desc {
    display: block;
    margin: 0;
    font-size: 14px;
  }
  &__time {
    padding: 8px 0;
    font-size: 12px;
    color: #b0b0b0;
  }

  &:first-child {
    border: 0;
  }
}
</style>
