<template>
  <ul class="simpleInfoPanel">
    <li class="simpleInfoPanel__row">
      SR Num.:
      <strong class="simpleInfoPanel__value">
        {{ props.prodsrcaseId ? rowProps.prodsrcaseId : "-" }}
      </strong>
    </li>
    <li class="simpleInfoPanel__row">
      SR Status:
      <strong class="simpleInfoPanel__value">
        {{ props.prodsrcaseStatus ? props.prodsrcaseStatus : "-" }}
      </strong>
    </li>
    <li class="simpleInfoPanel__row">
      Files count(s):
      <strong class="simpleInfoPanel__value">
        {{ hasSentFilesListData.length }}
      </strong>
    </li>
    <li class="simpleInfoPanel__row">
      Stakeholders:
      <strong class="simpleInfoPanel__value">
        soros_liu@trendmicro.com, lucas_chen@trendmicro.com
      </strong>
    </li>
    <li class="simpleInfoPanel__row">
      Summary:
      <strong class="simpleInfoPanel__value">詳細</strong>
    </li>
  </ul>

  <div class="tableWrapper">
    <template v-if="tableAptStgResults.rows.length">
      <table-lite
        class="tableAptStgResults"
        :is-static-mode="true"
        :is-fixed-first-row="true"
        :max-height="650"
        :is-slot-mode="true"
        :columns="tableAptStgResults.columns"
        :rows="tableAptStgResults.rows"
        :total="tableAptStgResults.totalRecordCount"
        :sortable="tableAptStgResults.sortable"
        :page-options="tableAptStgResults.pageOptions"
        :page-size="100"
        :messages="tableAptStgResults.messages"
      >
        <template v-slot:function="data">
          <i
            class="bi bi-cloud-arrow-down-fill cursor-pointer me-2"
            @click="handleDownloadZipFile(data.value.Id)"
          ></i>
          <i
            class="bi bi-pencil-fill cursor-pointer me-2"
            @click="handleEditTableCell(data.value.Id)"
          ></i>
          <i
            class="bi bi-trash3-fill cursor-pointer"
            @click="handleDeleteTableAssets(data.value.Id)"
          ></i>
        </template>
        <template v-slot:Summary="data">
          <template v-if="data.value.Summary">
            <a
              href="javascript:;"
              class="link-primary"
              @click.stop="showSummaryPopup(data.value.Sha1)"
            >
              詳細
            </a>
            <!-- <div v-html="data.value.Summary"></div> -->
            <!-- <van-text-ellipsis
      rows="1"
      :content="data.value.Summary"
      expand-text="more"
      collapse-text="...close"
    /> -->
          </template>
          <template v-else> - </template>
        </template>
      </table-lite>
    </template>

    <div v-if="!tableAptStgResults.rows.length" class="noDataDisplay pb-5">
      <i class="bi bi-file-earmark noDataDisplay__icon"></i>
      <strong class="noDataDisplay__text">無資料</strong>
    </div>
  </div>

  <div class="accordion" style="display: none">
    <div v-if="isEnvProduction" class="accordion-item">
      <h2 class="accordion-header" id="prodAptData">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-prod"
        >
          <div class="accordion__title">
            <strong>Files send to APT:</strong>
            ({{ tableAptprodResults.rows.length }})筆
            <tepmplate v-if="props.prodData.length">
              /
              <span class="badge text-bg-dark">
                {{ props.prodData.length ? props.prodData[0].XCaseId : "-" }}
              </span>
            </tepmplate>
          </div>
        </button>
      </h2>
      <div
        id="collapse-prod"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#prodAptData"
      >
        <div class="accordion-body">
          <div v-if="props.prodData.length" class="accordion--secondaryBody">
            <div class="row">
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">客戶名稱</label>
                  <span class="fieldDisplay__data">
                    {{ props.prodData[0].Company }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Stakeholders</label>
                  <span class="fieldDisplay__data">
                    {{ props.prodData[0].Stakeholders }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Status</label>
                  <span class="fieldDisplay__data">
                    {{ props.prodData[0].Status }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Summary</label>
                  <span class="fieldDisplay__data">
                    <template v-if="props.prodData[0].Summary !== ''">
                      <a
                        href="javascript:;"
                        class="link-primary"
                        @click.stop="showSummaryPopup('prod')"
                      >
                        詳細
                      </a>
                    </template>
                    <template v-else>-</template>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="props.prodData.length" class="searchInput mb-3">
            <input
              type="text"
              v-model="searchFilesTerm"
              id="filterHermesFormResults"
              class="filter__input form-control"
              placeholder="輸入關鍵字..."
            />
          </div>
          <div v-if="tableAptprodResults.rows.length" class="tableWrapper">
            <table-lite
              class="tableAptprodResults"
              :is-static-mode="true"
              :is-fixed-first-row="true"
              :max-height="650"
              :is-slot-mode="true"
              :columns="tableAptprodResults.columns"
              :rows="tableAptprodResults.rows"
              :total="tableAptprodResults.totalRecordCount"
              :sortable="tableAptprodResults.sortable"
              :page-options="tableAptprodResults.pageOptions"
              :page-size="100"
              :messages="tableAptprodResults.messages"
            >
              <template v-slot:function="data">
                <i
                  class="bi bi-cloud-arrow-down-fill cursor-pointer me-2"
                  @click="handleDownloadZipFile(data.value.Id)"
                ></i>
                <i
                  class="bi bi-pencil-fill cursor-pointer me-2"
                  @click="handleEditTableCell(data.value.Id)"
                ></i>
                <i
                  class="bi bi-trash3-fill cursor-pointer"
                  @click="handleDeleteTableAssets(data.value.Id)"
                ></i>
              </template>
              <template v-slot:Summary="data">
                <template v-if="data.value.Summary">
                  <a
                    href="javascript:;"
                    class="link-primary"
                    @click.stop="showSummaryPopup(data.value.Sha1)"
                  >
                    詳細
                  </a>
                  <!-- <div v-html="data.value.Summary"></div> -->
                  <!-- <van-text-ellipsis
            rows="1"
            :content="data.value.Summary"
            expand-text="more"
            collapse-text="...close"
          /> -->
                </template>
                <template v-else> - </template>
              </template>
            </table-lite>
          </div>

          <div
            v-if="!tableAptprodResults.rows.length"
            class="noDataDisplay pb-5"
          >
            <i class="bi bi-file-earmark noDataDisplay__icon"></i>
            <strong class="noDataDisplay__text">無資料</strong>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isEnvProduction" class="accordion-item">
      <h2 class="accordion-header" id="stgAptData">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-stg"
        >
          <div class="accordion__title">
            <strong>Files send to APT:</strong>
            ({{ tableAptStgResults.rows.length }})筆
            <tepmplate v-if="props.stgData.length">
              /
              <span class="badge text-bg-dark">
                {{ props.stgData.length ? props.stgData[0].XCaseId : "-" }}
              </span>
            </tepmplate>
          </div>
        </button>
      </h2>
      <div
        id="collapse-stg"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#stgAptData"
      >
        <div class="accordion-body">
          <div v-if="props.stgData.length" class="accordion--secondaryBody">
            <div class="row">
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">客戶名稱</label>
                  <span class="fieldDisplay__data">
                    {{ props.stgData[0].Company }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Stakeholders</label>
                  <span class="fieldDisplay__data">
                    {{ props.stgData[0].Stakeholders }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Status</label>
                  <span class="fieldDisplay__data">
                    {{ props.stgData[0].Status }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Summary</label>
                  <span class="fieldDisplay__data">
                    <template v-if="props.stgData[0].Summary !== ''">
                      <a
                        href="javascript:;"
                        class="link-primary"
                        @click.stop="showSummaryPopup('stg')"
                      >
                        詳細
                      </a>
                    </template>
                    <template v-else>-</template>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="props.stgData.length" class="searchInput mb-3">
            <input
              type="text"
              v-model="searchFilesTerm"
              id="filterHermesFormResults"
              class="filter__input form-control"
              placeholder="輸入關鍵字..."
            />
          </div>
          <div v-if="tableAptStgResults.rows.length" class="tableWrapper">
            <table-lite
              class="tableAptStgResults"
              :is-static-mode="true"
              :is-fixed-first-row="true"
              :max-height="650"
              :is-slot-mode="true"
              :columns="tableAptStgResults.columns"
              :rows="tableAptStgResults.rows"
              :total="tableAptStgResults.totalRecordCount"
              :sortable="tableAptStgResults.sortable"
              :page-options="tableAptStgResults.pageOptions"
              :page-size="100"
              :messages="tableAptStgResults.messages"
            >
              <template v-slot:function="data">
                <i
                  class="bi bi-cloud-arrow-down-fill cursor-pointer me-2"
                  @click="handleDownloadZipFile(data.value.Id)"
                ></i>
                <i
                  class="bi bi-pencil-fill cursor-pointer me-2"
                  @click="handleEditTableCell(data.value.Id)"
                ></i>
                <i
                  class="bi bi-trash3-fill cursor-pointer"
                  @click="handleDeleteTableAssets(data.value.Id)"
                ></i>
              </template>
              <template v-slot:Summary="data">
                <template v-if="data.value.Summary">
                  <a
                    href="javascript:;"
                    class="link-primary"
                    @click.stop="showSummaryPopup(data.value.Sha1)"
                  >
                    詳細
                  </a>
                  <!-- <div v-html="data.value.Summary"></div> -->
                  <!-- <van-text-ellipsis
            rows="1"
            :content="data.value.Summary"
            expand-text="more"
            collapse-text="...close"
          /> -->
                </template>
                <template v-else> - </template>
              </template>
            </table-lite>
          </div>

          <div
            v-if="!tableAptStgResults.rows.length"
            class="noDataDisplay pb-5"
          >
            <i class="bi bi-file-earmark noDataDisplay__icon"></i>
            <strong class="noDataDisplay__text">無資料</strong>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="popupAptSummaryDetail" id="fixedToolbar" class="fixedToolbar">
    <div class="card">
      <div
        id="fixedToolbar__header"
        class="card-header pe-5"
        style="cursor: move"
      >
        <i
          class="van-icon van-icon-cross fixedToolbar__closeBtn"
          role="button"
          tabindex="0"
          @click="popupAptSummaryDetail = false"
        ></i>
        <i class="bi bi-arrows-move"></i>
        Summary / {{ selectedSrName }}
      </div>
      <div class="card-body">
        <div v-html="selectedSummaryContent"></div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import TableLite from "vue3-table-lite";

const props = defineProps([
  "stgData",
  "prodData",
  "prodsrcaseId",
  "prodsrcaseStatus",
]);
console.log("props", props);
const store = useStore();
const isEnvProduction = ref(null);
const popupAptSummaryDetail = ref(false);
const selectedSummaryContent = ref(null);
const selectedSrName = ref(null);
// Init table settings
const searchFilesTerm = ref(""); // Search text
const hasSentFilesListData = ref([]);
const workspaceCollectFilesDataVuex = computed(() => {
  return store.getters.workspaceCollectFilesData;
});
const updateHasSendFilesListView = () => {
  hasSentFilesListData.value = workspaceCollectFilesDataVuex.value.filter(
    (file) => file.ChangeStatus === 2
  );
};

const tableAptStgResults = reactive({
  isLoading: false,
  columns: [
    {
      label: "Campaign",
      field: "Campaign",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Campaign) {
          const obj = JSON.parse(row.Campaign);
          if (obj.length) {
            const keys = Object.keys(obj[0]);
            return `${keys}`;
          } else {
            return `-`;
          }
        } else {
          return "-";
        }
      },
    },
    {
      label: "Connection",
      field: "Connection",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Connection) {
          return `${row.Connection}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Detection",
      field: "Detection",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Detection) {
          return `${row.Detection}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Filename",
      field: "Filename",
      width: "",
      sortable: true,
      isKey: true,
    },
    {
      label: "Operator",
      field: "Operator",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Operator) {
          return `${row.Operator}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "PatterVersion",
      field: "PatterVersion",
      width: "",
      sortable: false,
      display: function (row) {
        if (row.PatterVersion) {
          return `${row.PatterVersion}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "ReleaseDate",
      field: "ReleaseDate",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.ReleaseDate) {
          return `${row.ReleaseDate}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "SHA1",
      field: "Sha1",
      width: "10%",
      sortable: true,
    },
    // {
    //   label: "Stakeholders",
    //   field: "Stakeholders",
    //   width: "",
    //   sortable: true,
    // },
    // {
    //   label: "Status",
    //   field: "Status",
    //   width: "",
    //   sortable: true,
    // },
  ],
  rows: computed(() => {
    console.log("props.stgData", props.stgData);
    return props.stgData.filter(
      (x) =>
        x.Campaign.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Company.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.Connection.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Detection.includes(searchFilesTerm.value.toLowerCase()) ||
        x.Filename.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Operator.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.PatterVersion.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.ReleaseDate.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Sha1.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.Stakeholders.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Status.toLowerCase().includes(searchFilesTerm.value.toLowerCase())
    );
  }),
  totalRecordCount: computed(() => {
    return tableAptStgResults.rows.length;
  }),
  sortable: {
    order: "index",
    sort: "asc",
  },
  messages: {
    pagingInfo: "現在顯示 {0} 到 {1}筆 共{2}筆",
    pageSizeChangeLabel: "每頁筆數:",
    gotoPageLabel: "現在頁數:",
    noDataAvailable: "無資料",
  },
  pageOptions: [
    {
      value: 20,
      text: 20,
    },
    {
      value: 50,
      text: 50,
    },
    {
      value: 100,
      text: 100,
    },
    {
      value: 500,
      text: 500,
    },
    {
      value: 10000,
      text: "All",
    },
  ],
});
const tableAptprodResults = reactive({
  isLoading: false,
  columns: [
    {
      label: "Campaign",
      field: "Campaign",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Campaign) {
          const obj = JSON.parse(row.Campaign);
          if (obj.length) {
            const keys = Object.keys(obj[0]);
            return `${keys}`;
          } else {
            return `-`;
          }
        } else {
          return "-";
        }
      },
    },
    // {
    //   label: "Company",
    //   field: "Company",
    //   width: "",
    //   sortable: true,
    // },
    {
      label: "Connection",
      field: "Connection",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Connection) {
          return `${row.Connection}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Detection",
      field: "Detection",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Detection) {
          return `${row.Detection}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Filename",
      field: "Filename",
      width: "",
      sortable: true,
      isKey: true,
    },
    {
      label: "Operator",
      field: "Operator",
      width: "",
      sortable: true,
    },
    {
      label: "PatterVersion",
      field: "PatterVersion",
      width: "",
      sortable: false,
      display: function (row) {
        if (row.PatterVersion) {
          return `${row.PatterVersion}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "ReleaseDate",
      field: "ReleaseDate",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.ReleaseDate) {
          return `${row.ReleaseDate}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "SHA1",
      field: "Sha1",
      width: "10%",
      sortable: true,
    },
    // {
    //   label: "Stakeholders",
    //   field: "Stakeholders",
    //   width: "",
    //   sortable: true,
    // },
    // {
    //   label: "Status",
    //   field: "Status",
    //   width: "",
    //   sortable: true,
    // },
  ],
  rows: computed(() => {
    console.log("props.prodData", props.prodData);
    return props.prodData.filter(
      (x) =>
        x.Campaign.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Company.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.Connection.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Detection.includes(searchFilesTerm.value.toLowerCase()) ||
        x.Filename.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Operator.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.PatterVersion.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.ReleaseDate.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Sha1.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.Stakeholders.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Status.toLowerCase().includes(searchFilesTerm.value.toLowerCase())
    );
  }),
  totalRecordCount: computed(() => {
    return tableAptprodResults.rows.length;
  }),
  sortable: {
    order: "index",
    sort: "asc",
  },
  messages: {
    pagingInfo: "現在顯示 {0} 到 {1}筆 共{2}筆",
    pageSizeChangeLabel: "每頁筆數:",
    gotoPageLabel: "現在頁數:",
    noDataAvailable: "無資料",
  },
  pageOptions: [
    {
      value: 20,
      text: 20,
    },
    {
      value: 50,
      text: 50,
    },
    {
      value: 100,
      text: 100,
    },
    {
      value: 500,
      text: 500,
    },
    {
      value: 10000,
      text: "All",
    },
  ],
});

const showSummaryPopup = (env) => {
  if (env == "stg") {
    selectedSummaryContent.value = props.stgData[0].Summary;
    selectedSrName.value = props.stgData[0].XCaseId;
  } else {
    selectedSummaryContent.value = props.prodData[0].Summary;
    selectedSrName.value = props.prodData[0].XCaseId;
  }
  // const selectedItem = props.stgData.filter((item) => item.Sha1 == Sha1);
  // selectedSummaryContent.value = selectedItem[0].Summary;
  // selectedSrName.value = selectedItem[0].Filename;
  popupAptSummaryDetail.value = true;
  setTimeout(() => {
    bindingDragElement(document.getElementById("fixedToolbar"));
  }, 500);
};

const bindingDragElement = (element) => {
  if (!element) {
    return false;
  }
  console.log("bindingDragElement", element);
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(element.id + "__header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(element.id + "__header").onmousedown =
      dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    element.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    element.style.top = element.offsetTop - pos2 + "px";
    element.style.left = element.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
};

onMounted(() => {
  if (process.env.NODE_ENV == "production") {
    isEnvProduction.value = true;
  } else {
    isEnvProduction.value = false;
  }
  updateHasSendFilesListView();
});
</script>

<style scoped lang="scss">
.simpleInfoPanel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
  padding: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 25px;
  background-color: #ddd;
  border: 1px solid #cbcbcb;
  border-radius: 5px;

  &__row {
    padding: 4px 16px;
    // width: 50%;
    border-right: 1px dashed #b6b6b6;
    &:last-child {
      border: 0;
    }
  }
  &__value {
    display: block;
  }
}
.accordion {
  margin-bottom: 32px;
}
.accordion-body {
  padding-top: 0;
}
.accordion--secondaryBody {
  margin: 0 -20px 16px;
  padding: 16px 20px;
  background-color: #e9e9e9;
  border-bottom: 1px solid #ccc;
}
.fieldDisplay {
  display: flex;
  align-items: center;
  line-height: 32px;
  &__label {
    width: 125px;
    color: rgba(var(--bs-inverse-rgb), 0.5);
    &--large {
      width: 122px;
    }
  }
  &__data {
    padding-left: 8px;
    // max-width: 239px;
  }
}
.fixedToolbar {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 400px;
  transform: translate3d(500px, 449px, 0px);
  transition: none 0s ease 0s;

  background-color: rgb(255 255 255 / 90%);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 999;
  hr {
    margin: 0.5rem 0;
  }
  &__fileList {
    max-height: 350px;
    overflow: auto;
  }
  &__closeBtn {
    position: absolute;
    right: 16px;
    top: 14px;
  }
}
</style>
<style scoped>
:deep(.vtl-row) {
  overflow-y: auto;
}
:deep(.vtl-table) {
  display: block !important;
  /* width: 3400px; */
}
</style>
