<template>
  <div
    class="pageLogin"
    :class="{ 'pageLogin--trendmicro': isActivatedTrendMicro }"
  >
    <header v-if="isActivatedTrendMicro" class="header container-xl">
      <img
        class="header__logo"
        src="https://cdn-assets-cloud.frontify.com/s3/frontify-cloud-files-us/eyJwYXRoIjoiZnJvbnRpZnlcL2ZpbGVcL21iSmExcFRMSnhzTm1aUjlrZThvLnBuZyJ9:frontify:vm412E3oxj8PRlBTaZWFXk6_rvyIVIrnyQ_i7tBV4Nk?width={width}&rect=0,26.981707317073,800,223.01829268293&reference_width=800"
        alt="Trend Micro"
      />
    </header>
    <section class="loginPanel container-xl">
      <h1 class="loginPanel__title">{{ customerName }} IR System</h1>
      <h2 class="loginPanel__subTitle mb-3">
        透過您的 {{ customerName }} 帳號進行登入
      </h2>
      <template v-if="!AZURE_AD_LOGIN">
        <div class="loginPanel__panel needs-validation">
          <div class="form-floating mb-3">
            <input
              class="form-control"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              autofocus
              required=""
              v-model="email"
            />
            <label class="loginPanel__placeholder" for="floatingInput"
              >Email</label
            >
            <div class="invalid-tooltip">Please enter a valid email.</div>
          </div>

          <div class="form-floating mb-3">
            <input
              class="loginPanel__field form-control"
              type="password"
              id="password"
              name="password"
              placeholder="密碼"
              minlength="6"
              required=""
              v-model="password"
              @keydown.enter="BeforeRegister"
            />
            <label class="loginPanel__placeholder" for="floatingPassword"
              >密碼</label
            >
            <!-- <div class="valid-feedback">Looks good!</div> -->
            <div class="invalid-tooltip">
              Please enter a password at least 6 characters.
            </div>
          </div>

          <hr />

          <button
            type="button"
            class="btn btn-trend loginPanel__buttons mb-4"
            @click="BeforeRegister()"
          >
            登入
          </button>
          <a class="btn btn-link btn-block loginPanel__buttons" href="#"
            >忘記密碼?</a
          >
        </div>
      </template>
      <template v-else>
        <button
          type="button"
          class="btn btn-trend loginPanel__buttons mb-4"
          style="width: 128px"
          @click="LoginByAD()"
        >
          Azure ID 登入
        </button>
      </template>

      <span class="text-secondary opacity-50">{{ CORE_VERSION }}</span>

      <!-- <p class="copyright">
        Copyright © 1999-{{ currentYear }} {{ customerName }} Incorporated. All
        rights reserved.
      </p> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "../components/HelloWorld.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { showDialog } from "vant";

import AuthService from "../services/auth.service";
// import GraphService from "../services/graph.service";
import { special } from "../special";

export default {
  name: "LoginPage",
  components: {
    // HelloWorld,
  },
  setup() {
    // eslint-disable-next-line no-undef
    const AZURE_AD_LOGIN = special.AZURE_AD_LOGIN || false;
    // eslint-disable-next-line no-undef
    const CORE_VERSION = versionControl.core.version || "v0.1.00";
    const email = ref("");
    const password = ref("");
    const error = ref(null);
    const currentYear = computed(() => {
      return store.getters.currentYear;
    });

    const store = useStore();
    const router = useRouter();

    const authService = new AuthService();
    // const graphService = new GraphService();
    const user = ref(null);
    const loginFailed = ref(false);

    const BeforeRegister = () => {
      const forms = document.querySelectorAll(".needs-validation");
      Array.from(forms).forEach((form) => {
        form.addEventListener(
          "click",
          () => {
            form.classList.add("was-validated");
            if (document.querySelectorAll("input:invalid").length == 0) {
              LoginByFirebase();
            }
          },
          false
        );
      });
    };

    const LoginByFirebase = async () => {
      try {
        const response = await store.dispatch("logInFirebase", {
          email: email.value,
          password: password.value,
        });
        console.log("response - Login.vue response", response);
        console.log("response - Login.vue response.status", response.status);
        console.log("response - Login.vue response.message", response.message);
        console.log("response - Login.vue response.data", response.data);
        console.log("Dispatch logIn FINISHED");

        if (response.status == 200) {
          localStorage.setItem(
            "firebase.idtoken",
            response.data._tokenResponse.idToken
          );
          router.push("/caseManagement");
        } else {
          showDialog({
            message: response.message,
            confirmButtonText: "返回",
          }).then(() => {
            // on close
          });
        }
        // router.push("/index");
      } catch (err) {
        error.value = err.message;
      }
    };

    const LoginByAD = () => {
      authService
        .login()
        .then(
          (userData) => {
            if (userData) {
              user.value = userData;
              console.log("AAD 登入成功", userData);
              const registerRedirect =
                sessionStorage.getItem("SWS_pageRedirect");
              sessionStorage.removeItem("SWS_pageRedirect");
              if (registerRedirect?.length > 0) {
                router.push(registerRedirect);
              } else {
                router.push("/caseManagement");
              }
            } else {
              loginFailed.value = true;
            }
          },
          () => {
            loginFailed.value = true;
          }
        )
        .catch((err) => {
          console.log("App login() error - LoginByAD / Login.vue", err);
          // handle error
        });
    };

    // onMounted(() => {
    // const idtoken = localStorage.getItem("msal.idtoken");
    // if (idtoken) {
    //   router.push("/caseManagement");
    // } else {
    //   sessionStorage.clear();
    // }
    // sessionStorage.clear();
    // });

    return {
      BeforeRegister,
      LoginByFirebase,
      LoginByAD,
      email,
      password,
      error,
      currentYear,
      AZURE_AD_LOGIN,
      CORE_VERSION,
    };
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      customerName: special.custom_name,
      isActivatedTrendMicro: false,
    };
  },
  beforeMount() {
    // this.parmsId = this.$route.params.customerId;
    this.checkWhichCustomer();
  },
  methods: {
    checkWhichCustomer() {
      if (this.customerName == "Trend Micro") {
        this.isActivatedTrendMicro = true;
      } else {
        this.isActivatedTrendMicro = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  border: 0;
}
.pageLogin {
  width: 100%;
  height: 100vh;
  color: #333;
  background-color: #000;
  background-image: url("../assets/image/background/Gray-Gradient-3-with-pattern-compressed.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top: 2px solid var(--bs-color-theme);

  &--trendmicro {
    color: #fff;
    background-image: url("../assets/image/background/Red-Black-Gradient-3-with-pattern-No-Dots.jpg");

    h1 {
      color: #fff;
    }
    .loginPanel__subTitle {
      color: #fff;
    }
    .form-control {
      background-color: #fff;
    }
  }
}
.header {
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 70px;
  background-color: #000;

  &__logo {
    margin-top: 6px;
    width: 200px;
    height: 56px;
  }
}

.loginPanel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__title {
    font-size: 40px;
  }
  &__subTitle {
    font-size: 18px;
  }
  &__panel {
    width: 320px;
  }
  &__placeholder {
    color: #999;
  }
  &__buttons {
    padding: 8px 12px;
    width: 100%;
  }
}
</style>
