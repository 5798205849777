<template>
  <div
    class="timeLineCard"
    :class="[`timeLineCard--${event.event_progress_eng}`]"
  >
    <div class="timeLineItem__title">
      <i :class="event.event_icon_className"></i>
      {{ event.event_progress }}
    </div>
    <template v-for="item in event.events" :key="item.AlertId">
      <div class="timeLineItem">
        <div class="timeLineItem__time">
          {{ item.update_date }}
          <span>{{ item.update_time }}</span>
        </div>
        <div class="timeLineItem__eventName">
          <template v-if="item.operated_by"
            >"{{ item.operated_by }}" 將
          </template>
          <template v-if="item.event_name == '系統指派' || item.operated_by">
            {{ item.event_name }} to "{{ item.owner }}"
          </template>
          <template v-else>
            "{{ item.owner }}" {{ item.event_name }}
            <van-popover
              v-model:show="showPopover"
              theme="dark"
              placement="top"
            >
              <div class="popoverContent">{{ item.Remark }}</div>
              <template #reference>
                <i v-if="item.State == 1" class="bi bi-card-text"></i>
              </template>
            </van-popover>
          </template>
        </div>
      </div>
    </template>
    <div class="timeLineItem timeLineItem--default">
      <div class="timeLineItem__time">
        {{ caseDetailDataVuex.create_time_date }}
        <span>{{ caseDetailDataVuex.create_time_time }}</span>
      </div>
      <div class="timeLineItem__eventName">案件告警</div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "TrackEventBlock",
  props: ["event"],
  setup() {
    const store = useStore();
    const showPopover = ref(false);
    const caseDetailDataVuex = computed(() => {
      return store.getters.caseDetailData;
    });
    return { caseDetailDataVuex, showPopover };
  },
};
</script>
<style scoped lang="scss">
.timeLineCard {
  margin-top: 16px;
  margin-left: 64px;
  border: 1px solid #ccc;

  &--preparing {
    border-left: 4px solid var(--bs-process-preparing-bg-color-rgba);
    .timeLineItem__title {
      color: var(--bs-process-preparing-bg-color-rgba);
    }
  }
  &--inprogress {
    border-left: 4px solid var(--bs-process-inprogress-bg-color-rgba);
    .timeLineItem__title {
      color: var(--bs-process-inprogress-bg-color-rgba);
    }
  }
  &--closed {
    border-left: 4px solid var(--bs-process-closed-bg-color-rgba);
    .timeLineItem__title {
      color: var(--bs-process-closed-bg-color-rgba);
    }
  }
  &--cancel {
    border-left: 4px solid var(--bs-process-cancel-bg-color-rgba);
    .timeLineItem__title {
      color: var(--bs-process-cancel-bg-color-rgba);
    }
  }
  &:last-child .timeLineItem--default {
    display: block;
  }
}
.timeLineItem {
  position: relative;
  padding: 8px 12px;
  &__title {
    padding: 8px 12px;
    font-size: 18px;
  }
  &__time {
    position: absolute;
    // left: -64px;
    right: 304px;
    font-size: 12px;
    line-height: 12px;
    text-align: right;

    span {
      display: block;
    }
  }
  &__eventName {
    padding-left: 28px;
    font-size: 14px;
    word-wrap: break-word;
  }
  &--default {
    display: none;
  }
}
.popoverContent {
  padding: 8px;
  font-size: 12px;
  max-width: 230px;
}
</style>
