<template>
  <van-form :class="{ 'opacity-75': isProgress.popupLoading }">
    <div v-if="isProgress.popupLoading" class="position--center">
      <van-loading class="text-center" style="margin-top: 20%" size="60" />
    </div>
    <div class="popup__header">Add New Asset</div>
    <div class="alert alert-primary" role="alert">
      <ul style="margin-left: 16px; list-style: circle">
        <li>
          建立主機後，請透過 "Upload Virus Sample" 將檔案歸戶於該新增主機。
        </li>
        <li>主機建立後可於"主機清單"編輯相關資訊。</li>
      </ul>
    </div>
    <div v-if="v$.$silentErrors.length" class="bd-callout bd-callout-warning">
      <strong>填入資料錯誤</strong>
      請修正以下 {{ v$.$silentErrors.length }} 個欄位： {{ errorFields }}
    </div>
    <div class="popup__content">
      <div class="fieldRow">
        <label
          for="new_asset_role"
          class="fieldRow__label fieldRow__label--required"
        >
          Role
        </label>
        <select
          v-model="newAssetData.Role"
          id="new_asset_role"
          class="form-select fieldRow__editField"
        >
          <option value="Server">Server</option>
          <option value="User">User</option>
        </select>
      </div>
      <van-field
        v-model="newAssetData.ServerIp"
        type="text"
        name="ip"
        class="fieldRow"
        :class="{
          invalid: v$.ServerIp.serverIpRule.$invalid,
          valid: !v$.ServerIp.serverIpRule.$invalid,
        }"
        label="IP"
        label-class="fieldRow__label"
        :border="false"
        required
      />
      <div
        v-if="v$.ServerIp.serverIpRule.$invalid"
        class="van-field__error-message"
      >
        {{ validationRegex.ipAddress.errMessage }}
      </div>
      <van-field
        v-model="newAssetData.DomainName"
        type="text"
        name="domain_name"
        class="fieldRow"
        :class="{
          invalid: v$.DomainName.domainNameRule.$invalid,
          valid: !v$.DomainName.domainNameRule.$invalid,
        }"
        label="Domain Name"
        label-class="fieldRow__label"
        :border="false"
        required
      />
      <div
        v-if="v$.DomainName.domainNameRule.$invalid"
        class="van-field__error-message"
      >
        {{ validationRegex.name.errMessage }}
      </div>
      <van-field
        v-model="newAssetData.HostName"
        type="text"
        name="domain_name"
        class="fieldRow"
        :class="{
          invalid: v$.HostName.HostNameRule.$invalid,
          valid: !v$.HostName.HostNameRule.$invalid,
        }"
        label="Host Name"
        label-class="fieldRow__label"
        :border="false"
        required
      />
      <div
        v-if="v$.HostName.HostNameRule.$invalid"
        class="van-field__error-message"
      >
        {{ validationRegex.name.errMessage }}
      </div>
    </div>
    <div class="popup__footer">
      <button
        class="btn btn-primary"
        :disabled="v$.$invalid"
        @click="handleAddAsset()"
      >
        新增主機
      </button>
    </div>
  </van-form>
</template>

<script setup>
import { reactive, computed, defineEmits, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { showDialog } from "vant";
import formHelper from "../formHelper";
// import { form_config } from "../form.config";
import { useVuelidate } from "@vuelidate/core";

const route = useRoute();
const store = useStore();
let parmsId = ref(null);
const validationRegex = formHelper.validation;
const errorFields = ref(null);
const emits = defineEmits(["showPopup", "reloadData"]);

// const store = useStore();
// const popupAddNewAsset = ref(false);

// const initConfig = form_config || [];
const isProgress = computed(() => {
  return store.getters.isProgress;
});

const newAssetData = reactive({
  Role: "Server",
  ServerIp: null,
  DomainName: null,
  HostName: null,
});

const serverIpRule = (value) => {
  return validationRegex.ipAddress.reg.test(value);
};
const domainNameRule = (value) => {
  return validationRegex.englishShortName.reg.test(value);
};
const HostNameRule = (value) => {
  return validationRegex.englishShortName.reg.test(value);
};
const rules = {
  ServerIp: { serverIpRule },
  DomainName: { domainNameRule },
  HostName: { HostNameRule },
};
const v$ = useVuelidate(rules, newAssetData, { $scope: false });

const resetAddAssetForm = () => {
  newAssetData.Role = "Server";
  newAssetData.ServerIp = null;
  newAssetData.DomainName = null;
  newAssetData.HostName = null;
};
const handleAddAsset = async () => {
  console.log("handleAddAsset");
  store.dispatch("handlePopupLoadingState", true);
  const addAsset2SendPackage = {
    caseId: parmsId.value,
    data: newAssetData,
  };
  const sourceData = addAsset2SendPackage;
  console.log("handleAddAsset - postCreateAsset", sourceData);

  const response = await store.dispatch("postCreateAsset", sourceData);
  console.log("response - postCreateAsset", response);
  if (response.status == 200) {
    emits("showPopup", false);
    emits("reloadData");

    showDialog({
      message: "新增主機 - 成功！",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      store.dispatch("handlePopupLoadingState", false);
      resetAddAssetForm();
    });
  } else {
    showDialog({
      message: "新增主機 - 失敗",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      store.dispatch("handlePopupLoadingState", false);
    });
  }
};

watch(v$, () => {
  const filterPropertyArray = [];
  if (v$.value.$silentErrors.length) {
    v$.value.$silentErrors.map((obj) => {
      const newObj = { ...obj };
      filterPropertyArray.push(obj.$property);

      return newObj;
    });
  }
  filterPropertyArray.forEach((item, i) => {
    filterPropertyArray[i] = formHelper.whatFieldNameItis(item);
  });
  errorFields.value = Object.values(filterPropertyArray).toString();
  console.log("errorFields", errorFields.value);
});

watch(
  route,
  () => {
    parmsId.value = route.params.caseId;
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" scoped>
.van-field__error-message {
  display: block;
  width: 100%;
  margin-left: 140.4px !important;
  line-height: 24px;
}
.fieldRow + .van-field__error-message {
  margin-top: -8px;
}
</style>
<style scoped>
/* Error Fields */
.form-select.invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
::v-deep(.invalid .van-field__control) {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
::v-deep(.invalid.v-select) {
  padding-top: 3px;
  padding-bottom: 3px;
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
/* v-select component styles */
::v-deep(.vs__dropdown-toggle) {
  padding: 3px 0;
  height: 37px;
  border: 0;
}
::v-deep(.vs__actions) {
  display: none;
}
::v-deep(.vs__search) {
  padding-left: 0;
}
::v-deep(.vs__selected) {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  width: 350px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
