<template>
  <!-- props: {{ props }} -->
  <!-- propertyValues: {{ propertyValues }} -->
  <!-- cartFilesListData: {{ cartFilesListData }} -->
  <!-- workspaceCollectFilesDataVuex: {{ workspaceCollectFilesDataVuex }} -->
  <!-- StakeHolderListOptions: {{ StakeHolderListOptions }} -->
  <!-- tableCollectFiles: {{ tableCollectFiles }} -->
  <!-- searchFilesTerm: {{ searchFilesTerm }} -->
  <div v-if="isProgress.page" class="preLoader preLoader--opacity10">
    <span class="spin"></span>
  </div>
  <div class="multipleSelector">
    <div class="multipleSelector__wrapper">
      <div class="multipleSelector__box multipleSelector__box--left">
        <div class="position-relative">
          <ul class="multipleSelector__status">
            <li>
              已選擇 {{ currentCheckedFilesLength }}/{{
                updatedFilesData.length -
                currentMarkedFilesLength +
                currentMarkedFilesLength
              }}
            </li>
            <li>已隱藏 {{ currentMarkedFilesLength }}</li>
          </ul>

          <div
            v-if="currentMarkedFilesLength"
            class="multipleSelector__switcher"
          >
            <span class="label">顯示全部</span>
            <van-switch v-model="isShowMarked" />
          </div>
        </div>

        <div class="searchInput multipleSelector__searchInput">
          <input
            type="text"
            v-model="searchFilesTerm"
            id="filterFilesList"
            class="filter__input form-control"
            placeholder="輸入關鍵字..."
            :disabled="!workspaceCollectFilesDataVuex.length"
          />
          <i class="bi bi-filter"></i>
        </div>
        <div class="multipleSelector__content multipleSelector__content--left">
          <div v-if="workspaceCollectFilesDataVuex.length" class="tableWrapper">
            <table-lite
              class="tableCollectFiles"
              :is-static-mode="true"
              :is-fixed-first-row="false"
              :max-height="650"
              :is-slot-mode="true"
              :has-checkbox="true"
              :start-collapsed="false"
              :is-keep-collapsed="true"
              :grouping-key="tableCollectFiles.groupingKey"
              :has-group-toggle="tableCollectFiles.hasGroupToggle"
              :grouping-display="tableCollectFiles.groupingDisplay"
              :columns="tableCollectFiles.columns"
              :rows="tableCollectFiles.rows"
              :total="tableCollectFiles.totalRecordCount"
              :sortable="tableCollectFiles.sortable"
              :page-options="tableCollectFiles.pageOptions"
              :page-size="500"
              :messages="tableCollectFiles.messages"
              @is-finished="tableLoadingFinish"
              @return-checked-rows="updateCheckedRows"
            >
              <template v-slot:function="data">
                <div class="columnFunction">
                  <i
                    class="bi bi-pencil-fill columnFunction__button"
                    @click="handleEditTableCell(data.value.FileID)"
                  ></i>
                </div>
              </template>

              <template v-slot:IsMarked="data">
                <div class="form-check form-switch">
                  <input
                    v-model="data.value.IsMarked"
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    :id="'flexSwitchCheckChecked' + data.value.FileID"
                    :checked="data.value.IsMarked"
                    style="width: 34px; height: 18px"
                    @change="handleFileMarkedChange(data.value.FileID, $event)"
                  />
                </div>
              </template>

              <template v-slot:IsAptUploaded="data">
                <span
                  v-if="data.value.SRCaseResultStg.CaseIrInfo"
                  class="badge text-bg-success"
                >
                  <i class="bi bi-check-lg"></i> Stg 已送樣
                </span>
                <span
                  v-if="data.value.SRCaseResultStg.SRCaseResultProd"
                  class="badge text-bg-success"
                >
                  <i class="bi bi-check-lg"></i> Prod 已送樣
                </span>
                <span
                  v-if="
                    !data.value.SRCaseResultStg.CaseIrInfo &&
                    !data.value.SRCaseResultStg.SRCaseResultProd
                  "
                  class="badge text-bg-secondary"
                  >未送樣</span
                >
              </template>
            </table-lite>
          </div>
          <div
            v-if="!workspaceCollectFilesDataVuex.length"
            class="noDataDisplay"
          >
            <i class="bi bi-file-earmark noDataDisplay__icon"></i>
            <strong class="noDataDisplay__text">無資料</strong>
          </div>
        </div>
      </div>
      <div class="multipleSelector__footer multipleSelector__footer--left">
        All Files
      </div>
      <button
        type="button"
        class="multipleSelector__collect btn btn-secondary btn-lg"
        style="display: none"
        :disabled="!currentCheckedFilesLength"
        @click="handleAdd2Cart()"
      >
        <i class="fa-solid fa-right-long"></i>
      </button>
      <button
        type="button"
        class="multipleSelector__collect btn btn-secondary btn-lg"
        :disabled="!currentCheckedFilesLength"
        @click="handleAdd2CartNew()"
      >
        <i class="fa-solid fa-right-long"></i>
      </button>
    </div>

    <div class="multipleSelector__wrapper">
      <div class="multipleSelector__box multipleSelector__box--right">
        <div class="position-relative">
          <ul
            class="multipleSelector__status multipleSelector__status--underline"
          >
            <li>
              <div class="form-check">
                <input
                  class="form-check-checkbox"
                  type="checkbox"
                  id="checkboxPending"
                  v-model="isShowPendingItems"
                />
                <label class="form-check-label" for="checkboxPending">
                  待送樣 {{ cartFilesListData ? cartFilesListData.length : 0 }}
                </label>
              </div>
              <!-- 待送樣 {{ cartFilesListData ? cartFilesListData.length : 0 }} -->
            </li>
            <li>
              <div class="form-check">
                <input
                  class="form-check-checkbox"
                  type="checkbox"
                  id="checkboxSend"
                  v-model="isShowSendItems"
                />
                <label class="form-check-label" for="checkboxSend">
                  已送樣
                  {{ hasSentFilesListData ? hasSentFilesListData.length : 0 }}
                </label>
              </div>
              <!-- 已送樣
              {{ hasSentFilesListData ? hasSentFilesListData.length : 0 }} -->
            </li>
          </ul>
        </div>

        <ul class="multipleSelector__info">
          <li>
            送樣案號：
            <template v-if="!isEnvProduction">
              <strong>{{ props.stgsrcaseId ? props.stgsrcaseId : "-" }}</strong>
              (stg)
            </template>
            <template v-else>
              <strong>
                {{ props.prodsrcaseId ? props.prodsrcaseId : "-" }}
              </strong>
              <!-- (prod) -->
            </template>
          </li>
          <li>
            送樣狀態:
            <template v-if="!isEnvProduction">
              <strong>
                {{ props.stgsrcaseStatus ? props.stgsrcaseStatus : "未送樣" }}
              </strong>
              (stg)
            </template>
            <template v-else>
              <strong>
                {{ props.prodsrcaseStatus ? props.prodsrcaseStatus : "未送樣" }}
              </strong>
              <!-- (prod) -->
            </template>
          </li>
        </ul>
        <div class="multipleSelector__content multipleSelector__content--right">
          <template v-if="isShowPendingItems">
            <h3
              v-if="cartFilesListData.length"
              class="multipleSelector__subject multipleSelector__subject--pending"
            >
              待送樣
            </h3>
            <div v-if="cartFilesListData.length" class="mb-3">
              <template v-for="item in cartFilesListData" :key="item.FileID">
                <div class="cell">
                  <div class="cell__box cell__box--left">
                    <span class="cell__name">
                      {{ item.Filename }}
                      <span
                        v-if="item.IsSHA1DuplicatedInCart"
                        class="badge text-bg-warning ms-2"
                      >
                        SHA1 重複(待送)
                      </span>
                    </span>
                    <div class="cell__description">
                      <i class="bi bi-pc-display"></i>
                      {{ item.ServerIp }}_{{ item.DomainName }}_{{
                        item.HostName
                      }}
                    </div>
                  </div>
                  <div class="cell__box cell__box--right">
                    <span class="cell__slot1">
                      <i class="bi bi-person"></i>
                      {{ item.Add2CartBy }}
                    </span>
                    <span class="cell__slot2"> {{ item.Add2CartTime }} </span>
                  </div>
                  <i
                    class="cell__button bi bi-x-lg"
                    @click="handleDeleteFromCart(item.FileID)"
                  ></i>
                </div>
              </template>
            </div>
          </template>
          <template v-if="isShowSendItems">
            <h3
              v-if="hasSentFilesListData.length"
              class="multipleSelector__subject"
            >
              已送樣
            </h3>
            <div v-if="hasSentFilesListData.length">
              <template v-for="item in hasSentFilesListData" :key="item.FileID">
                <div class="cell cell--done">
                  <div class="cell__box cell__box--left">
                    <span class="cell__name">{{ item.Filename }}</span>
                    <div class="cell__description">
                      <i class="bi bi-pc-display"></i>
                      {{ item.ServerIp }}_{{ item.DomainName }}_{{
                        item.HostName
                      }}
                    </div>
                  </div>
                  <div class="cell__box cell__box--right">
                    <span class="cell__slot1">
                      <i class="bi bi-person"></i>
                      {{ item.Add2CartBy }}
                    </span>
                    <span class="cell__slot2"> {{ item.Add2CartTime }} </span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="multipleSelector__footer multipleSelector__footer--right">
        <button
          v-if="ENABLE_FUNCTION_APT_SUBMIT_STAGING && !isEnvProduction"
          class="btn me-1"
          :disabled="
            !cartFilesListData ||
            !cartFilesListData.length ||
            !Object.keys(optionsAptOpsFormVuex).length
          "
          @click.stop="showSend2AptOpsForm('stg')"
        >
          <template v-if="!Object.keys(optionsAptOpsFormVuex).length">
            <van-loading type="spinner" class="me-2" size="15" />
            Getting form data...
          </template>
          <template v-else> Upload to APT (stg)</template>
        </button>

        <button
          v-if="ENABLE_FUNCTION_APT_SUBMIT_PRODUCTION && isEnvProduction"
          class="btn"
          :disabled="
            !cartFilesListData ||
            !cartFilesListData.length ||
            !Object.keys(optionsAptOpsFormVuex).length
          "
          @click.stop="showSend2AptOpsForm('prod')"
        >
          <template v-if="!Object.keys(optionsAptOpsFormVuex).length">
            <van-loading type="spinner" class="me-2" size="15" />
            Getting form data...
          </template>
          <template v-else> Upload to APT</template>
        </button>
      </div>
    </div>
  </div>

  <van-popup
    v-model:show="popupEditFileProfile"
    class="popup popup__right popup__right--medium"
    closeable
    position="right"
  >
    <div v-if="isProgress.submit" class="loadingOverlay">
      <van-loading size="60" />
    </div>
    <div class="popup__header">編輯檔案資訊</div>
    <div class="popup__content">
      <van-field
        v-model="editFileData.Role"
        type="text"
        name="Role"
        class="fieldRow"
        label="Server/User"
        label-class="fieldRow__label"
        :disabled="!editFileData.IsEditable"
        :border="false"
      />
      <van-field
        v-model="editFileData.ServerIp"
        type="text"
        name="ip"
        class="fieldRow"
        label="IP"
        label-class="fieldRow__label"
        :disabled="!editFileData.IsEditable"
        :border="false"
      />
      <van-field
        v-model="editFileData.DomainName"
        type="text"
        name="domain_name"
        class="fieldRow"
        label="Domain Name"
        label-class="fieldRow__label"
        :disabled="!editFileData.IsEditable"
        :border="false"
      />
      <van-field
        v-model="editFileData.HostName"
        type="text"
        name="domain_name"
        class="fieldRow"
        label="Host Name"
        label-class="fieldRow__label"
        :disabled="!editFileData.IsEditable"
        :border="false"
      />

      <van-field
        v-model="editFileData.ActualPath"
        type="text"
        name="file_path"
        class="fieldRow"
        label="ActualPath"
        label-class="fieldRow__label"
        :border="false"
      />

      <van-field
        v-model="editFileData.Timeline"
        type="text"
        name="time_line"
        class="fieldRow"
        label="TimeLine"
        label-class="fieldRow__label"
        :border="false"
      />

      <van-field
        v-model="editFileData.Guid"
        type="text"
        name="guid"
        class="fieldRow"
        label="GUID"
        label-class="fieldRow__label"
        :border="false"
      />

      <van-field
        v-model="editFileData.Pguid"
        type="text"
        name="pguid"
        class="fieldRow"
        label="PGUID"
        label-class="fieldRow__label"
        :border="false"
      />

      <van-field
        v-model="editFileData.Sha1"
        type="text"
        name="sha1"
        class="fieldRow"
        label="Sha1"
        label-class="fieldRow__label"
        readonly
        disabled
        :border="false"
      />

      <van-field
        v-model="editFileData.WhySuspect"
        type="text"
        name="why_suspect"
        class="fieldRow"
        label="Why Suspect"
        label-class="fieldRow__label"
        :border="false"
      />

      <!-- <div class="fieldRow">
        <label for="source" class="fieldRow__label">Marked</label>
        <div class="form-check form-switch">
          <input
            v-model="editFileData.IsMarked"
            class="form-check-input"
            type="checkbox"
            role="switch"
            style="width: 64px; height: 30px"
            :id="'flexSwitchCheckChecked' + editFileData.FileID"
            :checked="editFileData.IsMarked"
          />
        </div>
      </div> -->
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="isProgress.submit"
        @click="handleSaveFileData()"
      >
        儲存
      </button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupHermesForm"
    id="popupHermesForm"
    class="popup popup__right popup__right--large popupHermesForm"
    closeable
    position="right"
    :close-on-click-overlay="false"
  >
    <div v-if="isProgress.submit" class="loadingOverlay"></div>
    <van-form
      validate-trigger="onChange"
      autocomplete="off"
      @submit="handleSendHermesFormDataCheck"
    >
      <div class="popup__header">
        送樣表單
        <span v-if="isProdSrCaseExist"> / {{ props.prodsrcaseId }} </span>
        <span v-if="isStgSrCaseExist">
          (Staging) / {{ props.stgsrcaseId }}
        </span>
      </div>
      <!-- {{ v$ }} -->
      <div v-if="v$.$silentErrors.length" class="bd-callout bd-callout-warning">
        <strong>填入資料錯誤</strong>
        請修正以下 {{ v$.$silentErrors.length }} 個欄位： {{ errorFields }}
      </div>
      <!-- optionsAptOpsFormVuex: {{ optionsAptOpsFormVuex }}<br /> -->
      <!-- hermesFormData: {{ hermesFormData }} -->
      <div class="popup__content">
        <div class="row">
          <div class="col-12">
            <div class="fieldRow">
              <label for="hermes_form_priority" class="fieldRow__label">
                Priority
              </label>
              <select
                v-model="hermesFormData.priority"
                id="hermes_form_priority"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option value="" selected disabled>Select ...</option>
                <option
                  v-for="item in optionsAptOpsFormVuex.APT.PRIORITIES"
                  :value="item.value"
                  :key="item.key"
                  :disabled="item.disabled"
                >
                  {{ item.key }}
                </option>
              </select>
            </div>
            <div class="fieldRow">
              <label for="hermes_form_source_category" class="fieldRow__label">
                Source Category
              </label>
              <select
                v-model="hermesFormData.source_category"
                id="hermes_form_source_category"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option value="" selected disabled>Select ...</option>
                <option
                  v-for="item in optionsAptOpsFormVuex.APT.SOURCE_CATEGORIES"
                  :value="item.value"
                  :key="item.key"
                >
                  {{ item.key }}
                </option>
              </select>
            </div>
            <div class="fieldRow">
              <label for="hermes_form_region_id" class="fieldRow__label">
                Region
              </label>
              <select
                v-model="hermesFormData.region_id"
                id="hermes_form_region_id"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option value="" selected disabled>Select ...</option>
                <option
                  v-for="item in optionsAptOpsFormVuex.COMMON.REGIONS"
                  :value="item.value"
                  :key="item.key"
                >
                  {{ item.key }}
                </option>
              </select>
            </div>
            <van-field
              v-model="hermesFormData.case_ir_info"
              type="text"
              name="hermes_form_case_ir_info"
              class="fieldRow"
              label="Case or IR ID"
              label-class="fieldRow__label"
              :border="false"
              :disabled="isSrCaseExist"
            />

            <div class="fieldRow">
              <label
                for="hermes_form_country_code"
                class="fieldRow__label fieldRow__label--required"
              >
                Country
              </label>
              <select
                v-model="hermesFormData.country_code"
                id="hermes_form_country_code"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option value="" selected disabled>Select ...</option>
                <option
                  v-for="item in optionsAptOpsFormVuex.COMMON.COUNTRIES"
                  :value="item.value"
                  :key="item.key"
                >
                  {{ item.key }}
                </option>
              </select>
              <div
                v-if="v$.country_code.$invalid"
                class="van-field__error-message"
              >
                此為必填項目
              </div>
            </div>
            <div class="fieldRow">
              <label
                for="company_name"
                class="fieldRow__label fieldRow__label--required"
              >
                Victim Info
              </label>

              <v-select
                v-model="hermesFormData.company"
                class="vs-datalist form-control"
                :class="{
                  invalid: v$.company.$invalid,
                  valid: !v$.company.$invalid,
                }"
                label="displayValue"
                :placeholder="initConfig.placeholder.company_name.forCaseForm"
                :disabled="isSrCaseExist"
                :options="optionsAllCustomerListVuex"
                :reduce="(customer) => customer.value"
              />
              <div v-if="v$.company.$invalid" class="van-field__error-message">
                請正確選取項目
              </div>
            </div>
            <div class="fieldRow">
              <label
                for="hermes_form_industry_id"
                class="fieldRow__label fieldRow__label--required"
              >
                Industry
              </label>
              <select
                v-model="industry_id_value"
                id="hermes_form_industry_id"
                class="form-select fieldRow__editField"
                :class="{
                  invalid: v$.industry_id.$invalid,
                  valid: !v$.industry_id.$invalid,
                }"
                :disabled="isSrCaseExist"
                required
                @change="handleIndustryChange()"
              >
                <option
                  v-for="item in initConfig.field.ops_industry_picker.options"
                  :value="item.value"
                  :key="item.key"
                  :disabled="item.disabled"
                >
                  {{ item.key }}
                </option>
                <option
                  v-for="item in optionsAptOpsFormVuex.COMMON.INDUSTRIES"
                  :value="item.value"
                  :key="item.key"
                >
                  {{ item.key }}
                </option>
              </select>
              <div
                v-if="v$.industry_id.$invalid"
                class="van-field__error-message"
              >
                請選擇 APT 產業類別
              </div>
            </div>
            <div class="fieldRow">
              <label for="hermes_form_tlp" class="fieldRow__label">TLP</label>
              <select
                v-model="hermesFormData.tlp"
                id="hermes_form_tlp"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option value="" selected disabled>Select ...</option>
                <option
                  v-for="item in optionsAptOpsFormVuex.COMMON.TLP"
                  :value="item.value"
                  :key="item.key"
                >
                  {{ item.key }}
                </option>
              </select>
            </div>

            <div class="fieldRow">
              <label
                for="hermes_form_tip"
                class="fieldRow__label fieldRow__label--required"
              >
                TIP
              </label>
              <select
                v-model="hermesFormData.tip"
                id="hermes_form_tip"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option
                  v-for="item in initConfig.field.apt_tip.options"
                  :value="item.value"
                  :key="item.key"
                  :disabled="item.disabled"
                >
                  {{ item.key }}
                </option>
              </select>
              <div v-if="v$.tip.$invalid" class="van-field__error-message">
                此為必填項目
              </div>
            </div>
            <div class="fieldRow">
              <label for="hermes_form_sharing" class="fieldRow__label">
                Sharing
              </label>
              <select
                v-model="share_options_value"
                id="hermes_form_sharing"
                class="form-select fieldRow__editField"
                :disabled="isSrCaseExist"
              >
                <option value="" selected disabled>Select ...</option>
                <option>-</option>
              </select>
            </div>
            <van-field
              v-model="hermesFormData.possible_apt_reason"
              type="textarea"
              name="hermes_form_possible_apt_reason"
              class="fieldRow fieldRow--baseline"
              :class="{
                invalid: v$.possible_apt_reason.$invalid,
                valid: !v$.possible_apt_reason.$invalid,
              }"
              label="Possible APT Reason"
              label-class="fieldRow__label"
              rows="7"
              required
              :border="false"
              :disabled="isSrCaseExist"
              :autosize="{ maxHeight: 176 }"
            />
            <div
              v-if="v$.possible_apt_reason.$invalid"
              class="van-field__error-message"
            >
              此為必填項目
            </div>
            <van-field
              v-model="hermesFormData.additional_info"
              type="textarea"
              name="hermes_form_additional_info"
              class="fieldRow fieldRow--baseline"
              label="Special Request"
              label-class="fieldRow__label"
              rows="7"
              :border="false"
              :disabled="isSrCaseExist"
              :autosize="{ maxHeight: 176 }"
            />

            <div class="fieldRow">
              <label for="hermes_form_sharing" class="fieldRow__label">
                Email CC
              </label>
              <Multiselect
                v-model="hermesFormData.email_cc_list"
                mode="tags"
                class="form-select fieldRow__editField"
                :class="{
                  invalid: v$.email_cc_list.$invalid,
                  valid: !v$.email_cc_list.$invalid,
                }"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :options="EmailCcListOptions"
                :disabled="isSrCaseExist"
              />
              <div
                v-if="v$.email_cc_list.$invalid"
                class="van-field__error-message"
              >
                此為必填項目
              </div>
            </div>

            <div class="fieldRow">
              <label for="hermes_form_sharing" class="fieldRow__label">
                Stake Holders
              </label>
              <Multiselect
                v-model="hermesFormData.stakeholder_list"
                mode="tags"
                class="form-select fieldRow__editField"
                :class="{
                  invalid: v$.stakeholder_list.$invalid,
                  valid: !v$.stakeholder_list.$invalid,
                }"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :options="StakeHolderListOptions"
                :disabled="isSrCaseExist"
              />
              <div
                v-if="v$.stakeholder_list.$invalid"
                class="van-field__error-message"
              >
                此為必填項目
              </div>
            </div>
            <hr v-if="isSrCaseExist" />
          </div>
          <div v-if="isSrCaseExist" class="col-6">
            <div class="filesListGroup">
              <p class="filesListGroup__title">
                Ready to re-submit (共{{ cartFilesListData.length }}個):
              </p>
              <ol class="list-group list-group-numbered">
                <li
                  v-for="item in cartFilesListData"
                  :key="item.FileID"
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold filesListGroup__subject">
                      {{ item.Filename ? item.Filename : "N/A" }}
                    </div>
                    <span
                      class="d-inline-block text-truncate filesListGroup__des"
                      style="max-width: 350px"
                    >
                      <i class="bi bi-pc-display"></i>
                      {{ item.ServerIp }}_{{ item.DomainName }}_{{
                        item.HostName
                      }}
                    </span>
                  </div>
                  <!-- <span class="badge bg-primary rounded-pill">14</span> -->
                </li>
              </ol>
            </div>

            <div class="filesListGroup" style="display: none">
              <p class="filesListGroup__title">Recent uploaded files:</p>
              <ol class="list-group list-group-numbered">
                <li
                  v-for="item in selectedFilesData"
                  :key="item.FileID"
                  class="list-group-item d-flex justify-content-between align-items-start"
                >
                  <div class="ms-2 me-auto">
                    <div class="fw-bold filesListGroup__subject">
                      {{ item.Filename ? item.Filename : "N/A" }}
                    </div>
                    <span
                      class="d-inline-block text-truncate filesListGroup__des"
                      style="max-width: 350px"
                    >
                      {{ item.ActualPath ? item.ActualPath : "N/A" }}
                    </span>
                  </div>
                  <!-- <span class="badge bg-primary rounded-pill">14</span> -->
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__footer">
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="isProgress.submit || v$.$invalid"
        >
          送出
        </button>
      </div>
    </van-form>
  </van-popup>

  <van-popup
    v-model:show="popupSendAptConfirm"
    class="popup"
    round
    style="width: 550px"
  >
    <div class="popup__header">送樣至 APT Ops？</div>
    <div class="popup__content">
      送樣後，所選擇的檔案將會全數上傳至 APT Opts
      分析，請確認填寫資料與檔案清單。
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupSendAptConfirm = false"
      >
        取消送樣
      </button>
      <button
        v-if="
          ENABLE_FUNCTION_APT_SUBMIT_STAGING &&
          selectedAptEnv == 'stg' &&
          !props.stgsrcaseId
        "
        type="button"
        class="btn btn-primary"
        @click.stop="handleSendHermesFormStgConfirmed()"
      >
        送樣 (Stg)
      </button>
      <button
        v-if="
          ENABLE_FUNCTION_APT_SUBMIT_STAGING &&
          selectedAptEnv == 'stg' &&
          props.stgsrcaseId
        "
        type="button"
        class="btn btn-primary"
        @click.stop="handleReSendHermesFormStgConfirmed()"
      >
        補送樣 (Stg)
      </button>
      <button
        v-if="
          ENABLE_FUNCTION_APT_SUBMIT_PRODUCTION &&
          selectedAptEnv == 'prod' &&
          !props.prodsrcaseId
        "
        type="button"
        class="btn btn-primary"
        @click.stop="handleSendHermesFormProdConfirmed()"
      >
        送樣
      </button>
      <button
        v-if="
          ENABLE_FUNCTION_APT_SUBMIT_PRODUCTION &&
          selectedAptEnv == 'prod' &&
          props.prodsrcaseId
        "
        type="button"
        class="btn btn-primary"
        @click.stop="handleReSendHermesFormProdConfirmed()"
      >
        補送樣
      </button>
    </div>
  </van-popup>
</template>
<script setup>
import {
  ref,
  watch,
  reactive,
  computed,
  defineProps,
  defineEmits,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  showDialog,
  showSuccessToast,
  showLoadingToast,
  closeToast,
} from "vant";
// import moment from "moment";
import formHelper from "../formHelper";
import TableLite from "vue3-table-lite";
import Multiselect from "@vueform/multiselect";
import vSelect from "vue-select";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { form_config } from "../form.config";
import { special } from "../special";

const props = defineProps([
  "srNumber",
  "aptStatus",
  "companyInfo",
  "memberData",
  "ownerEmail",
  "stgsrcaseId",
  "prodsrcaseId",
  "stgsrcaseStatus",
  "prodsrcaseStatus",
]);
console.log("props", props);
const emits = defineEmits(["reloadData"]);
const isEnvProduction = ref(null);
const ENABLE_FUNCTION_APT_SUBMIT_STAGING =
  special.ENABLE_FUNCTION_APT_SUBMIT_STAGING || false;
const ENABLE_FUNCTION_APT_SUBMIT_PRODUCTION =
  special.ENABLE_FUNCTION_APT_SUBMIT_PRODUCTION || false;
const initConfig = form_config || [];

let parmsId = ref(null);
const route = useRoute();
const store = useStore();
const updatedFilesData = ref([]);
const isShowMarked = ref(false);
const isShowPendingItems = ref(true);
const isShowSendItems = ref(true);
const editFileData = ref(null);
const propertyValues = ref(null);
const selectedFilesData = ref([]);
const cartFilesListData = ref([]);
const hasSentFilesListData = ref([]);
const currentCheckedFilesLength = ref(null);
const currentMarkedFilesLength = ref(0);
const fileForm = reactive({
  ActualPath: null,
  FileStatus: null,
  Timeline: null,
  WhySuspect: null,
  Guid: null,
  Pguid: null,
  IsMarked: false,
});
// SendAPT
const popupSendAptConfirm = ref(false);
const popupEditFileProfile = ref(false);
const popupHermesForm = ref(false);
const industry_id_value = ref(null);
const share_options_value = ref(null);
const selectedAptEnv = ref(null);
const srCaseIdNumber = ref(null);
const isProdSrCaseExist = ref(false);
const isStgSrCaseExist = ref(false);
const isSrCaseExist = ref(false);
const hermesFormData = reactive({
  priority: 2,
  region_id: 4,
  industry_id: [],
  country_code: "TWN",
  company: "",
  tlp: 2,
  case_ir_info: "",
  source_category: 9,
  possible_apt_reason: "",
  tip: true,
  share_status: 1,
  share_after: 1,
  share_industry: 1,
  additional_info: "",
  email_cc_list: [],
  stakeholder_list: [],
  sample_file_list: [],
});
const EmailCcListOptions = ref([]);
const StakeHolderListOptions = ref([]);

// Vuelidate
const errorFields = ref(null);
const companyRule = (value) => {
  const matchedCompany = optionsAllCustomerListVuex.value.filter(
    (item) => item.value === value
  );
  return matchedCompany.length ? true : false;
};
const industry_idRule = (value) => {
  return value.length >= 1;
};
const rules = {
  country_code: { required },
  company: { companyRule },
  industry_id: { industry_idRule },
  tip: { required },
  possible_apt_reason: { required },
  email_cc_list: { required },
  stakeholder_list: { required },
};
const v$ = useVuelidate(rules, hermesFormData);

const isProgress = computed(() => {
  return store.getters.isProgress;
});
const allCustomerDataVuex = computed(() => {
  return store.getters.allCustomerData;
});
const optionsAptOpsFormVuex = computed(() => {
  return store.getters.optionsAptOpsForm;
});
const optionsAllCustomerListVuex = computed(() => {
  return store.getters.optionsAllCustomerList;
});
const workspaceCollectFilesDataVuex = computed(() => {
  return store.getters.workspaceCollectFilesData;
});

// START - Init table settings
const searchFilesTerm = ref(""); // Search text
const tableCollectFiles = reactive({
  isLoading: false,
  columns: [
    {
      label: "File ID",
      field: "FileID",
      headerClasses: ["hide"],
      columnClasses: ["hide"],
      width: "5%",
      sortable: true,
      isKey: true,
    },
    {
      label: "Ignored",
      field: "IsMarked",
      width: "",
      sortable: true,
    },
    {
      label: "IP",
      field: "ServerIp",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.ServerIp) {
          return `${row.ServerIp}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Domain",
      field: "DomainName",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.DomainName) {
          return `${row.DomainName}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Host",
      field: "HostName",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.HostName) {
          return `${row.HostName}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Path",
      field: "ActualPath",
      width: "",
      sortable: true,
      display: function (row) {
        return `<div class="d-flex justify-content-between">${row.ActualPath} <a :href="${row.DownloadURL}" class="columnFunction__button"><i class="bi bi-cloud-arrow-down-fill"></i></a></div>`;
      },
    },
    {
      label: "Timeline",
      field: "Timeline",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Timeline) {
          return `${row.Timeline}`;
        } else {
          return "-";
        }
      },
    },
    {
      label: "SHA1",
      field: "Sha1",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.Sha1) {
          if (row.IsSHA1Duplicated) {
            return `<div class="van-field__error-message">${row.Sha1} <i class="bi bi-exclamation-triangle-fill"></i>檔案重複</div>`;
          } else {
            return `${row.Sha1}`;
            // return ``;
          }
        } else {
          return "-";
        }
      },
    },
    {
      label: "編輯",
      field: "function",
      sortable: false,
    },
  ],
  rows: computed(() => {
    // console.log("workspaceCollectFilesDataVuex.value", workspaceCollectFilesDataVuex.value);
    return updatedFilesData.value.filter(
      (x) =>
        x.FileID.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.Role.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.HostName.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.DomainName.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.ActualPath.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.ServerIp.toLowerCase().includes(
          searchFilesTerm.value.toLowerCase()
        ) ||
        x.Sha1.toLowerCase().includes(searchFilesTerm.value.toLowerCase()) ||
        x.Timeline.toLowerCase().includes(searchFilesTerm.value.toLowerCase())
    );
  }),
  totalRecordCount: computed(() => {
    return tableCollectFiles.rows.length;
  }),
  sortable: {
    order: "index",
    sort: "asc",
  },
  messages: {
    pagingInfo: "現在顯示 {0} 到 {1}筆 共{2}筆",
    pageSizeChangeLabel: "每頁筆數:",
    gotoPageLabel: "現在頁數:",
    noDataAvailable: "無資料",
  },
  pageOptions: [
    {
      value: 20,
      text: 20,
    },
    {
      value: 50,
      text: 50,
    },
    {
      value: 100,
      text: 100,
    },
    {
      value: 500,
      text: 500,
    },
    {
      value: 10000,
      text: "All",
    },
  ],
  groupingKey: "HostName",
  hasGroupToggle: true,
  groupingDisplay: function (key) {
    return '<span class="multipleSelector__label">' + key + "</span>";
  },
});
// END - Init table settings

const tableLoadingFinish = () => {
  console.log("tableLoadingFinish");
  currentCheckedFilesLength.value = document.querySelectorAll(
    "td input[type='checkbox'].vtl-tbody-checkbox:checked"
  ).length;

  // const currentMarkedFilesLength = document.querySelectorAll(
  //   "input[type='checkbox'].form-check-input:checked"
  // ).length;

  const markedCheckboxes = document.querySelectorAll(
    "td input[type='checkbox'].form-check-input:checked"
  );

  const unMarkedCheckboxes = document.querySelectorAll(
    "td input[type='checkbox'].form-check-input:not(:checked)"
  );

  for (var i = 0; i < unMarkedCheckboxes.length; i++) {
    unMarkedCheckboxes[i].closest(
      "tr"
    ).children[0].children[0].children[0].disabled = false;
  }

  if (currentMarkedFilesLength.value > 0) {
    for (var n = 0; n < markedCheckboxes.length; n++) {
      markedCheckboxes[n].closest("tr").classList.add("marked");
      markedCheckboxes[n].closest(
        "tr"
      ).children[0].children[0].children[0].disabled = true;
    }
  }
  var table = document.getElementsByClassName("vtl-table ")[0];
  table.classList.remove("vtl-table-bordered");
  alignTheEqualHeight();
};

const alignTheEqualHeight = () => {
  // Re-Style talbe section
  console.log("alignTheEqualHeight");
  const leftSelectSection = document.getElementsByClassName(
    "multipleSelector__content--left"
  )[0];
  const rightSelectSection = document.getElementsByClassName(
    "multipleSelector__content--right"
  )[0];
  if (leftSelectSection.offsetHeight > rightSelectSection.offsetHeight) {
    rightSelectSection.style.height = `${leftSelectSection.offsetHeight}px`;
  } else {
    leftSelectSection.style.height = `${rightSelectSection.offsetHeight}px`;
  }
};

const updateCheckedRows = (rowsKey) => {
  console.log("updateCheckedRows", rowsKey);
  console.log(
    "currentCheckedFilesLength",
    document.querySelectorAll(
      "input[type='checkbox'].vtl-tbody-checkbox:checked"
    ).length
  );
  const checkedCheckboxes = document.querySelectorAll(
    "input[type='checkbox'].vtl-tbody-checkbox:checked"
  );
  const checkboxes = document.querySelectorAll(
    "input[type='checkbox'].vtl-tbody-checkbox"
  );
  currentCheckedFilesLength.value = document.querySelectorAll(
    "td input[type='checkbox'].vtl-tbody-checkbox:checked"
  ).length;

  if (currentCheckedFilesLength.value > 0) {
    const checkdedIdsArray = [];
    for (var i = 0; i < checkedCheckboxes.length; i++) {
      checkedCheckboxes[i].closest("tr").classList.add("selected");
      checkdedIdsArray.push(Number(checkedCheckboxes[i].value));
    }
    console.log("checkdedIdsArray", checkdedIdsArray);
    propertyValues.value = checkdedIdsArray;
    console.log("propertyValues.value", propertyValues.value);

    selectedFilesData.value = workspaceCollectFilesDataVuex.value.filter(
      (obj) => propertyValues.value.includes(obj.FileID)
    );

    console.log("selectedFilesData", selectedFilesData.value);
  } else {
    for (var n = 0; n < checkboxes.length; n++) {
      checkboxes[n].closest("tr").classList.remove("selected");
    }
  }
};

// ChangeStatus 說明
// -1: 隱藏不顯示在畫面上
// 0: 原始狀態
// 1: 於購物車中待送樣
// 2: 已送樣

const handleFileMarkedChange = (fileId, event) => {
  console.log("handleFileMarkedChange - fileId", fileId);
  console.log("handleFileMarkedChange - event", event.target.disabled);
  if (event.target.checked) {
    event.target.closest("tr").classList.add("marked");
  } else {
    event.target.closest("tr").classList.remove("marked");
  }

  // const editTargetAssetData = workspaceCollectFilesDataVuex.value.filter(
  //   (file) => file.FileID === fileId
  // );
  // editFileData.value = editTargetAssetData[0];
  // console.log("editFileData", editFileData.value);
  // const markedFiles = workspaceCollectFilesDataVuex.value.filter(
  //   (file) => file.IsMarked === true
  // );
  const markedFiles = workspaceCollectFilesDataVuex.value.filter(
    (file) => file.ChangeStatus === -1
  );
  currentMarkedFilesLength.value = markedFiles.length;
  // handleSaveFileData("silent");
  let ids = [];
  ids.push(Number(fileId));

  const sourceData = {
    caseId: parmsId.value,
    data: {
      ChangeStatus: event.target.checked ? -1 : 0,
      FileIds: ids,
      TriggerEmail: sessionStorage.getItem("userEmail"),
    },
  };
  store.dispatch("handleUpdateFileStatus", sourceData);
  handleSetFileStatusAPI(sourceData);
};

const handleSetFileStatusAPI = async (sourceData) => {
  console.log("handleUpdateFileIsMarked - ids", sourceData);
  store.dispatch("handlePageLoadingState", true);
  try {
    const response = await store.dispatch(
      "postUpdateFileStatusBatch",
      sourceData
    );

    if (response.status !== 200) {
      store.dispatch("handlePageLoadingState", false);
    } else {
      console.log("Success - postUpdateFileStatusBatch");
      store.dispatch("handlePageLoadingState", false);
    }
  } catch (err) {
    console.log("FAIL - postUpdateFileStatusBatch:", err);
    store.dispatch("handlePageLoadingState", false);
  }
};

const handleSaveFileData = (type) => {
  store.dispatch("handleSubmitLoadingState", true);
  fileForm.ActualPath = editFileData.value.ActualPath;
  fileForm.Timeline = editFileData.value.Timeline;
  fileForm.WhySuspect = editFileData.value.WhySuspect;
  fileForm.Guid = editFileData.value.Guid;
  fileForm.Pguid = editFileData.value.Pguid;
  fileForm.IsMarked = editFileData.value.IsMarked;
  // fileForm.ChangeStatus = fileForm.IsMarked ? -1 : 0;
  console.log("fileForm", fileForm);
  const sourceData = {
    fileID: editFileData.value.FileID,
    data: fileForm,
  };
  putFileDataAPI(sourceData, type);
};

const putFileDataAPI = async (sourceData, type) => {
  try {
    const response = await store.dispatch("putUpdateFileData", sourceData);
    console.log("response - putUpdateFileData", response);
    if (response.status !== 200) {
      store.dispatch("handleSubmitLoadingState", false);
      showDialog({
        message: "Update file data FAIL.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      setTimeout(() => {
        if (type !== "silent") {
          emits("reloadData");
          popupEditFileProfile.value = false;
          showSuccessToast("儲存成功!");
        }
        store.dispatch("handleSubmitLoadingState", false);
      }, 2000);
    }
  } catch (err) {
    console.log("Update file data FAIL.", err);
  }
};

// START - Shopping Cart
const handleAdd2CartNew = () => {
  console.log("handleAdd2CartNew", propertyValues.value);
  const sourceData = {
    caseId: parmsId.value,
    data: {
      ChangeStatus: 1,
      FileIds: propertyValues.value,
      TriggerEmail: sessionStorage.getItem("userEmail"),
    },
  };
  store.dispatch("handleUpdateFileStatus", sourceData);
  handleSetFileStatusAPI(sourceData);
  updateShopListView(isShowMarked.value);
  updateCartListView();
};

const handleDeleteFromCart = (targetId) => {
  console.log(
    "handleDeleteFromCart - cartFilesListData",
    cartFilesListData.value
  );
  cartFilesListData.value = cartFilesListData.value.filter(
    (item) => item.FileID !== targetId
  );
  console.log("handleDeleteFromCart - targetId", targetId);
  console.log(
    "handleDeleteFromCart - cartFilesListData",
    cartFilesListData.value
  );

  const sourceData = {
    caseId: parmsId.value,
    data: {
      ChangeStatus: 0,
      FileIds: [Number(targetId)],
      TriggerEmail: sessionStorage.getItem("userEmail"),
    },
  };
  store.dispatch("handleUpdateFileStatus", sourceData);
  handleSetFileStatusAPI(sourceData);
  updateShopListView(isShowMarked.value);
};

const updateShopListView = (boolean) => {
  console.log("updateShopListView", boolean);
  if (boolean) {
    console.log("Show All");
    updatedFilesData.value = workspaceCollectFilesDataVuex.value.filter(
      (file) => file.ChangeStatus === 0 || file.ChangeStatus === -1
    );
    console.log("updatedFilesData", updatedFilesData.value);
  } else {
    console.log("Hide Marked");
    updatedFilesData.value = workspaceCollectFilesDataVuex.value.filter(
      (file) => file.ChangeStatus === 0
    );
    console.log("updatedFilesData", updatedFilesData.value);
  }
  setTimeout(() => {
    alignTheEqualHeight();
  }, 500);
};

const updateCartListView = () => {
  cartFilesListData.value = workspaceCollectFilesDataVuex.value.filter(
    (file) => file.ChangeStatus === 1
  );
  console.log("updateCartListView - before", cartFilesListData.value);
  const uniqueObjects = [];
  const duplicates = [];
  cartFilesListData.value.map((obj) => {
    const newObj = { ...obj };
    const isDuplicate = uniqueObjects.some((uniqueObj) => {
      console.log("uniqueObj", uniqueObj);
      console.log("uniqueObj.Sha1", uniqueObj.Sha1);
      console.log("obj", obj);
      console.log("obj.Sha1", obj.Sha1);
      return uniqueObj.Sha1 === obj.Sha1;
    });
    if (isDuplicate) {
      console.log("IsSHA1DuplicatedInCart - obj", obj);
      obj.IsSHA1DuplicatedInCart = true;
      duplicates.push(obj);
    } else {
      console.log("isNotDuplicateInCart - obj", obj);
      obj.IsSHA1DuplicatedInCart = false;
      uniqueObjects.push(obj);
    }
    return newObj;
  });
  console.log("updateCartListView - after", cartFilesListData.value);
};

const updateHasSendFilesListView = () => {
  console.log("updateHasSendFilesListView");
  hasSentFilesListData.value = workspaceCollectFilesDataVuex.value.filter(
    (file) => file.ChangeStatus === 2
  );

  // const intersection = hasSentFilesListData.value.filter((element) =>
  //   cartFilesListData.value.includes(element.Sha1)
  // );
  // console.log("updateHasSendFilesListView - intersection", intersection);
  console.log("updateHasSendFilesListView", hasSentFilesListData.value);
};
// END - Shopping Cart

const handleEditTableCell = (fileId) => {
  console.log("handleEditTableCell - fileId", fileId);
  const editTargetAssetData = workspaceCollectFilesDataVuex.value.filter(
    (file) => file.FileID === fileId
  );
  editFileData.value = editTargetAssetData[0];
  console.log("editFileData", editFileData.value);
  showEditFileProfilePopup();
};

const showEditFileProfilePopup = () => {
  popupEditFileProfile.value = true;
  store.dispatch("handlePartialDataLoadingState", true);
};

const showSend2AptOpsForm = (aptEnv) => {
  selectedAptEnv.value = aptEnv;
  let filesDataArray = [];

  cartFilesListData.value.map((obj) => {
    filesDataArray.push({
      filename: `${obj.Filename}`,
      url: obj.DownloadURL,
      file_id: `${obj.FileID}`,
    });
  });
  hermesFormData.sample_file_list = filesDataArray;
  presetAptFormValue(aptEnv);
  popupHermesForm.value = true;
  console.log("hermesFormData", hermesFormData);
};

const presetAptFormValue = (aptEnv) => {
  // console.log("selectedAptEnv.value", selectedAptEnv.value);
  console.log("props.prodsrcaseId", props.prodsrcaseId);
  console.log("props.stgsrcaseId", props.stgsrcaseId);
  if (aptEnv == "prod" && props.prodsrcaseId) {
    // apt production 已經有送過
    console.log("apt production 已經有送過");
    isProdSrCaseExist.value = true;
    isSrCaseExist.value = true;
    const srCaseIdNumberOrigin = props.prodsrcaseId.split("-")[1];
    srCaseIdNumber.value = parseInt(srCaseIdNumberOrigin);
    getSrCaseProdDetail(String(srCaseIdNumber.value));
  } else if (aptEnv == "stg" && props.stgsrcaseId) {
    // apt production 已經有送過
    console.log("apt staging 已經有送過");
    isStgSrCaseExist.value = true;
    isSrCaseExist.value = true;
    const srCaseIdNumberOrigin = props.stgsrcaseId.split("-")[1];
    srCaseIdNumber.value = parseInt(srCaseIdNumberOrigin);
    getSrCaseStgDetail(String(srCaseIdNumber.value));
  } else {
    // 目前所選 env 沒送過
    console.log(`APT - ${aptEnv} 沒送過`);
    isProdSrCaseExist.value = false;
    isStgSrCaseExist.value = false;
    isSrCaseExist.value = false;
    resetAptFormValue();
  }

  if (props.memberData) {
    const memberEmailList = [];
    props.memberData.map((obj) => {
      memberEmailList.push(obj.Email);
    });
    EmailCcListOptions.value = memberEmailList;
    EmailCcListOptions.value.push("alloftwirteam@dl.trendmicro.com");

    const memberAccountNameList = [];
    props.memberData.map((obj) => {
      memberAccountNameList.push(obj.AccountName);
    });
    StakeHolderListOptions.value = memberAccountNameList;
  }
};

const resetAptFormValue = () => {
  console.log("resetAptFormValue");
  hermesFormData.priority = 2;
  hermesFormData.region_id = 4;
  hermesFormData.industry_id = [];
  hermesFormData.country_code = "TWN";
  hermesFormData.company = props.companyInfo.CustomerName;
  hermesFormData.tlp = 2;
  hermesFormData.case_ir_info = parmsId.value;
  hermesFormData.source_category = 9;
  hermesFormData.possible_apt_reason = "";
  hermesFormData.tip = true;
  hermesFormData.share_status = 1;
  hermesFormData.share_after = 1;
  hermesFormData.share_industry = 1;
  hermesFormData.additional_info = "";
  industry_id_value.value = props.companyInfo.OpsIndustryId;
  if (industry_id_value.value) {
    handleIndustryChange();
  }

  hermesFormData.email_cc_list = [];
  hermesFormData.stakeholder_list = [];
  hermesFormData.email_cc_list.push("alloftwirteam@dl.trendmicro.com");
  hermesFormData.stakeholder_list.push("lyn_chuang", "josh_huang");
  console.log("props - resetAptFormValue", props);
  const ownerAccountName = props.ownerEmail
    ? props.ownerEmail.substring(0, props.ownerEmail.indexOf("@"))
    : null;
  if (ownerAccountName) {
    hermesFormData.stakeholder_list.push(ownerAccountName);
  }
  // hermesFormData.sample_file_list = [];
};

const setAptFormValue = (responseData) => {
  hermesFormData.priority = responseData.priority;
  hermesFormData.region_id = responseData.region_id;
  hermesFormData.industry_id = responseData.industry_id;
  industry_id_value.value = responseData.industry_id[0];
  hermesFormData.country_code = responseData.country_code;
  hermesFormData.company = responseData.company;
  hermesFormData.tlp = responseData.tlp;
  hermesFormData.case_ir_info = responseData.case_ir_info;
  hermesFormData.possible_apt_reason = responseData.possible_apt_reason;
  hermesFormData.tip = responseData.tip;
  hermesFormData.share_status = responseData.share_status;
  hermesFormData.share_after = responseData.share_after;
  hermesFormData.share_industry = responseData.share_industry;
  hermesFormData.additional_info = responseData.additional_info;
  hermesFormData.email_cc_list = responseData.email_cc_list;
  hermesFormData.stakeholder_list = responseData.stakeholder_list;
};

const handleIndustryChange = () => {
  let industryIdArray = [];
  industryIdArray.push(industry_id_value.value);
  hermesFormData.industry_id = industryIdArray;
};

const handleSendHermesFormDataCheck = () => {
  console.log("handleSendHermesFormDataCheck");
  popupSendAptConfirm.value = true;
};

const setHeightToOverlay = () => {
  const contentHeight =
    document.getElementsByClassName("popupHermesForm")[0].scrollHeight;

  setTimeout(() => {
    document
      .getElementsByClassName("loadingOverlay")[0]
      .setAttribute("style", `height: ${contentHeight}px`);
  }, 500);
};

const handleSendHermesFormStgConfirmed = async () => {
  popupSendAptConfirm.value = false;
  store.dispatch("handleSubmitLoadingState", true);
  showLoadingToast({
    duration: 0,
    message: "資料傳送中...",
    forbidClick: true,
  });

  console.log("handleSendHermesFormStg - hermesFormData", hermesFormData);
  setHeightToOverlay();

  // Reopen it after the SR number can be write to case detail data.
  const response = await store.dispatch(
    "postSend2AptOpsStaging",
    hermesFormData
  );
  console.log("response - postSend2AptOpsStaging", response);
  if (response.status == 200) {
    if (response.status == 200 && response.data.status == "error") {
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT create case 失敗 (Staging)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      updateFileStatusAfterAptCreated();
      popupHermesForm.value = false;
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT create case 成功 (Staging)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    }
  } else {
    store.dispatch("handleSubmitLoadingState", false);
    closeToast();
    showDialog({
      message: "APT create case 失敗 (Staging)",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};

const handleSendHermesFormProdConfirmed = async () => {
  popupSendAptConfirm.value = false;
  store.dispatch("handleSubmitLoadingState", true);
  showLoadingToast({
    duration: 0,
    message: "資料傳送中...",
    forbidClick: true,
  });
  console.log("handleSendHermesFormProd - hermesFormData", hermesFormData);
  setHeightToOverlay();

  // Reopen it after the SR number can be write to case detail data.
  const response = await store.dispatch(
    "postSend2AptOpsProduction",
    hermesFormData
  );
  console.log("response - postSend2AptOpsProduction", response);
  if (response.status == 200) {
    if (response.status == 200 && response.data.status == "error") {
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT create case 失敗 (Production)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT create case 成功 (Production)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
        updateFileStatusAfterAptCreated();
        popupHermesForm.value = false;
      });
    }
  } else {
    store.dispatch("handleSubmitLoadingState", false);
    closeToast();
    showDialog({
      message: "APT create case 失敗 (Production)",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};

const handleReSendHermesFormStgConfirmed = async () => {
  popupSendAptConfirm.value = false;
  store.dispatch("handleSubmitLoadingState", true);
  showLoadingToast({
    duration: 0,
    message: "資料傳送中...",
    forbidClick: true,
  });
  console.log(
    "handleReSendHermesFormStgConfirmed - hermesFormData",
    hermesFormData
  );
  const reSendFilesData = {
    sample_file_list: hermesFormData.sample_file_list,
  };
  const sourceData = {
    caseId: srCaseIdNumber.value,
    data: reSendFilesData,
  };
  console.log("sourceData - handleReSendHermesFormStgConfirmed", sourceData);
  setHeightToOverlay();

  const response = await store.dispatch("postReSendAptOpsStaging", sourceData);
  console.log("response - postReSendAptOpsStaging", response);
  if (response.status == 200) {
    if (response.status == 200 && response.data.status == "error") {
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT Resend case 失敗 (Staging)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      updateFileStatusAfterAptCreated();
      popupHermesForm.value = false;
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT Resend case 成功 (Staging)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    }
  } else {
    store.dispatch("handleSubmitLoadingState", false);
    closeToast();
    showDialog({
      message: "APT Resend case 失敗 (Staging)",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};

const handleReSendHermesFormProdConfirmed = async () => {
  popupSendAptConfirm.value = false;
  store.dispatch("handleSubmitLoadingState", true);
  showLoadingToast({
    duration: 0,
    message: "資料傳送中...",
    forbidClick: true,
  });
  console.log(
    "handleReSendHermesFormProdConfirmed - hermesFormData",
    hermesFormData
  );
  const reSendFilesData = {
    sample_file_list: hermesFormData.sample_file_list,
  };
  const sourceData = {
    caseId: srCaseIdNumber.value,
    data: reSendFilesData,
  };
  console.log("sourceData - handleReSendHermesFormProdConfirmed", sourceData);
  setHeightToOverlay();

  const response = await store.dispatch(
    "postReSendAptOpsProduction",
    sourceData
  );
  console.log("response - postReSendAptOpsProduction", response);
  if (response.status == 200) {
    if (response.status == 200 && response.data.status == "error") {
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT Resend case 失敗 (Staging)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      updateFileStatusAfterAptCreated();
      popupHermesForm.value = false;
      store.dispatch("handleSubmitLoadingState", false);
      closeToast();
      showDialog({
        message: "APT Resend case 成功 (Production)",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    }
  } else {
    store.dispatch("handleSubmitLoadingState", false);
    closeToast();
    showDialog({
      message: "APT Resend case 失敗 (Production)",
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
  }
};

const updateFileStatusAfterAptCreated = () => {
  const filesSend2AptArray = [];
  for (var i = 0; i < cartFilesListData.value.length; i++) {
    filesSend2AptArray.push(Number(cartFilesListData.value[i].Id));
  }
  console.log(
    "updateFileStatusAfterAptCreated - filesSend2AptArray",
    filesSend2AptArray
  );

  const sourceData = {
    caseId: parmsId.value,
    data: {
      ChangeStatus: 2,
      FileIds: filesSend2AptArray,
      TriggerEmail: sessionStorage.getItem("userEmail"),
    },
  };
  console.log("updateFileStatusAfterAptCreated", sourceData);
  store.dispatch("handleUpdateFileStatus", sourceData);
  handleSetFileStatusAPI(sourceData);
};

const getAptOpsFormOptions = async () => {
  try {
    store.dispatch("handleSubmitLoadingState", true);
    await store.dispatch("getAptOpsFormOptions");
    store.dispatch("handleSubmitLoadingState", false);
  } catch (err) {
    console.log("getAptOpsFormOptions", err);
  }
};

const getSrCaseStgDetail = async (srCaseId) => {
  console.log("getSrCaseStgDetail - srCaseId", srCaseId);
  try {
    store.dispatch("handleSubmitLoadingState", true);
    const response = await store.dispatch("getSrCaseStgDetail", srCaseId);

    console.log("getSrCaseStgDetail - response", response);
    if (response.status == 200) {
      store.dispatch("handleSubmitLoadingState", false);
      setAptFormValue(response.data);
    } else {
      showDialog({
        message: response.message,
        confirmButtonText: "返回",
      }).then(() => {
        // on close
        store.dispatch("handleSubmitLoadingState", false);
      });
    }
  } catch (error) {
    console.log("error - getCaseDetailData", error);
    showDialog({
      message: error.message,
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      store.dispatch("handleSubmitLoadingState", false);
    });
  }
};

const getSrCaseProdDetail = async (srCaseId) => {
  console.log("getSrCaseProdDetail - srCaseId", srCaseId);
  try {
    store.dispatch("handleSubmitLoadingState", true);
    const response = await store.dispatch("getSrCaseProdDetail", srCaseId);

    console.log("getSrCaseProdDetail - response", response);
    if (response.status == 200) {
      store.dispatch("handleSubmitLoadingState", false);
      setAptFormValue(response.data);
    } else {
      showDialog({
        message: response.message,
        confirmButtonText: "返回",
      }).then(() => {
        // on close
        store.dispatch("handleSubmitLoadingState", false);
      });
    }
  } catch (error) {
    console.log("error - getCaseDetailData", error);
    showDialog({
      message: error.message,
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      store.dispatch("handleSubmitLoadingState", false);
    });
  }
};

onMounted(() => {
  if (process.env.NODE_ENV == "production") {
    isEnvProduction.value = true;
  } else {
    isEnvProduction.value = false;
  }
  // getCollectFilesData(parmsId);
  getAptOpsFormOptions();
});

watch(
  route,
  () => {
    parmsId.value = route.params.caseId;
  },
  { deep: true, immediate: true }
);

watch(
  allCustomerDataVuex,
  (data) => {
    if (!Object.keys(data).length) {
      console.log("No allCustomerDataVuex data");
      store.dispatch("getAllCustomerData");
    } else {
      console.log("Has allCustomerDataVuex data", data);
    }
  },
  { deep: true, immediate: true }
);

watch(
  workspaceCollectFilesDataVuex,
  (data) => {
    console.log("Watch - workspaceCollectFilesDataVuex", data);
    if (data.length) {
      updateShopListView(isShowMarked.value);
      updateCartListView();
      updateHasSendFilesListView();

      const markedFiles = workspaceCollectFilesDataVuex.value.filter(
        (file) => file.ChangeStatus === -1
      );
      currentMarkedFilesLength.value = markedFiles.length;
    }
  },
  { deep: true, immediate: true }
);

watch(
  isShowMarked,
  (boolean) => {
    updateShopListView(boolean);
  },
  { deep: true, immediate: true }
);

watch(v$, () => {
  const filterPropertyArray = [];
  if (v$.value.$silentErrors.length) {
    v$.value.$silentErrors.map((obj) => {
      const newObj = { ...obj };
      filterPropertyArray.push(obj.$property);

      return newObj;
    });
  }
  filterPropertyArray.forEach((item, i) => {
    filterPropertyArray[i] = formHelper.whatFieldNameItis(item);
  });
  errorFields.value = Object.values(filterPropertyArray).toString();
  console.log("errorFields", errorFields.value);
});
</script>

<style lang="scss" scoped>
// multipleSelector
.multipleSelector {
  display: grid;
  -moz-column-count: 2;
  column-count: 2;
  grid-template-columns: 60% 40%;
  &__wrapper {
    position: relative;
  }

  &__status {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 8px;
    font-size: 12px;
    list-style: square;
    &--underline {
      border-bottom: 1px solid #dee2e6;
    }

    li {
      margin-left: 20px;
      padding-right: 8px;
    }
    .form-check {
      margin: 0;
      padding: 0;
      min-height: 18px;
    }
    .form-check-label {
      padding-left: 4px;
    }
    .form-check-checkbox {
      position: relative;
      top: 2px;
    }
  }

  &__switcher {
    position: absolute;
    right: 8px;
    top: -9px;
    display: flex;
    align-items: center;
    font-size: 13px;
    .label {
      padding-right: 4px;
      color: #9a9a9a;
    }
  }

  &__searchInput {
    position: relative;
    margin-bottom: 8px;
    .bi {
      position: absolute;
      top: 12px;
      right: 16px;
    }
  }

  &__box {
    padding: 16px 16px 32px;
    background-color: #f9f9f9;
    border: 1px solid #cacaca;
    &--left {
      padding-right: 8px;
      border-right: 0;
    }
    &--right {
      padding-left: 8px;
    }
  }

  &__content {
    padding: 8px;
    max-height: 680px;
    background-color: #fff;
    border: 1px solid #cacaca;
    border-radius: 15px;
    overflow: auto;
    &--left {
      min-height: 350px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &--right {
      // display: flex;
      // flex-wrap: wrap;
      // flex-direction: column;
      min-height: 350px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__footer {
    border-top: 8px solid #ff8400;
    font-weight: bold;
    font-size: 16px;
    &--left {
      color: #ff8400;
      border-top-color: #ff8400;
    }
    &--right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      // text-align: right;
      color: #3dc5ab;
      border-top-color: #3dc5ab;
      .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        color: #fff;
        background-color: #00b290;
      }
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // list-style: square;
    margin-bottom: 8px;
    line-height: 44px;
    li {
      padding-right: 18px;
      padding-left: 18px;
      border-right: 1px solid #ebebeb;
      border-left: 1px solid #ffffff;
      &:first-child {
        border-left: 0;
      }
    }
  }

  &__collect {
    position: absolute;
    right: -18px;
    top: 50%;
    z-index: 9;
  }

  &__subject {
    margin-bottom: 0;
    padding: 4px 8px;
    color: #fff;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    line-height: 35px;
    background-color: #4c6f8c;
    border-radius: 5px 5px 0 0;

    &--pending {
      background-color: #668c4c;
    }
  }
}
.filter__input.form-control {
  background-color: #fff;
  border-color: #dee2e6;
}
// Switch
.form-check-input {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input:checked {
  background-color: var(--bs-form-check-bg);
  border-color: var(--bs-border-color);
}
.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}
// Cell
.cell {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  // padding-bottom: 32px;
  width: 100%;
  // border-top: 1px solid #d5d5d5;
  &::before {
    content: "";
    position: relative;
    top: -12px;
    margin: 0 auto;
    width: 100%;
    height: 1px;
    background-color: #d5d5d5;
  }
  &:first-child {
    border-top: 0;
    &::before {
      height: 0;
    }
  }
  &--done {
    background-color: #e6e6e6;
    opacity: 0.6;
  }
  &__box {
    display: flex;
    flex-direction: column;
    line-height: 28px;
    &--right {
      padding-right: 20px;
      color: #a0a0a0;
      font-size: 13px;
    }
  }
  &__name {
    display: flex;
    align-items: center;
    .badge {
      font-size: 11px;
    }
  }
  &__description {
    color: #666;
    font-size: 13px;
  }
  &__slot1 {
    text-align: right;
  }
  &__slot2 {
    text-align: right;
  }
  &__button {
    cursor: pointer;
    position: absolute;
    right: 5px;
  }
}

/* APT Form */
.loadingOverlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.van-field__error-message {
  display: block;
  width: 100%;
  // margin-left: 203.33px !important;
  margin-left: 244px !important;
  line-height: 24px;
}
.fieldRow + .van-field__error-message {
  margin-top: -8px;
}
.popup__content {
  padding-bottom: 72px;
}
</style>
<style scoped>
:deep(.vtl-table) {
  margin-bottom: 0;
}
:deep(.vtl-table thead th) {
  color: #666;
  background-color: #fff;
  border-color: #dee2e6;
  border-top: 0;
}
:deep(.vtl-paging) {
  display: none;
}
:deep(.vtl-tbody-tr.selected) {
  background-color: #e9ebff;
}
:deep(.vtl-tbody-tr.marked) {
  opacity: 0.3;
}
:deep(.vtl-tbody-tr.selected.marked) {
  opacity: 1;
}
:deep(.vtl-empty-msg) {
  padding: 16px;
}
:deep(.multipleSelector__label) {
  background: #ececec;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}

/* Mutiple Selector */
:deep(.multiselect) {
  padding: 3px;
}
:deep(.multiselect-tags-search) {
  background-color: transparent;
}

/* Error Fields */
.form-select.invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
::v-deep(.invalid .van-field__control) {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
::v-deep(.invalid.v-select) {
  padding-top: 3px;
  padding-bottom: 3px;
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* v-select component styles */
::v-deep(.vs__dropdown-toggle) {
  padding: 3px 0;
  height: 37px;
  border: 0;
  background-color: transparent;
}
::v-deep(.vs__actions) {
  display: none;
}
::v-deep(.vs__search) {
  padding-left: 0;
  background-color: transparent;
}
::v-deep(.vs__selected) {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  min-width: 350px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
