<template>
  <div v-if="isProgress.page || isProgress.login" class="preLoader">
    <span class="spin"></span>
  </div>
  <van-back-top immediate />

  <div class="content container-fluid">
    <!-- filterParamsDateRange: {{ filterParamsDateRange }} -->
    <!-- showCalendar: {{ showCalendar }} -->
    <!-- allCustomerDataVuex: {{ allCustomerDataVuex }} -->
    <!-- currentSelectedCaseFilterTab: {{ currentSelectedCaseFilterTab }} -->
    <!-- DEFAULT_TAB_FILTER: {{ DEFAULT_TAB_FILTER }} -->
    <!-- isProgress.login: {{ isProgress.login }} -->
    <div class="mainFunctionBar" style="display: flex">
      <router-link to="/caseNew" v-slot="{ href, navigate }" custom>
        <button
          :href="href"
          @click="navigate"
          class="mainFunctionBar__button mainFunctionBar__button--circle me-3"
          title="Add IR Request"
          style="font-size: 33px"
        >
          <i class="van-badge__wrapper van-icon van-icon-plus"></i>
        </button>
      </router-link>

      <button
        v-if="ENABLE_FUNCTION_UPLOAD_WITHOUT_ASSET"
        @click="showUploadFilesPopup(1)"
        class="mainFunctionBar__button mainFunctionBar__button--rectangle me-3"
        title="APT Submit"
        style="font-size: 23px"
      >
        <i class="fa-solid fa-vial-virus"></i>
      </button>

      <div class="mainFunctionBar__section">
        <div
          v-if="!CARD_MODE_ACTIVE"
          class="mainFunctionBar__input"
          :class="{
            'mainFunctionBar__input--underline': isShowAdvencedSearchPanel,
          }"
        >
          <input
            type="text"
            v-model="searchTerm"
            id="filter"
            class="filter__input form-control"
            placeholder="輸入關鍵字..."
            :class="{ diabled: CARD_MODE_ACTIVE == true }"
            :disabled="CARD_MODE_ACTIVE"
          />
          <div
            class="filter__innerButton"
            style="cursor: pointer"
            @click="handleShowSearchPad()"
          >
            進階搜尋
            <i
              v-if="!isShowAdvencedSearchPanel"
              class="fa-solid fa-chevron-down"
            ></i>
            <i v-else class="fa-solid fa-chevron-up"></i>
          </div>
          <div v-if="isShowAdvencedSearchPanel" class="advancedSearchForm">
            <!-- {{ advencedSearchFieldsData }} -->
            <div class="row gx-4">
              <div class="col-lg-6">
                <div class="fieldRow">
                  <label for="engineer" class="fieldRow__label">
                    負責工程師
                  </label>
                  <select
                    v-model="advencedSearchFieldsData.engineer"
                    name="engineer"
                    class="form-select fieldRow__editField"
                  >
                    <option value="" selected>未指定</option>
                    <template
                      v-for="item in shiftMemberListDataVuex"
                      :key="item.sid"
                    >
                      <option :value="item.Email">
                        {{ item.DisplayName }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="fieldRow">
                  <label for="state" class="fieldRow__label"> 案件狀態 </label>
                  <select
                    v-model="advencedSearchFieldsData.state"
                    name="state"
                    class="form-select fieldRow__editField"
                  >
                    <template
                      v-for="item in initConfig.field.state.options"
                      :key="item.key"
                    >
                      <option :value="item.value" :disabled="item.disabled">
                        {{ item.key }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="fieldRow">
                  <label for="date" class="fieldRow__label"> 日期區間 </label>
                  <VueDatePicker
                    v-model="advancedSearchTermDateRangeDisplay"
                    class="form-control"
                    placeholder="選擇時間區間"
                    model-type="yyyy-MM-dd"
                    format="yyyy / MM / dd"
                    month-name-format="long"
                    week-start="0"
                    locale="zh-TW"
                    prevent-min-max-navigation
                    year-first
                    range
                    :enable-time-picker="false"
                    :max-date="new Date()"
                    :partial-range="false"
                    :action-row="{ showPreview: false }"
                    :day-names="['日', '一', '二', '三', '四', '五', '六']"
                    :space-confirm="false"
                    :offset="0"
                    @update:model-value="onDatepickerConfirm"
                  />
                </div>
              </div>
              <!-- <div class="col-lg-6">
                <div class="position-relative">
                  <van-field
                    v-model="advancedSearchTermDateRangeDisplay"
                    type="text"
                    name="advancedSearchTermDateRangeDisplay"
                    class="fieldRow"
                    label="日期區間"
                    label-class="fieldRow__label"
                    :border="false"
                    @click="showCalendar = true"
                    readonly
                  />

                  <van-calendar
                    v-if="showCalendar"
                    type="range"
                    class="componentCalendar"
                    :show-confirm="false"
                    :poppable="false"
                    :show-title="false"
                    :min-date="calendarMinDate"
                    :formatter="
                      (day) => {
                        if (day.type === 'start') {
                          day.bottomInfo = '開始';
                        } else if (day.type === 'end') {
                          day.bottomInfo = '結束';
                        }
                        return day;
                      }
                    "
                    @confirm="onDatepickerConfirm"
                    round
                  />
                  <span
                    v-if="showCalendar"
                    class="closeCalendarButton"
                    @click="handleCloseCalendar()"
                    >關閉</span
                  >
                  <i
                    class="bi bi-x-circle crossButton"
                    @click="handleCleanCalendarValue()"
                  ></i>
                </div>
              </div> -->
            </div>
            <!-- <p>on site</p>
            <select v-model="advencedSearchFieldsData.onsite">
              <option value="today">今日案件</option>
              <option value="tomorrow">明日案件</option>
            </select>
            <p>處理中</p>
            <select v-model="advencedSearchFieldsData.inProgress">
              <option value="true">是</option>
              <option value="false">否</option>
            </select> -->
            <div class="advancedSearchForm__buttonsWrapper">
              <button
                type="button"
                class="btn btn-danger"
                @click.stop="handleAdvencedReset()"
              >
                重置
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :disabled="
                  (advencedSearchFieldsData.engineer == null &&
                    advencedSearchFieldsData.state == null &&
                    advencedSearchFieldsData.dateRange == null) ||
                  (advencedSearchFieldsData.engineer == '' &&
                    advencedSearchFieldsData.state == null &&
                    advencedSearchFieldsData.dateRange == null)
                "
                @click.stop="handleSearch(false)"
              >
                搜尋
              </button>
            </div>
          </div>
          <i
            v-if="!isShowAdvencedSearchPanel"
            class="bi bi-search filter__icon"
          ></i>
        </div>

        <button
          type="button"
          class="mainFunctionBar__button"
          @click="handleRefreshData()"
        >
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
    </div>

    <ul class="nav nav-pills mb-4" id="pills-tab">
      <li class="nav-item position-relative">
        <button
          type="button"
          class="nav-link"
          :class="{
            active: currentSelectedCaseFilterTab === 'unconfirmed',
          }"
          :disabled="isProgress.mainData"
          @click.stop="selectFilterTab('unconfirmed')"
        >
          待確認
          <span
            v-if="currentSelectedCaseFilterTab === 'unconfirmed'"
            class="badge rounded-pill text-bg-light ms-1"
          >
            {{ allCaseListData.length }}
          </span>
        </button>
        <!-- <span
          class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
        >
          <span class="visually-hidden">New alerts</span>
        </span> -->
      </li>
      <li class="nav-item">
        <button
          type="button"
          class="nav-link"
          :class="{
            active: currentSelectedCaseFilterTab === 'established',
          }"
          :disabled="isProgress.mainData"
          @click="selectFilterTab('established')"
        >
          處理中
          <span
            v-if="currentSelectedCaseFilterTab === 'established'"
            class="badge rounded-pill text-bg-light ms-1"
          >
            {{ allCaseListData.length }}
          </span>
        </button>
      </li>
      <li class="nav-item">
        <button
          type="button"
          class="nav-link"
          :class="{
            active: currentSelectedCaseFilterTab === 'personal',
          }"
          :disabled="isProgress.mainData"
          @click.stop="selectFilterTab('personal')"
        >
          個人任務
          <span
            v-if="currentSelectedCaseFilterTab === 'personal'"
            class="badge rounded-pill text-bg-light ms-1"
          >
            {{ allCaseListData.length }}
          </span>
        </button>
      </li>
      <li class="nav-item">
        <button
          type="button"
          class="nav-link"
          :class="{
            active: currentSelectedCaseFilterTab === 'closed',
          }"
          :disabled="isProgress.mainData"
          @click.stop="selectFilterTab('closed')"
        >
          已結案
          <span
            v-if="currentSelectedCaseFilterTab === 'closed'"
            class="badge rounded-pill text-bg-light ms-1"
          >
            {{ allCaseListData.length }}
          </span>
        </button>
      </li>
      <li class="nav-item">
        <button
          type="button"
          class="nav-link"
          :class="{
            active: currentSelectedCaseFilterTab === 'all',
          }"
          :disabled="isProgress.mainData"
          @click.stop="selectFilterTab('all')"
        >
          所有案件
          <span
            v-if="currentSelectedCaseFilterTab === 'all'"
            class="badge rounded-pill text-bg-light ms-1"
          >
            {{ allCaseListData.length }}
          </span>
        </button>
      </li>
      <li v-if="isShowAdvencedSearchPanelTab" class="nav-item">
        <button
          type="button"
          class="nav-link active"
          :disabled="isProgress.mainData"
          @click.stop="handleCloseTab()"
        >
          <i class="bi bi-x-circle crossButton"></i>
          {{ allCaseListData.length }} 筆資料
        </button>
      </li>
    </ul>

    <div v-if="isProgress.mainData" class="noContent">
      <van-loading size="60px" vertical>Loading...</van-loading>
    </div>

    <template
      v-if="
        !caseListData4CardMode.length &&
        !isProgress.mainData &&
        PAGE_MODE == 'card'
      "
    >
      <div class="noContent">
        <i class="bi bi-file-earmark"></i>
        <span class="noContent__text">無案件</span>
      </div>
    </template>

    <section
      v-if="
        PAGE_MODE == 'card' &&
        caseListData4CardMode.length &&
        !isProgress.mainData
      "
      class="cardsList"
    >
      <TicketCard
        v-for="item in caseListData4CardMode"
        :key="item.index"
        :case-item="item"
      >
      </TicketCard>
    </section>

    <section
      v-if="PAGE_MODE == 'table' && !isProgress.mainData"
      class="position-relative"
      :class="[`filterSection--${currentSelectedCaseFilterTab}`]"
    >
      <div class="fixedToolbar">
        <div class="card" v-if="currentCheckedCasesLength > 0">
          <div class="card-body">
            <small>已選擇數量: 共 {{ currentCheckedCasesLength }} 件</small>
            <hr />
            <button
              type="button"
              class="btn btn-danger btn-sm me-2"
              @click="showCancelCasePopup('')"
            >
              全部取消
            </button>
            <button
              type="button"
              class="btn btn-success btn-sm"
              @click="showChangeAssignee2whoPopup()"
            >
              重新指派
            </button>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>
      </div>

      <div
        class="tableWrapper"
        :class="[`mode--${currentSelectedCaseFilterTab}`]"
      >
        <table-lite
          :is-loading="table.isLoading"
          :is-static-mode="true"
          :is-fixed-first-row="true"
          :is-slot-mode="true"
          :rowClasses="table.rowClasses"
          :columns="table.columns"
          :rows="table.rows"
          :total="table.totalRecordCount"
          :sortable="table.sortable"
          :page-options="table.pageOptions"
          :page-size="20"
          :messages="table.messages"
          @row-clicked="rowClicked"
          @is-finished="tableLoadingFinish"
        >
        </table-lite>
      </div>
    </section>

    <van-pagination
      class="d-flex justify-content-center"
      v-if="
        PAGE_MODE == 'card' &&
        caseListData4CardMode.length &&
        !isProgress.mainData &&
        PAGINATION_TOTAL_PAGES > 1
      "
      v-model="PAGINATION_CURRENT_PAGE"
      prev-text="Prev"
      next-text="Next"
      :page-count="PAGINATION_TOTAL_PAGES"
      :total-items="PAGINATION_TOTAL_ITEMS"
      force-ellipses
      @change="changePage"
    />
  </div>

  <van-popup
    v-model:show="popupChangeAssigneeToOther"
    class="popup"
    round
    style="width: 400px"
  >
    <div class="popup__header">指派案件處理人員</div>
    <div class="popup__content">
      確認將此案件處理人員指派給：
      <select
        class="form-select mt-3"
        aria-label="Default select example"
        v-model="newAssignee"
      >
        <option value="">請選擇</option>
        <template v-for="item in shiftMemberListDataVuex" :key="item.sid">
          <option :value="item.email2">{{ item.user_name }}</option>
        </template>
      </select>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupChangeAssigneeToOther = false"
      >
        不更換
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleChangeAssignee2whoBatch()"
        :disabled="newAssignee == ''"
      >
        確認指派
      </button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupUploadLogFiles"
    class="popup popup__right popup__right--medium"
    closeable
    position="right"
    :close-on-click-overlay="false"
    @click-close-icon="handleClickUploadPopupCloseIcon"
  >
    <div class="popup__header">
      {{
        uploadType == 0
          ? "Upload Log Files"
          : uploadType == 1
          ? "Upload Virus Samples"
          : "Add New Asset"
      }}
    </div>
    <!-- newCaseRecord: {{ newCaseRecord }} -->
    <div class="popup__content">
      <div class="alert alert-primary" role="alert">
        <ul style="margin-left: 8px; list-style: circle">
          <li>
            檔案上傳成功後，解壓縮程序需耗時約3分鐘。解壓縮完畢該筆"上傳"狀態會顯示為成功。
          </li>
          <li>相同檔名壓縮檔上傳，會覆寫前一次的檔案內容。</li>
          <li>
            檔案上傳完成後需等候約五分鐘，資料才會納入關鍵字搜尋資料庫內。
          </li>
        </ul>
      </div>
      <div v-if="isProgress.uploadFiles" class="loading__wrapper">
        <van-loading
          class="text-center text-black"
          style="margin-top: 20%"
          size="60"
        />
      </div>
      <FileUploadPlayGround
        :upload-type="uploadType"
        :case-id="null"
        @active-refresh="intervalHandle"
      />
    </div>
    <div class="popup__footer">
      <!-- <button type="button" class="btn btn-primary">關閉</button> -->
      <button
        type="button"
        class="btn btn-primary upload-button"
        @click="showSend2AptOpsForm()"
      >
        APT Submit
      </button>
    </div>
  </van-popup>
</template>

<script setup>
// @ is an alias to /src
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  reactive,
} from "vue";
import { useStore } from "vuex";
import { showDialog, closeDialog } from "vant";
import TicketCard from "./../components/TicketCard";
import TableLite from "vue3-table-lite";
import { useRouter } from "vue-router";
import { special } from "../special";
import { form_config } from "../form.config";

import FileUploadPlayGround from "./FileUploadPlayGround.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// 4 types of "state"
// unconfirmed, established, closed, cancel

const DEFAULT_TAB_FILTER = special.DEFAULT_TAB_FILTER || "personal";
const ENABLE_FUNCTION_UPLOAD_WITHOUT_ASSET =
  special.ENABLE_FUNCTION_UPLOAD_WITHOUT_ASSET || false;
// eslint-disable-next-line no-undef
const initConfig = form_config || [];
const CARD_MODE_ACTIVE = ref(null);
const PAGE_MODE = ref(null);
const store = useStore();
const router = useRouter();
const filterParams = ref(null);

const filterParamsDateRange = ref(null);
const popupChangeAssigneeToOther = ref(false);

// Upload
const uploadType = ref(null);
const popupUploadLogFiles = ref(false);
const showUploadFilesPopup = (type) => {
  console.log("showUploadFilesPopup");
  uploadType.value = type;
  popupUploadLogFiles.value = true;
};
const handleClickUploadPopupCloseIcon = () => {
  console.log("handleClickUploadPopupCloseIcon");
};

// const showCalendar = ref(false);
const newAssignee = ref(null);
const advancedSearchTermDateRangeDisplay = ref(null);
const currentCheckedCasesLength = ref(null);
// const calendarMinDate = new Date(2023, 0, 1);
const intervalHandle = setInterval(() => {
  console.log("intervalHandle", 11111111);
  console.log(
    "currentSelectedCaseFilterTab.value",
    currentSelectedCaseFilterTab.value
  );
  console.log("DEFAULT_TAB_FILTER", DEFAULT_TAB_FILTER);
  selectFilterTab(
    currentSelectedCaseFilterTab.value
      ? currentSelectedCaseFilterTab.value
      : DEFAULT_TAB_FILTER
  );
}, 300000);
const isShowAdvencedSearchPanel = ref(false);
const handleShowSearchPad = () => {
  let showSearcPadState = null;
  if (isShowAdvencedSearchPanel.value) {
    showSearcPadState = false;
  } else {
    showSearcPadState = true;
  }
  isShowAdvencedSearchPanel.value = showSearcPadState;
};
const isShowAdvencedSearchPanelTab = ref(false);
const handleShowSearchTab = (boolean) => {
  isShowAdvencedSearchPanelTab.value = boolean;
};
const advencedSearchFieldsData = reactive({
  state: null,
  onsite: null,
  engineer: "",
  inProgress: null,
  dateRange: null,
});
const handleAdvencedReset = () => {
  advencedSearchFieldsData.state = null;
  advencedSearchFieldsData.onsite = null;
  advencedSearchFieldsData.engineer = "";
  advencedSearchFieldsData.inProgress = null;
  advencedSearchFieldsData.dateRange = null;
  advancedSearchTermDateRangeDisplay.value = "";
};
const handleSearch = () => {
  // const filteredState = Object.keys(advencedSearchFieldsData).filter(
  //   (key) => advencedSearchFieldsData[key] !== null
  // );
  // const resultString = filteredState
  //   .map((key) => `${key}=${advencedSearchFieldsData[key]}`)
  //   .join("&");

  let resultString = "";
  if (
    advencedSearchFieldsData.state !== null &&
    advencedSearchFieldsData.state !== ""
  ) {
    resultString += `state=${advencedSearchFieldsData.state}`;
  }
  if (
    advencedSearchFieldsData.engineer !== null &&
    advencedSearchFieldsData.engineer !== ""
  ) {
    resultString += `&engineer=${advencedSearchFieldsData.engineer}`;
  }
  if (
    advencedSearchFieldsData.dateRange !== null &&
    advencedSearchFieldsData.dateRange !== ""
  ) {
    resultString += `&${advencedSearchFieldsData.dateRange}`;
  }

  store.commit("updateCurrentSelectedCaseFilterTab", null);
  handleShowSearchTab(true);
  getAllCasesListData(resultString, filterParamsDateRange.value);
  handleShowSearchPad(false);
};
const handleCloseTab = () => {
  handleShowSearchTab(false);
  const tabType = sessionStorage.getItem("currentSelectedCaseFilterTab");
  selectFilterTab(tabType);
  handleAdvencedReset();
};

const userData = computed(() => {
  return store.getters.user;
});
const PAGINATION_CURRENT_PAGE = computed({
  get: () => {
    return store.getters.casePageCurrent;
  },
  set: (value) => {
    store.commit("updateCasesPage", value);
  },
});
const PAGINATION_TOTAL_PAGES = computed(() => {
  return store.getters.casePageTotal;
});
const PAGINATION_TOTAL_ITEMS = computed(() => {
  return store.getters.casePageAmount;
});
const isProgress = computed(() => {
  return store.getters.isProgress;
});
const currentSelectedCaseFilterTab = computed(() => {
  return store.getters.currentSelectedCaseFilterTab;
});
const caseListData4CardMode = computed(() => {
  return store.getters.caseListData4CardMode;
});
const allCaseListData = computed(() => {
  return store.getters.allCaseListData;
});
const allCustomerData = computed(() => {
  return store.getters.allCustomerData;
});
const shiftMemberListDataVuex = computed(() => {
  return store.getters.shiftMemberListData;
});
const userEmail = ref(null);

const changePage = () => {
  console.log("changePagechangePage");
  // const filterParams = sessionStorage.getItem("lastSearchCaseParams");
  // getCasesListData(filterParams);
  window.scrollTo(0, 0);
};

const selectFilterTab = (selectedType) => {
  if (isShowAdvencedSearchPanelTab.value === true) {
    handleShowSearchTab(false);
    handleAdvencedReset();
  }

  const lastSelectedType = sessionStorage.getItem(
    "currentSelectedCaseFilterTab"
  );
  const lastCaseListPageP = sessionStorage.getItem("lastCaseListPageP");

  if (selectedType !== lastSelectedType) {
    store.commit("updateCasesPage", 1);
  } else {
    store.commit("updateCasesPage", lastCaseListPageP);
  }
  store.commit("updateCurrentSelectedCaseFilterTab", selectedType);
  // sessionStorage.setItem("currentSelectedCaseFilterTab", selectedType);

  function getHotParams(type) {
    let params;
    let tabs = {
      unconfirmed: function () {
        params = `state=0`;
      },
      established: function () {
        params = `state=2`;
      },
      tomorrow: function () {
        params = `onsite=tomorrow`;
      },
      today: function () {
        params = `onsite=today`;
      },
      personal: function () {
        params = `engineer=${userEmail.value}&state=2`;
      },
      inProgress: function () {
        params = `inProgress=True`;
      },
      closed: function () {
        params = `state=3`;
      },
      all: function () {
        params = ``;
      },
    };

    (tabs[type] || tabs[DEFAULT_TAB_FILTER])();
    return params;
  }
  // console.log("user", user.value.data.mail);
  filterParams.value = getHotParams(selectedType);
  advancedSearchTermDateRangeDisplay.value = null;
  filterParamsDateRange.value = null;
  getAllCasesListData(filterParams.value);
};

const getAllCasesListData = async (params, dateRangeParams) => {
  try {
    // console.log("getCasesListData", params);
    const response = await store.dispatch("searchAllCaseList", {
      params: params,
      dateRangeParams: dateRangeParams,
    });
    console.log("response - getAllCasesListData", response);
    if (response.status == 200) {
      if (!Object.keys(response).length) {
        console.log("No data!!!");
      } else {
        console.log("Has data!!!");
      }
      closeDialog();
    } else {
      showDialog({
        message: response.message,
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    }
    store.dispatch("handlePageLoadingState", false);
  } catch (error) {
    showDialog({
      message: error.message,
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
    console.log("Case 尚未撈到", error);
  }
};

const showChangeAssignee2whoPopup = () => {
  console.log("showChangeAssignee2whoPopup");
  popupChangeAssigneeToOther.value = true;
  newAssignee.value = "";
};

const handleRefreshData = () => {
  console.log("handleRefreshData");
  selectFilterTab(
    sessionStorage.getItem("currentSelectedCaseFilterTab")
      ? sessionStorage.getItem("currentSelectedCaseFilterTab")
      : DEFAULT_TAB_FILTER
  );
};

const onDatepickerConfirm = (values) => {
  console.log("onDatepickerConfirm", values);

  advencedSearchFieldsData.dateRange = values;
  if (
    advencedSearchFieldsData.dateRange !== null &&
    advencedSearchFieldsData.dateRange !== ""
  ) {
    advencedSearchFieldsData.dateRange =
      `from=${advancedSearchTermDateRangeDisplay.value[0]}&to=${advancedSearchTermDateRangeDisplay.value[1]}` ||
      null;
  }
};

// Init table settings
const searchTerm = ref(""); // Search text
const table = reactive({
  isLoading: false,
  columns: [
    {
      label: "狀態",
      field: "State",
      width: "",
      sortable: true,
      display: function (row) {
        return `<span class="badge ${row.progress_background_color}">${row.progress}</span>`;
      },
    },
    {
      label: "Case No.",
      field: "CaseId",
      width: "",
      sortable: true,
      isKey: true,
    },
    {
      label: "入侵天數",
      field: "invasionDays",
      width: "",
      sortable: true,
      display: function (row) {
        if (row.invasionDays) {
          return `${row.invasionDays} day(s)`;
        } else {
          return "N/A";
        }
      },
    },
    {
      label: "On-site",
      field: "on_site_date",
      width: "8%",
      sortable: true,
    },
    {
      label: "客戶代號",
      field: "CustomerShortName",
      width: "8%",
      sortable: true,
    },
    {
      label: "客戶名稱",
      field: "CustomerName",
      width: "12%",
      sortable: true,
    },
    {
      label: "產業類別",
      field: "Industry",
      sortable: true,
    },
    {
      label: "攻擊方式",
      field: "attack_type_display",
      width: "",
      sortable: true,
    },
    {
      label: "負責工程師",
      field: "owner_display_name",
      width: "",
      sortable: true,
      display: function (row) {
        return `${row.owner_display_name}`;
      },
    },
    {
      label: "趨勢業務",
      field: "SalesEnglishName",
      width: "",
      sortable: true,
    },
    {
      label: "案件來源",
      field: "source_display",
      width: "",
      sortable: true,
    },
    {
      label: computed(() => {
        if (currentSelectedCaseFilterTab.value == "cancel") {
          return "取消時間";
        } else if (currentSelectedCaseFilterTab.value == "established") {
          return "建案時間";
        } else if (currentSelectedCaseFilterTab.value == "unconfirmed") {
          return "請求時間";
        } else {
          return "更新時間";
        }
      }),
      field: "CreationTime",
      width: "8%",
      headerClasses: computed(() => {
        if (currentSelectedCaseFilterTab.value == "closed") {
          return ["hide"];
        } else {
          return [""];
        }
      }),
      columnClasses: computed(() => {
        if (currentSelectedCaseFilterTab.value == "closed") {
          return ["hide"];
        } else {
          return [""];
        }
      }),
      sortable: true,
    },
    {
      label: "Duration",
      field: "duration_display",
      width: "",
      headerClasses: computed(() => {
        if (currentSelectedCaseFilterTab.value == "closed") {
          return ["hide"];
        } else {
          return [""];
        }
      }),
      columnClasses: computed(() => {
        if (currentSelectedCaseFilterTab.value == "closed") {
          return ["hide"];
        } else {
          return [""];
        }
      }),
      sortable: true,
    },
  ],
  rows: computed(() => {
    return allCaseListData.value.filter(
      (x) =>
        x.CaseId.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        x.on_site_date.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        x.CustomerEnglishName.toLowerCase().includes(
          searchTerm.value.toLowerCase()
        ) ||
        x.CustomerName.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        x.issue_type_display
          .toLowerCase()
          .includes(searchTerm.value.toLowerCase()) ||
        x.owner_display_name
          .toLowerCase()
          .includes(searchTerm.value.toLowerCase()) ||
        x.SalesEngilishName.toLowerCase().includes(
          searchTerm.value.toLowerCase()
        ) ||
        x.source_display
          .toLowerCase()
          .includes(searchTerm.value.toLowerCase()) ||
        x.CreationTime.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
  }),
  rowClasses: function (row) {
    return row.className4CardBg;
  },
  totalRecordCount: computed(() => {
    return table.rows.length;
  }),
  sortable: {
    order: "CaseId",
    sort: "desc",
  },
  messages: {
    pagingInfo: "現在顯示 {0} 到 {1}筆 共{2}筆",
    pageSizeChangeLabel: "每頁筆數:",
    gotoPageLabel: "現在頁數:",
    noDataAvailable: "無案件",
  },
  pageOptions: [
    {
      value: 20,
      text: 20,
    },
    {
      value: 50,
      text: 50,
    },
    {
      value: 100,
      text: 100,
    },
    {
      value: 500,
      text: 500,
    },
    {
      value: 10000,
      text: "All",
    },
  ],
});
const tableLoadingFinish = () => {
  table.isLoading = false;
  currentCheckedCasesLength.value = document.querySelectorAll(
    "input[type='checkbox'].vtl-tbody-checkbox:checked"
  ).length;
};
const rowClicked = (row) => {
  console.log("rowClicked - router", router);
  console.log("rowClicked - row", row);
  console.log(
    "rowClicked -currentSelectedCaseFilterTab",
    currentSelectedCaseFilterTab.value
  );
  // router.push(`/caseEdit/${row.CaseId}`);
  if (row.State == 0) {
    router.push(`/caseEdit/${row.CaseId}`);
  } else {
    router.push(`/caseDetail/${row.CaseId}`);
  }
};

watch(
  () => CARD_MODE_ACTIVE.value,
  (boolean) => {
    if (boolean) {
      // console.log("watch - card");
      sessionStorage.setItem("lastCaseListDisplayMode", "card");
      PAGE_MODE.value = "card";
    } else {
      // console.log("watch - table");
      sessionStorage.setItem("lastCaseListDisplayMode", "table");
      PAGE_MODE.value = "table";
    }
  }
);

watch(
  userData,
  (data) => {
    if (data.data && data.data.mail) {
      console.log("Login data coming!", data);
      userEmail.value = data.data.mail.toLowerCase();
      selectFilterTab(
        sessionStorage.getItem("currentSelectedCaseFilterTab")
          ? sessionStorage.getItem("currentSelectedCaseFilterTab")
          : DEFAULT_TAB_FILTER
      );
    } else {
      console.log("No Login data!");
    }
  },
  { deep: true, immediate: true }
);

watch(
  allCustomerData,
  (data) => {
    if (!Object.keys(data).length) {
      console.log("No allCustomerData data");
      store.dispatch("getAllCustomerData");
    } else {
      console.log("Has allCustomerData data", data);
    }
  },
  { deep: true, immediate: true }
);

onBeforeUnmount(() => {
  clearInterval(intervalHandle);
});

onMounted(() => {
  PAGE_MODE.value = sessionStorage.getItem("lastCaseListDisplayMode");
  sessionStorage.removeItem("workSpace_currentTab");
  if (
    !PAGE_MODE.value ||
    PAGE_MODE.value == "null" ||
    PAGE_MODE.value == "table"
  ) {
    PAGE_MODE.value = "table";
    CARD_MODE_ACTIVE.value = false;
    sessionStorage.setItem("lastCaseListDisplayMode", "table");
  } else {
    PAGE_MODE.value = "card";
    CARD_MODE_ACTIVE.value = true;
    sessionStorage.setItem("lastCaseListDisplayMode", "card");
  }
});
</script>

<style scoped lang="scss">
@import "../assets/css/_viewport.scss";
@import "../assets/css/_tableLite.css";
.dp__theme_light {
  --dp-background-color: none;
  --dp-border-color-hover: none;
  --dp-border-color: none;
  --dp-input-padding: 0px 30px 0px 15px;
}
.content {
  margin: auto;
  max-width: 1320px;

  @include desktop {
    max-width: 1640px;
  }
}
.cardsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  padding-bottom: 1rem;
}
.noContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  font-size: 64px;
  &__text {
    font-size: 16px;
  }
}
.mainFunctionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  &__section {
    display: flex;
    flex: 1 1 0%;
    flex-direction: row;
    justify-content: flex-end;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    // overflow: hidden;
  }
  &__input {
    // flex: 1 1 0%;
    position: relative;
    width: 100%;
    // border-radius: 5px 0 0 5px;
    // overflow: hidden;
    &--underline {
      &::after {
        content: "";
        position: absolute;
        --bg-opacity: 1;
        background-color: #c1c1c1;
        background-color: rgba(193, 193, 193, var(--bg-opacity));
        bottom: 0;
        margin: auto;
        right: 0;
        left: 0;
        width: 98%;
        height: 1px;
        z-index: 18;
      }
    }
  }
  &__button {
    width: 56px;
    height: 50px;
    font-size: 26px;
    background-color: #fff;
    border: 0;
    border-left: 1px solid #e8e8e8;
    border-radius: 0 5px 5px 0;

    &--circle {
      width: 50px;
      border: 0;
      border-radius: 50%;
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
    }
    &--rectangle {
      width: 50px;
      border: 0;
      border-radius: 5px;
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
    }
  }
  .filter {
    &__input {
      padding: 14px 12px;
      width: 100%;
      min-height: 50px;
      background-color: #ffffff;
      border: 0;
      border-radius: 5px 0 0 5px;
      &:focus {
        box-shadow: none;
      }
      // box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
      &:focus {
        background-color: #ffffff !important;
      }
    }
    &__icon {
      position: absolute;
      top: 9px;
      right: 15px;
      font-size: 20px;
      color: #6f6f6f;
    }
    &__innerButton {
      position: absolute;
      right: 50px;
      top: 14px;
      // text-decoration: underline;
      border-bottom: 1px solid #ddd;
      .crossButton {
        position: absolute;
        top: 1px;
        left: -4px;
      }
    }
    &__calendar {
      position: absolute;
      left: 50px;
      top: 1px;
    }
    &__range {
      // position: absolute;
      // right: 50px;
      // top: 13px;
      width: 200px;
      text-align: center;
      border: 0;
    }
  }
}
.mainFunctionBar__input--underline {
  .filter__innerButton {
    text-decoration: none;
  }
}
.advancedSearchForm {
  position: absolute;
  padding: 16px 32px;
  width: 100%;
  // height: 80dvh;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: var(--bs-box-shadow-lg);
  z-index: 99;
  &__buttonsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0;
    .btn {
      margin-left: 8px;
      width: 100%;
      max-width: 100px;
    }
  }
  .crossButton {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 12px;
    z-index: 999;
  }
  .closeCalendarButton {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 72px;
    z-index: 100;
  }
}
.fixedToolbar {
  // position: fixed;
  // top: 30%;
  // left: 16%;
  position: absolute;
  top: 10%;
  left: 5%;
  background-color: rgb(255 255 255 / 90%);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 2;
  hr {
    margin: 0.5rem 0;
  }
}
.calendarWrapper {
  position: absolute;
  top: 131px;
  right: 10%;
  z-index: 9;
}
.componentCalendar {
  position: absolute;
  right: 0;
  width: 80%;
  height: 380px;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
.van-field__error-message {
  display: block;
  width: 100%;
  // margin-left: 203.33px !important;
  margin-left: 244px !important;
  line-height: 24px;
}
button .badge {
  --bs-badge-padding-x: 0.5em;
  --bs-badge-padding-y: 0.1em;
}
</style>
<style scoped>
::v-deep(.vtl-table) {
  display: table;
}
::v-deep(.vtl-table thead th) {
  text-align: center;
  font-weight: 400;
}
::v-deep(.vtl-table thead th.hide) {
  display: none;
}
::v-deep(.vtl-table td) {
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
::v-deep(.vtl-table td a) {
  color: #212529;
}
::v-deep(.vtl-table td.hide) {
  display: none;
}
::v-deep(.vtl-table td.overflowCell div) {
  overflow: auto;
}
::v-deep(.vtl-table td.overflowCell span) {
  position: relative;
  left: -11px;
}
::v-deep(.vtl-checkbox-th) {
  display: none;
}
::v-deep(.vtl-checkbox-td) {
  display: none;
}
::v-deep(.mode--unconfirmed .vtl-checkbox-th) {
  display: table-cell;
}
::v-deep(.mode--unconfirmed .vtl-checkbox-td) {
  display: table-cell;
}
::v-deep(.mode--personal .vtl-checkbox-th) {
  display: table-cell;
}
::v-deep(.mode--personal .vtl-checkbox-td) {
  display: table-cell;
}
</style>
<style scoped>
.vs-datalist .vs__dropdown-toggle {
  border: none;
  border-radius: 0;
  padding: 0;
  padding: 0;
  margin: 0;
}
.vs-datalist .vs__search {
  padding: 0;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
}
.vs-datalist .vs__selected {
  padding: 0;
  border: 0;
  margin: 0;
}
.vs-datalist .vs__actions {
  display: none;
}
</style>
