<template>
  <div v-if="isProgress.page || isProgress.login" class="preLoader">
    <span class="spin"></span>
  </div>

  <section class="appContent formWrapper">
    <h1>新建 IR Request</h1>
    <hr />
    <FormTemplate
      ref="orderNewForm"
      :old-form="''"
      @changeDisableButton="changeDisableButton"
      :disableButton="disableButton"
    />

    <div class="functionBar functionBar--fixed">
      <button
        type="button"
        class="btn btn-link"
        @click="handleGo2CaseManagement()"
      >
        離開
      </button>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          @click.stop="handleSendCreateCaseConfirm()"
          :disabled="disableButton"
        >
          建立
        </button>
      </div>
    </div>
  </section>

  <van-popup
    v-model:show="popupSendCreateCaseConfirm"
    class="popup"
    round
    style="width: 400px"
  >
    <div class="popup__header">建立案件？</div>
    <div class="popup__content">
      案件建立後僅能完成或取消，並請確認申請內容。是否要執行建立案件？
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupSendCreateCaseConfirm = false"
      >
        不建立案件
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click.stop="handleSendCreateCaseRequest()"
      >
        建立
      </button>
    </div>
  </van-popup>
</template>
<script setup>
import FormTemplate from "../components/FormTemplate";
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const orderNewForm = ref(null);
const popupSendCreateCaseConfirm = ref(false);

const isProgress = computed(() => {
  return store.getters.isProgress;
});

const disableButton = ref(true);
const changeDisableButton = (boolean) => {
  disableButton.value = boolean;
};

const showSendCreateCaseConfirmPopup = () => {
  popupSendCreateCaseConfirm.value = true;
};
const handleSendCreateCaseConfirm = () => {
  showSendCreateCaseConfirmPopup();
};
const handleSendCreateCaseRequest = async () => {
  orderNewForm.value.caseEditConfirm();
};
const handleGo2CaseManagement = () => {
  router.push("/caseManagement");
};

onMounted(() => {
  isProgress.value.page = false;
});
</script>
<style scoped lang="scss">
.functionBar {
  max-width: 1000px;
}
</style>
