<template>
  <div v-if="isProgress.page || isProgress.login" class="preLoader">
    <span class="spin"></span>
  </div>

  <section class="content container-fluid">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h4>
        <i class="bi bi-filter-square-fill text-theme"></i> Filter Configs
        Setting
      </h4>
      <span class="btn btn-outline-theme" @click="popupFilterAddNewItem = true">
        <i class="fa fa-plus-circle fa-fw me-1"></i> Add Filter Set
      </span>
    </div>

    <div class="card">
      <div class="card-body">
        <label for="filter" class="form-label">SearchBy:</label>
        <div class="position-relative">
          <input
            type="text"
            v-model="searchTerm"
            id="filter"
            class="filter__input form-control"
            placeholder="輸入關鍵字..."
          />
          <i class="bi bi-search filter__icon"></i>
        </div>
        <hr />
        <table-lite
          :is-loading="table.isLoading"
          :is-static-mode="true"
          :columns="table.columns"
          :rows="table.rows"
          :total="table.totalRecordCount"
          :sortable="table.sortable"
          :page-options="table.pageOptions"
          @row-clicked="rowClicked"
          @is-finished="tableLoadingFinish"
        ></table-lite>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </section>

  <van-popup
    v-model:show="popupFilterItemDetail"
    class="popup popup__right popup__right--small"
    closeable
    position="right"
  >
    <div class="popup__header">
      Filter Config Setting ({{
        SelectedFilterConfigSettingData[0].FilterName
      }})
    </div>
    <!-- newCaseRecord: {{ newCaseRecord }} -->
    <div class="popup__content" style="height: 60%">
      <!-- <div class="twoColumnsFormTemplate"> -->
      <div class="oneColumnsFormTemplate">
        <div class="card">
          <div class="list-group list-group-flush">
            <template
              v-for="item in SelectedFilterConfigSettingData"
              :key="item.id"
            >
              <div
                class="list-group-item d-flex align-items-center justify-content-between cursor-pointer"
                @click="item.configEnabled = !item.configEnabled"
              >
                <div class="flex-1 text-break">
                  <div>{{ item.FieldDisplayName }} ({{ item.FieldName }})</div>
                  <div
                    class="text-inverse text-opacity-50 d-flex align-items-center"
                  >
                    <template v-if="item.configEnabled">
                      <i
                        class="fa fa-circle fs-8px fa-fw text-success me-1"
                      ></i>
                      Enabled
                    </template>
                    <template v-else>
                      <i class="fa fa-circle fs-8px fa-fw me-1"></i>
                      Disabled
                    </template>
                  </div>
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    v-model="item.configEnabled"
                    :checked="item.configEnabled"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="popup__footer">
      <button type="button" class="btn btn-primary">儲存</button>
    </div> -->
  </van-popup>

  <van-popup
    v-model:show="popupFilterAddNewItem"
    class="popup popup__right"
    closeable
    position="right"
  >
    <div class="popup__header">Add Filter Set</div>
    <!-- newCaseRecord: {{ newCaseRecord }} -->
    <div class="popup__content">
      <!-- <div class="twoColumnsFormTemplate"> -->
      <div class="twoColumnsFormTemplate">
        <div>
          <div class="fieldRow pe-3">
            <label for="level" class="fieldRow__label">Level</label>
            <input
              type="text"
              id="level"
              class="form-control"
              placeholder="輸入等級"
            />
          </div>
          <div class="fieldRow pe-3">
            <label for="filterNo" class="fieldRow__label">Filter No.</label>
            <input
              type="text"
              id="filterNo"
              class="form-control"
              placeholder="輸入 Filter No."
            />
          </div>
          <div class="fieldRow pe-3">
            <label for="filterName" class="fieldRow__label">Filter Name</label>
            <input
              type="text"
              id="filterName"
              class="form-control"
              placeholder="輸入 Filter Name"
            />
          </div>
          <div class="fieldRow pe-3">
            <label for="name" class="fieldRow__label">Name</label>
            <input
              type="text"
              id="name"
              class="form-control"
              placeholder="輸入 Name"
            />
          </div>
          <div class="fieldRow pe-3">
            <label for="product" class="fieldRow__label">Product</label>
            <input
              type="text"
              id="product"
              class="form-control"
              placeholder="輸入 product"
            />
          </div>
        </div>
        <div class="card">
          <div
            class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
          >
            Select filter fields:
          </div>
          <div class="list-group list-group-flush">
            <template v-for="item in NewFilterConfigSettingData" :key="item.id">
              <div
                class="list-group-item d-flex align-items-center justify-content-between cursor-pointer"
                @click="item.configEnabled = !item.configEnabled"
              >
                <div class="flex-1 text-break">
                  <div>{{ item.FieldDisplayName }} ({{ item.FieldName }})</div>
                  <div
                    class="text-inverse text-opacity-50 d-flex align-items-center"
                  >
                    <template v-if="item.configEnabled">
                      <i
                        class="fa fa-circle fs-8px fa-fw text-success me-1"
                      ></i>
                      Enabled
                    </template>
                    <template v-else>
                      <i class="fa fa-circle fs-8px fa-fw me-1"></i>
                      Disabled
                    </template>
                  </div>
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    v-model="item.configEnabled"
                    :checked="item.configEnabled"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup__footer">
      <button type="button" class="btn btn-primary">建立</button>
    </div>
  </van-popup>
</template>

<script>
// @ is an alias to /src
import { onMounted, computed, reactive, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import TableLite from "vue3-table-lite";

const sampleData1 = [
  {
    id: "1",
    ClassLevel: "A",
    Category: "APT 連線威脅事件",
    FilterNo: "AutoPS-A101",
    FilterName: "目標式攻擊行為事件",
    Name: "目標式攻擊行為事件",
    Product: "DDI",
    Description: "發現主機網路連線行為符合趨勢科技全球...",
    Suggestion: "1.立即將連線中繼站加入防火牆或其他閘道設定...",
    CreationTime: "2023-05-05",
    FilterConfigSetting: [
      {
        id: "1",
        FilterName: "AutoPS-A101",
        FieldName: "rt",
        FieldDisplayName: "時間",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "2",
        FilterName: "AutoPS-A101",
        FieldName: "ruleName",
        FieldDisplayName: "規則名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "3",
        FilterName: "AutoPS-A101",
        FieldName: "shost",
        FieldDisplayName: "來源主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "4",
        FilterName: "AutoPS-A101",
        FieldName: "src",
        FieldDisplayName: "來源主機 IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "5",
        FilterName: "AutoPS-A101",
        FieldName: "spt",
        FieldDisplayName: "來源通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "6",
        FilterName: "AutoPS-A101",
        FieldName: "dhost",
        FieldDisplayName: "目標主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "7",
        FilterName: "AutoPS-A101",
        FieldName: "dhost",
        FieldDisplayName: "目標主機IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "8",
        FilterName: "AutoPS-A101",
        FieldName: "dpt",
        FieldDisplayName: "目標通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "9",
        FilterName: "AutoPS-A101",
        FieldName: "hostName",
        FieldDisplayName: "網域名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "10",
        FilterName: "AutoPS-A101",
        FieldName: "request",
        FieldDisplayName: "連線位置",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
    ],
  },
  {
    id: "2",
    ClassLevel: "A",
    Category: "APT 連線威脅事件",
    FilterNo: "AutoPS-A102",
    FilterName: "連線已知 APT 惡意中繼站",
    Name: "連線已知 APT 惡意中繼站",
    Product: "DDI",
    Description: "發現主機網路連線行為符合趨勢科技全球...",
    Suggestion: "若受害主機為外部來源 IP 可先行排除，代表...",
    CreationTime: "2023-05-06",
    FilterConfigSetting: [
      {
        id: "11",
        FilterName: "AutoPS-A102",
        FieldName: "rt",
        FieldDisplayName: "時間",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "12",
        FilterName: "AutoPS-A102",
        FieldName: "ruleName",
        FieldDisplayName: "規則名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "13",
        FilterName: "AutoPS-A102",
        FieldName: "shost",
        FieldDisplayName: "來源主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "14",
        FilterName: "AutoPS-A102",
        FieldName: "src",
        FieldDisplayName: "來源主機 IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "15",
        FilterName: "AutoPS-A102",
        FieldName: "spt",
        FieldDisplayName: "來源通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "16",
        FilterName: "AutoPS-A102",
        FieldName: "dhost",
        FieldDisplayName: "目標主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "17",
        FilterName: "AutoPS-A102",
        FieldName: "dhost",
        FieldDisplayName: "目標主機IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "18",
        FilterName: "AutoPS-A102",
        FieldName: "dpt",
        FieldDisplayName: "目標通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "19",
        FilterName: "AutoPS-A102",
        FieldName: "hostName",
        FieldDisplayName: "網域名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "20",
        FilterName: "AutoPS-A102",
        FieldName: "request",
        FieldDisplayName: "連線位置",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
    ],
  },
  {
    id: "6",
    ClassLevel: "A",
    Category: "APT 惡意程式",
    FilterNo: "AutoPS-A203",
    FilterName: "郵件傳遞 APT 惡意程式",
    Name: "郵件傳遞 APT 惡意程式",
    Product: "OSCE",
    Description: "端點主機防護記錄檔案，趨勢科技全球威...",
    Suggestion: "1.確認是否為使用者下載。2. 若非使用者自行下載...",
    CreationTime: "2023-05-08",
    FilterConfigSetting: [
      {
        id: "61",
        FilterName: "AutoPS-A203",
        FieldName: "rt",
        FieldDisplayName: "時間",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "62",
        FilterName: "AutoPS-A203",
        FieldName: "ruleName",
        FieldDisplayName: "規則名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "63",
        FilterName: "AutoPS-A203",
        FieldName: "shost",
        FieldDisplayName: "來源主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "64",
        FilterName: "AutoPS-A203",
        FieldName: "src",
        FieldDisplayName: "來源主機 IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "65",
        FilterName: "AutoPS-A203",
        FieldName: "spt",
        FieldDisplayName: "來源通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "66",
        FilterName: "AutoPS-A203",
        FieldName: "dhost",
        FieldDisplayName: "目標主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "67",
        FilterName: "AutoPS-A203",
        FieldName: "dhost",
        FieldDisplayName: "目標主機IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "68",
        FilterName: "AutoPS-A203",
        FieldName: "dpt",
        FieldDisplayName: "目標通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "69",
        FilterName: "AutoPS-A203",
        FieldName: "hostName",
        FieldDisplayName: "網域名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "70",
        FilterName: "AutoPS-A203",
        FieldName: "request",
        FieldDisplayName: "連線位置",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
    ],
  },
  {
    id: "8",
    ClassLevel: "A",
    Category: "內部網路擴散行為",
    FilterNo: "AutoPS-A302",
    FilterName: "遠程工具使用（Psexec）",
    Name: "管理工具傳遞或執行",
    Product: "DDI",
    Description: "發現主機對其他電腦以遠端方式執行管控...",
    Suggestion: "1.立即將連線中繼站加入防火牆或其他閘道設定...",
    CreationTime: "2023-05-11",
    FilterConfigSetting: [
      {
        id: "81",
        FilterName: "AutoPS-A302",
        FieldName: "rt",
        FieldDisplayName: "時間",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "82",
        FilterName: "AutoPS-A302",
        FieldName: "ruleName",
        FieldDisplayName: "規則名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "83",
        FilterName: "AutoPS-A302",
        FieldName: "shost",
        FieldDisplayName: "來源主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "84",
        FilterName: "AutoPS-A302",
        FieldName: "src",
        FieldDisplayName: "來源主機 IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "85",
        FilterName: "AutoPS-A302",
        FieldName: "spt",
        FieldDisplayName: "來源通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "86",
        FilterName: "AutoPS-A302",
        FieldName: "dhost",
        FieldDisplayName: "目標主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "87",
        FilterName: "AutoPS-A302",
        FieldName: "dhost",
        FieldDisplayName: "目標主機IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "88",
        FilterName: "AutoPS-A302",
        FieldName: "dpt",
        FieldDisplayName: "目標通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "89",
        FilterName: "AutoPS-A302",
        FieldName: "hostName",
        FieldDisplayName: "網域名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "90",
        FilterName: "AutoPS-A302",
        FieldName: "request",
        FieldDisplayName: "連線位置",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
    ],
  },
  {
    id: "13",
    ClassLevel: "A",
    Category: "高風險檔案",
    FilterNo: "AutoPS-A404",
    FilterName: "OSCE 偵測 - 網頁後門程式",
    Name: "網頁後門程式",
    Product: "OSCE",
    Description: "偵測已知網頁後門程式，表示駭客已利用...",
    Suggestion: "1.檢查紀錄上 URL 是否有存在確認是否為有...",
    CreationTime: "2023-05-15",
    FilterConfigSetting: [
      {
        id: "131",
        FilterName: "AutoPS-A404",
        FieldName: "rt",
        FieldDisplayName: "時間",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "132",
        FilterName: "AutoPS-A404",
        FieldName: "ruleName",
        FieldDisplayName: "規則名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "133",
        FilterName: "AutoPS-v",
        FieldName: "shost",
        FieldDisplayName: "來源主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "134",
        FilterName: "AutoPS-A404",
        FieldName: "src",
        FieldDisplayName: "來源主機 IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "135",
        FilterName: "AutoPS-v",
        FieldName: "spt",
        FieldDisplayName: "來源通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "136",
        FilterName: "AutoPS-A404",
        FieldName: "dhost",
        FieldDisplayName: "目標主機名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "137",
        FilterName: "AutoPS-A404",
        FieldName: "dhost",
        FieldDisplayName: "目標主機IP",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "138",
        FilterName: "AutoPS-A404",
        FieldName: "dpt",
        FieldDisplayName: "目標通訊埠",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "139",
        FilterName: "AutoPS-A404",
        FieldName: "hostName",
        FieldDisplayName: "網域名稱",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
      {
        id: "140",
        FilterName: "AutoPS-A404",
        FieldName: "request",
        FieldDisplayName: "連線位置",
        CreationTime: "2023-05-15 14:10:33.303",
        configEnabled: true,
      },
    ],
  },
];

// eslint-disable-next-line no-unused-vars
const sampleNewData1 = {
  id: "0",
  ClassLevel: "",
  Category: "",
  FilterNo: "",
  FilterName: "",
  Name: "",
  Product: "",
  Description: "",
  Suggestion: "",
  CreationTime: "",
  FilterConfigSetting: [
    {
      id: "temp01",
      FilterName: "AutoPS-A101",
      FieldName: "rt",
      FieldDisplayName: "時間",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp02",
      FilterName: "AutoPS-A101",
      FieldName: "ruleName",
      FieldDisplayName: "規則名稱",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp03",
      FilterName: "AutoPS-A101",
      FieldName: "shost",
      FieldDisplayName: "來源主機名稱",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp04",
      FilterName: "AutoPS-A101",
      FieldName: "src",
      FieldDisplayName: "來源主機 IP",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp05",
      FilterName: "AutoPS-A101",
      FieldName: "spt",
      FieldDisplayName: "來源通訊埠",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp06",
      FilterName: "AutoPS-A101",
      FieldName: "dhost",
      FieldDisplayName: "目標主機名稱",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp07",
      FilterName: "AutoPS-A101",
      FieldName: "dhost",
      FieldDisplayName: "目標主機IP",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp08",
      FilterName: "AutoPS-A101",
      FieldName: "dpt",
      FieldDisplayName: "目標通訊埠",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp09",
      FilterName: "AutoPS-A101",
      FieldName: "hostName",
      FieldDisplayName: "網域名稱",
      CreationTime: "",
      configEnabled: false,
    },
    {
      id: "temp10",
      FilterName: "AutoPS-A101",
      FieldName: "request",
      FieldDisplayName: "連線位置",
      CreationTime: "",
      configEnabled: false,
    },
  ],
};

export default {
  name: "FilterConfig",
  components: { TableLite },
  setup() {
    const store = useStore();
    // const router = useRouter();
    const isProgress = computed(() => {
      return store.getters.isProgress;
    });
    const popupFilterItemDetail = ref(false);
    const popupFilterAddNewItem = ref(false);

    // Init table settings
    const searchTerm = ref(""); // Search text

    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "ID",
          field: "id",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Level",
          field: "ClassLevel",
          width: "3%",
          sortable: true,
        },
        {
          label: "Filter No.",
          field: "FilterNo",
          width: "10%",
          sortable: true,
        },
        {
          label: "Filter Name",
          field: "FilterName",
          width: "18%",
          sortable: true,
        },
        {
          label: "Name",
          field: "Name",
          width: "18%",
          sortable: true,
        },
        {
          label: "Product",
          field: "Product",
          width: "12%",
          sortable: true,
        },
        {
          label: "Function",
          field: "",
          width: "3%",
          sortable: false,
          display: function () {
            return `<i class="bi bi-pencil-square"></i> Edit`;
          },
        },
      ],
      rows: computed(() => {
        return sampleData1.filter(
          (x) =>
            x.ClassLevel.toLowerCase().includes(
              searchTerm.value.toLowerCase()
            ) ||
            x.FilterNo.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            x.FilterName.toLowerCase().includes(
              searchTerm.value.toLowerCase()
            ) ||
            x.Name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            x.Product.toLowerCase().includes(searchTerm.value.toLowerCase())
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    const SelectedFilterConfigSettingData = ref([]);
    const NewFilterConfigSettingData = ref([]);

    /**
     * Table search finished event
     */
    const tableLoadingFinish = (elements) => {
      console.log("tableLoadingFinish - elements", elements);
      table.isLoading = false;
    };

    const rowClicked = (row) => {
      console.log("rowClicked", row);
      const selectedConfigSetting = sampleData1.filter(
        (item) => item.id == row.id
      );
      SelectedFilterConfigSettingData.value =
        selectedConfigSetting[0].FilterConfigSetting;

      console.log(
        "SelectedFilterConfigSettingData",
        SelectedFilterConfigSettingData
      );

      popupFilterItemDetail.value = true;
    };

    onMounted(() => {
      NewFilterConfigSettingData.value = sampleNewData1.FilterConfigSetting;
      console.log(
        "NewFilterConfigSettingData",
        NewFilterConfigSettingData.value
      );
    });

    return {
      isProgress,
      searchTerm,
      table,
      tableLoadingFinish,
      rowClicked,
      popupFilterItemDetail,
      popupFilterAddNewItem,
      SelectedFilterConfigSettingData,
      NewFilterConfigSettingData,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/_viewport.scss";
@import "../assets/css/_tableLite.css";
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-form-placholder-color);
}
.content {
  margin: auto;
  max-width: 1320px;

  @include desktop {
    max-width: 1640px;
  }
}
.filter {
  &__input {
    padding: 12px;
    background-color: #ffffff;

    &:focus {
      background-color: #ffffff;
    }
  }
  &__icon {
    position: absolute;
    top: 7px;
    right: 15px;
    font-size: 20px;
    color: #6f6f6f;
  }
}
</style>
