export const special = {
  version: "v0.1.3",
  title: "Case Management Platform",
  custom_name: "Trend Micro",
  customer_code: "trendmicro",
  INTRANET_USE_ONLY: false,
  AZURE_AD_LOGIN: true,
  FIREBASE_LOGIN: false,
  DEFAULT_TAB_FILTER: "personal",
  ENABLE_MENU_DASHBOARD: false,
  ENABLE_MENU_FILTER: false,
  ENABLE_MENU_CUSTOMER: false,
  ENABLE_MENU_MYPROFILE: false,
  ENABLE_MENU_NOTIFICATION: false,
  ENABLE_FUNCTION_ASSETS_DELETE: true,
  ENABLE_FUNCTION_COMMENTS: false,
  ENABLE_FUNCTION_ADD_ASSET: true,
  ENABLE_FUNCTION_UPLOAD_WITHOUT_ASSET: false,
  ENABLE_FUNCTION_APT_SUBMIT_STAGING: true,
  ENABLE_FUNCTION_APT_SUBMIT_PRODUCTION: true,
  ENABLE_SECTION_KEYWORD_SEARCH: true,
  ENABLE_SECTION_MITRETAGS: false,
};
