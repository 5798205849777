<template>
  <button
    type="button"
    class="btn btn-danger me-3"
    :disabled="props.state == 1"
    @click.stop="showCancelCasePopup()"
  >
    取消案件
  </button>

  <van-popup
    v-model:show="popupCancelCase"
    class="popup"
    round
    style="width: 500px"
  >
    <div class="popup__header">取消案件？</div>
    <div class="popup__content">
      取消案件後，案件會標記為 "已取消"。
      <textarea
        class="form-control mt-3"
        v-model="cancelReason"
        rows="5"
        placeholder="輸入原因"
      ></textarea>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupCancelCase = false"
      >
        不取消案件
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="!cancelReason"
        @click.stop="handleSendCancelCaseRequest()"
      >
        取消案件
      </button>
    </div>
  </van-popup>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useStore } from "vuex";
import { showDialog } from "vant";

const props = defineProps(["state", "caseId", "submitterMail"]);
console.log("props", props);
const store = useStore();
const popupCancelCase = ref(false);
const cancelReason = ref(null);

const showCancelCasePopup = () => {
  console.log("showCancelCasePopup");
  popupCancelCase.value = true;
};

const handleSendCancelCaseRequest = async () => {
  store.dispatch("handlePageLoadingState", true);
  try {
    const CancelCaseFormData2Send = {
      Submitter: props.submitterMail,
      CaseID: props.caseId,
      State: 1,
      CancelReason: cancelReason.value,
    };
    const sourceData = {
      caseId: props.caseId,
      data: CancelCaseFormData2Send,
    };
    console.log("handleSendCancelCaseRequest - sourceData", sourceData);
    const response = await store.dispatch("putUpdateCaseState", sourceData);
    popupCancelCase.value = false;
    if (response.status !== 200) {
      store.dispatch("handlePageLoadingState", false);
      showDialog({
        message: "Cancel case FAIL.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      setTimeout(() => {
        store.dispatch("handlePageLoadingState", false);
        location.reload();
      }, 2000);
    }
  } catch (err) {
    console.log("Cancel case FAIL.", err);
  }
};
</script>
