<template>
  <div
    v-if="isProgress.page || isProgress.login || !caseDetailDataVuex"
    class="preLoader"
  >
    <span class="spin"></span>
  </div>

  <section v-if="caseDetailDataVuex" class="appContent formWrapper">
    <!-- caseDetailDataVuex: {{ caseDetailDataVuex }} -->
    <h1>
      {{
        caseDetailDataVuex.State === 0 ? "確認 IR Request" : "編輯 IR Request"
      }}
      / {{ caseDetailDataVuex.CaseID }}
    </h1>
    <hr />
    <FormTemplate
      ref="orderEditForm"
      :old-form="caseDetailDataVuex"
      :disableButton="disableButton"
      @changeDisableButton="changeDisableButton"
    />

    <div class="functionBar functionBar--fixed">
      <button type="button" class="btn btn-link" @click="handleGo2CaseDetail()">
        離開
      </button>
      <div>
        <CancelCaseButton
          v-if="caseDetailDataVuex.State == 0 || caseDetailDataVuex.State == 2"
          :state="caseDetailDataVuex.State"
          :case-id="caseDetailDataVuex.CaseID"
          :submitter-mail="userEmail"
        ></CancelCaseButton>
        <button
          type="button"
          class="btn btn-primary"
          @click.stop="handleSendEditCaseConfirm()"
          :disabled="disableButton"
        >
          {{ caseDetailDataVuex.State === 0 ? "確認" : "更新" }}
        </button>
      </div>
    </div>
  </section>

  <van-popup
    v-model:show="popupSendConfirmCaseConfirm"
    class="popup"
    round
    style="width: 400px"
  >
    <div class="popup__header">
      {{ caseDetailDataVuex.State === 0 ? "案件確認" : "案件更新" }}
    </div>
    <div class="popup__content">是否已確認此案件內容？</div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupSendConfirmCaseConfirm = false"
      >
        取消
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click.stop="handleUpdateCaseRequest()"
      >
        {{ caseDetailDataVuex.State === 0 ? "確認" : "更新" }}
      </button>
    </div>
  </van-popup>

  <van-dialog
    v-model:show="popupAlertDialog"
    title=""
    show-confirm-button
    z-index="2300"
    message="無此案件"
    confirm-button-text="返回"
    @confirm="handleClickTurningBackButton()"
  ></van-dialog>
</template>

<script setup>
import FormTemplate from "../components/FormTemplate";
import { onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { showDialog } from "vant";
import CancelCaseButton from "./../components/CancelCaseButton.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
let parmsId = ref(null);
const userEmail = sessionStorage.getItem("userEmail")
  ? sessionStorage.getItem("userEmail")
  : null;
const orderEditForm = ref(null);

const disableButton = ref(false);
const changeDisableButton = (boolean) => {
  disableButton.value = boolean;
};

const popupSendConfirmCaseConfirm = ref(false);
const popupAlertDialog = ref(false);

const isProgress = computed(() => {
  return store.getters.isProgress;
});
const caseDetailDataVuex = computed(() => {
  return store.getters.caseDetailData;
});

const showSendConfirmCaseConfirmPopup = () => {
  popupSendConfirmCaseConfirm.value = true;
};
const handleSendEditCaseConfirm = async () => {
  showSendConfirmCaseConfirmPopup();
};
const handleUpdateCaseRequest = async () => {
  orderEditForm.value.caseEditConfirm();
};
const handleGo2CaseDetail = () => {
  if (caseDetailDataVuex.value.State == 0) {
    router.push("/caseManagement");
  } else {
    router.push(`/caseDetail/${parmsId.value}`);
  }
};

const getCaseDetailData = async (parmsId) => {
  try {
    const response = await store.dispatch("getCaseDetailData", parmsId.value);

    console.log("getCaseDetailData - response", response);
    if (response.status == 200) {
      if (Object.keys(response.data).length == 0) {
        // popupAlertDialog.value = true;
        console.log("No data!!!");
        showDialog({
          message: "No data found.",
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          router.push("/caseManagement");
        });
      } else {
        console.log("Has data!!!");
        if (response.data.State == 1) {
          handleGo2CaseDetail();
        }
      }
      store.dispatch("handlePageLoadingState", false);
    } else {
      // popupAlertDialog.value = true;
      console.log("No data!!!");
      showDialog({
        message: "No data found.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
        router.push("/caseManagement");
      });
    }
  } catch (error) {
    console.log("error - getCaseDetailData", error);
    error.value = error.message;
    showDialog({
      message: error,
      confirmButtonText: "返回",
    }).then(() => {
      // on close
      router.push("/caseManagement");
    });
  }
};

watch(
  route,
  () => {
    parmsId.value = route.params.caseId;
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  if (!caseDetailDataVuex.value) {
    getCaseDetailData(parmsId);
  } else {
    if (caseDetailDataVuex.value.CaseID !== parmsId.value) {
      getCaseDetailData(parmsId);
    }
    store.dispatch("handlePageLoadingState", false);
  }
});
</script>
<style scoped lang="scss">
.functionBar {
  max-width: 1000px;
}
</style>
