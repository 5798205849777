<template>
  <div
    v-if="!props.assetLength && !props.fileLength"
    class="alert alert-warning alert-dismissible fade show"
    role="alert"
  >
    There is no asset data or file for searching.
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>

  <div
    v-if="props.assetLength || props.fileLength"
    class="input-group keywordSearchGroup"
  >
    <select
      class="form-select keywordSearchGroup__type"
      v-model="keywordSearch.type"
      :disabled="!props.assetLength"
      @change="handleChangeSearchType"
    >
      <option disabled>Choose search type...</option>
      <option value="1" selected>From files</option>
      <option value="2" :disabled="isNoEventLog">From event logs</option>
    </select>

    <van-loading
      v-if="!store.state.channelList && keywordSearch.type === '2'"
      type="spinner"
      class="keywordSearchGroup__loading"
    >
      Loading Channels...
    </van-loading>

    <select
      v-if="store.state.channelList && keywordSearch.type === '2'"
      v-model="keywordSearch.channel"
      class="form-select keywordSearchGroup__channel"
    >
      <option value="" disabled>Choose channel...</option>
      <template v-for="(item, index) in channelList" :key="index">
        <option :value="item">{{ item }}</option>
      </template>
    </select>

    <input
      v-if="keywordSearch.type === '2'"
      type="text"
      class="form-control keywordSearchGroup__input"
      v-model="keywordSearch.eventId"
      placeholder="輸入 event ID..."
    />

    <input
      type="text"
      class="form-control keywordSearchGroup__input"
      v-model="keywordSearch.string"
      placeholder="輸入關鍵字... (至少三個字元)"
    />
    <!-- inline 屬性可刪除輸入欄 -->
    <!-- multi-calendars可複數日曆 -->
    <!-- :markers="[{date: new Date(), type: 'dot', color: 'yellow',}]" 標記日期 -->
    <!-- hide-offset-dates 只顯示當月日期 -->
    <VueDatePicker
      v-if="keywordSearch.type === '2'"
      v-model="keywordSearch.time"
      class="form-control keywordSearchGroup__rangePicker"
      placeholder="選擇時間區間"
      model-type="yyyy-MM-dd hh:mm"
      format="yyyy-MM-dd HH:mm"
      hours-grid-increment="2"
      month-name-format="long"
      minutes-increment="2"
      week-start="0"
      locale="zh-TW"
      time-picker-inline
      year-first
      no-today
      range
      :max-date="new Date()"
      :partial-range="false"
      :action-row="{ showPreview: false }"
      :day-names="['日', '一', '二', '三', '四', '五', '六']"
      :space-confirm="false"
      :offset="0"
    />

    <button
      v-if="keywordSearch.type === '1'"
      class="btn btn-outline-secondary keywordSearchGroup__button"
      type="button"
      :disabled="keywordSearch.string.length <= 2 || isProgress.keywordSearch"
      @click.prevent="handleGetSearchString()"
    >
      Search
      <i class="bi bi-search"></i>
    </button>

    <button
      v-if="keywordSearch.type === '2'"
      class="btn btn-outline-secondary keywordSearchGroup__button"
      type="button"
      :disabled="
        isProgress.keywordSearch ||
        keywordSearch.channel.length === 0 ||
        (keywordSearch.eventId.length === 0 && keywordSearch.string.length <= 2)
      "
      @click.prevent="handleGetSearchStringBigquery()"
    >
      Search
      <i class="bi bi-search"></i>
    </button>
  </div>

  <van-loading
    v-if="isProgress.keywordSearch"
    type="spinner"
    class="mt-5"
    vertical
  >
    Loading...
  </van-loading>

  <template v-if="!isProgress.keywordSearch">
    <div
      v-if="!keywordResult.length && !keywordResultBigQuery.length"
      class="noDataDisplay"
    >
      <i class="bi bi-file-earmark noDataDisplay__icon"></i>
      <strong class="noDataDisplay__text">無資料</strong>
    </div>
  </template>

  <div
    v-if="!isProgress.keywordSearch && keywordResult.length"
    class="accordion"
    id="keywordResult"
  >
    <p>
      Search keyword: "<strong>{{ keywordSearchInfoVuex.keyword }}</strong
      >" from files - Found
      <strong>
        <i>{{ keywordSearchInfoVuex.total_matches }}</i>
      </strong>
      result(s) in
      <strong>{{ keywordSearchInfoVuex.total_matched_files }}</strong> files.
      Total file(s):
      <strong>{{ keywordSearchInfoVuex.total_files }}</strong>
      file(s)
    </p>
    <div
      class="accordion-item"
      v-for="(item, index) in keywordResult"
      :key="index"
    >
      <h2 class="accordion-header" :id="'keywordSearchResult-' + index">
        <button
          class="btn btn-success btn-sm"
          @click.stop="handleDownloadFile(item.download_url)"
        >
          下載
        </button>
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse-' + index"
        >
          <div class="accordion__title">#{{ index + 1 }} {{ item.path }}</div>
        </button>
      </h2>
      <div
        :id="'collapse-' + index"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#keywordResult"
      >
        <div class="accordion-body">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr v-for="(match, index) in item.matches" :key="index">
                <th class="fw-normal" v-html="highlightText(match)"></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="!isProgress.keywordSearch && keywordResultBigQuery.length"
    class="accordion"
    id="keywordResultBigQuery"
  >
    <p>
      Search keyword: "<strong>{{ keywordSearch.string }}</strong
      >" from event logs - Found
      <strong>
        <i>{{ keywordSearchInfoVuex.total_matches }}</i>
      </strong>
      result(s) in
      <strong>{{ keywordSearchInfoVuex.total_matched_files }}</strong> files.
      Total file(s):
      <strong>{{ keywordSearchInfoVuex.total_files }}</strong>
      file(s)
    </p>
    <div
      class="accordion-item"
      v-for="(item, index) in keywordResultBigQuery"
      :key="index"
    >
      <h2 class="accordion-header" :id="'keywordSearchResult-' + index">
        <button
          class="btn btn-success btn-sm"
          @click.stop="handleDownloadFile(item.DownloadURL)"
        >
          下載
        </button>
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse-' + index"
        >
          <div class="accordion__title">#{{ index }} - {{ item.Filename }}</div>
        </button>
      </h2>
      <div
        :id="'collapse-' + index"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#keywordResultBigQuery"
      >
        <div class="accordion-body">
          <table class="table table-borderless table-striped">
            <thead>
              <tr>
                <th class="text-center">Level</th>
                <th class="text-center">ProviderName</th>
                <th class="text-center">Task</th>
                <th class="text-center">TimeCreated</th>
                <th class="text-center">EventID</th>
                <th class="text-center">EventData</th>
              </tr>
            </thead>
            <tbody style="font-size: 0.9rem !important">
              <tr v-for="(data, dataIndex) in item.data" :key="dataIndex">
                <td class="text-center align-middle">{{ data.Level }}</td>
                <td class="text-center align-middle">
                  {{ data.ProviderName }}
                </td>
                <td class="text-center align-middle">{{ data.Task }}</td>
                <td class="text-center align-middle">{{ data.TimeCreated }}</td>
                <td class="text-center align-middle">{{ data.EventID }}</td>
                <td>
                  <div
                    class="text-break"
                    v-for="(EventDataItem, EventDataIndex) in data.EventData"
                    :key="EventDataIndex"
                    v-html="highlightText(EventDataItem)"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  defineProps,
  onUnmounted,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { showDialog } from "vant";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const props = defineProps(["assetLength", "fileLength"]);

let parmsId = ref(null);
const route = useRoute();
const store = useStore();

const isNoEventLog = ref(true);
const isProgress = computed(() => {
  return store.getters.isProgress;
});
const keywordResult = computed(() => {
  return store.state.keywordResult;
});
const keywordResultBigQuery = computed(() => {
  return store.state.keywordResultBigQuery;
});
const keywordSearch = reactive({
  type: "1",
  string: "",
  channel: "",
  eventId: "",
  time: null,
});

const channelList = computed(() => store.state.channelList);

const keywordSearchInfoVuex = computed(() => store.state.keywordSearchInfo);
const handleChangeSearchType = () => {
  keywordSearch.string = "";
};
const handleGetChannelList = async () => {
  // store.dispatch("handlePageLoadingState", true);
  const params = {
    case_id: parmsId.value,
  };

  try {
    const response = await store.dispatch("getChannelList", params);
    console.log("response - getChannelList:", response);
    if (response.status == 404) {
      isNoEventLog.value = true;
    } else {
      isNoEventLog.value = false;
    }
    // store.dispatch("handlePageLoadingState", false);
  } catch (err) {
    console.log("Get channel list FAIL.", err);
    // store.dispatch("handlePageLoadingState", false);
  }
};
const handleGetSearchString = async () => {
  store.dispatch("handleKeywordSearchLoadingState", true);

  // 搜尋結果初始化
  store.commit("SET_KEYWORD_RESULT", []);
  store.commit("SET_KEYWORD_RESULT_BIG", []);
  store.commit("SET_KEYWORD_SEARCH_INFO", {
    keyword: null,
    total_files: null,
    total_matches: null,
    total_matches_files: null,
    total_page: null,
  });

  const params = {
    case_id: parmsId.value,
    search_string: keywordSearch.string,
  };

  try {
    const response = await store.dispatch("getSearchResult", params);

    if (response.status === 204) {
      store.dispatch("handleKeywordSearchLoadingState", false);
      showDialog({
        message: "沒有任何搜尋結果",
        confirmButtonText: "返回",
      });
    } else if (response.status !== 200) {
      store.dispatch("handleKeywordSearchLoadingState", false);
      showDialog({
        message: "Search FAIL.",
        confirmButtonText: "返回",
      });
    } else {
      const data = {
        keyword: keywordSearch.string,
        total_files: response.data.total_files,
        total_matched_files: response.data.total_matched_files,
        total_matches: response.data.total_matches,
      };
      store.commit("SET_KEYWORD_SEARCH_INFO", data);
      store.dispatch("handleKeywordSearchLoadingState", false);
    }
  } catch (err) {
    console.log("Keyword search FAIL.", err);
    store.dispatch("handleKeywordSearchLoadingState", false);
  }
};
const handleGetSearchStringBigquery = async () => {
  store.dispatch("handleKeywordSearchLoadingState", true);

  // 搜尋結果初始化
  store.commit("SET_KEYWORD_RESULT", []);
  store.commit("SET_KEYWORD_RESULT_BIG", []);
  store.commit("SET_KEYWORD_SEARCH_INFO", {
    keyword: null,
    total_files: null,
    total_matches: null,
    total_matches_files: null,
    total_page: null,
  });

  const params = {
    case_id: parmsId.value,
    search_string: keywordSearch.string,
    channel: keywordSearch.channel,
    start_datetime: keywordSearch.time ? keywordSearch.time[0] : null,
    end_datetime: keywordSearch.time ? keywordSearch.time[1] : null,
    event_id: keywordSearch.eventId,
  };
  try {
    const response = await store.dispatch("getSearchResultBig", params);

    if (response.status === 204) {
      showDialog({
        message: "沒有任何搜尋結果",
        confirmButtonText: "返回",
      });
    } else if (response.status !== 200) {
      store.dispatch("handleKeywordSearchLoadingState", false);
      showDialog({
        message: "Update asset data FAIL.",
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      setTimeout(() => {
        store.dispatch("handleKeywordSearchLoadingState", false);
        // const data = { ...response.data, keyword: searchString.value };
        // store.commit("SET_KEYWORD_SEARCH_INFO", data);
        const data = {
          keyword: keywordSearch.string,
          total_files: response.data.total_files,
          total_matched_files: response.data.total_matched_files,
          total_matches: response.data.total_matches,
        };
        store.commit("SET_KEYWORD_SEARCH_INFO", data);
      }, 2000);
    }
  } catch (err) {
    console.log("Update asset data FAIL.", err);
  }
  store.dispatch("handleKeywordSearchLoadingState", false);
};
const handleDownloadFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const highlightText = (text) => {
  // 使用正則表達式將匹配的文字加上 highlight 的樣式

  // 轉成string
  text = "" + text;
  const regex = new RegExp(`(${store.state.keywordSearchInfo.keyword})`, "gi");
  return text.replace(
    regex,
    '<span style="background-color: yellow">$1</span>'
  );
};

onMounted(() => {
  handleGetChannelList();
});
watch(
  route,
  () => {
    parmsId.value = route.params.caseId;
  },
  { deep: true, immediate: true }
);
onUnmounted(() => {
  store.commit("RESET_KEYWORD_ALL");
});
</script>

<style lang="scss" scoped>
.keywordSearchGroup {
  margin-bottom: 32px;
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  &__type {
    width: 250px;
    flex: 0 1 auto;
    // background-color: #fff;
    border: 0;
    &:focus {
      box-shadow: none;
    }
  }
  &__channel {
    border-radius: 0 !important;
    background-color: #fff;
    border: 0;
    &:focus {
      box-shadow: none;
    }
  }
  &__input {
    border-radius: 0 !important;
    background-color: #fff;
    border: 0;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    &:focus {
      background-color: #fff !important;
      border-left: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      box-shadow: none;
    }
  }
  &__rangePicker {
    padding: 0;
    background-color: #fff;
    border-radius: 0;
    border-top: 0;
    border-bottom: 0;
  }
  &__loading {
    display: flex;
    align-items: center;
  }
  &__button {
    border: 0;
    border-left: 1px solid #e8e8e8;
    border-radius: 0 5px 5px 0 !important;
  }
  .form-select {
    background-color: #fff;
    &:disabled {
      background-color: var(--bs-secondary-bg);
    }
  }
}
.accordion {
  &-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  &-button {
    &:focus {
      box-shadow: none;
    }
  }
  &__title {
    margin-left: 20px;
  }
}
.noDataDisplay {
  padding-top: 0;
}
</style>

<style scoped>
:deep(.vtl-row) {
  overflow-y: auto;
}
:deep(.vtl-table) {
  display: block !important;
}
:deep(.dp__input) {
  border: 0;
  line-height: 34px;
}
.accordion-button {
  position: relative;
}
.accordion-button::after {
  position: absolute !important;
  left: 15px !important;
}
</style>
