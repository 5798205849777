<template>
  <!-- files: {{ files }}<br /> -->
  <!-- filesHasInvalidError: {{ filesHasInvalidError }} -->
  <!-- uploadFilesLength: {{ uploadFilesLength }} -->
  <!-- uploadType: {{ uploadType }}<br /> -->
  <!-- optionsAssetsListVuex: {{ optionsAssetsListVuex }}<br /> -->
  <!-- uploadFilesTargetAssetId: {{ uploadFilesTargetAssetId }} -->
  <!-- buttonDisabledStatus: {{ buttonDisabledStatus }} <br /> -->
  <!-- isFileNameFormateValid: {{ isFileNameFormateValid }} <br /> -->
  <div v-if="uploadType !== 2" class="fileUploader">
    <DropZone @files-dropped="addFiles" #default="{ dropZoneActive }">
      <label class="fileUploader__drop" for="file-input">
        <template v-if="!dropZoneActive">
          <i class="bi bi-cloud-upload fileUploader__uploadIcon"></i>
          <strong>Drag and Drop your file here,</strong>
          <span class="smaller">
            or
            <strong class="fileUploader__highlight">
              <em>click here</em>
            </strong>
            to browse files
          </span>
        </template>
        <template v-else>
          <i class="bi bi-cloud-upload fileUploader__uploadIcon"></i>
          <strong>Drop your file here,</strong>
          <span class="smaller">
            or
            <strong class="fileUploader__highlight">
              <em>click here</em>
            </strong>
            to browse files
          </span>
        </template>
        <small class="fileUploader__notice"> Accept file type: ZIP </small>
        <input
          type="file"
          id="file-input"
          style="display: none"
          multiple
          @change="onInputChange"
        />
      </label>
    </DropZone>
    <ul class="fileUploader__list" v-show="files.length">
      <FilePreview
        v-for="file of files"
        :key="file.id"
        :case-id="props.caseId"
        :file="file"
        :upload-type="uploadType"
        tag="li"
        @remove="removeFile"
      />
    </ul>
  </div>

  <div v-if="props.caseId && props.uploadType == 1" class="fieldRow">
    <label for="issue_type" class="fieldRow__label"> Under which asset? </label>

    <v-select
      class="vs-datalist form-control"
      label="key"
      :options="optionsAssetsListVuex"
      :reduce="(assets) => assets.value"
      v-model="uploadFilesTargetAssetId"
      :placeholder="
        initConfig.placeholder.upload_files_target_asset.forCaseForm
      "
      :disabled="initConfig.field.upload_files_target_asset.readonly"
    />
  </div>
  <button
    class="btn btn-primary upload-button"
    :disabled="!files.length || filesHasInvalidError.length"
    @click.prevent="getUploadUrl(files)"
  >
    Upload
  </button>
</template>

<script setup>
import { reactive, ref, watch, defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { showDialog } from "vant";
// Components
import DropZone from "./../components/DropZone.vue";
import FilePreview from "./../components/FilePreview.vue";
// File Management
import useFileList from "./../compositions/file-list";
// Uploader
import createUploaderCustom from "./../compositions/file-uploader";
import { form_config } from "../form.config";
// vue-select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const initConfig = form_config || [];
const props = defineProps({
  uploadType: Number,
  caseId: String,
});
const emits = defineEmits([
  "activeRefresh",
  "reloadData",
  "showPopup",
  "setStatus",
]);
const store = useStore();
const userData = computed(() => {
  return store.getters.user;
});
const getUploadUrlData = reactive({
  case_id: null,
  type: null,
  engineer_email: null,
  zipfilename: null,
  assetID: null,
});
const uploadFilesLength = ref(null);
const uploadFilesTargetAssetId = ref(null);
// const buttonDisabledStatus = ref(false);
const optionsAssetsListVuex = computed(() => {
  return store.getters.optionsAssetsList;
});
const filesHasInvalidError = ref([]);

const { files, addFiles, removeFile } = useFileList();

const onInputChange = (e) => {
  addFiles(e.target.files);
  e.target.value = null;
};
const getUploadUrl = async (filesData) => {
  store.commit("SET_ISPROGRESS_UPLOADFILES", true);
  console.log("getUploadUrl - filesData", filesData);
  uploadFilesLength.value = filesData.length;
  let fileNameList = [];
  filesData.forEach((item) => {
    console.log("forEach item.file.name", item.file.name);
    fileNameList.push(item.file.name);
  });
  getUploadUrlData.case_id = props.caseId;
  getUploadUrlData.engineer_email = userData.value.data.mail;
  getUploadUrlData.zipfilename = fileNameList;
  if (props.uploadType == 0 && uploadFilesTargetAssetId.value) {
    getUploadUrlData.assetID = uploadFilesTargetAssetId.value;
    getUploadUrlData.type = 1;
  } else if (props.uploadType == 0) {
    getUploadUrlData.assetID = null;
    getUploadUrlData.type = props.uploadType;
  } else {
    // props.uploadType == 1
    getUploadUrlData.assetID = uploadFilesTargetAssetId.value;
    getUploadUrlData.type = props.uploadType;
  }

  console.log("getUploadUrl - getUploadUrlData", getUploadUrlData);
  try {
    const responseSignedURL = await store.dispatch(
      "postGetSignedURL",
      getUploadUrlData
    );
    console.log(
      "getUploadUrl - responseSignedURL",
      responseSignedURL.data.urls
    );

    filesData.forEach(function (obj) {
      obj.uploadTargetURL = responseSignedURL.data.urls.filter(
        (item) => item.zipfile === obj.file.name
      )[0].url;
      obj.requestHeader = responseSignedURL.data.headers;
    });

    console.log("getUploadUrl - filesDataComputed.filesData", filesData);
    // "file-uploader.js" takes place to upload files
    createUploaderCustom(filesData);
  } catch (error) {
    showDialog({
      message: error.message,
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
    console.log("Fail - postGetSignedURL", error);
  }
};

watch(
  files,
  (data) => {
    if (data.length) {
      console.log("Has files to upload", data);

      filesHasInvalidError.value = [];
      data.forEach(function (item) {
        // Check if is ZIP file
        if (
          item.file.type == "application/zip" ||
          item.file.type == "application/x-zip-compressed"
        ) {
          item.validation.isZipFile = true;
        }

        // Check if is acceptable file name (Need 3 underscores) when uploading log file
        const fileName = item.file.name;
        const testFileNameResult = fileName.match(/.*_.*_.*_.*/);
        if (props.uploadType == 0 && testFileNameResult) {
          item.validation.isMatchFileNameFormat = true;
        } else if (props.uploadType == 1) {
          item.validation.isMatchFileNameFormat = true;
        }

        // Check if each file size is below 20 MB when uploading sample file
        if (props.uploadType == 1 && item.file.size < 20000000) {
          item.validation.inSizeLimit = true;
        } else if (props.uploadType == 0) {
          item.validation.inSizeLimit = true;
        }

        // Check if all validation are passed.
        const validOrNot = Object.values(item.validation).every(
          (item) => item === true
        );
        item.valid = validOrNot ? true : false;

        if (item.valid == false) {
          filesHasInvalidError.value.push(item.id);
        }
      });

      // Check if file is uploaded
      console.log(
        "data.filter((file) => file.status === false).length",
        data.filter((file) => file.status === false).length
      );
      console.log("uploadFilesLength.value", uploadFilesLength.value);
      if (
        data.filter((file) => file.status === true).length ==
        uploadFilesLength.value
      ) {
        console.log("Files All Uploaded Completed.");
        uploadFilesLength.value = null;
        store.commit("SET_ISPROGRESS_UPLOADFILES", false);
        emits("reloadData");
        showDialog({
          message: "Files upload successfully.",
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          emits("showPopup", false);
          location.reload();
        });
      } else if (
        data.filter((file) => file.status === false).length ==
        uploadFilesLength.value
      ) {
        console.log("UPLOAD FAILED.");
        store.commit("SET_ISPROGRESS_UPLOADFILES", false);
        emits("reloadData");
        showDialog({
          message: "Files upload failed.",
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          uploadFilesLength.value = null;
        });
      }
    } else {
      filesHasInvalidError.value = [];
      console.log("No files ready to upload");
    }
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss">
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fileUploader {
  &__list {
    padding-top: 8px;
  }
  &__drop {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4px 0 8px 0;
    border: 2px dashed #c7cbf9;
    border-radius: 15px;
  }
  &__notice {
    padding-top: 16px;
    opacity: 0.6;
  }
  &__uploadIcon {
    font-size: 60px;
    color: #e2e0e0;
  }
  &__highlight {
    color: #2c3fea;
    text-decoration: underline;
  }
}

.drop-area {
  width: 100%;
  // max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  // background: #ffffff55;
  background: #db3d4473;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: 0.2s ease;

  &[data-active="true"] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #ffffffcc;
  }
}
.drop-area {
  label {
    font-size: 36px;
    cursor: pointer;
    display: block;

    span {
      display: block;
    }

    input[type="file"] {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }

    .smaller {
      font-size: 16px;
    }
  }
}

.image-list {
  // display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}

.upload-button {
  display: block;
  appearance: none;
  // border: 0;
  // border-radius: 50px;
  // padding: 0.75rem 3rem;
  margin: 1rem auto;
  // font-size: 1.25rem;
  // font-weight: bold;
  // background: #369;
  // color: #fff;
  // text-transform: uppercase;
}

button {
  cursor: pointer;
}
</style>
<style scoped>
/* v-select component styles */
::v-deep(.vs__dropdown-toggle) {
  padding: 3px 0;
  height: 37px;
  border: 0;
}
::v-deep(.vs__actions) {
  display: none;
}
::v-deep(.vs__search) {
  padding-left: 0;
}
::v-deep(.vs__selected) {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  width: 350px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
