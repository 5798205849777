export async function uploadFile(file, url, header) {
  // set up the request data
  // let formData = new FormData();
  // formData.append("data", file.file);

  // track status and upload file
  file.status = "loading";
  console.log("uploadFile - url", url);
  console.log("uploadFile - header", header);

  try {
    let response = await fetch(url, {
      headers: header,
      method: "PUT",
      body: file.file,
    });

    console.log("Result - fetch/file", file);
    console.log("Result - fetch/response", response);
    // change status to indicate the success of the upload request
    file.status = response.ok;

    return response;
  } catch (error) {
    // TypeError: Failed to fetch
    file.status = false;
    console.log("There was an fetch error", error);
  }
}

export function uploadFiles(files) {
  return Promise.all(
    files.map((file) => {
      uploadFile(file, file.uploadTargetURL, file.requestHeader);
    })
  );
}

// export default function createUploader(url) {
export function createUploader(url) {
  return {
    uploadFile: function (file) {
      return uploadFile(file, url);
    },
    uploadFiles: function (files) {
      console.log("file-uploader - createUploader / files", files);
      // console.log("file-uploader - createUploader / url", url);
      // return uploadFiles(files, url);
      return uploadFiles(files);
    },
  };
}

export default function createUploaderCustom(files) {
  console.log("file-uploader - createUploaderCustom / files", files);
  // console.log("file-uploader - createUploaderCustom / url", url);
  return uploadFiles(files);
}
