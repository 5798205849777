<template>
  <div
    v-if="isProgress.page || isProgress.login || !caseDetailDataVuex"
    class="preLoader"
  >
    <span class="spin"></span>
  </div>

  <section v-if="caseDetailDataVuex" class="appContent detailPageWrapper">
    <div class="row gx-4">
      <div class="col-lg-8 detailPageWrapper--left">
        <div class="d-flex align-items-center mb-3">
          <div>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/caseManagement"> 案件 </router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ caseDetailDataVuex.CaseID }}
              </li>
            </ol>
            <h1
              class="page-header mb-0"
              :class="[`pageHeader--${caseDetailDataVuex.Severity}`]"
            >
              {{ caseDetailDataVuex.CaseID }}
              {{ caseDetailDataVuex.CompanyInfo.CustomerName }}
              <span v-if="caseDetailDataVuex.state == 1">（案件已取消）</span>
              <span v-if="caseDetailDataVuex.state == 2">（已建案）</span>
              <span v-if="caseDetailDataVuex.state == 3">（已完成）</span>
            </h1>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-lg-6">
                <ul class="cardItemlist">
                  <li class="cardItemList__row">
                    <i class="bi bi-person-fill-gear"></i>
                    {{
                      caseDetailDataVuex.LeadEngineerName
                        ? caseDetailDataVuex.LeadEngineerName
                        : "未指定"
                    }}
                  </li>
                  <li class="cardItemList__row">
                    <i
                      class="fa-solid fa-file-circle-plus"
                      style="
                        font-size: 26px;
                        margin-left: 3px;
                        margin-right: 14px;
                      "
                    ></i>
                    {{ caseDetailDataVuex.source_display }}
                  </li>
                  <li class="cardItemList__row">
                    <!-- 服務類別 -->
                    <i class="bi bi-bookmark-star-fill"></i>
                    {{ caseDetailDataVuex.issue_type_display }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 infoBox--subFields">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">攻擊方式</label>
                  <span class="fieldDisplay__data">
                    {{ caseDetailDataVuex.attack_type_display }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">入侵時間</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.first_hack_time_display
                        ? caseDetailDataVuex.first_hack_time_display
                        : "-"
                    }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">參與人數</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.ParticipationNumbers == 0
                        ? 0 + "人"
                        : caseDetailDataVuex.ParticipationNumbers == null
                        ? "-"
                        : caseDetailDataVuex.ParticipationNumbers + "人"
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">客戶名稱</label>
                  <span
                    class="fieldDisplay__data text-truncate"
                    :title="caseDetailDataVuex.CompanyInfo.CustomerName"
                    style="max-width: 239px"
                  >
                    {{ caseDetailDataVuex.CompanyInfo.CustomerName }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">客戶代號</label>
                  <span class="fieldDisplay__data">{{
                    caseDetailDataVuex.CompanyInfo.CustomerShortName
                      ? caseDetailDataVuex.CompanyInfo.CustomerShortName
                      : "-"
                  }}</span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">產業類別</label>
                  <span class="fieldDisplay__data">{{
                    caseDetailDataVuex.CompanyInfo.Industry
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">扣除點數</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.PointDeduction == 0
                        ? 0 + "點"
                        : caseDetailDataVuex.PointDeduction == null
                        ? "-"
                        : caseDetailDataVuex.PointDeduction + "點"
                    }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">通報單號</label>
                  <span class="fieldDisplay__data" style="min-width: 300px">
                    <template v-if="caseDetailDataVuex.MxdrCaseNumber">
                      <van-text-ellipsis
                        rows="1"
                        :content="caseDetailDataVuex.MxdrCaseNumber"
                        expand-text="more"
                        collapse-text="...close"
                      />
                    </template>
                    <template v-else>-</template>
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">趨勢業務</label>
                  <span class="fieldDisplay__data">
                    {{ caseDetailDataVuex.CompanyInfo.SalesEnglishName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>

        <div class="row gx-3">
          <div class="col-lg-12">
            <div class="card mb-4">
              <div
                class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
              >
                開單原因
              </div>
              <div class="card-body">
                {{
                  caseDetailDataVuex.Reason ? caseDetailDataVuex.Reason : "-"
                }}
              </div>
              <div class="card-arrow">
                <div class="card-arrow-top-left"></div>
                <div class="card-arrow-top-right"></div>
                <div class="card-arrow-bottom-left"></div>
                <div class="card-arrow-bottom-right"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mb-4">
              <div
                class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
              >
                主機清單
              </div>
              <div class="card-body">
                {{
                  caseDetailDataVuex.AssetsList
                    ? caseDetailDataVuex.AssetsList
                    : "-"
                }}
              </div>
              <div class="card-arrow">
                <div class="card-arrow-top-left"></div>
                <div class="card-arrow-top-right"></div>
                <div class="card-arrow-bottom-left"></div>
                <div class="card-arrow-bottom-right"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mb-4">
              <div
                class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
              >
                備註說明
              </div>
              <div class="card-body">
                {{ caseDetailDataVuex.Note ? caseDetailDataVuex.Note : "-" }}
              </div>
              <div class="card-arrow">
                <div class="card-arrow-top-left"></div>
                <div class="card-arrow-top-right"></div>
                <div class="card-arrow-bottom-left"></div>
                <div class="card-arrow-bottom-right"></div>
              </div>
            </div>
          </div>
          <div v-if="ENABLE_FUNCTION_COMMENTS" class="col-lg-12">
            <div class="card mb-4">
              <div
                class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
              >
                Comments
                <span
                  class="ms-auto cursor-pointer"
                  @click.stop="showCommentPopup()"
                >
                  <i class="bi bi-chat-left-dots-fill"></i> 新增
                </span>
              </div>
              <div class="card-body">-</div>
              <div class="card-arrow">
                <div class="card-arrow-top-left"></div>
                <div class="card-arrow-top-right"></div>
                <div class="card-arrow-bottom-left"></div>
                <div class="card-arrow-bottom-right"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div
            class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
          >
            聯絡資訊
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">姓名</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.contact_info.name
                        ? caseDetailDataVuex.contact_info.name
                        : "-"
                    }}
                    {{ caseDetailDataVuex.contact_info.titleDisplay }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">Email</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.contact_info.email
                        ? caseDetailDataVuex.contact_info.email
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">電話(O)</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.contact_info.office_phone
                        ? caseDetailDataVuex.contact_info.office_phone
                        : "-"
                    }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">電話(M)</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.contact_info.mobile_phone
                        ? caseDetailDataVuex.contact_info.mobile_phone
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">地址</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.contact_info.address
                        ? caseDetailDataVuex.contact_info.address
                        : "-"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>

        <div class="card mb-4">
          <div
            class="card-header d-flex align-items-center justify-content-between bg-inverse bg-opacity-10 fw-400"
          >
            工作計畫
            <!-- <span
              class="text-inverse text-opacity-50 cursor-pointer"
              @click="showCaseRecordPopup"
            >
              <i class="bi bi-file-text"></i>
              處理紀錄
            </span> -->
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">建立時間</label>
                  <span class="fieldDisplay__data">{{
                    caseDetailDataVuex.create_time
                  }}</span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">確認時間</label>
                  <span class="fieldDisplay__data"> - </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">On-site</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.onsite_date_display
                        ? caseDetailDataVuex.onsite_date_display
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">取消時間</label>
                  <span class="fieldDisplay__data">
                    <template v-if="caseDetailDataVuex.state == 1">
                      {{
                        caseDetailDataVuex.update_time
                          ? caseDetailDataVuex.update_time
                          : caseDetailDataVuex.update_time_from_log
                      }}
                    </template>
                    <template v-else>-</template>
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">更新時間</label>
                  <span class="fieldDisplay__data">
                    {{
                      caseDetailDataVuex.update_time
                        ? caseDetailDataVuex.update_time
                        : caseDetailDataVuex.update_time_from_log
                    }}
                  </span>
                </div>
                <div class="fieldDisplay">
                  <label class="fieldDisplay__label">歷時總計</label>
                  <span class="fieldDisplay__data">
                    {{ caseDetailDataVuex.duration_display }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="col-lg-4">
        <TimeLineTrack :case-detail="caseDetailDataVuex"></TimeLineTrack>
      </div>
      <!-- END of Right Side -->
    </div>

    <div class="functionBar functionBar--fixed">
      <button
        type="button"
        class="btn btn-link"
        @click="handleGo2CaseManagement()"
      >
        離開
      </button>
      <div>
        <CancelCaseButton
          v-if="caseDetailDataVuex.State == 0 || caseDetailDataVuex.State == 2"
          :state="caseDetailDataVuex.State"
          :case-id="caseDetailDataVuex.CaseID"
          :submitter-mail="userEmail"
        ></CancelCaseButton>
        <button
          v-if="caseDetailDataVuex.state == 2"
          type="button"
          class="btn btn-primary"
          @click.stop="handleEditCase()"
        >
          編輯
        </button>
        <button
          v-if="caseDetailDataVuex.state == 1"
          type="button"
          class="btn btn-danger"
          :disabled="!managerWhiteListVuex.includes(userEmail)"
          @click.stop="showReopenCasePopup()"
        >
          Reopen
        </button>
      </div>
    </div>
  </section>

  <van-popup
    v-model:show="popupReopenCase"
    class="popup"
    round
    style="width: 400px"
  >
    <div class="popup__header">Reopen 案件？</div>
    <div class="popup__content">Reopen 此案件，並回復到待確認狀態。</div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupReopenCase = false"
      >
        不 Reopen
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click.stop="handleSendReopenCaseRequest()"
      >
        Reopen
      </button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupChangeAssigneeToOther"
    class="popup"
    round
    style="width: 400px"
  >
    <div class="popup__header">指派案件處理人員</div>
    <div class="popup__content">
      確認將此案件處理人員指派給：
      <select
        class="form-select mt-3"
        aria-label="Default select example"
        v-model="newAssignee"
      >
        <option value="">請選擇</option>
        <template v-for="item in shiftMemberListDataVuex" :key="item.sid">
          <option :value="item.email2">{{ item.user_name }}</option>
        </template>
      </select>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupChangeAssigneeToOther = false"
      >
        不更換
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleChangeAssignee2who()"
        :disabled="newAssignee == ''"
      >
        確認指派
      </button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupComment"
    class="popup"
    round
    style="width: 500px"
  >
    <div class="popup__header">Comment</div>
    <div class="popup__content">
      <textarea
        class="form-control mt-3"
        v-model="caseComment"
        rows="5"
        placeholder="輸入內容"
      ></textarea>
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupComment = false"
      >
        取消
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="!caseComment"
        @click.stop="handleSendAlertComment()"
      >
        送出
      </button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupDeleteCommentConfirm"
    class="popup"
    round
    style="width: 400px"
  >
    <div class="popup__header">刪除留言？</div>
    <div class="popup__content">
      留言刪除後無法反悔，請確認是否要刪除此留言？
    </div>
    <div class="popup__footer">
      <button
        type="button"
        class="btn btn-danger"
        @click="popupDeleteCommentConfirm = false"
      >
        取消
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click.stop="handleDeleteCommentPopup()"
      >
        刪除留言
      </button>
    </div>
  </van-popup>

  <van-dialog
    v-model:show="popupAlertDialog"
    title=""
    show-confirm-button
    z-index="2300"
    message="無此案件"
    confirm-button-text="返回"
    @confirm="handleClickTurningBackButton()"
  ></van-dialog>
</template>

<script>
// @ is an alias to /src
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import TimeLineTrack from "./../components/TimeLineTrack.vue";
import CancelCaseButton from "./../components/CancelCaseButton.vue";
import { showDialog, closeDialog } from "vant";
import { special } from "../special";

export default {
  name: "CaseDetail",
  components: { TimeLineTrack, CancelCaseButton },
  setup() {
    const ENABLE_FUNCTION_COMMENTS = special.ENABLE_FUNCTION_COMMENTS || false;
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let parmsId = ref(null);
    const popupChangeAssigneeToOther = ref(false);
    const popupReopenCase = ref(false);
    const popupComment = ref(false);
    const popupDeleteCommentConfirm = ref(false);
    const popupAlertDialog = ref(false);
    const caseComment = ref(null);
    const deleteCommentTargetId = ref(null);
    const newAssignee = ref(null);
    const userEmail = ref(null);

    const newCaseRecord2Send = reactive({
      State: null,
      Submitter: "",
      CaseID: "",
      CancelReason: "",
    });
    const newCaseDraft2Save = reactive({
      draft_edit: "",
      submitter_email: "",
    });
    const comment2Send = reactive({
      submitter_email: "",
      comment: "",
    });

    const userData = computed(() => {
      return store.getters.user;
    });
    const isProgress = computed(() => {
      return store.getters.isProgress;
    });
    const isReloadRequest = computed(() => {
      return store.getters.isReloadRequest;
    });
    const managerWhiteListVuex = computed(() => {
      return store.getters.managerWhiteList;
    });
    const shiftMemberListDataVuex = computed(() => {
      return store.getters.shiftMemberListData;
    });
    const caseDetailDataVuex = computed(() => {
      return store.getters.caseDetailData;
    });

    const showChangeAssignee2whoPopup = () => {
      console.log("showChangeAssignee2whoPopup");
      popupChangeAssigneeToOther.value = true;
      newAssignee.value = "";
    };
    const showReopenCasePopup = () => {
      popupReopenCase.value = true;
    };
    const showCommentPopup = () => {
      caseComment.value = "";
      popupComment.value = true;
    };
    const showDeleteCommentConfirmPopup = (commentId) => {
      deleteCommentTargetId.value = commentId;
      popupDeleteCommentConfirm.value = true;
    };
    const handleGo2CaseManagement = () => {
      router.push("/caseManagement");
    };

    const handleSendReopenCaseRequest = async () => {
      newCaseRecord2Send.Submitter = userData.value.data.mail;
      newCaseRecord2Send.CaseID = caseDetailDataVuex.value.CaseID;
      newCaseRecord2Send.state = 0;
      newCaseRecord2Send.remark = `Reopen case*by ${userData.value.data.displayName}`;
      console.log(
        "handleSendReopenCaseRequest - newCaseRecord2Send",
        newCaseRecord2Send
      );

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("postSetCaseState", newCaseRecord2Send);

        setTimeout(() => {
          popupReopenCase.value = false;
          // location.reload();
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Case reopen FAIL", err);
      }
    };
    const handleChangeAssignee2who = async () => {
      newCaseRecord2Send.Submitter = newAssignee.value;
      newCaseRecord2Send.CaseID = caseDetailDataVuex.value.CaseID;
      newCaseRecord2Send.state = caseDetailDataVuex.value.current_state;
      newCaseRecord2Send.remark = `Assignee changed*by ${userData.value.data.displayName}`;

      console.log(
        "handleChangeAssignee2who - newCaseRecord2Send",
        newCaseRecord2Send
      );

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("postSetCaseState", newCaseRecord2Send);
        setTimeout(() => {
          popupChangeAssigneeToOther.value = false;
          // location.reload();
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
          // router.push("/caseManagement");
        }, 2000);
      } catch (err) {
        console.log("Assignee change FAIL", err);
      }
    };
    const handleResetDraft = () => {
      console.log(
        "handleResetDraft newCaseRecord2Send.draft",
        newCaseRecord2Send.draft
      );
      // console.log("handleResetDraft - originalDraft", originalDraft);
      // console.log(
      //   "handleResetDraft - originalDraft.value",
      //   originalDraft.value
      // );
      newCaseRecord2Send.draft = "";
      // newCaseRecord2Send.draft = originalDraft.value;
      console.log("handleResetDraft- newCaseRecord2Send", newCaseRecord2Send);
    };
    const handleSendAlertComment = async () => {
      comment2Send.submitter_email = userData.value.data.mail;
      comment2Send.comment = caseComment.value;

      const comment2SendPackage = {
        CaseID: caseDetailDataVuex.value.CaseID,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("postAlertComment", comment2SendPackage);

        setTimeout(() => {
          // location.reload();
          popupComment.value = false;
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Send Comment FAIL", err);
      }
    };
    const handleUpdateAlertComment = async (commentId) => {
      comment2Send.submitter_email = userData.value.data.mail;
      comment2Send.comment = document.getElementById(
        `commentTextarea--${commentId}`
      ).value;

      const comment2SendPackage = {
        CaseID: caseDetailDataVuex.value.CaseID,
        commentId: commentId,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("updateAlertComment", comment2SendPackage);

        setTimeout(() => {
          // location.reload();
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Update comment FAIL", err);
      }
    };
    const handleOpenCommentEditor = async (commentId) => {
      let allCommentEditor = document.querySelectorAll(
        ".commentHistory__editor"
      );
      let allCommentDisplay = document.querySelectorAll(
        ".commentHistory__body"
      );
      allCommentEditor.forEach(function (item) {
        item.style.display = "none";
      });
      allCommentDisplay.forEach(function (item) {
        item.style.display = "block";
      });
      let targetEditorDom = document.getElementsByClassName(
        `comment--${commentId}`
      );
      let targetDisplayDom = document.getElementsByClassName(
        `commentDisplay--${commentId}`
      );
      targetEditorDom[0].style.display = "block";
      targetDisplayDom[0].style.display = "none";
    };
    const handleCloseCommentEditor = async () => {
      let allCommentEditor = document.querySelectorAll(
        ".commentHistory__editor"
      );
      let allCommentDisplay = document.querySelectorAll(
        ".commentHistory__body"
      );
      allCommentEditor.forEach(function (item) {
        item.style.display = "none";
      });
      allCommentDisplay.forEach(function (item) {
        item.style.display = "block";
      });
    };
    const handleDeleteCommentPopup = async () => {
      comment2Send.submitter_email = userData.value.data.mail;

      const comment2SendPackage = {
        CaseID: caseDetailDataVuex.value.CaseID,
        commentId: deleteCommentTargetId.value,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);
      popupDeleteCommentConfirm.value = false;

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("deleteAlertComment", comment2SendPackage);

        setTimeout(() => {
          popupDeleteCommentConfirm.value = false;
          // location.reload();
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Delete comment FAIL", err);
      }
    };
    const handleClickTurningBackButton = () => {
      router.push("/caseManagement");
    };
    const handleEditCase = () => {
      router.push(`/caseEdit/${parmsId.value}`);
    };

    const getCaseDetailData = async (parmsId) => {
      store.dispatch("handlePageLoadingState", true);
      try {
        const response = await store.dispatch(
          "getCaseDetailData",
          parmsId.value
        );

        console.log("getCaseDetailData - response.status", response.status);
        if (response.status == 200) {
          if (!Object.keys(response).length) {
            popupAlertDialog.value = true;
            console.log("No data!!!");
          } else {
            console.log("Has data!!! - response", response);
            closeDialog();
            if (response.data.State == 0) {
              router.push(`/caseEdit/${parmsId.value}`);
            }
          }
          store.dispatch("handlePageLoadingState", false);
        } else {
          store.dispatch("handlePageLoadingState", false);
          showDialog({
            message: response.message,
            confirmButtonText: "返回",
          }).then(() => {
            // on close
            router.push("/caseManagement");
          });
        }
      } catch (error) {
        console.log("error - getCaseDetailData", error);
        store.dispatch("handlePageLoadingState", false);
        showDialog({
          message: error.message,
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          router.push(`/caseManagement`);
        });
      }
    };

    watch(
      route,
      () => {
        parmsId.value = route.params.caseId;
      },
      { deep: true, immediate: true }
    );

    watch(
      userData,
      (data) => {
        if (data.data) {
          console.log("Login data coming!", data);
          userEmail.value = data.data.mail.toLowerCase();
          // userEmail.value = "lucas_chen@trend.com";
          // userEmail.value = data.data.email.toLowerCase();
        } else {
          userEmail.value = "";
        }
      },
      { deep: true, immediate: true }
    );

    onMounted(() => {
      if (!caseDetailDataVuex.value || isReloadRequest.value) {
        store.dispatch("handleReloadRequestState", false);
        getCaseDetailData(parmsId);
      } else {
        if (
          caseDetailDataVuex.value.CaseID !== parmsId.value ||
          caseDetailDataVuex.value.State == 0
        ) {
          getCaseDetailData(parmsId);
        }
        store.dispatch("handlePageLoadingState", false);
      }
    });

    return {
      ENABLE_FUNCTION_COMMENTS,
      parmsId,
      isProgress,
      popupChangeAssigneeToOther,
      popupReopenCase,
      popupComment,
      popupDeleteCommentConfirm,
      popupAlertDialog,
      showChangeAssignee2whoPopup,
      showReopenCasePopup,
      showCommentPopup,
      showDeleteCommentConfirmPopup,
      handleGo2CaseManagement,
      handleSendReopenCaseRequest,
      handleChangeAssignee2who,
      handleSendAlertComment,
      handleUpdateAlertComment,
      handleResetDraft,
      handleOpenCommentEditor,
      handleCloseCommentEditor,
      handleDeleteCommentPopup,
      handleClickTurningBackButton,
      handleEditCase,
      newCaseRecord2Send,
      newCaseDraft2Save,
      caseDetailDataVuex,
      managerWhiteListVuex,
      shiftMemberListDataVuex,
      userData,
      caseComment,
      deleteCommentTargetId,
      userEmail,
      newAssignee,
    };
  },
};
</script>

<style scoped lang="scss">
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-form-placholder-color);
}

.cardItemList {
  &__row {
    padding: 8px 0;

    .bi,
    .fa-solid {
      margin-right: 16px;
      font-size: 30px;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  &__subRow {
    padding-left: 0;
  }
}
.card-header:first-child {
  color: #808080;
}
.car,
.infoBox {
  &--right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
  }
  &--subFields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: end;
  }
}
.textRightTop {
  position: absolute;
  right: 16px;
  top: 8px;
  font-size: 14px;
  font-style: italic;
  color: rgba(var(--bs-inverse-rgb), 0.4);
}
.riskLevel {
  position: relative;
  animation: beat 0.5s infinite alternate;

  .triangle {
    top: 50px;
    box-shadow: var(--bs-box-shadow-lg);
    z-index: 1;
  }

  &__text {
    position: absolute;
    top: 83px;
    left: 11px;
    width: 60px;
    color: var(--bs-risk-alert-text-color);
    font-size: 28px;
    text-align: center;
    z-index: 2;
  }
  &__note {
    position: absolute;
    top: 126px;
    left: 11px;
    width: 100%;
    color: rgba(var(--bs-body-bg-rgb), 0.7);
    font-size: 14px;
    z-index: 2;
  }
}
.fieldDisplay {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  line-height: 32px;
  &__label {
    width: 67px;
    color: rgba(var(--bs-inverse-rgb), 0.5);
    &--large {
      width: 122px;
    }
  }
  &__data {
    padding-left: 8px;
    // max-width: 239px;
  }
}
.commentHistory {
  padding-bottom: 16px;
  &__header {
    padding-bottom: 8px;
  }
  &__body {
    padding: 16px 24px;
    background-color: #f5f5f5;
    border: 1px solid #dadada;
    border-radius: 5px;
  }
  &__textarea {
    padding: 16px 24px;
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #dadada;
    border-radius: 5px;
  }
}
.triangle {
  position: relative;
  background-color: #ccc;

  &--danger {
    background-color: var(--bs-risk-critical-bg-color);
  }
  &--high {
    background-color: rgba(var(--bs-risk-high-bg-rgb), 1);
  }
  &--medium {
    background-color: rgba(var(--bs-risk-medium-bg-rgb), 1);
  }
  &--low {
    background-color: rgba(var(--bs-risk-low-bg-rgb), 1);
  }
}
.triangle:before,
.triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width: 5em;
  height: 5em;
  border-top-right-radius: 30%;
}

.triangle {
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

@keyframes beat {
  to {
    transform: scale(1.01);
  }
}
</style>
<style scoped>
/* :deep(.ql-editor) {
  height: 320px;
} */
</style>
