export const form_config = {
  version: "0.0.1.trendmicro_ir",
  field: {
    lead_engineer_name: {
      live: true,
      default: null,
    },
    state: {
      live: true,
      options: [
        { key: "未指定", value: null, disabled: false },
        { key: "案件準備", value: 0 },
        { key: "案件進行", value: 2 },
        { key: "案件完成", value: 3 },
        { key: "案件取消", value: 1 },
      ],
      default: null,
    },
    source: {
      live: true,
      options: [
        { key: "未指定", value: null, disabled: true },
        { key: "業務建案", value: 1 },
        { key: "MxDR 建案", value: 2 },
        { key: "IR 人員建案", value: 3 },
        { key: "其他", value: 0 },
      ],
      default: null,
    },
    issue_type: {
      live: true,
      options: [
        { key: "未指定", value: null, disabled: true },
        { key: "健診", value: 1 },
        { key: "事故處理", value: 2 },
        { key: "其他", value: 0 },
      ],
      default: null,
    },
    attack_type: {
      live: true,
      options: [
        { key: "未指定", value: null, disabled: true },
        { key: "APT", value: 0 },
        { key: "Cyber Crime", value: 1 },
        { key: "Target Ransom", value: 2 },
        { key: "Others", value: 3 },
      ],
    },
    mxdr_case_number: {
      live: true,
    },
    sales_name: {
      live: true,
      readonly: true,
      default: null,
    },
    sales_name_picker: {
      live: true,
      readonly: false,
      default: null,
    },
    industry_picker: {
      live: true,
      options: [{ key: "選擇...", value: null }],
      readonly: false,
      disabled: false,
      default: null,
    },
    ops_industry_picker: {
      live: true,
      options: [{ key: "選擇...", value: null }],
      disabled: false,
      readonly: false,
      default: null,
    },
    reason: {
      live: true,
    },
    note: {
      live: true,
    },
    point_deduction: {
      live: true,
    },
    assets_list: {
      live: true,
    },
    company_name: {
      live: true,
      disabled: false,
      readonly: false,
      default: null,
    },
    company_sid: {
      live: true,
      disabled: false,
      readonly: true,
      default: null,
    },
    company_industry: {
      live: true,
      disabled: false,
      readonly: true,
      default: null,
    },
    contact_title: {
      live: true,
      options: [
        { key: "貴賓", value: null },
        { key: "先生", value: 0 },
        { key: "小姐", value: 1 },
        { key: "其他", value: 2 },
      ],
      disabled: false,
      readonly: false,
      default: null,
    },
    asset_type: {
      live: true,
      options: [
        { key: "Server", value: 0 },
        { key: "User", value: 1 },
      ],
      disabled: false,
      readonly: false,
      default: null,
    },
    asset_is_hacked: {
      live: true,
      options: [
        { key: "否", value: false },
        { key: "是", value: true },
      ],
      disabled: false,
      readonly: false,
      default: null,
    },
    upload_files_target_asset: {
      live: true,
      options: [{ key: "不選擇", value: null }],
      disabled: false,
      readonly: false,
      default: null,
    },
    apt_tip: {
      live: true,
      options: [
        { key: "是", value: true },
        { key: "否", value: false },
      ],
      disabled: false,
      readonly: false,
      default: null,
    },
  },
  placeholder: {
    lead_engineer_name: {
      forCaseForm: "選擇...",
    },
    mxdr_case_number: {
      forCaseForm: "N202307280002",
    },
    reason: {
      forCaseForm: "添加描述",
    },
    note: {
      forCaseForm: "添加描述",
    },
    assets_list: {
      forCaseForm: "添加主機清單",
    },
    company_name: {
      forCaseForm: "選擇...",
    },
    sales_name_picker: {
      forCaseForm: "選擇...",
    },
    industry_picker: {
      forCaseForm: "選擇...",
    },
    contact_name: {
      forCaseForm: "王小美",
    },
    contact_email: {
      forCaseForm: "填入有效電子郵件地址",
    },
    contact_phone_office: {
      forCaseForm: "0211112222",
    },
    contact_phone_mobile: {
      forCaseForm: "0900000000",
    },
    contact_address: {
      forCaseForm: "輸入地址",
    },
    upload_files_target_asset: {
      forCaseForm: "請選擇",
    },
  },
  operatemenu: {
    import: {
      live: false,
    },
    sync: {
      live: false,
    },
    export: {
      live: true,
    },
  },
};
