<template>
  <div v-if="v$.$silentErrors.length" class="bd-callout bd-callout-warning">
    <strong>填入資料錯誤</strong>
    請修正以下 {{ v$.$silentErrors.length }} 個欄位： {{ errorFields }}
  </div>
  <van-form validate-trigger="onChange" autocomplete="off">
    <div class="row gx-4">
      <div class="col-lg-6">
        <div class="fieldRow">
          <div class="input-group">
            <label for="lead_engineer_name" class="fieldRow__label text-center">
              <i class="bi bi-person-fill-gear"></i>
            </label>
            <v-select
              class="vs-datalist form-control"
              label="displayValue"
              :options="optionsAllIrEngineersNameListVuex"
              :reduce="(engineer) => engineer.value"
              v-model="currentForm.LeadEngineerName"
              :placeholder="initConfig.placeholder.company_name.forCaseForm"
            />
            <button
              type="button"
              class="btn btn-success"
              style="padding: 10px 16px 9px 15px; border-radius: 0 6px 6px 0"
              @click="popupAddEngineer = true"
            >
              <i class="bi bi-plus-lg"></i>
            </button>
            <div
              v-if="v$.LeadEngineerName.$invalid"
              class="van-field__error-message"
            >
              請正確選取名單
            </div>
          </div>
        </div>

        <div v-if="initConfig.field.source.live" class="fieldRow">
          <label for="source" class="fieldRow__label text-center">
            <i class="fa-solid fa-file-circle-plus"></i>
          </label>
          <select
            v-model="currentForm.SourceType"
            name="source"
            class="form-select fieldRow__editField"
          >
            <option
              v-for="item in initConfig.field.source.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
          </select>
        </div>

        <div v-if="initConfig.field.issue_type.live" class="fieldRow">
          <label for="issue_type" class="fieldRow__label text-center">
            <i class="fbi bi-bookmark-star-fill"></i>
          </label>
          <select
            v-model="currentForm.IssueType"
            name="issue_type"
            class="form-select fieldRow__editField"
          >
            <option
              v-for="item in initConfig.field.issue_type.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
          </select>
        </div>

        <div v-if="initConfig.field.attack_type.live" class="fieldRow">
          <label for="attack_type" class="fieldRow__label">攻擊方式</label>
          <select
            v-model="currentForm.AttackTypeID"
            name="attack_type"
            class="form-select fieldRow__editField"
          >
            <option
              v-for="item in initConfig.field.attack_type.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
          </select>
        </div>

        <div class="fieldRow">
          <label for="OnsiteDate" class="fieldRow__label">On-site</label>
          <VueDatePicker
            v-model="currentForm.OnsiteDate"
            class="form-control"
            placeholder="選擇日期"
            model-type="yyyy-MM-dd"
            format="yyyy / MM / dd"
            month-name-format="long"
            week-start="0"
            locale="zh-TW"
            year-first
            auto-apply
            :enable-time-picker="false"
            :partial-range="false"
            :action-row="{ showPreview: false }"
            :day-names="['日', '一', '二', '三', '四', '五', '六']"
            :space-confirm="false"
            :offset="0"
            @update:model-value="onDatepickerConfirm"
          />
        </div>
      </div>

      <div class="col-lg-6">
        <van-field
          v-model="currentForm.MxdrCaseNumber"
          type="text"
          name="mxdr_case_number"
          class="fieldRow"
          :class="{
            invalid: v$.MxdrCaseNumber.$invalid,
            valid: !v$.MxdrCaseNumber.$invalid,
          }"
          label="通報單號"
          label-class="fieldRow__label"
          :placeholder="initConfig.placeholder.mxdr_case_number.forCaseForm"
          :border="false"
        />
        <div
          v-if="v$.MxdrCaseNumber.mxdrCaseNumberRule.$invalid"
          class="van-field__error-message"
        >
          {{ validationRegex.mxdrCaseNumber.errMessage }}
        </div>
        <div
          v-if="v$.MxdrCaseNumber.maxLengthValue.$invalid"
          class="van-field__error-message"
        >
          {{ v$.MxdrCaseNumber.maxLengthValue.$message }}
        </div>

        <van-field
          v-model="currentForm.CompanyInfo.SalesEnglishName"
          type="text"
          name="mxdr_case_number"
          class="fieldRow"
          label="趨勢業務"
          label-class="fieldRow__label"
          :readonly="initConfig.field.sales_name.readonly"
          :border="false"
        />

        <van-field
          v-model="currentForm.Reason"
          type="textarea"
          name="reason"
          class="fieldRow fieldRow--baseline"
          label="開單原因"
          label-class="fieldRow__label"
          rows="7"
          :placeholder="initConfig.placeholder.reason.forCaseForm"
          :border="false"
          :autosize="{ maxHeight: 48.21 }"
        />
        <div class="fieldRow position-relative">
          <label for="FirstHackTime" class="fieldRow__label">入侵時間</label>
          <VueDatePicker
            v-model="currentForm.FirstHackTime"
            class="form-control firstHackTimePicker"
            placeholder="選擇日期"
            model-type="yyyy-MM-dd"
            format="yyyy / MM / dd"
            month-name-format="long"
            week-start="0"
            locale="zh-TW"
            year-first
            auto-apply
            :disabled="isNonHackTime"
            :enable-time-picker="false"
            :max-date="new Date()"
            :partial-range="false"
            :action-row="{ showPreview: false }"
            :day-names="['日', '一', '二', '三', '四', '五', '六']"
            :space-confirm="false"
            :offset="0"
          />
          <div class="position-absolute" style="right: 11px">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                v-model="isNonHackTime"
                :disabled="!currentForm.OnsiteDate"
                @change="handleSetHackTime($event.target.checked)"
              />
              <label class="form-check-label fw-bold" for="flexCheckDefault">
                無入侵時間
              </label>
            </div>
          </div>
        </div>

        <van-field
          v-model="currentForm.ParticipationNumbers"
          type="digit"
          name="ParticipationNumbers"
          class="fieldRow"
          label="參與人數"
          label-class="fieldRow__label"
          :border="false"
        />
      </div>

      <div class="col-lg-6">
        <van-field
          v-model="currentForm.PointDeduction"
          type="number"
          name="point_deduction"
          class="fieldRow"
          label="扣除點數"
          label-class="fieldRow__label"
          :border="false"
        />

        <div class="fieldRow">
          <div class="input-group">
            <label for="company_name" class="fieldRow__label">客戶名稱</label>

            <v-select
              v-model="currentForm.CompanyInfo.CustomerName"
              class="vs-datalist form-control"
              :class="{
                invalid: v$.CompanyInfo.CustomerName.$invalid,
                valid: !v$.CompanyInfo.CustomerName.$invalid,
              }"
              label="displayValue"
              :options="optionsAllCustomerListVuex"
              :reduce="(customer) => customer.value"
              :placeholder="initConfig.placeholder.company_name.forCaseForm"
              :disabled="initConfig.field.company_name.readonly"
            />
            <AddCustomerTemplate :options="optionsAptOpsFormVuex" />
            <div
              v-if="v$.CompanyInfo.CustomerName.$invalid"
              class="van-field__error-message"
            >
              {{ v$.CompanyInfo.CustomerName.customerNameRule.$message }}
            </div>
          </div>
        </div>

        <van-field
          v-model="currentForm.CompanyInfo.CustomerShortName"
          type="text"
          name="company_sid"
          class="fieldRow"
          label="客戶代號"
          label-class="fieldRow__label"
          :readonly="initConfig.field.company_sid.readonly"
          :border="false"
        />

        <van-field
          v-model="currentForm.CompanyInfo.Industry"
          type="text"
          name="company_industry"
          class="fieldRow"
          label="產業類別"
          label-class="fieldRow__label"
          :readonly="initConfig.field.company_industry.readonly"
          :border="false"
        />
      </div>

      <div class="col-lg-6">
        <van-field
          v-model="currentForm.AssetsList"
          type="textarea"
          name="assets_list"
          class="fieldRow fieldRow--baseline"
          label="主機清單"
          label-class="fieldRow__label"
          rows="4"
          :placeholder="initConfig.placeholder.assets_list.forCaseForm"
          :border="false"
          :autosize="{ maxHeight: 112 }"
        />

        <van-field
          v-model="currentForm.Note"
          type="textarea"
          name="note"
          class="fieldRow fieldRow--baseline"
          label="備註說明"
          label-class="fieldRow__label"
          rows="4"
          :placeholder="initConfig.placeholder.note.forCaseForm"
          :border="false"
          :autosize="{ maxHeight: 112 }"
        />
      </div>

      <hr class="mt-4 mb-4" />
      <h2>聯絡資訊</h2>

      <div class="col-lg-6">
        <div class="fieldRow">
          <van-field
            v-model="currentForm.contact_info.name"
            type="text"
            name="comtact_name"
            class="fieldRow"
            label="姓名"
            label-class="fieldRow__label"
            :placeholder="initConfig.placeholder.contact_name.forCaseForm"
            :border="false"
          />
          <div
            v-if="v$.contact_info.name.$invalid"
            class="van-field__error-message"
          >
            {{ validationRegex.name.errMessage }}
          </div>
        </div>

        <div class="fieldRow">
          <label for="contact_title" class="fieldRow__label">稱謂</label>
          <select
            v-model="currentForm.contact_info.title"
            id="contact_title"
            name="contact_title"
            class="form-select fieldRow__editField"
          >
            <option
              v-for="item in initConfig.field.contact_title.options"
              :value="item.value"
              :key="item.key"
              :disabled="item.disabled"
            >
              {{ item.key }}
            </option>
          </select>
        </div>

        <div class="fieldRow">
          <van-field
            v-model="currentForm.contact_info.email"
            type="text"
            name="company_industry"
            class="fieldRow"
            label="Email"
            label-class="fieldRow__label"
            :placeholder="initConfig.placeholder.contact_email.forCaseForm"
            :border="false"
          />
          <div
            v-if="v$.contact_info.email.$invalid"
            class="van-field__error-message"
          >
            {{ validationRegex.email.errMessage }}
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="fieldRow">
          <van-field
            v-model="currentForm.contact_info.office_phone"
            type="text"
            name="office_phone"
            class="fieldRow"
            label="電話（O）"
            label-class="fieldRow__label"
            :placeholder="
              initConfig.placeholder.contact_phone_office.forCaseForm
            "
            :border="false"
          />
          <div
            v-if="v$.contact_info.office_phone.$invalid"
            class="van-field__error-message"
          >
            {{ validationRegex.phoneNumber.errMessage }}
          </div>
        </div>

        <div class="fieldRow">
          <van-field
            v-model="currentForm.contact_info.mobile_phone"
            type="text"
            name="mobile_phone"
            class="fieldRow"
            label="電話（M）"
            label-class="fieldRow__label"
            :placeholder="
              initConfig.placeholder.contact_phone_mobile.forCaseForm
            "
            :border="false"
          />
          <div
            v-if="v$.contact_info.mobile_phone.$invalid"
            class="van-field__error-message"
          >
            {{ validationRegex.phoneNumber.errMessage }}
          </div>
        </div>

        <van-field
          v-model="currentForm.contact_info.address"
          type="text"
          name="contact_address"
          class="fieldRow"
          label="地址"
          label-class="fieldRow__label"
          :placeholder="initConfig.placeholder.contact_address.forCaseForm"
          :border="false"
        />
      </div>
    </div>
  </van-form>

  <!-- 新增工程師 -->
  <van-popup
    v-model:show="popupAddEngineer"
    class="popup popup__right popup__right--medium"
    closeable
    position="right"
  >
    <AddEngineerTemplate
      @showPopup="(boolean) => (popupAddEngineer = boolean)"
    />
  </van-popup>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  computed,
  watch,
  defineProps,
  defineEmits,
  defineExpose,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { showDialog } from "vant";
import formHelper from "../formHelper";
import { form_config } from "../form.config";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { helpers, maxLength } from "@vuelidate/validators";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import AddEngineerTemplate from "./AddEngineerTemplate.vue";
import AddCustomerTemplate from "./AddCustomerTemplate.vue";

const validationRegex = formHelper.validation;

const props = defineProps(["oldForm", "disableButton"]);
const emits = defineEmits(["changeDisableButton"]);

const store = useStore();
const router = useRouter();
const submitterID = ref(null);
// const showCalendar = ref(false);
const isLockedButton = ref(true);
const popupAddEngineer = ref(false);
const errorFields = ref(null);
// const showErrorMessageSalesName = ref(false);
// eslint-disable-next-line no-undef
const initConfig = form_config || [];
let currentForm = reactive({
  OnsiteDate: null,
  MxdrCaseNumber: null,
  AttackTypeID: null,
  Reason: null,
  Note: null,
  current_state: null,
  PointDeduction: null,
  FirstHackTime: null,
  AssetsList: null,
  // eslint-disable-next-line no-undef
  SourceType: form_config.field.source.default,
  // eslint-disable-next-line no-undef
  IssueType: form_config.field.issue_type.default,
  // eslint-disable-next-line no-undef
  LeadEngineerName: form_config.field.lead_engineer_name.default,
  LeadEngineerEmail: null,
  LeadEngineerID: null,
  // eslint-disable-next-line no-undef
  // sales_name: form_config.field.sales_name.default,
  companySID: null,
  CompanyInfo: {
    // eslint-disable-next-line no-undef
    CustomerName: form_config.field.company_name.default,
    CustomerEnglishName: null,
    CustomerShortName: null,
    sid: null,
    Industry: null,
    SalesEnglishName: null,
  },
  contact_info: {
    name: null,
    // eslint-disable-next-line no-undef
    title: form_config.field.contact_title.default,
    email: null,
    office_phone: null,
    mobile_phone: null,
    address: null,
  },
  State: null,
  ParticipationNumbers: null,
});
const newForm = reactive({
  OnsiteDate: null,
  MxdrCaseNumber: null,
  AttackTypeID: null,
  Reason: null,
  Note: null,
  current_state: null,
  PointDeduction: null,
  AssetsList: null,
  // eslint-disable-next-line no-undef
  SourceType: form_config.field.source.default,
  // eslint-disable-next-line no-undef
  IssueType: form_config.field.issue_type.default,
  // eslint-disable-next-line no-undef
  LeadEngineerName: form_config.field.lead_engineer_name.default,
  LeadEngineerEmail: null,
  LeadEngineerID: null,
  // eslint-disable-next-line no-undef
  // sales_name: form_config.field.sales_name.default,
  companySID: null,
  CompanyInfo: {
    // eslint-disable-next-line no-undef
    CustomerName: form_config.field.company_name.default,
    CustomerEnglishName: null,
    CustomerShortName: null,
    sid: null,
    Industry: null,
    SalesEnglishName: null,
  },
  contact_info: {
    name: null,
    // eslint-disable-next-line no-undef
    title: form_config.field.contact_title.default,
    email: null,
    office_phone: null,
    mobile_phone: null,
    address: null,
  },
  State: null,
  FirstHackTime: null,
  ParticipationNumbers: null,
});
const finalCaseForm2Send = reactive({
  CompanySID: null,
  OnsiteDate: null,
  Reason: null,
  Note: null,
  LeadEngineerID: null,
  PointType: null,
  PointDeduction: null, //Number
  State: null,
  IssueType: null,
  SourceType: null,
  SubmitterID: null,
  AssetsList: null,
  MxdrCaseNumber: null,
  ContactAddress: null,
  ContactEmail: null,
  ContactMobilePhone: null,
  ContactName: null,
  ContactOfficePhone: null,
  ContactTitle: null,
  AttackTypeID: null,
  FirstHackTime: null,
  ParticipationNumbers: null,
});

// Vuelidate Start
const leadEngineerNameRule = (value) => {
  if (value === null || value === "") {
    return true;
  }
  const selectedEngineerData = shiftMemberListDataVuex.value.filter(
    (item) => item.DisplayName === value
  );
  if (selectedEngineerData.length) {
    currentForm.LeadEngineerID = selectedEngineerData[0].Id;
    return true;
  } else {
    currentForm.LeadEngineerID = null;
    return false;
  }
};
const customerNameRule = (value) => {
  const selectedCompanyData = allCustomerDataVuex.value.filter(
    (item) => item.CustomerName === value
  );
  if (selectedCompanyData.length) {
    currentForm.CompanyInfo.sid = selectedCompanyData[0].sid;
    currentForm.CompanyInfo.CustomerEnglishName =
      selectedCompanyData[0].CustomerEnglishName;
    currentForm.CompanyInfo.CustomerShortName =
      selectedCompanyData[0].CustomerShortName;
    currentForm.CompanyInfo.Industry = selectedCompanyData[0].Industry;
    currentForm.CompanyInfo.SalesEnglishName =
      selectedCompanyData[0].SalesEnglishName;

    return true;
  } else {
    currentForm.CompanyInfo.sid = null;
    currentForm.CompanyInfo.CustomerEnglishName = null;
    currentForm.CompanyInfo.CustomerShortName = null;
    currentForm.CompanyInfo.Industry = null;
    currentForm.CompanyInfo.SalesEnglishName = null;

    return false;
  }
};
const mxdrCaseNumberRule = (value) => {
  if (currentForm.SourceType === 2) {
    return validationRegex.mxdrCaseNumber.reg.test(value);
  } else {
    return true;
  }
};
const contactNameRule = (value) => {
  if (value === "" || value === null) return true;
  else return validationRegex.name.reg.test(value);
};
const contactEmailRule = (value) => {
  if (value === "" || value === null) return true;
  else return validationRegex.email.reg.test(value);
};
const contactOfficePhoneRule = (value) => {
  if (value === "" || value === null) return true;
  else return validationRegex.phoneNumber.reg.test(value);
};
const contactMobilePhoneRule = (value) => {
  if (value === "" || value === null) return true;
  else return validationRegex.phoneNumber.reg.test(value);
};
const rules = {
  LeadEngineerName: { leadEngineerNameRule },
  MxdrCaseNumber: { mxdrCaseNumberRule, maxLengthValue: maxLength(200) },
  CompanyInfo: {
    CustomerName: {
      customerNameRule: helpers.withMessage(
        validationRegex.errorMessages.required,
        customerNameRule
      ),
    },
  },
  contact_info: {
    name: { contactNameRule },
    email: { contactEmailRule },
    office_phone: { contactOfficePhoneRule },
    mobile_phone: { contactMobilePhoneRule },
  },
};
const v$ = useVuelidate(rules, currentForm, { $scope: false });
// Vuelidate End

// const onsiteDayMinDate = new Date(2023, 0, 1);

const allCustomerDataVuex = computed(() => {
  return store.getters.allCustomerData;
});
const optionsAllCustomerListVuex = computed(() => {
  return store.getters.optionsAllCustomerList;
});
const optionsAllIrEngineersNameListVuex = computed(() => {
  return store.getters.optionsAllIrEngineersNameList;
});
const shiftMemberListDataVuex = computed(() => {
  return store.getters.shiftMemberListData;
});
const optionsAptOpsFormVuex = computed(() => {
  return store.getters.optionsAptOpsForm;
});

const isNonHackTime = ref(false);
const handleSetHackTime = (ischecked) => {
  // 如果 (CreationTime) 的話 FirstHackTime = CreationTime 不然 今天
  if (ischecked === true) {
    currentForm.FirstHackTime = moment(
      currentForm.OnsiteDate || new Date()
    ).format("YYYY-MM-DD");
    isNonHackTime.value = true;
  } else {
    currentForm.FirstHackTime = null;
  }
};

const getAptOpsFormOptions = async () => {
  try {
    await store.dispatch("getAptOpsFormOptions");
    isLockedButton.value = false;
  } catch (err) {
    console.log("getAptOpsFormOptions", err);
  }
};

// const handleCleanCalendarValue = () => {
//   currentForm.onsite_date_display = "";
//   currentForm.OnsiteDate = null;
//   showCalendar.value = false;
// };
// const handleCloseCalendar = () => {
//   showCalendar.value = false;
// };

const caseEditConfirm = async () => {
  store.dispatch("handlePageLoadingState", true);
  console.log("caseEditConfirm - currentForm", currentForm);
  const submitter = shiftMemberListDataVuex.value.filter(
    // (item) => item.Email == userData.value.data.mail
    (item) => item.Email == "lucas_CK.chen@digicosmos.com.tw"
  );
  if (submitter.length == 0) {
    submitterID.value = 2;
  } else {
    submitterID.value = submitter[0].Id;
  }
  console.log("submitter-caseEditConfirm", submitter);

  finalCaseForm2Send.CompanySID = currentForm.CompanyInfo.sid;
  finalCaseForm2Send.OnsiteDate = currentForm.onsite_date_display
    ? moment(currentForm.onsite_date_display).format("YYYY-MM-DD")
    : null;
  finalCaseForm2Send.AttackTypeID = currentForm.AttackTypeID;
  finalCaseForm2Send.Reason = currentForm.Reason;
  finalCaseForm2Send.Note = currentForm.Note;
  finalCaseForm2Send.LeadEngineerID = currentForm.LeadEngineerID;
  finalCaseForm2Send.PointType = null;
  finalCaseForm2Send.PointDeduction = parseInt(currentForm.PointDeduction);
  finalCaseForm2Send.State = currentForm.State;
  finalCaseForm2Send.IssueType = currentForm.IssueType;
  finalCaseForm2Send.SourceType = currentForm.SourceType;
  finalCaseForm2Send.MxdrCaseNumber = currentForm.MxdrCaseNumber;
  finalCaseForm2Send.AssetsList = currentForm.AssetsList;
  finalCaseForm2Send.ContactAddress =
    currentForm.contact_info.address == "-"
      ? null
      : currentForm.contact_info.address;
  finalCaseForm2Send.ContactEmail =
    currentForm.contact_info.email == "-"
      ? null
      : currentForm.contact_info.email;
  finalCaseForm2Send.ContactName =
    currentForm.contact_info.name == "-" ? null : currentForm.contact_info.name;
  finalCaseForm2Send.ContactOfficePhone =
    currentForm.contact_info.office_phone == "-"
      ? null
      : currentForm.contact_info.office_phone;
  finalCaseForm2Send.ContactTitle =
    currentForm.contact_info.title == "-"
      ? null
      : currentForm.contact_info.title;
  finalCaseForm2Send.ContactMobilePhone =
    currentForm.contact_info.mobile_phone == "-"
      ? null
      : currentForm.contact_info.mobile_phone;
  // Login 生效後要換成真的
  finalCaseForm2Send.SubmitterID = submitterID.value;
  finalCaseForm2Send.ParticipationNumbers = currentForm.ParticipationNumbers
    ? currentForm.ParticipationNumbers
    : null;
  finalCaseForm2Send.FirstHackTime = currentForm.FirstHackTime;
  console.log("確認案件 - finalCaseForm2Send", finalCaseForm2Send);
  console.log("確認案件 - currentForm.state", currentForm.State);
  if (currentForm.State == 0 || currentForm.State == 2) {
    try {
      finalCaseForm2Send.State = 2;
      const sourceData = {
        caseId: currentForm.CaseID,
        data: finalCaseForm2Send,
      };
      console.log("sourceData", sourceData);
      const response = await store.dispatch("putUpdateCase", sourceData);

      if (response.status !== 200) {
        store.dispatch("handlePageLoadingState", false);
        showDialog({
          message: "Update IR case FAIL.",
          confirmButtonText: "返回",
        }).then(() => {
          // on close
        });
      } else {
        setTimeout(() => {
          console.log("確認案件成功");
          store.dispatch("handleReloadRequestState", true);
          router.push(`/caseDetail/${currentForm.CaseID}`);
        }, 2000);
      }
    } catch (err) {
      console.log("Update IR case FAIL.", err);
    }
  } else {
    try {
      finalCaseForm2Send.State = 0;
      const response = await store.dispatch(
        "postCreateCase",
        finalCaseForm2Send
      );

      if (response.status !== 200) {
        store.dispatch("handlePageLoadingState", false);
        showDialog({
          message: "Create IR case FAIL.",
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          // router.push("/caseNew");
        });
      } else {
        setTimeout(() => {
          console.log("確認案件成功", response.data.caseID);
          store.dispatch("handlePageLoadingState", false);
          // router.push("/caseManagement");
          router.push(`/caseDetail/${response.data.caseID}`);
        }, 2000);
      }
    } catch (err) {
      console.log("Create IR case FAIL.", err);
      store.dispatch("handlePageLoadingState", false);
    }
  }
};

defineExpose({ caseEditConfirm });

const onDatepickerConfirm = (value) => {
  console.log("onDatepickerConfirm", value);
  // showCalendar.value = false;
  // currentForm.OnsiteDate = moment(value).format("YYYY-MM-DD");
  currentForm.onsite_date_display = moment(value).format("YYYY/MM/DD");
};

watch(
  allCustomerDataVuex,
  (data) => {
    if (!Object.keys(data).length) {
      console.log("No allCustomerDataVuex data");
      store.dispatch("getAllCustomerData");
    } else {
      console.log("Has allCustomerDataVuex data", data);
    }
  },
  { deep: true, immediate: true }
);

watch(v$, () => {
  emits("changeDisableButton", v$.value.$invalid);
  const filterPropertyArray = [];
  if (v$.value.$silentErrors.length) {
    v$.value.$silentErrors.map((obj) => {
      const newObj = { ...obj };
      filterPropertyArray.push(obj.$property);

      return newObj;
    });
  }
  filterPropertyArray.forEach((item, i) => {
    filterPropertyArray[i] = formHelper.whatFieldNameItis(item);
  });
  errorFields.value = Object.values(filterPropertyArray).toString();
  console.log("errorFields", errorFields.value);
});

onMounted(() => {
  getAptOpsFormOptions();
  if (!props.oldForm) {
    newForm.State = null;
  }
  Object.assign(
    currentForm,
    // eslint-disable-next-line no-undef
    _.cloneDeep(formHelper.mergeToCurrentForm(newForm, props.oldForm))
  );

  console.log(
    "\x1b[43m %s",
    `mergeToCurrentForm @${moment().format("YYYY/MM/DD HH:mm:ss.SSS")}`,
    "\n",
    "form-------new--",
    "\n",
    newForm,
    "\n",
    "form-------old--",
    "\n",
    props.oldForm,
    "\n",
    "form-------current--",
    "\n",
    currentForm
  );
});
</script>

<style lang="scss" scoped>
.componentCalendar {
  position: absolute;
  right: 0;
  width: 80%;
  height: 380px;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
}
.crossButton {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 12px;
  z-index: 999;
}
.closeCalendarButton {
  cursor: pointer;
  position: absolute;
  right: 13px;
  bottom: -32px;
  z-index: 10;
}
.fieldRow .van-field__error-message {
  display: block;
  width: 100%;
  margin-left: 91.2px !important;
  // margin-left: 140.4px !important;
  line-height: 24px;
}
.fieldRow + .van-field__error-message {
  display: block;
  width: 100%;
  margin-top: -8px;
  margin-left: 91.2px !important;
  // margin-left: 140.4px !important;
  line-height: 24px;
}
.dp__theme_light {
  --dp-background-color: none;
  --dp-border-color-hover: none;
  --dp-border-color: none;
  --dp-input-padding: 0px 30px 0px 15px;
}
</style>

<style scoped>
::v-deep(input[name="CustomerShortName"]) {
  text-transform: uppercase;
}
/* v-select component styles */
.vs-datalist {
  width: 317px !important;
}
::v-deep(.vs__dropdown-toggle) {
  padding: 3px 0;
  height: 37px;
  border: 0;
}
::v-deep(.vs__actions) {
  display: none;
}
::v-deep(.vs__search) {
  padding-left: 0;
}
::v-deep(.vs__selected) {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  width: 350px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Datepicker */
::v-deep(.dp__input_icons) {
  padding-left: 0;
}
::v-deep(.firstHackTimePicker .dp__input_icons) {
  right: 110px;
}
/* Error Fields */
::v-deep(.invalid .van-field__control) {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
::v-deep(.invalid.v-select) {
  padding-top: 3px;
  padding-bottom: 3px;
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-color: #ffe3e8;
  background-image: url("data: image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
/* ::v-deep(.valid .van-field__control) {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
} */
/* ::v-deep(.valid.v-select) {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
} */
</style>
