import { createRouter, createWebHashHistory } from "vue-router";
import LoginPage from "../views/Login.vue";
import HeaderComponent from "../components/Header.vue";
import DashboardView from "../views/Dashboard.vue";
import CaseManagement from "../views/CaseManagement.vue";
import CaseNew from "../views/CaseNew.vue";
import CaseEdit from "../views/CaseEdit.vue";
import CaseDetail from "../views/CaseDetail.vue";
import WorkspaceDetail from "../views/WorkspaceDetail.vue";
import ProfileList from "../views/ProfileList.vue";
import ProfileDetail from "../views/ProfileDetail.vue";
import FilterConfig from "../views/FilterConfig.vue";
import FileUploadPlayGround from "../views/FileUploadPlayGround.vue";
// import LoginDefaultPage from "../views/LoginDefault.vue";

let routes = [];

routes.push({
  path: "/login",
  name: "LoginPage",
  components: {
    default: LoginPage,
  },
});

routes.push({
  path: "/fileUploadPlayGround",
  name: "FileUploadPlayGround",
  components: {
    default: FileUploadPlayGround,
  },
});

routes.push({
  path: "/dashboard",
  name: "DashboardView",
  components: {
    default: DashboardView,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/filterConfig",
  name: "FilterConfig",
  components: {
    default: FilterConfig,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/caseManagement",
  name: "CaseManagement",
  components: {
    default: CaseManagement,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/profileDetail/:id",
  name: "ProfileDetail",
  components: {
    default: ProfileDetail,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/profileList",
  name: "ProfileList",
  components: {
    default: ProfileList,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/caseNew",
  name: "CaseNew",
  components: {
    default: CaseNew,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/caseEdit/:caseId",
  name: "CaseEdit",
  components: {
    default: CaseEdit,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/caseDetail/:caseId",
  name: "CaseDetail",
  components: {
    default: CaseDetail,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/workspaceDetail/:caseId",
  name: "WorkspaceDetail",
  components: {
    default: WorkspaceDetail,
    header: HeaderComponent,
  },
});

routes.push({
  path: "/:catchAll(.*)",
  name: "Login",
  components: {
    default: LoginPage,
  },
});

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 除非是按上一頁，才會回原本位置
      return savedPosition;
    } else {
      // 換頁的時候，都回置頂
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // const loginUserData = JSON.parse(localStorage.getItem('_cs_tmp_pors'))
  const idtoken = localStorage.getItem("msal.idtoken");
  // const refresh_token = localStorage.getItem('refresh_token')

  if (idtoken) {
    // const registerRedirect = sessionStorage.getItem("SWS_pageRedirect");
    // if (registerRedirect?.length > 0) {
    //   //使用者是點擊某網址進來的，且剛剛被記錄下來了
    //   next(registerRedirect);
    //   sessionStorage.removeItem("SWS_registerRedirect"); // 清除 SWS_registerRedirect
    // } else {
    //   next();
    // }
    next();
  } else {
    if (to.path !== "/login" && to.path !== "/") {
      // Here, we need both have "/"
      // 把未登入且前往路徑並非 login，把使用者要前往的地方存在 sessionStorage，登入後需要用到
      // sessionStorage.setItem("SWS_registerRedirect", to.path);
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
