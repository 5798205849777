<template>
  <div v-if="isProgress.page || isProgress.login" class="preLoader">
    <span class="spin"></span>
  </div>

  <section class="appContent appContent--hollow detailPageWrapper">
    <div class="d-flex align-items-center">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/profileList"> 客戶管理 </router-link>
          </li>
          <li class="breadcrumb-item active">c21877218</li>
        </ol>
      </div>
    </div>

    <div class="card">
      <div class="card-body p-0">
        <div class="profile">
          <div class="profile__container">
            <div class="profile__sidebar">
              <div class="desktop-sticky-top profile__mainData">
                <div class="profile__avatar">
                  <img
                    class="img-fluid"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/ROC_Civil_Aeronautics_Administration_Logo.svg/1200px-ROC_Civil_Aeronautics_Administration_Logo.svg.png"
                    alt=""
                  />
                </div>
                <h4>交通部民用航空局飛航服務總台</h4>
                <div class="mb-3 text-inverse text-opacity-50">
                  AIR NAVIGATION AND WEATHER SERVICES
                </div>
                <hr />
                <div class="mb-3 text-inverse text-opacity-75">
                  <i class="bi bi-geo-alt-fill"></i>
                  105台北市松山區濱江街362號
                </div>
                <button
                  type="button"
                  class="btn btn-outline-default profile__button--editMainProfile"
                  @click="showEditMainProfiledPopup"
                >
                  Edit
                </button>
              </div>
            </div>
            <div class="profile__content">
              <ul class="profile__tab nav nav-tabs nav-tabs-v2">
                <li class="nav-item">
                  <span
                    class="nav-link"
                    :class="{
                      active: DEFAULT_TAB_PROFILE === 'contact',
                    }"
                    @click="DEFAULT_TAB_PROFILE = 'contact'"
                  >
                    <div class="nav-field">聯絡人資訊</div>
                  </span>
                </li>
                <li class="nav-item">
                  <span
                    class="nav-link"
                    :class="{
                      active: DEFAULT_TAB_PROFILE === 'contract',
                    }"
                    @click="DEFAULT_TAB_PROFILE = 'contract'"
                  >
                    <div class="nav-field">合約資訊</div>
                  </span>
                </li>
                <li class="nav-item">
                  <span
                    class="nav-link"
                    :class="{
                      active: DEFAULT_TAB_PROFILE === 'setting',
                    }"
                    @click="DEFAULT_TAB_PROFILE = 'setting'"
                  >
                    <div class="nav-field">帳號設定</div>
                  </span>
                </li>
              </ul>
              <div class="profile__contentContainer">
                <div class="row gx-4">
                  <div class="col-xl-12">
                    <div class="tab-content p-0">
                      <template v-if="DEFAULT_TAB_PROFILE == 'contact'">
                        <div class="row gx-3">
                          <div class="col-xl-6">
                            <div class="card mb-3">
                              <div class="card-body p-0">
                                <div class="nameCard">
                                  <div class="nameCard__avatar">
                                    <img
                                      class="img-fluid"
                                      src="img/blankAvatar_02.png"
                                      alt=""
                                    />
                                  </div>
                                  <ul class="nameCard__info">
                                    <li class="nameCard__row nameCard__name">
                                      <span class="nameCard__nameValue">
                                        葉睿騰 組長
                                      </span>
                                      <span class="nameCard__ccid">
                                        ID: m21877218
                                      </span>
                                    </li>
                                    <li class="nameCard__row nameCards__badge">
                                      <span class="badge badge--green"
                                        >主要</span
                                      >
                                      <span class="badge badge--yellow"
                                        >技術(資安)</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-telephone-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >(03)3841373</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-telephone-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >0918790015</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-envelope-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >juiteng@anws.gov.tw</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                                <button
                                  type="button"
                                  class="btn btn-outline-default nameCard__button"
                                  @click="showPopupEditContactProfile()"
                                >
                                  Edit
                                </button>
                              </div>
                              <div class="card-arrow">
                                <div class="card-arrow-top-left"></div>
                                <div class="card-arrow-top-right"></div>
                                <div class="card-arrow-bottom-left"></div>
                                <div class="card-arrow-bottom-right"></div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="card mb-3">
                              <div class="card-body p-0">
                                <div class="nameCard">
                                  <div class="nameCard__avatar">
                                    <img
                                      class="img-fluid"
                                      src="img/blankAvatar_02.png"
                                      alt=""
                                    />
                                  </div>
                                  <ul class="nameCard__info">
                                    <li class="nameCard__row nameCard__name">
                                      <span class="nameCard__nameValue">
                                        葉睿騰 組長
                                      </span>
                                      <span class="nameCard__ccid">
                                        ID: m21877218
                                      </span>
                                    </li>
                                    <li class="nameCard__row nameCards__badge">
                                      <span class="badge badge--pink"
                                        >財務</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-telephone-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >(03)3841373</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-telephone-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >0918790015</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-envelope-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >juiteng@anws.gov.tw</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                                <button
                                  type="button"
                                  class="btn btn-outline-default nameCard__button"
                                  @click="showPopupEditContactProfile()"
                                >
                                  Edit
                                </button>
                              </div>
                              <div class="card-arrow">
                                <div class="card-arrow-top-left"></div>
                                <div class="card-arrow-top-right"></div>
                                <div class="card-arrow-bottom-left"></div>
                                <div class="card-arrow-bottom-right"></div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="card mb-3">
                              <div class="card-body p-0">
                                <div class="nameCard">
                                  <div class="nameCard__avatar">
                                    <img
                                      class="img-fluid"
                                      src="img/blankAvatar_02.png"
                                      alt=""
                                    />
                                  </div>
                                  <ul class="nameCard__info">
                                    <li class="nameCard__row nameCard__name">
                                      <span class="nameCard__nameValue">
                                        葉睿騰 組長
                                      </span>
                                      <span class="nameCard__ccid">
                                        ID: m21877218
                                      </span>
                                    </li>
                                    <li class="nameCard__row nameCards__badge">
                                      <span class="badge badge--purple"
                                        >業務</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-telephone-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >(03)3841373</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-telephone-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >0918790015</span
                                      >
                                    </li>
                                    <li class="nameCard__row">
                                      <label class="nameCard__label">
                                        <i class="bi bi-envelope-fill"></i>
                                      </label>
                                      <span class="nameCards__phone"
                                        >juiteng@anws.gov.tw</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                                <button
                                  type="button"
                                  class="btn btn-outline-default nameCard__button"
                                  @click="showPopupEditContactProfile()"
                                >
                                  Edit
                                </button>
                              </div>
                              <div class="card-arrow">
                                <div class="card-arrow-top-left"></div>
                                <div class="card-arrow-top-right"></div>
                                <div class="card-arrow-bottom-left"></div>
                                <div class="card-arrow-bottom-right"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="DEFAULT_TAB_PROFILE == 'contract'">
                        合約
                      </template>
                      <template v-if="DEFAULT_TAB_PROFILE == 'setting'">
                        <div class="form-group row mb-3">
                          <label
                            for="loginAccount"
                            class="col-sm-2 col-form-label"
                            >帳號 (Email)</label
                          >
                          <div class="col-sm-10">
                            <input
                              type="text"
                              readonly
                              class="form-control-plaintext"
                              id="staticEmail"
                              value="email@example.com"
                            />
                          </div>
                        </div>

                        <div class="form-group row mb-3">
                          <label for="userToken" class="col-sm-2 col-form-label"
                            >User Token</label
                          >
                          <div class="col-sm-10">
                            <textarea
                              id="userToken"
                              class="form-control"
                              readonly
                              rows="3"
                            >
eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY4NDIxOTEzNCwianRpIjoiYjNiZDI5ODYtOGMwZi00OTA3LWEzMDQtNjY1YTY3MzhlYjEzIiwidHlwZSI6InJlZnJlc2giLCJzdWIiOnsiYWNjb3VudCI6ImZ1aG9vIiwicm9sZXMiOlsiTWFuYWdlciIsIlN1cGVydmlzb3IiXSwib3JnYW5pemF0aW9uIjoiMjYifSwibmJmIjoxNjg0MjE5MTM0LCJleHAiOjE2ODQ4MjM5MzR9.DX2b2bAv0l42yzh8PM0Kef-k9cRrsZMT_p5dACBYrnY</textarea
                            >
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="col-xl-4"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-arrow">
        <div class="card-arrow-top-left"></div>
        <div class="card-arrow-top-right"></div>
        <div class="card-arrow-bottom-left"></div>
        <div class="card-arrow-bottom-right"></div>
      </div>
    </div>
  </section>

  <van-popup
    v-model:show="popupEditMainProfile"
    class="popup popup__right popup__right--small"
    closeable
    position="right"
  >
    <div class="popup__header">編輯公司資訊</div>
    <!-- newCaseRecord: {{ newCaseRecord }} -->
    <div class="popup__content" style="height: 60%">
      <!-- <div class="twoColumnsFormTemplate"> -->
      <div class="oneColumnsFormTemplate">
        <div class="fieldRow">
          <label for="companyName" class="fieldRow__label">公司名稱</label>
          <input
            id="companyName"
            type="text"
            class="form-control"
            placeholder="填入公司名稱"
            v-model="companyProfileData.company_name"
          />
        </div>

        <div class="fieldRow">
          <label for="companyNameEng" class="fieldRow__label">英文名稱</label>
          <input
            id="companyNameEng"
            type="text"
            class="form-control"
            placeholder="填入公司英文名稱"
            v-model="companyProfileData.company_name_eng"
          />
        </div>

        <div class="fieldRow">
          <label for="companyAddress" class="fieldRow__label">地址</label>
          <input
            id="companyAddress"
            type="text"
            class="form-control"
            placeholder="填入公司地址"
            v-model="companyProfileData.company_address"
          />
        </div>

        <div class="fieldRow">
          <label for="salesForceNumber" class="fieldRow__label">銷售編號</label>
          <input
            id="salesForceNumber"
            type="text"
            class="form-control"
            placeholder="填入 Sales Force Number"
            v-model="companyProfileData.sales_force_number"
          />
        </div>

        <div class="fieldRow">
          <label for="navisionId" class="fieldRow__label">Navision Id</label>
          <input
            id="navisionId"
            type="text"
            class="form-control"
            placeholder="填入 Navision Id"
            v-model="companyProfileData.navision_id"
          />
        </div>

        <div class="fieldRow">
          <label for="uploadLogo" class="fieldRow__label">上傳商標</label>
          <!-- file input -->
          <input type="file" class="form-control" id="uploadLogo" />
        </div>
      </div>
    </div>
    <div class="popup__footer">
      <!-- <button
        type="button"
        class="btn btn-outline-secondary"
        @click="popupAddCaseRecord = false"
      >
        取消
      </button> -->
      <button type="button" class="btn btn-primary">儲存</button>
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupEditContactProfile"
    class="popup popup__right popup__right--small"
    closeable
    position="right"
  >
    <div class="popup__header">編輯聯絡人資訊</div>
    <!-- newCaseRecord: {{ newCaseRecord }} -->
    <div class="popup__content" style="height: 60%">
      <!-- <div class="twoColumnsFormTemplate"> -->
      <div class="oneColumnsFormTemplate">
        <div class="fieldRow">
          <label for="companyName" class="fieldRow__label">聯絡人姓名</label>
          <input
            id="contactName"
            type="text"
            class="form-control"
            placeholder="填入聯絡人姓名"
            v-model="contactProfileData.name"
          />
        </div>

        <div class="fieldRow">
          <label for="contactTitle" class="fieldRow__label">稱謂</label>
          <input
            id="contactTitle"
            type="text"
            class="form-control"
            placeholder="填入稱謂"
            v-model="contactProfileData.title"
          />
        </div>

        <div class="fieldRow">
          <label for="contactAddress" class="fieldRow__label">地址</label>
          <input
            id="contactAddress"
            type="text"
            class="form-control"
            placeholder="填入地址"
            v-model="contactProfileData.address"
          />
        </div>

        <div class="fieldRow">
          <label for="contactType" class="fieldRow__label">角色</label>
          <input
            id="contactType"
            type="text"
            class="form-control"
            placeholder="填入聯絡人角色"
            v-model="contactProfileData.contact_types"
          />
        </div>

        <div class="fieldRow">
          <label for="contactTelephone" class="fieldRow__label">市話</label>
          <input
            id="contactTelephone"
            type="text"
            class="form-control"
            placeholder="填入市話"
            v-model="contactProfileData.telephone"
          />
        </div>

        <div class="fieldRow">
          <label for="contactPhone" class="fieldRow__label">手機</label>
          <input
            id="contactPhone"
            type="text"
            class="form-control"
            placeholder="填入手機號碼"
            v-model="contactProfileData.mobile_phone"
          />
        </div>

        <div class="fieldRow">
          <label for="contactEmail" class="fieldRow__label">Email</label>
          <input
            id="contactEmail"
            type="text"
            class="form-control"
            placeholder="填入電子郵件"
            v-model="contactProfileData.email"
          />
        </div>
      </div>
    </div>
    <div class="popup__footer">
      <!-- <button
        type="button"
        class="btn btn-outline-secondary"
        @click="popupAddCaseRecord = false"
      >
        取消
      </button> -->
      <button type="button" class="btn btn-primary">儲存</button>
    </div>
  </van-popup>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProfileDetail",
  components: {},
  setup() {
    const store = useStore();
    const isProgress = computed(() => {
      return store.getters.isProgress;
    });
    const DEFAULT_TAB_PROFILE = ref(null);
    const popupEditMainProfile = ref(false);
    const popupEditContactProfile = ref(false);

    const showEditMainProfiledPopup = () => {
      popupEditMainProfile.value = true;
    };

    const showPopupEditContactProfile = () => {
      popupEditContactProfile.value = true;
    };

    const companyProfileData = reactive({
      company_name: "",
      company_name_eng: "",
      company_address: "",
      salesForceNumber: "",
      navision_id: "",
    });

    const contactProfileData = reactive({
      name: "",
      title: "",
      address: "",
      contact_types: [],
      telephone: "",
      mobile_phone: "",
      email: "",
    });

    onMounted(() => {
      DEFAULT_TAB_PROFILE.value = "contact";
    });

    return {
      isProgress,
      DEFAULT_TAB_PROFILE,
      companyProfileData,
      contactProfileData,
      popupEditMainProfile,
      popupEditContactProfile,
      showEditMainProfiledPopup,
      showPopupEditContactProfile,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/_viewport.scss";
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-form-placholder-color);
}
.profile {
  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__sidebar {
    width: 15.625rem;
    padding: 1.5rem;
    border-right: 1px solid var(--bs-border-color);
  }
  &__content {
    flex: 1;
  }
  &__mainData {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__avatar {
    // height: 12.5rem;
    overflow: hidden;
    margin-bottom: 1rem;
    z-index: 10;
  }
  &__tab {
    position: relative;
    padding: 0 1.5rem;
    flex: 1;
  }
  &__contentContainer {
    padding: 1.5rem;
  }
  &__button--editMainProfile {
    width: 85px;
  }
}
.nameCard {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-top: 16px;
  padding-bottom: 5px;
  padding-right: 5px;
  &__avatar {
    display: flex;
    flex: 1 0 60px;
    justify-content: center;
    // margin-right: 8px;
    padding: 0 5px;
    width: 50px;
    height: 50px;
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 4;
    flex-shrink: 1;
    flex-basis: 0%;
    flex: 1 1 65%;
  }
  &__row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 0;
    &:nth-child(n + 3) {
      margin-left: -30px;
      width: calc(100% + 30px);
    }
  }
  &__name {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 3px;
    min-height: 52px;
  }
  &__nameValue {
    display: flex;
    align-self: flex-start;
    max-width: 155px;
    line-height: 22px;
  }
  &__ccid {
    display: flex;
    align-self: flex-start;
    font-size: 12px;
    line-height: 25px;
    color: #666;
  }
  &__badges {
    flex-wrap: wrap;
    padding: 0;
    min-height: 28.38px;
  }
  &__label {
    display: inline-block;
    margin-right: 8px;
    width: 25px;
    line-height: 26px;
    color: #797979;
    text-align: center;
  }
  &__button {
    position: absolute;
    top: 16px;
    right: 16px;
    // width: 20%;
    width: 85px;
    font-size: 14px;
  }
}
.badge {
  margin-right: 2px;
  &--green {
    color: #2c5000;
    background-color: #c0dd77;
  }
  &--yellow {
    color: #6c3b00;
    background-color: #f5cc6f;
  }
  &--pink {
    color: #8b171f;
    background-color: #ffc2c9;
  }
  &--purple {
    color: #644270;
    background-color: #c2c4ff;
  }
}
.desktop-sticky-top {
  @include laptop-small {
    position: sticky;
    top: 4.5rem;
  }
}
.profile .profile__tab .nav-item + .nav-item {
  margin-left: 1.5rem;
}
.profile .profile__tab .nav-item .nav-link {
  cursor: pointer;
  text-align: center;
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem 0;
  color: var(--bs-inverse);
}
.card .card-body .nav-tabs .nav-item .nav-link.active {
  cursor: default;
  background: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link:hover,
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link.active {
  color: var(--bs-emphasis-color);
  border-bottom-color: var(--bs-theme);
}
</style>
