/* eslint-disable no-undef */
import axios from "axios";

window.REQ = axios.create({
  baseURL: process.env.VUE_APP_API_PATH,
  timeout: 120000,
});

window.REQTRENDINTERNET = axios.create({
  baseURL: process.env.VUE_APP_TREND_API_PATH,
  timeout: 120000,
});

window.REQUPLOADFILE = axios.create({
  baseURL: process.env.VUE_APP_API_SIGNEDURL_PATH,
  timeout: 7200000,
});

window.REQMEDIA = axios.create({
  baseURL: process.env.VUE_APP_API_PATH,
  timeout: 1800000,
});

const token = localStorage.getItem("access_token");

const getCaseList = async (params) => {
  console.log(params);
  try {
    const res = await REQ.request({
      url: `v/CaseList?${params}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;
    // console.log("data", data);

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("Request error - getCaseList", e);
    return new Promise((resolve) =>
      resolve({
        // status: e.response.status,
        status: 500,
        // statusText: e.statusText,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};

const getCaseDetail = async (caseId) => {
  try {
    const res = await REQ.request({
      url: `c/getCaseDetail/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getCaseDetail", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};

const getAllCustomerData = async () => {
  try {
    const res = await REQ.request({
      url: `v/XDRCurrentCustomer?p=1&pp=10000`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getAllCustomerData", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getAllIndustryListData = async () => {
  try {
    const res = await REQ.request({
      url: `t/IndustryList`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getShiftMemberList = async () => {
  try {
    const res = await REQ.request({
      url: `t/EngineersList`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getSalesDataList = async () => {
  try {
    const res = await REQ.request({
      url: `t/SalesList`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getAptOpsFormOptions = async () => {
  try {
    const res = await axios({
      url: `https://apt-internal.azurewebsites.net/m/Options`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getSrCaseStgDetail = async (caseId) => {
  try {
    const res = await REQTRENDINTERNET.request({
      url: `apt/Case/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getSrCaseStgDetail", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};

const getSrCaseProdDetail = async (caseId) => {
  try {
    const res = await REQTRENDINTERNET.request({
      url: `apt/CaseProd/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getSrCaseProdDetail", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};

const getChannelList = async (params) => {
  try {
    const res = await REQ.request({
      url: process.env.VUE_APP_API_SERACH_STRING + "/channel",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 1500000,
      data: params,
    });

    return new Promise((resolve) =>
      resolve({ status: res.status, data: res.data.result })
    );
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getSearchResult = async (params) => {
  try {
    const res = await REQ.request({
      url: process.env.VUE_APP_API_SERACH_STRING,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 1800000,
      data: params,
    });

    return new Promise((resolve) =>
      resolve({ status: res.status, data: res.data })
    );
    // if (res.status === 200) {
    //   return new Promise((resolve) => resolve({ status: 200, data: data }));
    // } else {
    //   return new Promise((resolve) =>
    //     resolve({ status: res.status, data: data })
    //   );
    // }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getSearchResultBig = async (params) => {
  try {
    const res = await REQ.request({
      url: process.env.VUE_APP_API_SERACH_STRING + "/bigquery",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 1800000,
      data: params,
    });
    return new Promise((resolve) =>
      resolve({ status: res.status, data: res.data })
    );
    // if (res.status === 200) {
    //   return new Promise((resolve) => resolve({ status: 200, data: data }));
    // } else {
    //   return new Promise((resolve) =>
    //     resolve({ status: res.status, data: data })
    //   );
    // }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postSetCaseState = async (source) => {
  console.log(source);
  // try {
  //   const res = await REQ.request({
  //     url: `SetState`,
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //     data: source,
  //   });

  //   const data = res.data;

  //   if (data && data.message && data.message == "ok") {
  //     return new Promise((resolve) => resolve({ status: 200, data: data }));
  //   } else {
  //     return new Promise((resolve) =>
  //       resolve({ status: res.status, data: data })
  //     );
  //   }
  // } catch (e) {
  //   return new Promise((resolve) =>
  //     resolve({
  //       status: e.response.status,
  //       statusText: e.statusText,
  //       message: e.message,
  //     })
  //   );
  // }
};

const postSetCaseStateBatch = async (source) => {
  try {
    const res = await REQ.request({
      url: `SetStateBatch`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postAlertComment = async (source) => {
  try {
    const res = await REQ.request({
      url: `Alert/${source.caseId}/Comment`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const putUpdateAssetData = async (source) => {
  try {
    const res = await REQ.request({
      url: `a/${source.assetId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const putUpdateFileData = async (source) => {
  try {
    const res = await REQ.request({
      url: `f/update/${source.fileID}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const updateAlertComment = async (source) => {
  try {
    const res = await REQ.request({
      url: `Alert/${source.caseId}/Comment/${source.commentId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const deleteAssetData = async (source) => {
  try {
    const res = await REQ.request({
      url: `a/delete/${source.assetId}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const deleteAlertComment = async (source) => {
  try {
    const res = await REQ.request({
      url: `Alert/${source.caseId}/Comment/${source.commentId}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postSaveEditedDraft = async (source) => {
  try {
    const res = await REQ.request({
      url: `DraftEdit/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postCheckIfIsUploadedBefore = async (source) => {
  try {
    const res = await REQ.request({
      url: `z/validateZipPaths/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - postCheckIfIsUploadedBefore", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response ? e.response.status : null,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};

const postGetSignedURL = async (source) => {
  try {
    const res = await REQUPLOADFILE.request({
      url: `getSignedURL`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - postGetSignedURL", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postCreateCase = async (source) => {
  try {
    const res = await REQ.request({
      url: `i/IrRequests`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postCreateCompany = async (source) => {
  try {
    const res = await REQ.request({
      url: `t/IrCustomer`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postCreateEngineer = async (source) => {
  try {
    const res = await REQ.request({
      url: `t/Engineers`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postCreateAsset = async (source) => {
  try {
    const res = await REQ.request({
      url: `/a/emptyAsset/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postSend2AptOpsStaging = async (source) => {
  try {
    const res = await axios({
      // url: `https://apt-internal-stg.azurewebsites.net/apt/Case`,
      url: `https://apt-internal.azurewebsites.net/sr/CaseStg`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postSend2AptOpsProduction = async (source) => {
  try {
    const res = await axios({
      // url: `https://apt-internal.azurewebsites.net/apt/CaseProd`,
      url: `https://apt-internal.azurewebsites.net/sr/CaseProd`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postReSendAptOpsStaging = async (source) => {
  try {
    const res = await REQTRENDINTERNET({
      url: `/apt/Attach/${source.caseId}`,
      // url: `https://apt-internal-stg.azurewebsites.net/apt/Attach/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const postReSendAptOpsProduction = async (source) => {
  try {
    const res = await axios({
      url: `https://apt-internal.azurewebsites.net/apt/AttachProd/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const putUpdateCase = async (source) => {
  try {
    const res = await REQ.request({
      url: `i/IrRequests/${source.caseId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const putUpdateCaseState = async (source) => {
  try {
    const res = await REQ.request({
      url: `i/IrRequests/${source.caseId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

const getCollectFilesData = async (caseId) => {
  try {
    const res = await REQ.request({
      url: `collectfile/List/${caseId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getCaseDetail", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};

const postUpdateFileStatusBatch = async (source) => {
  try {
    const res = await REQ.request({
      url: `collectfile/SetStatus/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.statusText,
        message: e.message,
      })
    );
  }
};

// Mitre
const getAllMitreReferenceData = async () => {
  try {
    const res = await axios.request({
      url: `/mitre.json`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getAllMitreReferenceData", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};
const getCurrentCaseMitreTagsData = async (caseId) => {
  try {
    console.log("caseId - getCurrentCaseMitreTagsData", caseId);
    const res = await axios.request({
      url: `/mitreOfCase.json`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    console.log("request error - getCurrentCaseMitreTagsData", e);
    return new Promise((resolve) =>
      resolve({
        status: e.response.status,
        statusText: e.code,
        message: e.message,
      })
    );
  }
};
const postAddMitreData = async (source) => {
  try {
    const res = await REQ.request({
      url: `/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: 400,
        statusText: "Not Found",
        message: "Developing...",
        // status: e.response.status,
        // statusText: e.statusText,
        // message: e.message,
      })
    );
  }
};
const postUpdateCaseMitreTagData = async (source) => {
  try {
    const res = await REQ.request({
      url: `/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: 400,
        statusText: "Not Found",
        message: "Developing...",
        // status: e.response.status,
        // statusText: e.statusText,
        // message: e.message,
      })
    );
  }
};
const deleteCaseMitreTagData = async (source) => {
  try {
    const res = await REQ.request({
      url: `/${source.caseId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: source.data,
    });

    const data = res.data;

    if (data && data.message && data.message == "ok") {
      return new Promise((resolve) => resolve({ status: 200, data: data }));
    } else {
      return new Promise((resolve) =>
        resolve({ status: res.status, data: data })
      );
    }
  } catch (e) {
    return new Promise((resolve) =>
      resolve({
        status: 400,
        statusText: "Not Found",
        message: "Developing...",
        // status: e.response.status,
        // statusText: e.statusText,
        // message: e.message,
      })
    );
  }
};

export default {
  getCaseList,
  getCaseDetail,
  getShiftMemberList,
  getSalesDataList,
  getAllCustomerData,
  getAllIndustryListData,
  getAptOpsFormOptions,
  getSrCaseStgDetail,
  getSrCaseProdDetail,
  getChannelList,
  getSearchResult,
  getSearchResultBig,
  getCollectFilesData,
  getAllMitreReferenceData,
  postSetCaseState,
  postSetCaseStateBatch,
  postAlertComment,
  putUpdateAssetData,
  putUpdateFileData,
  putUpdateCase,
  putUpdateCaseState,
  postUpdateFileStatusBatch,
  updateAlertComment,
  deleteAlertComment,
  deleteAssetData,
  postSaveEditedDraft,
  postGetSignedURL,
  postCheckIfIsUploadedBefore,
  postCreateCase,
  postCreateCompany,
  postCreateEngineer,
  postCreateAsset,
  postSend2AptOpsStaging,
  postSend2AptOpsProduction,
  postReSendAptOpsStaging,
  postReSendAptOpsProduction,
  postAddMitreData,
  postUpdateCaseMitreTagData,
  getCurrentCaseMitreTagsData,
  deleteCaseMitreTagData,
};
