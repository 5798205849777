<template>
  <router-view name="header" v-if="isRouterAlive" />
  <router-view />
  <p class="copyright">
    Copyright © {{ currentYear }} {{ customerName }} Incorporated. All rights
    reserved.
  </p>
</template>

<script>
import { computed } from "vue";
import { mapMutations, useStore } from "vuex";
import { special } from "./special";
export default {
  provide() {
    return {
      reloadHeader: this.reload,
      reloadIndex: this.reloadOrder,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      isOrderAlive: true,
    };
  },
  setup() {
    const store = useStore();
    const currentYear = computed(() => {
      return store.getters.currentYear;
    });
    // eslint-disable-next-line no-undef
    const customerName = special.custom_name;

    return { currentYear, customerName };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    document.querySelector("title").textContent = special.title;
    this.updateCurrentYear(new Date().getFullYear());
  },
  computed: {},
  methods: {
    ...mapMutations(["updateCurrentYear"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
    reloadOrder() {
      this.isOrderAlive = false;
      this.$nextTick(() => (this.isOrderAlive = true));
    },
    closePopup() {
      this.$store.commit("changeErrorPopupShow", false);
    },
  },
};
</script>

<style lang="scss">
// @import "../assets/css/_viewport.scss";
:root .theme-teal {
  --bs-theme: #249d79 !important;
  --bs-theme-rgb: 36, 157, 121 !important;
  --bs-theme-color: #ffffff !important;
  --bs-theme-color-rgb: 255, 255, 255 !important;
}

:root,
[data-bs-theme="light"] {
  --bs-body-bg-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --bs-body-bg-image: url("./assets/image/background/pattern.png");
  --bs-body-bg-image-size: 4.6875rem;
  // --bs-body-bg-cover: url("./assets/image/background/cover-b6106344.jpg");
  --bs-body-bg-cover: url("./assets/image/background/Red-Black-Gradient-3-with-pattern-compressed.jpeg");
  --bs-body-font-family: "Chakra Petch", sans-serif;
  --bs-body-font-weight: 400;
  --bs-body-letter-spacing: 0.046875rem;
  --bs-body-line-height: 1.5;
  --bs-body-text-align: left;
  --bs-body-color: rgba(0, 0, 0, 0.75);
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-body-font-size: 14px;
  --bs-body-detail-font-size: 16px;

  --bs-color-theme: #db3d44;
  --bs-secondary-rgb: 121, 142, 152;
  --bs-link-color: #4a8db8;
  --bs-link-hover-color: #39c;
  --bs-heading-color: #000000;

  --bs-inverse-rgb: 0, 0, 0;

  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: rgba(0, 0, 0, 0.2);
  --bs-border-color-rgb: 0, 0, 0;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.1);
  --bs-border-radius: 5px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 8px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;

  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-inverse-rgb), 0.075);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-inverse-rgb), 0.035);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-inverse-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-inverse-rgb), 0.075);
  --bs-emphasis-color: #000000;

  --bs-notification-alert-dot-color: #d71920;
  --bs-notification-tabs-bg: #d5d5d5;
  --bs-notification-tabs-color: #888;

  --bs-form-placholder-color: #cccccc;

  --bs-risk-alert-text-color: #ffffff;
  --bs-risk-low-bg-rgb: 153, 200, 55;
  --bs-risk-medium-bg-rgb: 235, 198, 52;
  --bs-risk-high-bg-rgb: 255, 159, 12;
  --bs-risk-critical-bg-color: #d71920;
  --bs-risk-critical-bg-rgb: 215, 25, 32;

  --bs-process-preparing-bg-color-rgba: rgba(201, 102, 120, 0.7);
  --bs-process-inprogress-bg-color-rgba: rgba(150, 0, 20, 0.7);
  --bs-process-closed-bg-color-rgba: rgba(43, 75, 109, 1);
  --bs-process-cancel-bg-color-rgba: rgba(94, 94, 94, 0.5);

  --van-popup-close-icon-color: #000;
  --van-popup-round-radius: 5px;
  --van-primary-color: rgba(43, 75, 109, 1);
  --van-back-top-background: #249d79;
  --van-cell-font-size: var(--van-font-size-lg);
  --van-field-label-margin-right: 0;
  // --van-tabs-bottom-bar-width: 0;

  --bd-purple: #4c0bce;
  --bd-violet: #712cf9;
  --bd-accent: #ffe484;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
  --bd-accent-rgb: 255, 228, 132;
  --bd-pink-rgb: 214, 51, 132;
  --bd-teal-rgb: 32, 201, 151;
  --bd-violet-bg: var(--bd-violet);
  --bd-toc-color: var(--bd-violet);
  --bd-sidebar-link-bg: rgba(var(--bd-violet-rgb), 0.1);
  --bd-callout-link: 10, 88, 202;
  --bd-callout-code-color: #ab296a;
  --bd-pre-bg: var(--bs-tertiary-bg);

  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
}

hr {
  width: 100%;
}
h4,
.h4 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.3125rem;
  }
}
.fs-8px {
  font-size: 8px !important;
}
.btn {
  // --bs-btn-font-size: 0.875rem;
  --bs-btn-font-size: 1rem;
}
.btn-trend {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d71920;
  --bs-btn-border-color: #ac2026;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(215, 25, 32, 0.75);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #d71920;
  --bs-btn-active-border-color: #d71920;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d71920;
  --bs-btn-disabled-border-color: #d71920;
}
.btn-primary {
  --bg-opacity: 1;
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(43, 75, 109, var(--bg-opacity));
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(43, 75, 109, 0.8);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(43, 75, 109, var(--bg-opacity));
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgba(43, 75, 109, 0.5);
  --bs-btn-disabled-border-color: transparent;
}
.bg-theme {
  // --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  background-color: #fff;
}
.bg-critical {
  // --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-risk-critical-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.text-inverse {
  // --bs-text-opacity: 1;
  color: rgba(var(--bs-inverse-rgb), var(--bs-text-opacity)) !important;
}
.text-theme {
  color: var(--bs-color-theme);
}
.text-bg-preparing {
  background-color: var(--bs-process-preparing-bg-color-rgba);
}
.text-bg-inprogress {
  background-color: var(--bs-process-inprogress-bg-color-rgba);
}
.text-bg-cancel {
  background-color: var(--bs-process-cancel-bg-color-rgba);
}
.text-bg-closed {
  background-color: var(--bs-process-closed-bg-color-rgba);
}
.cursor-pointer {
  cursor: pointer;
}
.badge {
  --bs-badge-font-size: 1em;
  --bs-badge-font-weight: 400;
}
// html {
// background-color: var(--bs-body-bg);
// background-image: var(--bs-body-bg-gradient);
// }
html:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -5;
  background: var(--bs-body-bg-gradient);
}
html:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-body-bg-gradient);
  background-image: var(--bs-body-bg-cover);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: initial;
  height: 100%;
  transition: background 0.2s linear;
  background-size: cover;
}
body {
  position: relative;
  margin: 0;
  min-height: 100vh;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  letter-spacing: var(--bs-body-letter-spacing);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  z-index: 9999;
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: transparent;
  background-image: var(--bs-body-bg-image);
  background-size: var(--bs-body-bg-image-size);
  background-repeat: repeat;
  background-attachment: initial;
  z-index: -5;
}
html:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-body-bg-gradient);
  background-image: var(--bs-body-bg-cover);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: initial;
  height: 100%;
  transition: background 0.2s linear;
  background-size: cover;
}
h1 {
  padding-left: 16px;
  margin-bottom: 24px;
  font-size: 32px;
  border-left: 8px solid var(--bs-color-theme);
}
h2 {
  margin-bottom: 16px;
  font-size: 20px;
}
.card {
  --bs-card-spacer-y: 12px;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: transparent;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 10px;

  // cursor: pointer;
  position: relative;
  // margin: 8px 0;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  // border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border: none;
  border-radius: var(--bs-card-border-radius);
  z-index: 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
    // z-index: 11;
  }
  &:not(.border-0):before {
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    border-top: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
    border-bottom: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
  }
  &:not(.border-0):after {
    top: 15px;
    bottom: 15px;
    left: 0;
    right: 0;
    border-left: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
    border-right: 1px solid rgba(var(--bs-border-color-rgb), 0.3);
  }

  &__wrapper {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  }
}
.card
  > *:not(.card-arrow):not(.card-img-overlay):not(.card-img):not(
    .hljs-container
  ) {
  position: relative;
  z-index: 10;
}

.card .card-arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card .card-arrow-top-left,
.card .card-arrow-top-right,
.card .card-arrow-bottom-left,
.card .card-arrow-bottom-right {
  width: 10px;
  height: 10px;
  position: absolute;
}

.card .card-arrow-top-left:before,
.card .card-arrow-top-right:before,
.card .card-arrow-bottom-left:before,
.card .card-arrow-bottom-right:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  background: rgba(var(--bs-border-color-rgb), 0.75);
}

.card .card-arrow-top-left:after,
.card .card-arrow-top-right:after,
.card .card-arrow-bottom-left:after,
.card .card-arrow-bottom-right:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: rgba(var(--bs-border-color-rgb), 0.75);
}

.card .card-arrow-top-left,
.card .card-arrow-top-right {
  top: 0;
}

.card .card-arrow-top-left:before,
.card .card-arrow-top-right:before {
  top: 2px;
}

.card .card-arrow-top-left:after,
.card .card-arrow-top-right:after {
  top: 0;
}

.card .card-arrow-bottom-left,
.card .card-arrow-bottom-right {
  bottom: 0;
}

.card .card-arrow-bottom-left:before,
.card .card-arrow-bottom-right:before {
  bottom: 2px;
}

.card .card-arrow-bottom-left:after,
.card .card-arrow-bottom-right:after {
  bottom: 0;
}

.card .card-arrow-top-left,
.card .card-arrow-bottom-left {
  left: 0;
}

.card .card-arrow-top-left:before,
.card .card-arrow-top-left:after,
.card .card-arrow-bottom-left:before,
.card .card-arrow-bottom-left:after {
  left: 0;
}

.card .card-arrow-top-right,
.card .card-arrow-bottom-right {
  right: 0;
}

.card .card-arrow-top-right:before,
.card .card-arrow-top-right:after,
.card .card-arrow-bottom-right:before,
.card .card-arrow-bottom-right:after {
  right: 0;
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  font-size: 16px;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.cardItemList {
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 0;
    line-height: 22px;
    .bi,
    .fa-solid {
      margin-right: 8px;
      font-size: 22px;
    }
  }
  &__subRow {
    display: contents;
    padding-left: 32px;
    color: rgba(var(--bs-inverse-rgb), 0.6);
  }
}
.card {
  --bs-border-color-rgb: var(--bs-body-color-rgb);
  position: relative;
  border: none;
}
.bg--red {
  background-color: rgba(255, 0, 0, 0.5);
}
.bg--orange {
  background-color: rgb(255 136 0 / 49%);
}
.bg--yellow {
  background-color: rgb(255 236 0 / 22%);
}
.bg-green {
  background-color: rgb(176 255 0 / 21%);
}
.alert {
  padding: 8px 16px;
}
.alert-dismissible .btn-close {
  padding: 12px 1rem;
}
.pageHeader--medium {
  border-left-color: rgb(var(--bs-risk-medium-bg-rgb)) !important;
}
.pageHeader--high {
  border-left-color: rgb(var(--bs-risk-high-bg-rgb)) !important;
}
.pageHeader--critical {
  border-left-color: rgb(var(--bs-risk-critical-bg-rgb)) !important;
}
.list-group-item {
  background-color: transparent;
  overflow: auto;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg)
    var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color)
    var(--bs-border-color) transparent;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: 0 0;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills {
  --bs-nav-pills-border-radius: 4px;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #249d79;
}
.nav-pills .nav-item {
  margin-right: 8px;
}
.nav-pills .nav-link {
  cursor: pointer;
  color: rgba(var(--bs-inverse-rgb), 0.5);
  background-color: #fff;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
}
.nav-pills .nav-link:hover {
  color: rgba(var(--bs-inverse-rgb), 0.4);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #dddddd;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.content {
  padding: 30px;
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 1920px !important;
}
.appContent {
  margin: auto;
  // margin-left: 32px;
  // margin-right: 32px;
  padding: 16px 32px;
  // max-width: 1200px;
  max-width: 1920px;
  background-color: rgba(var(--bs-body-bg-rgb), 0.15);
  box-shadow: 0 1rem 3rem rgba(var(--bs-inverse-rgb), 0.175);
  &--hollow {
    background: none;
    box-shadow: none;
  }
}
.detailPageWrapper {
  // margin: 0 16px;
  padding-top: 66px;
  padding-bottom: 70px;
  max-width: 100%;
  min-height: 100vh;
  font-size: var(--bs-body-detail-font-size);
  &--left {
    border-right: 1px dashed #ccc;
  }
}
.formWrapper {
  position: relative;
  padding-top: 82px;
  padding-bottom: 90px;
  max-width: 1000px;
  min-height: 100vh;
  font-size: var(--bs-body-detail-font-size);
  background-color: #fff;
  overflow: hidden;
}
.page-header {
  padding-left: 8px;
  font-size: 25px;
  border-left: 8px solid var(--bs-color-theme);
}
.popup {
  // padding: 16px 24px;
  padding: 24px;
  width: 800px;
  max-height: 95%;
  // min-height: 300px;
  border-radius: 5px;

  &__header {
    margin-bottom: 16px;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid rgba(var(--bs-inverse-rgb), 0.15);
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding-top: 16px;
    // border-top: 1px solid rgba(var(--bs-inverse-rgb), 0.15);
  }
  &--large {
    width: 1024px;
  }
  &__right {
    padding: 16px 24px;
    width: 65%;
    // max-width: 1024px;
    height: 100%;
    max-height: 100%;
    min-width: 580px;
    border-radius: 0;

    &--normal {
      width: 50%;
    }
    &--small {
      width: 350px;
    }
    &--medium {
      width: 750px;
    }
    &--xmedium {
      width: 980px;
    }
    &--large {
      width: 1024px;
    }
    &--xlarge {
      width: 1280px;
    }
    .popup__footer {
      // position: absolute;
      // right: 24px;
      // bottom: 24px;
      justify-content: flex-end;
    }
  }
  .btn {
    min-width: 120px;
  }
}

.preLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: #0063f8;
  // background: #000000;
  background: rgba(0, 0, 0, 0.55);
  z-index: 2200;
  overflow: hidden;

  &--opacity10 {
    background: rgba(0, 0, 0, 0.1);
  }

  .spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;
    width: 70px;
    height: 70px;
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-radius: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.form-control {
  display: block;
  width: 100%;
  // padding: 0.375rem 0.75rem;
  // font-size: 0.875rem;
  // font-weight: 400;
  box-sizing: border-box;
  min-width: 0;
  margin: 0;
  padding: 11px;
  color: var(--van-field-input-text-color);
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #f4f4f4;
  resize: none;
  user-select: auto;
  border: var(--bs-border-width) solid #f4f4f4;
  border-radius: 0.375rem;

  line-height: 1.5;
  // color: var(--bs-body-color);
  // background-color: transparent;
  background-clip: padding-box;
  // border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  // border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: #f4f4f4 !important;
  }
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: transparent;
  border-color: rgba(var(--bs-inverse-rgb), 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-inverse-rgb), 0.25);
}
.form-select,
.fieldRow .van-field__control {
  padding: 11px;
  font-weight: bold;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.twoColumnsFormTemplate {
  display: grid;
  -moz-column-count: 2;
  column-count: 2;
  grid-template-columns: 50% 50%;
  margin-bottom: 16px;

  &--pureFields {
    .fieldRow:nth-child(odd) {
      padding-right: 16px;
    }
    .fieldRow:nth-child(even) {
      padding-left: 16px;
    }
  }
}
.oneColumnsFormTemplate {
  .fieldRow__label {
    width: 128px;
  }
}
.fieldRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  // align-items: baseline;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
  min-height: 64px;
  background: transparent;
  &__label {
    padding-right: 8px;
    width: 20%;
    i {
      font-size: 30px;
    }
    &--required::before {
      margin-right: 2px;
      color: var(--van-field-required-mark-color);
      content: "*";
    }
  }
  &__editField {
    width: 80%;
  }
  &__note {
    position: relative;
    top: -6px;
    padding-left: 140.4px;
    color: #a3a3a3;
  }
  &--baseline {
    align-items: baseline;
  }
  &--flexStart {
    align-items: flex-start;
  }
  .van-field__control {
    border: var(--bs-border-width) solid #f4f4f4;
    border-radius: 0.375rem;
  }
  .form-control {
    width: 80%;
    line-height: 24px;
  }
}
.form-select:disabled,
.form-control:disabled {
  color: var(--van-field-input-disabled-text-color);
  background-color: #f4f4f4;
}
.popupHermesForm {
  .fieldRow__label {
    width: 25%;
  }
  .fieldRow__editField,
  .form-control {
    width: 75%;
  }
}
.fieldRow .input-group {
  align-items: center;
  // .form-control {
  //   width: auto;
  // }
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
// .form-check-input:checked {
//   background-color: #249d79;
//   border-color: #249d79;
// }
.stateBall {
  display: block;
  padding: 1px 6px 0px 6px;
  border-radius: 50% 50%;
  &--unconfirmed {
    color: #fff;
    background-color: var(--bs-process-preparing-bg-color-rgba);
  }
  i {
    font-size: 24px;
  }
}
.jodit,
.htmlContent {
  font-size: 13px;
  h1 {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 1.5rem;
  }
  ol {
    margin-left: 20px;
    list-style: decimal;
  }
  ul {
    margin-left: 20px;
    list-style: disc;
  }
  table td,
  table th {
    padding: 2px 5px;
    border-collapse: collapse;
  }
}
.popup__content .htmlContent {
  h1 {
    margin-top: 26.8px;
    margin-bottom: 26.8px;
  }
}
.btn-outline-default {
  --bs-btn-color: #798e98;
  --bs-btn-border-color: #798e98;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #798e98;
  --bs-btn-hover-border-color: #798e98;
  --bs-btn-focus-shadow-rgb: 121, 142, 152;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #798e98;
  --bs-btn-active-border-color: #798e98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #798e98;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #798e98;
  --bs-gradient: none;
}
.btn-outline-theme {
  --bs-btn-color: #249d79;
  --bs-btn-border-color: #249d79;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #249d79;
  --bs-btn-hover-border-color: #249d79;
  --bs-btn-focus-shadow-rgb: 36, 157, 121;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #249d79;
  --bs-btn-active-border-color: #249d79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #249d79;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #249d79;
  --bs-gradient: none;
}
.btn-green {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #579904;
  --bs-btn-border-color: #579904;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #11741d;
  --bs-btn-hover-border-color: #106e1b;
  --bs-btn-focus-shadow-rgb: 55, 155, 67;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #106e1b;
  --bs-btn-active-border-color: #0f671a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #579904;
  --bs-btn-disabled-border-color: #579904;
}
.van-pagination__item--prev,
.van-pagination__item--next {
  padding: 0 1rem;
}
.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fff;
}
.noDataDisplay {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  &__icon {
    font-size: 40px;
  }
}
.functionBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  // margin-left: 32px;
  // margin-right: 32px;
  // max-width: 1200px;
  // max-width: 1920px;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 49;
  &--fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.functionBar > div {
  .btn {
    min-width: 120px;
  }
}
.tableWrapper {
  font-size: 12px;
}
// Function cell in table
.columnFunction {
  font-size: 15px;
  &__button {
    cursor: pointer;
    padding: 0 8px;
    font-size: 16px;
    border-left: 1px solid #a1a1a1;
    &:first-child {
      border-left-width: 0;
    }
  }
}
.position--center {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// v-select
.vs-datalist {
  word-break: break-all;
}
.vs-datalist.form-control {
  padding: 4px 11px;
}

// Component "Callout"
.bd-callout {
  --bs-link-color-rgb: var(--bd-callout-link);
  --bs-code-color: var(--bd-callout-code-color);
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: var(--bd-callout-color, inherit);
  background-color: var(--bd-callout-bg, var(--bs-gray-100));
  border-left: 0.25rem solid var(--bd-callout-border, var(--bs-gray-300));
}
.bd-callout-warning {
  --bd-callout-color: var(--bs-warning-text-emphasis);
  --bd-callout-bg: var(--bs-warning-bg-subtle);
  --bd-callout-border: var(--bs-warning-border-subtle);
}

// Animation
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
