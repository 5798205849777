<template>
  <div v-if="isProgress.page || isProgress.login" class="preLoader">
    <span class="spin"></span>
  </div>

  <section class="content container-fluid">
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card-group m-b-30">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <span class="d-block">New Employees</span>
                </div>
                <div>
                  <span class="text-success">+10%</span>
                </div>
              </div>
              <h3 class="mb-3">10</h3>
              <div class="progress mb-2" style="height: 5px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 70%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="mb-0">Overall Employees 218</p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <span class="d-block">Earnings</span>
                </div>
                <div>
                  <span class="text-success">+12.5%</span>
                </div>
              </div>
              <h3 class="mb-3">$1,42,300</h3>
              <div class="progress mb-2" style="height: 5px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 70%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="mb-0">
                Previous Month <span class="text-muted">$1,15,852</span>
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <span class="d-block">Expenses</span>
                </div>
                <div>
                  <span class="text-danger">-2.8%</span>
                </div>
              </div>
              <h3 class="mb-3">$8,500</h3>
              <div class="progress mb-2" style="height: 5px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 70%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="mb-0">
                Previous Month <span class="text-muted">$7,500</span>
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-3">
                <div>
                  <span class="d-block">Profit</span>
                </div>
                <div>
                  <span class="text-danger">-75%</span>
                </div>
              </div>
              <h3 class="mb-3">$1,12,000</h3>
              <div class="progress mb-2" style="height: 5px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 70%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="mb-0">
                Previous Month <span class="text-muted">$1,42,000</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
  <!-- {{ currentYear }} -->
</template>

<script>
// @ is an alias to /src
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "DashboardView",
  components: {},
  setup() {
    const error = ref(null);
    const store = useStore();
    const currentYear = computed(() => {
      return store.getters.currentYear;
    });
    const isProgress = computed(() => {
      return store.getters.isProgress;
    });

    return { isProgress, currentYear, error };
  },
};
</script>

<style scoped lang="scss"></style>
