<template>
  <component
    :is="tag"
    class="filePreviewRow"
    :class="{
      'alert alert-warning': !isUniqueFile,
      'alert alert-danger': !file.valid,
    }"
  >
    <div class="filePreviewRow__description">
      <template v-if="file.validation.isZipFile">
        <i
          class="fa fa-circle fs-8px fa-fw me-1"
          :class="{
            'text-success': isUniqueFile,
            'text-danger': !file.valid,
          }"
        ></i>
        <i class="filePreviewRow__icon bi bi-file-earmark-zip"></i>
      </template>
      <template v-else>
        <i class="fa fa-circle fs-8px fa-fw text-danger me-1"></i>
        <i class="filePreviewRow__icon bi bi-file-earmark"></i>
      </template>

      <!-- File name & File type display -->
      <div class="filePreviewRow__detail">
        <span class="filePreviewRow__fileName">
          {{ file.file.name }}
        </span>
        <span class="filePreviewRow__subContent">
          {{ file.file.type ? file.file.type : "(Unknown file type)" }} -
          {{ fileSize }}
        </span>
      </div>

      <!-- File pre-check result message -->
      <template v-if="uploadType == 1">
        <small v-if="!file.validation.inSizeLimit" class="text-theme ps-3">
          <i class="bi bi-exclamation-circle-fill"></i>
          Exceed sample file size limit. (20MB)
        </small>
      </template>

      <small
        v-if="!file.validation.isZipFile && file.validation.inSizeLimit"
        class="text-theme ps-3"
      >
        <i class="bi bi-exclamation-circle-fill"></i>
        Only ZIP files are allowed.
      </small>

      <template v-if="uploadType == 0">
        <small
          v-if="
            !file.validation.isMatchFileNameFormat && file.validation.isZipFile
          "
          class="text-theme ps-3"
        >
          <i class="bi bi-exclamation-circle-fill"></i>
          File format "(Role)_(IP)_(Domain)_(HostName)"
        </small>
      </template>

      <small v-if="!isUniqueFile" class="text-theme ps-3">
        <i class="bi bi-exclamation-circle-fill"></i>
        File exists. Upload will reset relative data.
      </small>
    </div>

    <!-- Upload process display -->
    <div class="filePreviewRow__function">
      <span class="badge text-bg-primary" v-show="file.status == 'loading'">
        <i class="bi bi-exclamation-diamond-fill"></i> Uploading...
      </span>
      <span class="badge text-bg-success" v-show="file.status == true">
        <i class="bi bi-check-circle-fill"></i> Upload Successfully
      </span>
      <span class="badge text-bg-danger" v-show="file.status == false">
        <i class="bi bi-exclamation-triangle-fill"></i> Upload Error
      </span>
      <i
        class="filePreviewRow__trash bi bi-trash3 cursor-pointer"
        @click="$emit('remove', file)"
      ></i>
    </div>
  </component>
</template>

<script setup>
import { defineEmits, defineProps, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { showDialog } from "vant";
const props = defineProps({
  caseId: { type: String, default: null },
  file: { type: Object, required: true },
  tag: { type: String, default: "li" },
  uploadType: { type: Number, default: 0 },
});
const store = useStore();
const isUniqueFile = ref(true);

defineEmits(["remove"]);

const fileSize = computed(() => {
  let result = 0;
  let unit = "";
  let output = null;
  if (props.file.file.size >= 1000000) {
    result = props.file.file.size / 1000000;
    unit = " MB";
    output = result.toFixed(1) + unit;
  } else if (props.file.file.size >= 1000 && props.file.file.size < 1000000) {
    result = props.file.file.size / 1000;
    unit = " KB";
    output = result.toFixed(1) + unit;
  } else {
    result = props.file.file.size;
    unit = " bytes";
    output = result.toFixed(0) + unit;
  }

  return output;
});

const checkIfIsUploadedBefore = async () => {
  console.log("checkIfIsUploadedBefore", props.file);
  let fileNameList = [];

  fileNameList.push(props.file.file.name);
  const sourceData = {
    caseId: props.caseId,
    Type: props.uploadType,
    Zipfilelist: fileNameList,
  };
  console.log("checkIfIsUploadedBefore - sourceData", sourceData);
  try {
    const response = await store.dispatch(
      "postCheckIfIsUploadedBefore",
      sourceData
    );
    console.log("response - postCheckIfIsUploadedBefore", response);
    if (response.status !== 200) {
      showDialog({
        message: response.message,
        confirmButtonText: "返回",
      }).then(() => {
        // on close
      });
    } else {
      // Todo: 做 isUnique 檢查
      const isNotUniqueItems = response.data.results.filter(
        (item) => item.isUnique == false
      );
      console.log(
        "checkIfIsUploadedBefore - isNotUniqueItems",
        isNotUniqueItems
      );
      if (isNotUniqueItems.length) {
        console.log("此檔案重複上傳", isNotUniqueItems[0].filename);
        return false;
      } else {
        console.log("此檔案沒上傳過");
        return true;
      }
    }
  } catch (error) {
    showDialog({
      message: error.message,
      confirmButtonText: "返回",
    }).then(() => {
      // on close
    });
    console.log("Fail - checkIfIsUploadedBefore", error);
  }
};

watch(
  props.file,
  async (data) => {
    if (data && data.status == null) {
      console.log("Watch - props.file:data", data.file);
      // Check if the file is uploaded before (duplicated or uniqjue)
      isUniqueFile.value = await checkIfIsUploadedBefore();
      console.log("isUniqueFile", isUniqueFile);
    }
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss">
.filePreviewRow {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  &__description {
    display: flex;
    align-items: center;
  }
  &__icon {
    font-size: 30px;
    color: #666;
  }
  &__trash {
    color: #868686;
    font-size: 24px;
    &:hover {
      color: var(--bs-color-theme);
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
  }
  &__function {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    .badge {
      // height: 18px;
      margin-right: 16px;
    }
  }
}
.filePreviewRow.alert {
  margin-bottom: 0;
}
.file-preview {
  // width: 20%;
  margin: 1rem 2.5%;
  position: relative;
  // aspect-ratio: 1/1;
  // overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  .close-icon,
  .status-indicator {
    --size: 20px;
    // position: absolute;
    line-height: var(--size);
    height: var(--size);
    border-radius: var(--size);
    box-shadow: 0 0 5px currentColor;
    // right: 0.25rem;
    appearance: none;
    border: 0;
    padding: 0;
  }

  .close-icon {
    width: var(--size);
    font-size: var(--size);
    background: #933;
    color: #fff;
    top: 0.25rem;
    cursor: pointer;
  }

  .status-indicator {
    font-size: calc(0.75 * var(--size));
    bottom: 0.25rem;
    padding: 0 10px;
  }

  .loading-indicator {
    animation: pulse 1.5s linear 0s infinite;
    color: #000;
  }

  .success-indicator {
    background: #6c6;
    color: #040;
  }

  .failure-indicator {
    background: #933;
    color: #fff;
  }
}

@keyframes pulse {
  0% {
    background: #fff;
  }

  50% {
    background: #ddd;
  }

  100% {
    background: #fff;
  }
}
</style>
