require("../core/vendors");
require("../core/central.config");
import { createApp } from "vue";
import App from "../core/App.vue";
import router from "../core/router";
import store from "../core/store";
// import Vant from 'vant';
import {
  button,
  Icon,
  Image as VanImage,
  Popup,
  Toast,
  Calendar,
  Checkbox,
  CheckboxGroup,
  Form,
  Field,
  CellGroup,
  RadioGroup,
  Radio,
  Switch,
  Dialog,
  Loading,
  Notify,
  Overlay,
  Badge,
  Empty,
  Tag,
  Tab,
  Tabs,
  BackTop,
  Divider,
  Pagination,
  Popover,
  TextEllipsis,
  FloatingBubble,
} from "vant";
import "vant/lib/index.css";

let app = createApp(App)
  .use(store)
  .use(router)
  .use(button)
  .use(Icon)
  .use(VanImage)
  .use(Popup)
  .use(Toast)
  .use(Calendar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Form)
  .use(Field)
  .use(CellGroup)
  .use(RadioGroup)
  .use(Radio)
  .use(Switch)
  .use(Dialog)
  .use(Loading)
  .use(Notify)
  .use(Overlay)
  .use(Badge)
  .use(Empty)
  .use(Tag)
  .use(Tab)
  .use(Tabs)
  .use(BackTop)
  .use(Divider)
  .use(Popover)
  .use(Pagination)
  .use(TextEllipsis)
  .use(FloatingBubble);

app.mount("#app");
