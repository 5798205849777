import { createStore } from "vuex";
import GraphService from "./../services/graph.service";
import Request from "./../services/request.service";
import moment from "moment";
import formHelper from "../formHelper";

export default createStore({
  state: {
    currentYear: null,
    user: {
      loggedIn: false,
      id: null,
      data: null,
    },
    isProgress: {
      login: true,
      page: true,
      mainData: false,
      uploadFiles: false,
      submit: false,
      keywordSearch: false,
      popupLoading: false,
    },
    isReloadRequest: false,
    currentSelectedCaseFilterTab: null,
    caseListData4CardMode: [],
    allCustomerData: [],
    shiftMemberListData: [],
    salesMemberListData: [],
    industryListData: [],
    optionsAllCustomerList: [],
    optionsAllIrEngineersNameList: [],
    optionsAllSalesNameList: [],
    optionsAllIndustryList: [],
    optionsAptOpsForm: [],
    optionsAssetsList: [],
    allCaseListData: [],
    caseDetailData: [],
    workspaceCollectFilesData: [],
    workspaceAptStgResultsData: [],
    workspaceAptProdResultsData: [],
    lastSearchCaseParams: null,
    lastSearchDateRange: null,
    paginationCaseList: {
      casePageCurrent: 1,
      casePageTotal: 1,
      casePagePerPage: 20,
      casePageAmount: 0,
    },
    managerWhiteList: [],
    channelList: null,
    keywordSearchInfo: {
      keyword: null,
      total_files: null,
      total_matches: null,
      total_matches_files: null,
      total_page: null,
    },
    keywordResult: [],
    keywordResultBigQuery: [],
    optionsAllMitreReference: [],
    optionsMitreVulnerabilityHistoryData: [],
    optionsMitreToolHistoryData: [],
    caseMitreTagsData: [],
  },
  getters: {
    currentYear: (state) => state.currentYear,
    user: (state) => state.user,
    isProgress: (state) => state.isProgress,
    isReloadRequest: (state) => state.isReloadRequest,
    currentSelectedCaseFilterTab: (state) => state.currentSelectedCaseFilterTab,
    managerWhiteList: (state) => state.managerWhiteList,
    shiftMemberListData: (state) => state.shiftMemberListData,
    salesMemberListData: (state) => state.salesMemberListData,
    industryListData: (state) => state.industryListData,
    caseListData4CardMode: (state) => state.caseListData4CardMode,
    allCustomerData: (state) => state.allCustomerData,
    optionsAllCustomerList: (state) => {
      return state.optionsAllCustomerList.map((item) => {
        const displayField = item.key ? `(${item.key})` : "";
        return {
          ...item,
          displayValue: `${item.value} ${displayField}`,
        };
      });
    },
    optionsAllIrEngineersNameList: (state) => {
      return state.optionsAllIrEngineersNameList.map((item) => {
        return {
          ...item,
          displayValue: `${item.value} (${item.key})`,
        };
      });
    },
    optionsAllSalesNameList: (state) => {
      return state.optionsAllSalesNameList.map((item) => {
        return {
          ...item,
          displayValue: `${item.value} (${item.key})`,
        };
      });
    },
    optionsAllIndustryList: (state) => state.optionsAllIndustryList,
    optionsAptOpsForm: (state) => state.optionsAptOpsForm,
    optionsAssetsList: (state) => state.optionsAssetsList,
    optionsAllMitreReference: (state) => state.optionsAllMitreReference,
    optionsMitreVulnerabilityHistoryData: (state) =>
      state.optionsMitreVulnerabilityHistoryData,
    optionsMitreToolHistoryData: (state) => state.optionsMitreToolHistoryData,
    caseMitreTagsData: (state) => state.caseMitreTagsData,
    allCaseListData: (state) => state.allCaseListData,
    caseDetailData: (state) => state.caseDetailData[0],
    workspaceCollectFilesData: (state) => state.workspaceCollectFilesData,
    workspaceAptStgResultsData: (state) => state.workspaceAptStgResultsData,
    workspaceAptProdResultsData: (state) => state.workspaceAptProdResultsData,
    lastSearchCaseParams: (state) => state.lastSearchCaseParams,
    lastSearchDateRange: (state) => state.lastSearchDateRange,
    casePageCurrent: (state) => state.paginationCaseList.casePageCurrent,
    casePageTotal: (state) => state.paginationCaseList.casePageTotal,
    casePagePerPage: (state) => state.paginationCaseList.casePagePerPage,
    casePageAmount: (state) => state.paginationCaseList.casePageAmount,
  },
  mutations: {
    SET_ISPROGRESS_LOGIN(state, value) {
      state.isProgress.login = value;
    },
    SET_ISPROGRESS_PAGE(state, value) {
      state.isProgress.page = value;
    },
    SET_ISPROGRESS_MAINDATA(state, value) {
      state.isProgress.mainData = value;
    },
    SET_ISPROGRESS_UPLOADFILES(state, value) {
      state.isProgress.uploadFiles = value;
    },
    SET_ISPROGRESS_SUBMIT(state, value) {
      state.isProgress.submit = value;
    },
    SET_ISPROGRESS_KEYWORDSEARCH(state, value) {
      state.isProgress.keywordSearch = value;
    },
    SET_ISPROGRESS_POPUP(state, value) {
      state.isProgress.popupLoading = value;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USERID(state, value) {
      state.user.id = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_CHANNEL_LIST(state, data) {
      state.channelList = data;
    },
    SET_KEYWORD_SEARCH_INFO(state, data) {
      state.keywordSearchInfo = { ...data };
    },
    SET_KEYWORD_RESULT(state, data) {
      state.keywordResult = data;
    },
    SET_KEYWORD_RESULT_BIG(state, data) {
      state.keywordResultBigQuery = data;
    },
    RESET_KEYWORD_ALL(state) {
      state.channelList = null;
      state.keywordSearchInfo = {
        keyword: null,
        total_files: null,
        total_matches: null,
        total_matches_files: null,
        total_page: null,
      };
      state.keywordResult = [];
      state.keywordResultBigQuery = [];
    },
    CLEAR_DETAIL_DATA(state) {
      console.log("RUN - CLEAR_DETAIL_DATA");
      state.caseDetailData = [];
      state.workspaceCollectFilesData = [];
      state.workspaceAptStgResultsData = [];
      state.workspaceAptProdResultsData = [];
    },
    UPDATE_FILE_STATUS(state, sourceData) {
      console.log("Store - UPDATE_FILE_STATUS: sourceData", sourceData);
      const updatedFilesData = state.workspaceCollectFilesData.map((obj) => {
        const newObj = { ...obj };
        if (sourceData.data.FileIds.indexOf(obj.Id) >= 0) {
          newObj.ChangeStatus = sourceData.data.ChangeStatus;
          newObj.TriggerEmail = sourceData.data.TriggerEmail;
          newObj.Add2CartBy = sourceData.data.TriggerEmail
            ? sourceData.data.TriggerEmail.substring(
                0,
                sourceData.data.TriggerEmail.indexOf("@")
              )
            : null;
          newObj.Add2CartTime = moment().format("YYYY/MM/DD HH:mm");
          // newObj.ActualPath = sourceData.data.ActualPath
          //   ? sourceData.data.ActualPath
          //   : `(${sourceData.data.Filename})`;
        }
        return newObj;
      });
      console.log(
        "Store - UPDATE_FILE_STATUS: updatedFilesData",
        updatedFilesData
      );
      state.workspaceCollectFilesData = updatedFilesData;
    },
    MUTATE_COLLECT_FILES_DATA(state, data) {
      state.workspaceCollectFilesData = data.map((obj) => {
        const newObj = { ...obj };
        newObj.FileID = obj.Id.toString();
        newObj.ServerIp = obj.ServerIp ? obj.ServerIp : "-";
        newObj.UpdateTimeFormat = obj.UpdateTime
          ? moment(obj.UpdateTime).format("YYYY/MM/DD HH:mm")
          : null;
        newObj.Add2CartBy = obj.TriggerUserEmail
          ? obj.TriggerUserEmail.substring(0, obj.TriggerUserEmail.indexOf("@"))
          : null;

        newObj.Add2CartTime = obj.UpdateTime ? newObj.UpdateTimeFormat : "-";
        newObj.ActualPath = obj.ActualPath
          ? obj.ActualPath
          : `(${obj.Filename})`;

        newObj.IsMarked = obj.ChangeStatus == -1 ? true : false;
        newObj.Timeline = `${obj.Date ? obj.Date : ""} ${
          obj.Time ? obj.Time : ""
        }`;
        if (!obj.Date && !obj.Time) {
          newObj.Timeline = "-";
        }

        return newObj;
      });

      const uniqueObjects = [];
      const duplicates = [];
      state.workspaceCollectFilesData.map((obj) => {
        const newObj = { ...obj };
        const isDuplicate = uniqueObjects.some((uniqueObj) => {
          return uniqueObj.Sha1 === obj.Sha1;
        });
        if (isDuplicate) {
          obj.IsSHA1Duplicated = true;
          duplicates.push(obj);
        } else {
          obj.IsSHA1Duplicated = false;
          uniqueObjects.push(obj);
        }
        return newObj;
      });

      console.log(
        "MUTATE_COLLECT_FILES_DATA - state.workspaceCollectFilesData",
        state.workspaceCollectFilesData
      );
      // 從新的 API 組合成新的 state.workspaceCollectFilesData
    },
    MUTATE_MITRE_REFERENCE_DATA(state, data) {
      state.optionsAllMitreReference = data.map((obj) => {
        const newObj = { ...obj };
        newObj.label = `${obj.TechniqueID} ${obj.Tactics} ${obj.TechniqueName} ${obj.TechniqueNameCht}`;

        return newObj;
      });
    },
    MUTATE_MITRE_AUTOCOMPLETE_DATA(state, data) {
      const mitreVulnerabilityHistoryData = data.map((a) => a.Vulnerability);
      const mitreToolHistoryData = data.map((a) => a.Tool);
      state.optionsMitreVulnerabilityHistoryData =
        mitreVulnerabilityHistoryData;
      state.optionsMitreToolHistoryData = mitreToolHistoryData;
    },
    MUTATE_CASE_MITRE_TAGS(state, data) {
      state.caseMitreTagsData = data;
    },
    SET_RELOAD_REQUEST(state, value) {
      state.isReloadRequest = value;
    },
    updateCurrentYear(state, year) {
      state.currentYear = year;
    },
    updateCurrentSelectedCaseFilterTab(state, value) {
      if (value !== null)
        sessionStorage.setItem("currentSelectedCaseFilterTab", value);
      state.currentSelectedCaseFilterTab = value;
    },
    updateAllCustomerData(state, list) {
      state.allCustomerData = list.data;
      state.optionsAllCustomerList = list.data.map((obj) => ({
        key: obj.CustomerShortName ? obj.CustomerShortName : "",
        key2: obj.CustomerEnglishName,
        value: obj.CustomerName,
        sid: obj.sid,
      }));

      // console.log("state.optionsAllCustomerList", state.optionsAllCustomerList);
    },
    updateAllIndustryListData(state, list) {
      state.industryListData = list;
      state.optionsAllIndustryList = list.map((obj) => ({
        key: obj.DisplayName,
        value: obj.DisplayName,
        sid: obj.Id,
      }));
    },
    updateShiftMemberListData(state, list) {
      state.shiftMemberListData = list.map((obj) => {
        const newObj = { ...obj };
        newObj.AccountName = obj.Email
          ? obj.Email.substring(0, obj.Email.indexOf("@"))
          : null;
        return newObj;
      });
      state.optionsAllIrEngineersNameList = list.map((obj) => ({
        key: obj.Email,
        value: obj.DisplayName,
        sid: obj.Id,
      }));
      console.log("Store - shiftMemberListData", state.shiftMemberListData);
      console.log(
        "Store - optionsAllIrEngineersNameList",
        state.optionsAllIrEngineersNameList
      );
    },
    updateSalesNameListData(state, list) {
      state.salesMemberListData = list;
      state.optionsAllSalesNameList = list.map((obj) => ({
        key: obj.Email,
        value: obj.DisplayName,
        sid: obj.Id,
      }));
    },
    SET_OPTIONS_APT_FORM(state, data) {
      state.optionsAptOpsForm = data;
      let optionArrayPriority = [];
      Object.entries(data.APT.PRIORITIES).map(([key, value]) => {
        optionArrayPriority.push({
          key: value,
          value: parseInt(key),
          disabled: key == 1 ? true : false,
        });
      });
      let optionArraySourceCategories = [];
      Object.entries(data.APT.SOURCE_CATEGORIES).map(([key, value]) => {
        optionArraySourceCategories.push({
          index: parseInt(key),
          key: value.ref_value,
          value: value.disp_order,
        });
      });
      let optionArrayCountry = [];
      Object.entries(data.COMMON.COUNTRIES).map(([key, value]) => {
        optionArrayCountry.push({
          index: parseInt(key),
          key: value.name,
          value: value.code,
          description: value.description,
        });
      });
      let optionArrayIndustry = [];
      Object.entries(data.COMMON.INDUSTRIES).map(([key, value]) => {
        optionArrayIndustry.push({
          index: parseInt(key),
          key: value.ref_value,
          value: value.disp_order,
          status: value.ref_status,
          alias: value.alias,
        });
      });
      let optionArrayRegion = [];
      Object.entries(data.COMMON.REGIONS).map(([key, value]) => {
        optionArrayRegion.push({
          index: parseInt(key),
          key: value.ref_value,
          value: value.disp_order,
          status: value.ref_status,
          alias: value.alias,
        });
      });
      let optionArrayTlp = [];
      Object.entries(data.COMMON.TLP).map(([key, value]) => {
        optionArrayTlp.push({
          key: value,
          value: parseInt(key),
        });
      });

      state.optionsAptOpsForm.APT.SOURCE_CATEGORIES =
        optionArraySourceCategories;
      state.optionsAptOpsForm.APT.PRIORITIES = optionArrayPriority;
      state.optionsAptOpsForm.COMMON.COUNTRIES = optionArrayCountry;
      state.optionsAptOpsForm.COMMON.INDUSTRIES = optionArrayIndustry;
      state.optionsAptOpsForm.COMMON.REGIONS = optionArrayRegion;
      state.optionsAptOpsForm.COMMON.TLP = optionArrayTlp;
      console.log("state.optionsAptOpsForm", state.optionsAptOpsForm);
    },
    SET_OPTIONS_ASSETS_LIST(state, list) {
      const fullList = list.map((obj) => ({
        key: `${obj.Role}_${obj.ServerIp}_${obj.DomainName}_${obj.HostName}`,
        value: obj.Id,
      }));
      state.optionsAssetsList = fullList.filter((item) => item.key !== "___");
    },
    updateManagerWhiteListData(state, list) {
      const adminIsTrue = list.filter((item) => item.admin == true);
      const adminWhiteList = adminIsTrue.map(function (i) {
        return i.email2.toLowerCase();
      });
      state.managerWhiteList = adminWhiteList;
      console.log("mutations - managerWhiteList:", adminWhiteList);
    },
    updateAllCaseList(state, list) {
      if (list.data) {
        const computedListData = list.data.map((obj) => {
          const newObj = { ...obj };
          newObj.progress = formHelper.whatCaseProgress(obj.State);
          newObj.source_display = formHelper.whatCaseSource(obj.SourceType);
          newObj.issue_type_display = formHelper.whatCaseIssueType(
            obj.IssueType
          );
          newObj.attack_type_display = formHelper.whatCaseAttackType(
            obj.AttackTypeID
          );
          newObj.SalesEngilishName = obj.SalesEngilishName
            ? obj.SalesEngilishName
            : "-";
          newObj.owner_display_name = obj.OwnerDisplayName
            ? obj.OwnerDisplayName
            : "未指定";
          newObj.on_site_date = obj.OnsiteDate
            ? moment(obj.OnsiteDate).format("YYYY/MM/DD")
            : "-";
          newObj.CreationTime = obj.CreationTime
            ? moment(obj.CreationTime).format("YYYY/MM/DD HH:mm")
            : "-";
          newObj.progress_text_color = formHelper.whatCaseFontColor4Progress(
            obj.State
          );
          newObj.progress_background_color =
            formHelper.whatCaseBackgrounfColor4Progress(obj.State);

          const FirstHackTimeMoment = moment(obj.FirstHackTime);
          const OnSiteDateTimeMoment = moment(obj.OnsiteDate);
          // const CreationTimeMoment = moment(obj.CreationTime);

          newObj.invasionDays = obj.OnsiteDate
            ? OnSiteDateTimeMoment.diff(FirstHackTimeMoment, "days") + 1
            : null;

          if (obj.ElapsedMin) {
            const duration = moment.duration(obj.ElapsedMin, "minutes");
            let months = `${duration._data.months}個月`;
            let days = `${duration._data.days}天`;
            let hours = `${duration._data.hours}小時`;
            let minutes = duration._data.minutes
              ? `${duration._data.minutes}分鐘`
              : "";
            if (duration._data.months) {
              // newObj.duration_display = months + days + hours + minutes;
              newObj.duration_display = months + days;
            } else if (duration._data.days) {
              // newObj.duration_display = days + hours + minutes;
              newObj.duration_display = days + hours;
            } else if (duration._data.hours) {
              newObj.duration_display = hours + minutes;
            } else if (duration._data.minutes) {
              newObj.duration_display = minutes;
            }
          } else {
            if (obj.ElapsedMin == 0) {
              newObj.duration_display = "0";
            } else {
              newObj.duration_display = "-";
            }
          }
          return newObj;
        });
        state.allCaseListData = computedListData;
      } else {
        state.allCaseListData = [];
      }

      console.log(
        "mutations - updateAllCaseList / state.allCaseListData",
        state.allCaseListData
      );
    },
    updateCaseDetailData(state, detailData) {
      let detailDataObject = [];
      detailDataObject.push(detailData);

      // Step 6-1: Generate assets data
      const computedDetailData = detailDataObject.map((obj) => {
        const newObj = { ...obj };
        newObj.create_time = moment(obj.CreationTime).format(
          "YYYY/MM/DD HH:mm"
        );
        newObj.state = obj.State ? obj.State : 0;
        newObj.source_display = formHelper.whatCaseSource(obj.SourceType);
        newObj.issue_type_display = formHelper.whatCaseIssueType(obj.IssueType);
        newObj.attack_type_display = formHelper.whatCaseAttackType(
          obj.AttackTypeID
        );
        newObj.HackDurationDay = obj.HackDurationDay
          ? obj.HackDurationDay
          : "-";
        // newObj.FirstHackTime = obj.FirstHackTime ? obj.FirstHackTime : null;
        newObj.onsite_date_display = obj.OnsiteDate
          ? moment(obj.OnsiteDate).format("YYYY/MM/DD")
          : "";
        newObj.first_hack_time_display = obj.FirstHackTime
          ? moment(obj.FirstHackTime).format("YYYY/MM/DD")
          : null;
        newObj.update_time = obj.LastUpdateTime
          ? moment(obj.LastUpdateTime).format("YYYY/MM/DD HH:mm")
          : null;
        newObj.contact_info = {
          name: obj.ContactName,
          title: obj.ContactTitle,
          email: obj.ContactEmail,
          office_phone: obj.ContactOfficePhone,
          mobile_phone: obj.ContactMobilePhone,
          address: obj.ContactAddress,
        };
        // newObj.contact_info.titleDisplay = formHelper.whatNameTitleDisplay(
        //   obj.contact_info[0].title
        // );
        newObj.AssetsList = obj.AssetsList ? obj.AssetsList : "";
        newObj.Reason = obj.Reason ? obj.Reason : "";
        newObj.Note = obj.Note ? obj.Note : "";
        newObj.history = [];

        if (obj.Duration) {
          const duration = moment.duration(obj.Duration, "minutes");
          let months = `${duration._data.months}個月`;
          let days = `${duration._data.days}天`;
          let hours = `${duration._data.hours}小時`;
          let minutes = duration._data.minutes
            ? `${duration._data.minutes}分鐘`
            : "";
          if (duration._data.months) {
            newObj.duration_display = months + days + hours + minutes;
          } else if (duration._data.days) {
            newObj.duration_display = days + hours + minutes;
          } else if (duration._data.hours) {
            newObj.duration_display = hours + minutes;
          } else if (duration._data.minutes) {
            newObj.duration_display = minutes;
          }
        } else {
          if (obj.estimated_service_mins == 0) {
            newObj.duration_display = "0";
          } else {
            newObj.duration_display = "-";
          }
        }

        newObj.Workspace.Assets = obj.Workspace.Assets.map((subObj, index) => {
          const newSubObj = { ...subObj };
          newSubObj.index = index + 1;
          newSubObj.Role = subObj.Role ? subObj.Role : "";
          newSubObj.creation_time_display = moment(subObj.CreationTime).format(
            "YYYY/MM/DD HH:mm"
          );
          newSubObj.first_hack_time_display = subObj.FirstHackTime
            ? moment(subObj.FirstHackTime).format("YYYY/MM/DD")
            : "";
          newSubObj.first_hack_time_value = subObj.FirstHackTime
            ? moment(subObj.FirstHackTime).format("YYYY-MM-DD")
            : "";
          newSubObj.is_hacked_display = subObj.IsHacked ? "是" : "否";
          newSubObj.ServerIp = subObj.ServerIp ? subObj.ServerIp : "";
          newSubObj.DomainName = subObj.DomainName ? subObj.DomainName : "";
          newSubObj.HostName = subObj.HostName ? subObj.HostName : "";
          newSubObj.InfectedSourceIP = subObj.InfectedSourceIP
            ? subObj.InfectedSourceIP
            : "";
          newSubObj.AccountName = subObj.AccountName ? subObj.AccountName : "";
          newSubObj.Description = subObj.Description ? subObj.Description : "";

          // Calculate the minutes from asset creation time till now.
          const given = moment(subObj.CreationTime);
          const current = moment().startOf("minute");
          newSubObj.Duration = moment.duration(current.diff(given)).asMinutes();

          // Set upload zip file status
          if (subObj.UploadedZipFiles.length) {
            newSubObj.UploadedZipFiles[0].Status =
              subObj.UploadedZipFiles[0].Status == 0 && newSubObj.Duration > 15
                ? -1
                : subObj.UploadedZipFiles[0].Status == 1 &&
                  newSubObj.Duration > 30
                ? -2
                : subObj.UploadedZipFiles[0].Status == 2 &&
                  newSubObj.Duration > 20
                ? 22
                : subObj.UploadedZipFiles[0].Status == -3 &&
                  newSubObj.Duration <= 20
                ? 33
                : subObj.UploadedZipFiles[0].Status;
          }

          // If is empty asset
          newSubObj.assetUploadStatusDisplay = subObj.UploadedZipFiles.length
            ? formHelper.whatAssetUploadStatusDisplay(
                subObj.UploadedZipFiles[0].Status
              )
            : "(Empty Asset)";

          newSubObj.assetUploadStatus = subObj.UploadedZipFiles.length
            ? subObj.UploadedZipFiles[0].Status
            : null;
          newSubObj.assetUploadStatusClassName = subObj.UploadedZipFiles.length
            ? formHelper.whatAssetUploadStatusClassName4Icon(
                subObj.UploadedZipFiles[0].Status
              )
            : "";
          newSubObj.SamplingDateDisplay = subObj.SamplingDate
            ? moment(subObj.SamplingDate).format("YYYY/MM/DD")
            : "";
          newSubObj.SamplingDateValue = subObj.SamplingDate
            ? moment(subObj.SamplingDate).format("YYYY-MM-DD")
            : "";
          newSubObj.UploadEngineerEmail = subObj.UploadEngineerEmail
            ? subObj.UploadEngineerEmail
            : "";
          newSubObj.UploadEngineerEnglishName = subObj.UploadEngineerEnglishName
            ? subObj.UploadEngineerEnglishName
            : "";
          newSubObj.UploadEngineerID = subObj.UploadEngineerID
            ? subObj.UploadEngineerID
            : "";

          return newSubObj;
        });

        return newObj;
      });

      console.log(
        "computedDetailData[0].Workspace.Assets",
        computedDetailData[0].Workspace.Assets
      );

      // Step 6-2: Pickup files from log files
      let collectFilesArray = [];
      let aptStgResultsArray = [];
      let aptProdResultsArray = [];
      computedDetailData[0].Workspace.Assets.map((obj) => {
        const newObj = {};
        if (obj.UploadedZipFiles.length) {
          obj.UploadedZipFiles[0].Files.map((item) => {
            const fileObj = {};
            const collectFilesContent = {
              FileID: item.FileID,
              FileStatus: item.FileStatus
                ? item.FileStatus
                : item.IsMarked
                ? -1
                : 0,
              CaseID: `IR-${obj.IrRequestID}`,
              Role: obj.Role ? obj.Role : "",
              ServerIp: obj.ServerIp ? obj.ServerIp : "",
              DomainName: obj.DomainName ? obj.DomainName : "",
              HostName: obj.HostName ? obj.HostName : "",
              UploadTimeDIsplay: obj.UploadedZipFiles[0].UploadTime
                ? moment(obj.UploadedZipFiles[0].UploadTime).format(
                    "YYYY/MM/DD HH:mm"
                  )
                : "",
              Owner: obj.UploadedZipFiles[0].UploadEngineerEnglishName,
              Filename: item.Filename ? item.Filename : "",
              Size: item.Size ? item.Size : "",
              Filepath: item.Filepath ? item.Filepath : "",
              // Time format: YYYY/MM/DD A hh:mm
              Timeline: item.Timeline ? item.Timeline : "",
              Sha1: item.Sha1 ? item.Sha1 : "",
              WhySuspect: item.WhySuspect ? item.WhySuspect : "",
              ZipFileName: obj.UploadedZipFiles[0].ZipFileName,
              DownloadURL: item.DownloadURL,
              FirstHackTime: obj.FirstHackTime ? obj.FirstHackTime : "",
              Guid: item.Guid ? item.Guid : "",
              Pguid: item.Pguid ? item.Pguid : "",
              IsIgnored: item.IsIgnored ? item.IsIgnored : false,
              IsMarked: item.IsMarked ? item.IsMarked : false,
              IsAptUploaded: item.IsAptUploaded ? item.IsAptUploaded : false,
              IsEditable: false,
              SRCaseResultProd: item.SRCaseResultProd
                ? item.SRCaseResultProd
                : "",
              SRCaseResultStg: item.SRCaseResultStg ? item.SRCaseResultStg : "",
              // Todo: UploadStatus
              //  0: Create signURL completed, Upload no complete.
              //  1: Upload success, un-zip in progress.
              //  2: Upload success & Unzip success.
              // -2: Upload success, Unzip FAIL.
              // -3: 轉 windows event 檔失敗
            };
            const aptStgResultContent = { ...item.SRCaseResultStg };
            const aptProfResultContent = { ...item.SRCaseResultProd };

            collectFilesArray.push(collectFilesContent);
            aptStgResultsArray.push(aptStgResultContent);
            aptProdResultsArray.push(aptProfResultContent);
            return fileObj;
          });
        }

        return newObj;
      });

      const filteredAptStgResultsArray = aptStgResultsArray.filter(
        (item) => item.XCaseId !== null
      );
      const filteredAptProdResultsArray = aptProdResultsArray.filter(
        (item) => item.XCaseId !== null
      );

      let replaceNullAptStgResultsArray = [];
      if (filteredAptStgResultsArray.length) {
        replaceNullAptStgResultsArray = filteredAptStgResultsArray.map(
          (obj) => {
            const newObj = { ...obj };
            newObj.AddtionalInfo = obj.AddtionalInfo ? obj.AddtionalInfo : "";
            newObj.Campaign = obj.Campaign ? obj.Campaign : "";
            newObj.CaseIrInfo = obj.CaseIrInfo ? obj.CaseIrInfo : "";
            newObj.Company = obj.Company ? obj.Company : "";
            newObj.Connection = obj.Connection ? obj.Connection : "";
            newObj.Detection = obj.Detection ? obj.Detection : "";
            newObj.Filename = obj.Filename ? obj.Filename : "";
            newObj.Operator = obj.Operator ? obj.Operator : "";
            newObj.PatterVersion = obj.PatterVersion ? obj.PatterVersion : "";
            newObj.ReleaseDate = obj.ReleaseDate ? obj.ReleaseDate : "";
            newObj.Sha1 = obj.Sha1 ? obj.Sha1 : "";
            newObj.Stakeholders = obj.Stakeholders ? obj.Stakeholders : "";
            newObj.Status = obj.Status ? obj.Status : "";
            newObj.SubmitTime = obj.SubmitTime ? obj.SubmitTime : "";
            newObj.Summary = obj.Summary ? obj.Summary : "";
            newObj.XCaseId = obj.XCaseId ? obj.XCaseId : "";

            return newObj;
          }
        );
      }

      // Step 6-3: Pickup files fron sample files
      let sampleFilesArray = [];
      if (computedDetailData[0].Workspace.Sample_files.length) {
        computedDetailData[0].Workspace.Sample_files.map((obj) => {
          const newObj = {};
          if (obj.Files.length) {
            obj.Files.map((item) => {
              const fileObj = {};
              const objectContent = {
                FileID: item.FileID,
                FileStatus: item.FileStatus
                  ? item.FileStatus
                  : item.IsMarked
                  ? -1
                  : 0,
                CaseID: `IR-${obj.IrRequestID}`,
                Role: "-",
                ServerIp: "-",
                DomainName: "-",
                HostName: "-",
                UploadTimeDIsplay: obj.UploadTime
                  ? moment(obj.UploadTime).format("YYYY/MM/DD HH:mm")
                  : "",
                Owner: obj.UploadEngineerEnglishName,
                Filename: item.Filename ? item.Filename : "",
                Size: item.Size ? item.Size : "",
                Filepath: item.Filepath ? item.Filepath : "",
                // Time format: YYYY/MM/DD A hh:mm
                Timeline: item.Timeline ? item.Timeline : "",
                Sha1: item.Sha1 ? item.Sha1 : "",
                WhySuspect: item.WhySuspect ? item.WhySuspect : "",
                ZipFileName: obj.ZipFileName,
                DownloadURL: item.DownloadURL,
                FirstHackTime: obj.FirstHackTime ? obj.FirstHackTime : "",
                Guid: item.Guid ? item.Guid : "",
                Pguid: item.Pguid ? item.Pguid : "",
                IsIgnored: item.IsIgnored ? item.IsIgnored : "",
                IsMarked: item.IsMarked ? item.IsMarked : false,
                IsAptUploaded: item.IsAptUploaded ? item.IsAptUploaded : false,
                IsEditable: true,
                SRCaseResultProd: item.SRCaseResultProd
                  ? item.SRCaseResultProd
                  : "",
                SRCaseResultStg: item.SRCaseResultStg
                  ? item.SRCaseResultStg
                  : "",
              };

              sampleFilesArray.push(objectContent);
              collectFilesArray.push(objectContent);
              return fileObj;
            });
          }

          return newObj;
        });
      }

      // Step 6-5: Pickup SHA1 duplicated files.
      const uniqueObjects = [];
      const duplicates = [];
      collectFilesArray.map((obj) => {
        const newObj = { ...obj };
        const isDuplicate = uniqueObjects.some((uniqueObj) => {
          return uniqueObj.Sha1 === obj.Sha1;
        });
        if (isDuplicate) {
          obj.IsSHA1Duplicated = true;
          duplicates.push(obj);
        } else {
          obj.IsSHA1Duplicated = false;
          uniqueObjects.push(obj);
        }
        return newObj;
      });
      console.log("TEST - collectFilesArray", collectFilesArray);

      // Step 6-6: Final
      state.caseDetailData = computedDetailData;
      // state.workspaceCollectFilesData = collectFilesArray;
      state.workspaceAptStgResultsData = replaceNullAptStgResultsArray;
      state.workspaceAptProdResultsData = filteredAptProdResultsArray;
      console.log(
        "mutations - updateCaseDetailData / computedDetailData",
        computedDetailData[0]
      );
      // console.log(
      //   "mutations - updateCaseDetailData / workspaceCollectFilesData",
      //   collectFilesArray
      // );
      console.log(
        "mutations - updateCaseDetailData / workspaceAptStgResultData",
        replaceNullAptStgResultsArray
      );
      console.log(
        "mutations - updateCaseDetailData / workspaceAptProdResultData",
        filteredAptProdResultsArray
      );
    },
    updateCasesPage(state, p) {
      state.paginationCaseList.casePageCurrent = p;
    },
    updateCasesTotalPage(state, tp) {
      state.paginationCaseList.casePageTotal = tp;
    },
    updateCasesAmount(state, amount) {
      state.paginationCaseList.casePageAmount = amount;
    },
    updateLastSearchCaseParams(state, params) {
      state.lastSearchCaseParams = params;
      console.log("updateLastSearchCaseParams", params);
      sessionStorage.setItem("lastSearchCaseParams", params);
    },
    updateLastSearchDateRange(state, params) {
      state.lastSearchDateRange = params;
      console.log("updateLastSearchDateRange", params);
      sessionStorage.setItem("lastSearchDateRange", params);
    },
    clearSearchCase(state) {
      state.caseListData4CardMode = [];
      state.paginationCaseList.casePageCurrent = 1;
      state.paginationCaseList.casePageTotal = 1;
      state.paginationCaseList.casePageAmount = 0;

      let storedLastCaseListDisplayMode = sessionStorage.getItem(
        "lastCaseListDisplayMode"
      );
      sessionStorage.clear();
      sessionStorage.setItem(
        "lastCaseListDisplayMode",
        storedLastCaseListDisplayMode
      );
    },
  },
  actions: {
    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        context.commit("SET_USER", null);
      }
    },

    async getAzureAdUserData(context) {
      const graphService = new GraphService();
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // console.log("store - getAzureAdUserData");
          const token = localStorage.getItem("access_token");
          let response = [];

          graphService.getUserInfo(token).then(
            (data) => {
              context.commit("SET_USER", data);
              context.commit("SET_LOGGED_IN", true);
              sessionStorage.setItem("userEmail", data.mail);
              sessionStorage.setItem("userDisplayName", data.displayName);
              response = data;
              console.log("data - store getAzureAdUserData", data);
              resolve(response);
            },
            (error) => {
              console.error(error);
              // this.apiCallFailed = true;
            }
          );

          // console.log("response / store - getAzureAdUserData", response);
          // resolve(response);
        } catch (e) {
          // console.log('store 11')
          reject(e);
        }
      });
    },

    async searchCaseList({ commit, state }, { params, pagination }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          commit("SET_ISPROGRESS_MAINDATA", true);
          commit("updateCasesAmount", 0); // 先清空 Amount 的數字
          console.log("store - searchCase:params", params);
          commit("updateLastSearchCaseParams", params); // 把最後一次搜尋的參數記下來
          // commit("clearSearchCase");

          if (pagination === "1") {
            // 如果是執行另一次搜尋，reset p&cursor
            commit("updateCasesPage", 1);
            sessionStorage.setItem("lastCaseListPageP", "1");
          } else if (pagination) {
            commit("updateCasesPage", pagination);
            sessionStorage.setItem("lastCaseListPageP", pagination);
          } else {
            commit("updateCasesPage", 1);
            sessionStorage.setItem("lastCaseListPageP", "1");
          }

          const pp_params = `&p=${state.paginationCaseList.casePageCurrent}&pp=${state.paginationCaseList.casePagePerPage}`;

          // let newParams = params ? (params+pp_params):pp_params;
          let newParams = params + pp_params;
          console.log("newParams", newParams);

          const response = await Request.getCaseList(newParams);
          if (response.data && Object.keys(response.data).length != 0) {
            console.log("有搜到服務單", response.data);
            // 有搜到服務單
            if (sessionStorage.getItem("lastSearchCaseParams") == params) {
              commit("updateCaseListData4CardMode", response.data);
              // console.log("pagination", response.data.pagination);
              commit("updateCasesTotalPage", response.data.pagination.tp);
              commit("updateCasesAmount", response.data.pagination.amount);
            } else {
              console.log(
                "[Exception] Store - searchAllCaseList: Catch the old request data, will ignore it."
              );
              return;
            }
          } else {
            // 搜不到服務單
            commit("updateCaseListData4CardMode", []);
            commit("updateCasesPage", 1);
            commit("updateCasesTotalPage", 1);
            commit("updateCasesAmount", 0);
          }
          // console.log("resolve data", response);
          setTimeout(() => {
            commit("SET_ISPROGRESS_MAINDATA", false);
          }, 500);
          resolve(response);
        } catch (e) {
          console.log("e - searchCaseList", e);
          reject(e);
        }
      });
    },

    async searchAllCaseList({ commit }, { params, dateRangeParams }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          commit("updateAllCaseList", []);
          commit("SET_ISPROGRESS_MAINDATA", true);
          commit("updateLastSearchCaseParams", params); // 把最後一次搜尋的參數記下來
          const pp_params = `&p=1&pp=10000`;
          // let newParams = params ? (params+pp_params):pp_params;
          let newParams = dateRangeParams
            ? params + dateRangeParams + pp_params
            : params + pp_params;
          console.log("newParams", newParams);
          const response = await Request.getCaseList(newParams);
          if (response.status == 200) {
            if (Object.keys(response.data).length != 0) {
              console.log("有搜到服務單", response.data);
              // 有搜到服務單
              if (sessionStorage.getItem("lastSearchCaseParams") == params) {
                commit("updateAllCaseList", response.data);
              } else {
                console.log(
                  "[Exception] Store - searchAllCaseList: Catch the old request data, will ignore it."
                );
                return;
              }
            } else {
              // 搜不到服務單
              commit("updateAllCaseList", []);
            }
            // console.log("resolve data", response);
            setTimeout(() => {
              commit("SET_ISPROGRESS_MAINDATA", false);
            }, 500);
          }
          resolve(response);
        } catch (error) {
          console.log("error - searchAllCaseList", error);
          reject(error);
        }
      });
    },

    async getSearchResult(context, params) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Request.getSearchResult(params);

          if (response.status === 200) {
            context.commit("SET_KEYWORD_RESULT", response.data.result);
          }
          resolve(response);
        } catch (error) {
          console.log("error - searchKeyword", error);
          reject(error);
        }
      });
    },

    async getSearchResultBig(context, params) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Request.getSearchResultBig(params);
          if (response.status === 200) {
            context.commit("SET_KEYWORD_RESULT_BIG", response.data.result);
          }
          resolve(response);
        } catch (error) {
          console.log("error - searchKeywordBig", error);
          reject(error);
        }
      });
    },

    async getCaseDetailData(context, caseId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          console.log("getCaseDetailData - caseId:", caseId);
          // context.commit("SET_ISPROGRESS_PAGE", true);
          context.commit("CLEAR_DETAIL_DATA");
          const response = await Request.getCaseDetail(caseId);

          console.log("response - getCaseDetailData", response);
          if (response.status == 200) {
            // 針對時間格式的例外處理以避免錯誤
            if (response.data.FirstHackTime !== null) {
              response.data.FirstHackTime = moment(
                response.data.FirstHackTime
              ).format("YYYY-MM-DD");
            }
            if (response.data.OnsiteDate !== null) {
              response.data.OnsiteDate = moment(
                response.data.OnsiteDate
              ).format("YYYY-MM-DD");
            }

            context.commit("updateCaseDetailData", response.data);
            context.commit(
              "SET_OPTIONS_ASSETS_LIST",
              response.data.Workspace.Assets
            );
            // context.commit("SET_ISPROGRESS_PAGE", false);
          }
          resolve(response);
        } catch (error) {
          console.log("error - getCaseDetailData", error);
          reject(error);
        }
      });
    },

    async getCaseDetailDataSilent(context, caseId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          console.log("getCaseDetailData - caseId:", caseId);
          // context.commit("CLEAR_DETAIL_DATA");
          const response = await Request.getCaseDetail(caseId);

          console.log("response - getCaseDetailData", response);
          if (response.status == 200) {
            // 針對時間格式的例外處理以避免錯誤
            if (response.data.FirstHackTime !== null) {
              response.data.FirstHackTime = moment(
                response.data.FirstHackTime
              ).format("YYYY-MM-DD");
            }

            context.commit("updateCaseDetailData", response.data);
            context.commit(
              "SET_OPTIONS_ASSETS_LIST",
              response.data.Workspace.Assets
            );
          }
          resolve(response);
        } catch (error) {
          console.log("error - getCaseDetailData", error);
          reject(error);
        }
      });
    },

    async getAllCustomerData(context) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getAllCustomerData();
          context.commit("updateAllCustomerData", response.data);
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getShiftMemberList(context) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getShiftMemberList();
          console.log("repsponse - getShiftMemberList", response);
          if (response.data.length) {
            context.commit("updateShiftMemberListData", response.data);
            context.commit("updateManagerWhiteListData", response.data);
          }
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getSalesDataList(context) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getSalesDataList();
          if (response.data.length) {
            context.commit("updateSalesNameListData", response.data);
          }
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getAllIndustryListData(context) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getAllIndustryListData();
          if (response.data.length) {
            context.commit("updateAllIndustryListData", response.data);
          }
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getAptOpsFormOptions(context) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getAptOpsFormOptions();
          console.log("getAptOpsFormOptions response", response);
          if (response.status == 200) {
            context.commit("SET_OPTIONS_APT_FORM", response.data);
          }
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Get eventlog channels data from BigQuery
    async getChannelList(context, params) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Request.getChannelList(params);
          if (response.status === 200) {
            context.commit("SET_CHANNEL_LIST", response.data);
          }
          resolve(response);
        } catch (error) {
          console.log("error - searchKeyword", error);
          reject(error);
        }
      });
    },

    async getSrCaseStgDetail(context, caseId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getSrCaseStgDetail(caseId);
          console.log("Action - getSrCaseStgDetail response", response);
          // if (response.status == 200) {
          //   // context.commit("SET_OPTIONS_APT_FORM", response.data);
          //   console.log("getSrCaseStgDetail response", response);
          // }
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getSrCaseProdDetail(context, caseId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          const response = await Request.getSrCaseProdDetail(caseId);
          console.log("getSrCaseProdDetail response", response);
          // if (response.status == 200) {
          //   // context.commit("SET_OPTIONS_APT_FORM", response.data);
          //   console.log("getSrCaseProdDetail response", response);
          // }
          // context.commit("SET_ISPROGRESS_PAGE", false);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postSetCaseState(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postSetCaseState", source);
          const response = await Request.postSetCaseState(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postSetCaseState", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postSetCaseStateBatch(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postSetCaseStateBatch", source);
          const response = await Request.postSetCaseStateBatch(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postSetCaseStateBatch", response);
          resolve(response);
        } catch (error) {
          console.log("error - postSetCaseStateBatch", error);
          reject(error);
        }
      });
    },

    async postCreateCase(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postCreateCase", source);
          // const response = [];
          const response = await Request.postCreateCase(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postCreateCase", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postCreateCompany(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postCreateCompany", source);
          // const response = [];
          const response = await Request.postCreateCompany(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postCreateCompany", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postCreateEngineer(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postCreateEngineer", source);
          // const response = [];
          const response = await Request.postCreateEngineer(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postCreateEngineer", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postCreateAsset(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postCreateAsset", source);
          // const response = [];
          const response = await Request.postCreateAsset(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postCreateAsset", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postSend2AptOpsStaging(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postSend2AptOpsStaging", source);
          // const response = [];
          const response = await Request.postSend2AptOpsStaging(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postSend2AptOpsStaging", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postSend2AptOpsProduction(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postSend2AptOpsProduction", source);
          // const response = [];
          const response = await Request.postSend2AptOpsProduction(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postSend2AptOpsProduction", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postReSendAptOpsStaging(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postReSendAptOpsStaging", source);
          // const response = [];
          const response = await Request.postReSendAptOpsStaging(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postReSendAptOpsStaging", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postReSendAptOpsProduction(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postReSendAptOpsProduction", source);
          // const response = [];
          const response = await Request.postReSendAptOpsStaging(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postReSendAptOpsProduction", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async putUpdateCase(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - putUpdateCase", source);
          // const response = [];
          const response = await Request.putUpdateCase(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - putUpdateCase", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async putUpdateCaseState(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - putUpdateCaseState", source);
          // const response = [];
          const response = await Request.putUpdateCaseState(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - putUpdateCaseState", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getCollectFilesData(context, caseId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          console.log("getCollectFilesData - caseId:", caseId);
          const response = await Request.getCollectFilesData(caseId);
          console.log("response - getCollectFilesData", response);
          context.commit("MUTATE_COLLECT_FILES_DATA", response.data);
          if (response.status == 200) {
            console.log("Success - getCollectFilesData");
          } else {
            console.log("Error - getCollectFilesData");
          }
          resolve(response);
        } catch (error) {
          console.log("error - getCollectFilesData", error);
          reject(error);
        }
      });
    },

    async postUpdateFileStatusBatch(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          console.log("source - putUpdateFileStatus", source);
          const response = await Request.postUpdateFileStatusBatch(source);
          console.log("response - putUpdateFileStatus", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async putUpdateAssetData(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - putUpdateAssetData", source);
          // const response = [];
          const response = await Request.putUpdateAssetData(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - putUpdateAssetData", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async deleteAssetData(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - deleteAssetData", source);
          const response = await Request.deleteAssetData(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - deleteAssetData", response);
          resolve(response);
        } catch (error) {
          console.log("error - deleteAssetData", error);
          reject(error);
        }
      });
    },

    async putUpdateFileData(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - putUpdateFileData", source);
          // const response = [];
          const response = await Request.putUpdateFileData(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - putUpdateFileData", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postAlertComment(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postAlertComment", source);
          const response = await Request.postAlertComment(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postAlertComment", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async updateAlertComment(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - updateAlertComment", source);
          const response = await Request.updateAlertComment(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - updateAlertComment", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async deleteAlertComment(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - deleteAlertComment", source);
          const response = await Request.deleteAlertComment(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - deleteAlertComment", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async postSaveEditedDraft(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postSaveEditedDraft", source);
          const response = await Request.postSaveEditedDraft(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postSaveEditedDraft", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async postCheckIfIsUploadedBefore(content, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          // console.log("source - postCheckIfIsUploadedBefore", source);
          const response = await Request.postCheckIfIsUploadedBefore(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          // console.log("response - postCheckIfIsUploadedBefore", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async postGetSignedURL(content, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postGetSignedURL", source);
          const response = await Request.postGetSignedURL(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postGetSignedURL", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    // MITRE Tags
    async getAllMitreReferenceData(context) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Request.getAllMitreReferenceData();
          context.commit("MUTATE_MITRE_REFERENCE_DATA", response.data);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getCurrentCaseMitreTagsData(context, caseId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await Request.getCurrentCaseMitreTagsData(caseId);
          context.commit("MUTATE_CASE_MITRE_TAGS", response.data);
          context.commit("MUTATE_MITRE_AUTOCOMPLETE_DATA", response.data);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async postAddMitreData(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postAddMitreData", source);
          // const response = [];
          const response = await Request.postAddMitreData(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postAddMitreData", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async postUpdateCaseMitreTagData(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - postUpdateCaseMitreTagData", source);
          // const response = [];
          const response = await Request.postUpdateCaseMitreTagData(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - postUpdateCaseMitreTagData", response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async deleteCaseMitreTagData(context, source) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // context.commit("SET_ISPROGRESS_PAGE", true);
          console.log("source - deleteCaseMitreTagData", source);
          const response = await Request.deleteCaseMitreTagData(source);
          // const response = "test";
          // context.commit("SET_ISPROGRESS_PAGE", false);
          console.log("response - deleteCaseMitreTagData", response);
          resolve(response);
        } catch (error) {
          console.log("error - deleteCaseMitreTagData", error);
          reject(error);
        }
      });
    },

    // Setting loading icon
    handleUpdateFileStatus({ commit }, array) {
      commit("UPDATE_FILE_STATUS", array);
    },
    handleClearDetailDate({ commit }) {
      console.log("Store - handleClearDetailDate");
      commit("CLEAR_DETAIL_DATA");
    },
    handlePageLoadingState({ commit }, boolean) {
      console.log("Store - handlePageLoadingState boolean", boolean);
      commit("SET_ISPROGRESS_PAGE", boolean);
    },
    handleLoginLoadingState({ commit }, boolean) {
      console.log("Store - handleLoginLoadingState boolean", boolean);
      commit("SET_ISPROGRESS_LOGIN", boolean);
    },
    handleSubmitLoadingState({ commit }, boolean) {
      console.log("Store - handleSubmitLoadingState boolean", boolean);
      commit("SET_ISPROGRESS_SUBMIT", boolean);
    },
    handleKeywordSearchLoadingState({ commit }, boolean) {
      console.log("Store - handleKeywordSearchLoadingState boolean", boolean);
      commit("SET_ISPROGRESS_KEYWORDSEARCH", boolean);
    },
    handlePopupLoadingState({ commit }, boolean) {
      console.log("Store - handlePopupLoadingState boolean", boolean);
      commit("SET_ISPROGRESS_POPUP", boolean);
    },
    handlePartialDataLoadingState({ commit }, boolean) {
      console.log("Store - hadlePartialDataLoadingState boolean", boolean);
      commit("SET_ISPROGRESS_MAINDATA", boolean);
    },
    handleReloadRequestState({ commit }, boolean) {
      console.log("Store - handleReloadRequestState boolean", boolean);
      commit("SET_RELOAD_REQUEST", boolean);
    },
  },
  modules: {},
});
