import { form_config } from "./form.config";
/* eslint-disable no-undef */
function whatCaseProgress(state) {
  if (state === 0) {
    return "案件準備";
  }
  if (state === 1) {
    return "案件取消";
  }
  if (state === 2) {
    return "案件進行";
  }
  if (state === 3) {
    return "案件完成";
  }
  if (!state) {
    return "案件準備";
  }
}

function whatCaseProgressEng(state) {
  if (state === 0) {
    return "preparing";
  }
  if (state === 2) {
    return "inprogress";
  }
  if (state === 1) {
    return "cancel";
  }
  if (state === 3) {
    return "closed";
  }
  if (!state) {
    return "preparing";
  }
}

function whatCaseFontColor4Progress(state) {
  if (state === 0) {
    return "text-preparing";
  }
  if (state === 2) {
    return "text-inprogress";
  }
  if (state === 1) {
    return "text-cancel";
  }
  if (state === 3) {
    return "text-closed";
  }
  if (!state) {
    return "text-preparing";
  }
}

function whatCaseBackgrounfColor4Progress(state) {
  if (state === 0) {
    return "text-bg-preparing";
  }
  if (state === 2) {
    return "text-bg-inprogress";
  }
  if (state === 1) {
    return "text-bg-cancel";
  }
  if (state === 3) {
    return "text-bg-closed";
  }
  if (!state) {
    return "text-bg-preparing";
  }
}

function whatIconClassName4Progress(state) {
  if (state === 0) {
    return "bi bi-list-task";
  }
  if (state === 2) {
    return "bi bi-wrench-adjustable";
  }
  if (state === 1) {
    return "bi bi-x";
  }
  if (!state) {
    return "bi bi-list-task";
  }
}

function whatCaseIssueType(state) {
  if (state === 1) {
    return "健診";
  }
  if (state === 2) {
    return "事故處理";
  }
  if (state === 0) {
    return "其他";
  }
  if (!state) {
    return "未指定";
  }
}

function whatCaseAttackType(state) {
  if (state === 0) {
    return "APT";
  }
  if (state === 1) {
    return "Cyber Crime";
  }
  if (state === 2) {
    return "Target Ransom";
  }
  if (state === 3) {
    return "Others";
  }
  if (!state) {
    return "未指定";
  }
}

function whatCaseSource(state) {
  if (state === 1) {
    return "業務建案";
  }
  if (state === 2) {
    return "MxDR 建案";
  }
  if (state === 3) {
    return "IR 人員建案";
  }
  if (!state) {
    return "未指定";
  }
}

function whatNameTitleDisplay(state) {
  if (state === 0) {
    return "先生";
  }
  if (state === 1) {
    return "小姐";
  }
  if (state === 2) {
    return "公司";
  }
  if (state === 3) {
    return "其他";
  }
  if (!state) {
    return "貴賓";
  }
}

function whatIconClassName4ProgressInEventTrack(state) {
  if (state === 0) {
    return "bi bi-list-task";
  }
  if (state === 2) {
    return "bi bi-wrench-adjustable-circle";
  }
  if (state === 1) {
    return "bi bi-x-circle";
  }
  if (state === 3) {
    return "bi bi-shield-check";
  }
  if (!state) {
    return "bi bi-list-task";
  }
}

function whatWindowsEventLogLevel(level) {
  if (level === "0") {
    return "資訊";
  }
  if (level === "1") {
    return "重大";
  }
  if (level === "2") {
    return "錯誤";
  }
  if (level === "3") {
    return "警告";
  }
  if (level === "4") {
    return "資訊";
  }
  if (level === "5") {
    return "稽核成功";
  }
  if (level === "6") {
    return "稽核失敗";
  }
  if (level === "7") {
    return "詳細";
  }
  if (!level) {
    return "資訊";
  }
}

function whatCaseState(state) {
  if (state === "unconfirmed") {
    return "待確認";
  }
  if (state === "confirmed") {
    return "已確認";
  }
  if (state === "finished") {
    return "案件完成";
  }
  if (state === "established") {
    return "案件處理中";
  }
  if (state === "closed") {
    return "已結案";
  }
  if (state === "cancel") {
    return "已取消";
  }
  if (!state) {
    return "待確認";
  }
}

function whatStateDisplay(state) {
  if (state === 0) {
    return "unconfirmed";
  }
  if (state === 1) {
    return "cancel";
  }
  if (state === 2) {
    return "established";
  }
  if (state === 3) {
    return "closed";
  }
  if (!state) {
    return "unconfirmed";
  }
}

function whatSeverityDisplay(level) {
  if (level === "low") {
    return "低";
  }
  if (level === "medium") {
    return "中";
  }
  if (level === "high") {
    return "高";
  }
  if (level === "critical") {
    return "危險";
  }
  if (!level) {
    return "不明";
  }
}

function whatSeverityDisplayWithSortingNumber(level) {
  if (level === "low") {
    return "0 低";
  }
  if (level === "medium") {
    return "1. 中";
  }
  if (level === "high") {
    return "2 高";
  }
  if (level === "critical") {
    return "3 危險";
  }
  if (!level) {
    return "不明";
  }
}

function whatSeverityDisplayWithEnglish(level) {
  if (level === "low") {
    return "低 (Low)";
  }
  if (level === "medium") {
    return "中 (Medium)";
  }
  if (level === "high") {
    return "高 (High)";
  }
  if (level === "critical") {
    return "危險 (Critical)";
  }
  if (!level) {
    return "不明";
  }
}

function whatSeverityClassName4Triangle(level) {
  if (level === "low") {
    return "triangle--low";
  }
  if (level === "medium") {
    return "triangle--medium";
  }
  if (level === "high") {
    return "triangle--high";
  }
  if (level === "critical") {
    return "triangle--danger";
  }
  if (!level) {
    return "";
  }
}

function whatPriorityClassName4Background(priority) {
  if (priority === 0) {
    return "bg--red";
  }
  if (priority === 1) {
    return "bg--orange";
  }
  if (priority === 2) {
    return "bg--yellow";
  }
  if (priority === 3) {
    return "";
  }
  if (!priority) {
    return "";
  }
}

function whatPriorityName4TextDisplay(priority) {
  if (priority === 0) {
    return "P0";
  }
  if (priority === 1) {
    return "P1";
  }
  if (priority === 2) {
    return "P2";
  }
  if (priority === 3) {
    return "P3";
  }
  if (!priority) {
    return "";
  }
}

function whatEventNameDisplay(eventName, state) {
  if (eventName === "initial assigment") {
    return "系統指派";
  }
  if (eventName === "draft edit") {
    return "儲存草稿";
  }
  if (eventName === "case created") {
    return "建立案件";
  }
  if (eventName === "assignee changed") {
    return "處理人員異動";
  }
  if (eventName === "reopen case") {
    return "Reopen 案件";
  }
  if (state === 1) {
    return "取消案件";
  }
  if (!eventName && state === 0) {
    return "處理人員異動";
  }
  if (!eventName && state === 1) {
    return "取消案件";
  }
  if (!eventName && state === 2) {
    return "案件建立";
  }
  if (!eventName) {
    return "(不明)";
  }
}

function whatAssetUploadStatusDisplay(status) {
  if (status === 0) {
    return "上傳中";
  }
  if (status === 1) {
    return "上傳成功, Unzipping...";
  }
  if (status === 2) {
    return "Unzip 成功, Log 分析中...";
  }
  if (status === 22) {
    return "Unzip 成功";
  }
  if (status === 3) {
    return "Log 分析成功";
  }
  if (status === -1) {
    return "上傳失敗";
  }
  if (status === -2) {
    return "Unzip 失敗";
  }
  if (status === -3) {
    return "Event logs 分析失敗";
  }
  if (!status) {
    return "狀況不明";
  }
}

function whatAssetUploadStatusClassName4Icon(status) {
  if (status === 0) {
    return "bi bi-exclamation-circle-fill text-warning";
  }
  if (status === 2 || status === 3 || status === 22) {
    return "bi bi-check-circle-fill text-success";
  }
  if (status === -1 || status === -2 || status === -3) {
    return "bi bi-exclamation-circle-fill text-danger";
  }
  if (!status) {
    return "bi bi-exclamation-circle-fill text-danger";
  }
}

function whatFieldNameItis(name) {
  if (name === "CustomerName") {
    return ` "客戶名稱"`;
  } else if (name === "MxdrCaseNumber") {
    return ` "通報單號"`;
  } else if (name == "LeadEngineerName") {
    return ` "案件負責人"`;
  } else if (name == "DisplayName") {
    return ` "英文姓名"`;
  } else if (name == "Email") {
    return ` "Email"`;
  } else if (name == "CustomerShortName") {
    return ` "客戶代號"`;
  } else if (name == "Industry") {
    return ` "產業類別"`;
  } else if (name == "SalesEnglishName") {
    return ` "業務"`;
  } else if (name == "ServerIp") {
    return ` "IP"`;
  } else if (name == "DomainName") {
    return ` "Domain Name"`;
  } else if (name == "HostName") {
    return ` "Host Name"`;
  } else if (name == "industry_id") {
    return ` "Industry"`;
  } else if (name == "possible_apt_reason") {
    return ` "Possible APT Reason"`;
  } else if (name == "company") {
    return ` "Victim Info"`;
  } else if (name == "email_cc_list") {
    return ` "Email CC"`;
  } else if (name == "stakeholder_list") {
    return ` "Stake Holders"`;
  } else if (name == "InfectedSourceIP") {
    return ` "來源 IP"`;
  } else if (name == "AccountName") {
    return ` "帳號"`;
  } else if (name == "Description") {
    return ` "入侵概況"`;
  } else {
    return name;
  }
}

const validation = {
  englishName: {
    reg: /^[A-Za-z_\s][A-Za-z._\s]*$/,
    errMessage: "限英文、空白、底線、點",
  },
  englishShortName: {
    reg: /^([A-Za-z])[A-Za-z0-9._-]{1,}$/,
    errMessage: "限英文及 '_' 或 '.'，不允許空白字元",
  },
  chineseName: {
    reg: /^([\u4E00-\u9FA5]+)$/,
    errMessage: "限中文字元",
  },
  mxdrCaseNumber: {
    reg: /^(?!\s+$)(?!null$).+/,
    errMessage: "MxDR 建案必填",
  },
  email: {
    reg: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    errMessage: "請檢查Email格式",
  },
  name: {
    reg: /^[\u4e00-\u9fa5a-zA-Z_.-\s]+$/,
    errMessage: "限中文及英文字元",
  },
  phoneNumber: {
    reg: /^[\d#-]+$/,
    errMessage: "請輸入數字",
  },
  ipAddress: {
    reg: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    errMessage: "請確認格式",
  },
  errorMessages: {
    required: "必填欄位",
  },
};

function mergeToCurrentForm(newForm, oldForm) {
  let currentForm = {};
  // 編輯表單的情況
  if (oldForm) {
    console.log("mergeToCurrentForm - oldForm", oldForm);
    currentForm = _.cloneDeep(oldForm); // 先把 oldForm 全部欄位copy一份過去

    Object.keys(form_config.field).forEach((item) => {
      // console.log(
      //   "mergeToCurrentForm (Old Form.form_config.field) - item: ",
      //   `${item} - ${index} `
      // );
      if (form_config.field[item].live) {
        if (oldForm[item] != undefined && oldForm[item] != null) {
          currentForm[item] = oldForm[item];
        } else if (newForm[item] != undefined && newForm[item] != null) {
          currentForm[item] = newForm[item];
        }
        // 如果 oldForm newForm 都沒有就別給value了
      }
    });
  }

  // 新建表單的情況，拿掉設定檔上沒有的
  if (!oldForm) {
    console.log("mergeToCurrentForm - newForm", newForm);
    currentForm = _.cloneDeep(newForm);

    Object.keys(form_config.field).forEach((item, index) => {
      console.log(
        "mergeToCurrentForm (New Form.form_config.field) - item: ",
        `${item} - ${index} `
      );
      // if (!form_config.field[item].live) {
      //   // 沒有的東西不該給後端
      //   delete currentForm[item];
      // }
    });
  }

  console.log("mergeToCurrentForm currentForm", currentForm);
  return currentForm;
}

export default {
  whatCaseProgress,
  whatCaseProgressEng,
  whatCaseIssueType,
  whatCaseAttackType,
  whatCaseSource,
  whatCaseState,
  whatCaseFontColor4Progress,
  whatCaseBackgrounfColor4Progress,
  whatStateDisplay,
  whatSeverityDisplay,
  whatSeverityDisplayWithEnglish,
  whatSeverityDisplayWithSortingNumber,
  whatSeverityClassName4Triangle,
  whatPriorityClassName4Background,
  whatIconClassName4Progress,
  whatIconClassName4ProgressInEventTrack,
  whatPriorityName4TextDisplay,
  whatEventNameDisplay,
  whatNameTitleDisplay,
  whatWindowsEventLogLevel,
  whatAssetUploadStatusDisplay,
  whatAssetUploadStatusClassName4Icon,
  mergeToCurrentForm,
  whatFieldNameItis,
  validation,
};
